import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { HiChevronDown, HiChevronUp } from 'react-icons/hi'
import { LuGamepad2 } from 'react-icons/lu'
import { GiHamburgerMenu, GiPodiumWinner } from 'react-icons/gi'
import { MdHistoryEdu, MdNotificationsNone } from 'react-icons/md'
import { TfiWallet } from 'react-icons/tfi'
import { PiDotFill } from 'react-icons/pi'
import { TbBuildingWarehouse, TbSettingsUp } from 'react-icons/tb'
import { FaUserShield } from 'react-icons/fa6'
import { FaUsers, FaHome } from 'react-icons/fa'

function Sidebar({ SidebarJSON, navbarOpen, setNavbarOpen }) {
  const location = useLocation();
  const navigate = useNavigate();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      if (window.innerWidth >= 1024) {
        setNavbarOpen(true);
      } else {
        setNavbarOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [setNavbarOpen]);

  const onClickMenu = (url) => {
    navigate(url);
    if (windowWidth < 1024) {
      setNavbarOpen(false);
    }
  };

  const handleNavbar = () => {
    setNavbarOpen(!navbarOpen);
  };

  let [localStoragedata, setlocalStoragedata] = useState();
  useEffect(() => {
    const stateData = window.sessionStorage.getItem("adminuser");
    setlocalStoragedata(JSON.parse(stateData));
  }, []);

  const [showSection, setShowSection] = React.useState('')
  useEffect(() => {
    const stateData = window.sessionStorage.getItem("adminuser");
    setlocalStoragedata(JSON.parse(stateData));
  }, []);

  return (
    <>
      <button
        className={` ${navbarOpen ? "flex" : "flex"} absolute top-0 left-0 px-5 items-center justify-center text-gray-500 focus:outline-none cursor-pointer z-50 h-16`}
        onClick={handleNavbar}>
        <svg
          className="h-6 w-6 text-white"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M4 6h16M4 12h16M4 18h7"
          ></path>
        </svg>
      </button>
      <div
        className={`${navbarOpen ? "flex" : "hidden"
          } fixed left-0 inset-y-0 w-64 z-50 flex-col`}
      >
        <div className="flex flex-col w-64 relative flex-1 h-full max-h-full">
          <div className="cursor-pointer relative flex justify-between items-center bg-[#911212] w-full z-50 h-14">
            <h1 className="lg:text-xl text-lg font-bold text-white my-2 lg:mx-8 mx-6">K - KING Admin</h1>
            <GiHamburgerMenu
              onClick={handleNavbar}
              className="text-white text-xl block mr-2"
            />
          </div>
          <nav className="overflow-y-auto bgcolor flex flex-col flex-wrap items-center justify-between relative w-64 z-10 h-full overflow-auto zilla-slab-medium">
            <div className="px-0 flex flex-col flex-wrap pt-2 items-center w-full mx-auto flex-1">
              <div className="relative z-40 flex-1 w-full space-y-1">
                <div>
                  <span
                    onClick={() => onClickMenu("/app/dashboard")}
                    className={`cursor-pointer h-8 flex items-center space-x-4 px-4 py-1 my-1 text-sm transition ease-in-out duration-150 ${location.pathname === "/app/dashboard"
                      ? 'text-white bg-black/70 font-semibold'
                      : 'text-white font-bold hover:bg-white/70 hover:text-black'
                      }`}>
                    <FaHome size={18} />
                    <span className="whitespace-nowrap">Dashboard</span>
                  </span>

                  {localStoragedata && localStoragedata.roleId === 0 && (
                    <span
                      onClick={() => onClickMenu('/app/winnerList')}
                      className={`cursor-pointer h-8 flex items-center space-x-4 px-4 py-1 my-1 text-sm transition ease-in-out duration-150
                      ${location.pathname === '/app/winnerList'
                          ? 'text-white bg-black/70 font-semibold'
                          : 'text-white font-bold hover:bg-white/70 hover:text-black'}`}>
                      <span>
                        <GiPodiumWinner size={18} className="hover:text-gray-800" />
                      </span>
                      <span className="whitespace-nowrap">Winner List</span>
                    </span>
                  )}

                  {localStoragedata && localStoragedata.roleId === 0 && (
                    <span
                      onClick={() => onClickMenu('/app/downsideUser')}
                      className={`cursor-pointer h-8 flex items-center space-x-4 px-4 py-1 my-1 text-sm transition ease-in-out duration-150    ${location.pathname === '/app/downsideUser'
                        ? 'text-white bg-black/70 font-semibold'
                        : 'text-white font-bold hover:bg-white/70 hover:text-black'
                        }`}
                    >
                      <span className='group texthover'>
                        <FaUserShield size={18} />
                      </span>
                      {localStoragedata && localStoragedata.roleId === 0 && (
                        <span className='whitespace-nowrap'>Agent</span>
                      )}
                      {localStoragedata && localStoragedata.roleId === 1 && (
                        <span className='whitespace-nowrap'>SUPER MASTER</span>
                      )}
                      {localStoragedata && localStoragedata.roleId === 2 && (
                        <span className='whitespace-nowrap'>MASTER</span>
                      )}
                      {localStoragedata && localStoragedata.roleId === 3 && (
                        <span className='whitespace-nowrap'>AGENT</span>
                      )}
                      {localStoragedata && localStoragedata.roleId === 4 && (
                        <span className='whitespace-nowrap'>OPERATOR</span>
                      )}
                      {localStoragedata && localStoragedata.roleId === 20 && (
                        <span className='whitespace-nowrap'>USER</span>
                      )}
                    </span>
                  )}

                  {localStoragedata && localStoragedata.roleId === 0 && (
                    <span
                      onClick={() => onClickMenu('/app/userofSuperadmin')}
                      className={`cursor-pointer h-8 flex items-center space-x-4 px-4 py-1 my-1 text-sm transition ease-in-out duration-150 font-semibold   ${location.pathname === '/app/userofSuperadmin'
                        ? 'text-white bg-black/70 font-semibold'
                        : 'text-white font-bold hover:bg-white/70 hover:text-black'
                        }`}
                    >
                      <span className='group-hover:text-[#D8BD66]'>
                        <FaUsers size={18} />
                      </span>
                      <span className='whitespace-nowrap'>User Management</span>
                    </span>
                  )}

                  {/* <span
                    onClick={() => onClickMenu('/app/user-profit-loss')}
                    className={`cursor-pointer h-8 flex items-center space-x-4 px-4 py-1 my-1 text-sm transition ease-in-out duration-150  ${location.pathname === '/app/user-profit-loss'
                      ? 'text-white bg-black/70 font-semibold'
                      : 'text-white font-bold hover:bg-white/70 hover:text-black'
                      }`}>
                    <MdHistoryEdu size={18} />{' '}
                    <span className='whitespace-nowrap'>User Profit Loss </span>
                  </span> */}

                  {localStoragedata && localStoragedata.roleId === 0 && (
                    <span
                      onClick={() => onClickMenu('/app/notification')}
                      className={`cursor-pointer h-8 flex items-center space-x-4 px-4 py-1 my-1 text-sm transition ease-in-out duration-150   ${location.pathname === '/app/notification'
                        ? 'text-white bg-black/70 font-semibold'
                        : 'text-white font-bold hover:bg-white/70 hover:text-black'
                        }`}
                    >
                      <span className='group-hover:text-[]'>
                        <MdNotificationsNone size={18} />
                      </span>
                      <span className='whitespace-nowrap'>
                        Notification Management
                      </span>
                    </span>
                  )}

                  {localStoragedata && localStoragedata.roleId === 0 && (
                    <div className="cursor-pointer">
                      {showSection === 'settingManagement' ? (
                        <span
                          onClick={() => setShowSection('')}
                          className="flex justify-between items-center px-4 py-1 my-1 w-full text-sm text-white font-semibold transition duration-150 ease-in-out cursor-pointer h-8 hover:bg-gray-100 hover:text-black"
                        >
                          <div className="flex space-x-4 items-center">
                            <TbSettingsUp size={18} className="hover:text-black" />
                            <span className="whitespace-nowrap">Setting Management</span>
                          </div>
                          <HiChevronUp className="h-5 w-5 hover:text-black" />
                        </span>
                      ) : (
                        <span
                          onClick={() => setShowSection('settingManagement')}
                          className="flex justify-between items-center px-4 py-1 my-1 w-full text-sm text-white font-semibold transition duration-150 ease-in-out cursor-pointer h-8 hover:bg-gray-100 hover:text-black"
                        >
                          <div className="flex space-x-4 items-center">
                            <TbSettingsUp size={18} className="hover:text-black" />
                            <span className="whitespace-nowrap">Setting Management</span>
                          </div>
                          <HiChevronDown className="h-5 w-5 hover:text-black" />
                        </span>
                      )}
                      {showSection === 'settingManagement' && (
                        <div className="flex flex-col">
                          <span
                            onClick={() => onClickMenu('/app/appsetting')}
                            className={`cursor-pointer h-8 flex items-center space-x-4 px-4 py-1 my-1 text-sm transition ease-in-out duration-150 ${location.pathname === '/app/appsetting'
                              ? 'text-white bg-black/70 font-semibold'
                              : 'text-white font-bold hover:bg-white/70 hover:text-black'
                              }`}
                          >
                            <PiDotFill className="w-6 h-6" />
                            <span className="whitespace-nowrap">App Setting</span>
                          </span>
                          <span
                            onClick={() => onClickMenu('/app/slider')}
                            className={`cursor-pointer h-8 flex items-center space-x-4 px-4 py-1 my-1 text-sm transition ease-in-out duration-150 ${location.pathname === '/app/slider'
                              ? 'text-white bg-black/70 font-semibold'
                              : 'text-white font-bold hover:bg-white/70 hover:text-black'
                              }`}
                          >
                            <PiDotFill className="w-6 h-6" />
                            <span className="whitespace-nowrap">Slider Setting</span>
                          </span>
                        </div>
                      )}
                    </div>
                  )}
                </div>

                {localStoragedata && localStoragedata.roleId === 0 && (
                  <div className='cursor-pointer group'>
                    {showSection === 'game' ? (
                      <span
                        onClick={() => setShowSection('')}
                        className="flex justify-between items-center px-4 py-1 my-1 w-full text-sm text-white font-semibold transition duration-150 
                        ease-in-out cursor-pointer h-8 hover:bg-gray-100 hover:text-black"
                      >
                        <div className="flex space-x-4 items-center">
                          <span>
                            <LuGamepad2 size={18} className="hover:text-black" />
                          </span>
                          <span className="whitespace-nowrap">Game Management</span>
                        </div>
                        <HiChevronUp className="h-5 w-5 hover:text-black" />
                      </span>
                    ) : (
                      <span
                        onClick={() => setShowSection('game')}
                        className="flex justify-between items-center px-4 py-1 my-1 w-full text-sm text-white font-semibold transition duration-150 
                        ease-in-out cursor-pointer h-8 hover:bg-gray-100 hover:text-black"
                      >
                        <div className="flex space-x-4 items-center">
                          <span>
                            <LuGamepad2 size={18} className="hover:text-black" />
                          </span>
                          <span className="whitespace-nowrap">Game Management</span>
                        </div>
                        <HiChevronDown className="h-5 w-5 hover:text-black" />
                      </span>
                    )}

                    {showSection === 'game' ? (
                      <div className='flex flex-col'>
                        <span
                          onClick={() => onClickMenu('/app/game')}
                          className={`cursor-pointer h-8 flex items-center space-x-4 px-4 py-1 my-1 text-sm transition ease-in-out duration-150  ${location.pathname === '/app/game'
                            ? 'text-white bg-black/70 font-semibold'
                            : 'text-white font-bold hover:bg-white/70 hover:text-black'
                            }`}
                        >
                          <PiDotFill className='w-6 h-6' />{' '}
                          <span className='text-sm whitespace-nowrap'>
                            Game{' '}
                          </span>
                        </span>

                        <span
                          onClick={() => onClickMenu('/app/market')}
                          className={`cursor-pointer h-8 flex items-center space-x-4 px-4 py-1 my-1 text-sm transition ease-in-out duration-150  ${location.pathname === '/app/market'
                            ? 'text-white bg-black/70 font-semibold'
                            : 'text-white font-bold hover:bg-white/70 hover:text-black'
                            }`}
                        >
                          <PiDotFill className='w-6 h-6' />{' '}
                          <span className='text-sm whitespace-nowrap'>
                            Market Management
                          </span>
                        </span>

                        <span
                          onClick={() => onClickMenu('/app/declareResult')}
                          className={`cursor-pointer h-8 flex items-center space-x-4 px-4 py-1 my-1 text-sm transition ease-in-out duration-150  ${location.pathname === '/app/declareResult'
                            ? 'text-white bg-black/70 font-semibold'
                            : 'text-white font-bold hover:bg-white/70 hover:text-black'
                            }`}
                        >
                          <PiDotFill className='w-6 h-6' />{' '}
                          <span className='text-sm whitespace-nowrap'>
                            Game Result
                          </span>
                        </span>

                        <span
                          onClick={() => onClickMenu('/app/userbidhistory')}
                          className={`cursor-pointer h-8 flex items-center space-x-4 px-4 py-1 my-1 text-sm transition ease-in-out duration-150  ${location.pathname === '/app/userbidhistory'
                            ? 'text-white bg-black/70 font-semibold'
                            : 'text-white font-bold hover:bg-white/70 hover:text-black'
                            }`}
                        >
                          <PiDotFill className='w-6 h-6' />{' '}
                          <span className='text-sm whitespace-nowrap'>
                            User Bet History{' '}
                          </span>
                        </span>

                        <span
                          onClick={() => onClickMenu('/app/placebet')}
                          className={`cursor-pointer h-8 flex items-center space-x-4 px-4 py-1 my-1 text-sm transition ease-in-out duration-150  ${location.pathname === '/app/placebet'
                            ? 'text-white bg-black/70 font-semibold'
                            : 'text-white font-bold hover:bg-white/70 hover:text-black'
                            }`}
                        >
                          <PiDotFill className='w-6 h-6' />{' '}
                          <span className='text-sm whitespace-nowrap'>
                            Place Bet{' '}
                          </span>
                        </span>

                        <span
                          onClick={() => onClickMenu('/app/betNumber')}
                          className={`cursor-pointer h-8 flex items-center space-x-4 px-4 py-1 my-1 text-sm transition ease-in-out duration-150  ${location.pathname === '/app/betNumber'
                            ? 'text-white bg-black/70 font-semibold'
                            : 'text-white font-bold hover:bg-white/70 hover:text-black'
                            }`}
                        >
                          <PiDotFill className='w-6 h-6' />{' '}
                          <span className='text-sm whitespace-nowrap'>
                            Games Chart{' '}
                          </span>
                        </span>

                        <span
                          onClick={() => onClickMenu('/app/game-profit-loss')}
                          className={`cursor-pointer h-8 flex items-center space-x-4 px-4 py-1 my-1 text-sm transition ease-in-out duration-150  ${location.pathname === '/app/game-profit-loss'
                            ? 'text-white bg-black/70 font-semibold'
                            : 'text-white font-bold hover:bg-white/70 hover:text-black'
                            }`}
                        >
                          <PiDotFill className='w-6 h-6' />{' '}
                          <span className='text-sm whitespace-nowrap'>
                            Games Profit Loss{' '}
                          </span>
                        </span>
                      </div>
                    ) : null}
                  </div>
                )}

                {localStoragedata && localStoragedata.roleId === 0 && (
                  <div className='cursor-pointer group'>
                    {showSection === 'karachi-game' ? (
                      <span
                        onClick={() => setShowSection('')}
                        className='flex justify-between items-center px-4 py-1 my-1 w-full text-sm text-white font-semibold transition duration-150 ease-in-out cursor-pointer h-8 texthover textwhite'
                      >
                        <div className='flex space-x-4 items-center'>
                          <span className='group texthover'>
                            <LuGamepad2 size={18} />
                          </span>
                          <span className='whitespace-nowrap'>
                            Karachi Game Mgmt
                          </span>
                        </div>
                        {showSection === 'karachi-game' ? (
                          <HiChevronUp className='group texthover' />
                        ) : (
                          <HiChevronDown className='group texthover' />
                        )}
                      </span>
                    ) : (
                      <span
                        onClick={() => setShowSection('karachi-game')}
                        className='flex justify-between items-center px-4 py-1 my-1 w-full text-sm text-white font-semibold transition duration-150 ease-in-out cursor-pointer h-8 texthover textwhite'
                      >
                        <div className='flex space-x-4 items-center'>
                          <span className='group texthover'>
                            <LuGamepad2 size={18} />{' '}
                          </span>
                          <span className='whitespace-nowrap'>
                            Karachi Game Mgmt
                          </span>
                        </div>
                        {showSection === 'karachi-game' ? (
                          <HiChevronUp className='group texthover' />
                        ) : (
                          <HiChevronDown className='group texthover' />
                        )}
                      </span>
                    )}
                    {showSection === 'karachi-game' ? (
                      <div className='flex flex-col'>
                        <span
                          onClick={() => onClickMenu('/app/karachi-game')}
                          className={`cursor-pointer h-8 flex items-center space-x-4 px-4 py-1 my-1 text-sm transition ease-in-out duration-150  ${location.pathname === '/app/karachi-game'
                            ? 'text-white bg-black/70 font-semibold'
                            : 'text-white font-bold hover:bg-white/70 hover:text-black'
                            }`}
                        >
                          <PiDotFill className='w-6 h-6' />{' '}
                          <span className='text-sm whitespace-nowrap'>
                            Karachi Game{' '}
                          </span>
                        </span>

                        <span
                          onClick={() => onClickMenu('/app/karachi-Management')}
                          className={`cursor-pointer h-8 flex items-center space-x-4 px-4 py-1 my-1 text-sm transition ease-in-out duration-150  ${location.pathname === '/app/karachi-Management'
                            ? 'text-white bg-black/70 font-semibold'
                            : 'text-white font-bold hover:bg-white/70 hover:text-black'
                            }`}
                        >
                          <PiDotFill className='w-6 h-6' />{' '}
                          <span className='text-sm whitespace-nowrap'>
                            Karachi Market Mgmt
                          </span>
                        </span>

                        <span
                          onClick={() =>
                            onClickMenu('/app/karachi-declare-result')
                          }
                          className={`cursor-pointer h-8 flex items-center space-x-4 px-4 py-1 my-1 text-sm transition ease-in-out duration-150  ${location.pathname === '/app/karachi-declare-result'
                            ? 'text-white bg-black/70 font-semibold'
                            : 'text-white font-bold hover:bg-white/70 hover:text-black'
                            }`}
                        >
                          <PiDotFill className='w-6 h-6' />{' '}
                          <span className='text-sm whitespace-nowrap'>
                            Karachi Game Result
                          </span>
                        </span>

                        <span
                          onClick={() =>
                            onClickMenu('/app/user-karachi-bid-history')
                          }
                          className={`cursor-pointer h-8 flex items-center space-x-4 px-4 py-1 my-1 text-sm transition ease-in-out duration-150  ${location.pathname ===
                            '/app/user-karachi-bid-history'
                            ? 'text-white bg-black/70 font-semibold'
                            : 'text-white font-bold hover:bg-white/70 hover:text-black'
                            }`}
                        >
                          <PiDotFill className='w-6 h-6' />{' '}
                          <span className='text-sm whitespace-nowrap'>
                            Karachi User Bet History{' '}
                          </span>
                        </span>
                        <span
                          onClick={() => onClickMenu('/app/KarachiPlaceBet')}
                          className={`cursor-pointer h-8 flex items-center space-x-4 px-4 py-1 my-1 text-sm transition ease-in-out duration-150  ${location.pathname === '/app/KarachiPlaceBet'
                            ? 'text-white bg-black/70 font-semibold'
                            : 'text-white font-bold hover:bg-white/70 hover:text-black'
                            }`}
                        >
                          <PiDotFill className='w-6 h-6' />{' '}
                          <span className='text-sm whitespace-nowrap'>
                            Karachi Place Bet{' '}
                          </span>
                        </span>

                        <span
                          onClick={() => onClickMenu('/app/karachi')}
                          className={`cursor-pointer h-8 flex items-center space-x-4 px-4 py-1 my-1 text-sm transition ease-in-out duration-150  ${location.pathname === '/app/karachi'
                            ? 'text-white bg-black/70 font-semibold'
                            : 'text-white font-bold hover:bg-white/70 hover:text-black'
                            }`}
                        >
                          <PiDotFill className='w-6 h-6' />{' '}
                          <span className='text-sm whitespace-nowrap'>
                            Karachi Chart{' '}
                          </span>
                        </span>

                        <span
                          onClick={() =>
                            onClickMenu('/app/karachi-profit-loss')
                          }
                          className={`cursor-pointer h-8 flex items-center space-x-4 px-4 py-1 my-1 text-sm transition ease-in-out duration-150  ${location.pathname === '/app/karachi-profit-loss'
                            ? 'text-white bg-black/70 font-semibold'
                            : 'text-white font-bold hover:bg-white/70 hover:text-black'
                            }`}
                        >
                          <PiDotFill className='w-6 h-6' />{' '}
                          <span className='text-sm whitespace-nowrap'>
                            Karachi Profit Loss{' '}
                          </span>
                        </span>
                      </div>
                    ) : null}
                  </div>
                )}

                {localStoragedata && localStoragedata.roleId === 0 && (
                  <div className='cursor-pointer group'>
                    {showSection === 'walletMangment' ? (
                      <span
                        onClick={() => setShowSection('')}
                        className='flex justify-between items-center px-4 py-1 my-1 w-full text-sm text-white font-semibold transition duration-150 ease-in-out cursor-pointer h-8 texthover textwhite'
                      >
                        <div className='flex space-x-4 items-center'>
                          <span className='group texthover'>
                            <TfiWallet size={18} />
                          </span>
                          <span className='whitespace-nowrap'>Wallet Mgmt</span>
                        </div>
                        {showSection === 'walletMangment' ? (
                          <HiChevronUp className='group texthover' />
                        ) : (
                          <HiChevronDown className='group texthover' />
                        )}
                      </span>
                    ) : (
                      <span
                        onClick={() => setShowSection('walletMangment')}
                        className='flex justify-between items-center px-4 py-1 my-1 w-full text-sm text-white font-semibold transition duration-150 ease-in-out cursor-pointer h-8 texthover textwhite'
                      >
                        <div className='flex space-x-4 items-center'>
                          <span className='group texthover'>
                            <TfiWallet size={18} />{' '}
                          </span>
                          <span className='whitespace-nowrap'>Wallet Mgmt</span>
                        </div>
                        {showSection === 'walletMangment' ? (
                          <HiChevronUp className='group texthover' />
                        ) : (
                          <HiChevronDown className='group texthover' />
                        )}
                      </span>
                    )}
                    {showSection === 'walletMangment' ? (
                      <div className='flex flex-col'>
                        <span
                          onClick={() => onClickMenu('/app/AddFundRequest')}
                          className={`cursor-pointer h-8 flex items-center space-x-4 px-4 py-1 my-1 text-sm transition ease-in-out duration-150  ${location.pathname === '/app/AddFundRequest'
                            ? 'text-white bg-black/70 font-semibold'
                            : 'text-white font-bold hover:bg-white/70 hover:text-black'
                            }`}
                        >
                          <PiDotFill className='w-6 h-6' />{' '}
                          <span className='text-sm  whitespace-nowrap'>
                            Add Fund Report{' '}
                          </span>
                        </span>

                        <span
                          onClick={() => onClickMenu('/app/withdrawRequest')}
                          className={`cursor-pointer h-8 flex items-center space-x-4 px-4 py-1 my-1 text-sm transition ease-in-out duration-150  ${location.pathname === '/app/withdrawRequest'
                            ? 'text-white bg-black/70 font-semibold'
                            : 'text-white font-bold hover:bg-white/70 hover:text-black'
                            }`}
                        >
                          <PiDotFill className='w-6 h-6' />{' '}
                          <span className='text-sm  whitespace-nowrap'>
                            Withdraw Fund Report
                          </span>
                        </span>

                        <span
                          onClick={() => onClickMenu('/app/addFund')}
                          className={`cursor-pointer h-8 flex items-center space-x-4 px-4 py-1 my-1 text-sm transition ease-in-out duration-150  ${location.pathname === '/app/addFund'
                            ? 'text-white bg-black/70 font-semibold'
                            : 'text-white font-bold hover:bg-white/70 hover:text-black'
                            }`}
                        >
                          <PiDotFill className='w-6 h-6' />{' '}
                          <span className='text-sm  whitespace-nowrap'>
                            Add Fund{' '}
                          </span>
                        </span>

                        <span
                          onClick={() => onClickMenu('/app/withdrawfund')}
                          className={`cursor-pointer h-8 flex items-center space-x-4 px-4 py-1 my-1 text-sm transition ease-in-out duration-150  ${location.pathname === '/app/withdrawfund'
                            ? 'text-white bg-black/70 font-semibold'
                            : 'text-white font-bold hover:bg-white/70 hover:text-black'
                            }`}
                        >
                          <PiDotFill className='w-6 h-6' />{' '}
                          <span className='text-sm  whitespace-nowrap'>
                            Withdraw Fund{' '}
                          </span>
                        </span>
                      </div>
                    ) : null}
                  </div>
                )}
                {localStoragedata && localStoragedata.roleId !== 0 && (
                  <span
                    onClick={() => onClickMenu('/app/betNumber')}
                    className={`cursor-pointer items-center flex space-x-4 px-4 py-1 text-sm transition ease-in-out duration-150  ${location.pathname === '/app/betNumber'
                      ? 'text-white bg-black/70 font-semibold'
                      : 'texthover textwhite font-semibold'
                      }`}
                  >
                    <MdHistoryEdu size={18} />{' '}
                    <span className='whitespace-nowrap'>Games Chart</span>
                  </span>
                )}

                {localStoragedata && localStoragedata.roleId !== 0 && (
                  <span
                    onClick={() => onClickMenu('/app/game-profit-loss')}
                    className={`cursor-pointer items-center flex space-x-4 px-4 py-1 text-sm transition ease-in-out duration-150  ${location.pathname === '/app/game-profit-loss'
                      ? 'text-white bg-black/70 font-semibold'
                      : 'texthover textwhite font-semibold'
                      }`}
                  >
                    <MdHistoryEdu size={18} />{' '}
                    <span className='whitespace-nowrap'>
                      Games Profit Loss{' '}
                    </span>
                  </span>
                )}

                {localStoragedata && localStoragedata.roleId !== 0 && (
                  <span
                    onClick={() => onClickMenu('/app/karachi')}
                    className={`cursor-pointer items-center flex space-x-4 px-4 py-1 text-sm transition ease-in-out duration-150  ${location.pathname === '/app/karachi'
                      ? 'text-white bg-black/70 font-semibold'
                      : 'texthover textwhite font-semibold'
                      }`}
                  >
                    <TbBuildingWarehouse size={18} />{' '}
                    <span className='whitespace-nowrap'>Karachi Chart</span>
                  </span>
                )}

                {localStoragedata && localStoragedata.roleId !== 0 && (
                  <span
                    onClick={() => onClickMenu('/app/karachi-profit-loss')}
                    className={`cursor-pointer items-center flex space-x-4 px-4 py-1 text-sm transition ease-in-out duration-150  ${location.pathname === '/app/karachi-profit-loss'
                      ? 'text-white bg-black/70 font-semibold'
                      : 'texthover textwhite font-semibold'
                      }`}
                  >
                    <MdHistoryEdu size={18} />{' '}
                    <span className='whitespace-nowrap'>
                      Karachi Profit Loss{' '}
                    </span>
                  </span>
                )}

                {localStoragedata && localStoragedata.roleId !== 0 && (
                  <><span
                    onClick={() => onClickMenu('/app/mybets')}
                    className={`cursor-pointer items-center flex space-x-4 px-4 py-1 text-sm transition ease-in-out duration-150  ${location.pathname === '/app/mybets'
                      ? 'text-white bg-black/70 font-semibold'
                      : 'texthover textwhite font-semibold'}`}
                  >
                    <MdHistoryEdu size={18} />{' '}
                    <span className='whitespace-nowrap'>Bet History </span>
                  </span><span
                    onClick={() => onClickMenu('/app/karachi-bet-history')}
                    className={`cursor-pointer items-center flex space-x-4 px-4 py-1 text-sm transition ease-in-out duration-150  ${location.pathname === '/app/karachi-bet-history'
                      ? 'text-white bg-black/70 font-semibold'
                      : 'texthover textwhite font-semibold'}`}
                  >
                      <MdHistoryEdu size={18} />{' '}
                      <span className='whitespace-nowrap'>Karachi Bet History </span>
                    </span></>
                )}

              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
