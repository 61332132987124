/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { gamesActions, karachiGamesActions } from '../../_actions'
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import ReactPaginate from 'react-paginate'
import Loader from '../../components/Loader/Loader'
import ViewGameModal from '../gameManagement/components/ViewGameModal/ViewGameModal'
import Select from 'react-select'
import moment from 'moment'
import { MdDeleteForever } from 'react-icons/md'

function generateNumbers() {
  const numbers = [];
  for (let i = 0; i <= 999; i++) {
    numbers.push(i.toString().padStart(3, '0'));
  }
  return numbers;
}

const KarachiDeclareResult = () => {
  const dispatch = useDispatch()
  const selector = useSelector(state => state)
  let [viewModal, setViewModal] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)
  const [fieldsGame, setFieldsGame] = useState({})
  const [errorsGame, setErrorsGame] = useState({})
  const [offset, setOffset] = useState(0)
  const [pageNo, setPageNo] = useState(1)
  const [roleList, setRoleList] = useState([])

  const { karachiGamesList } = useSelector(state => state.karachiGame)

  const numberList = generateNumbers()

  useEffect(() => {
    setRoleList([
      { id: 1, name: 'AGENT' },
      { id: 100, name: 'ONLINE' }
    ])
    let gameReq = {
      fromDate: '',
      toDate: '',
      keyWord: '',
      sortBy: '',
      sortOrder: '',
      pageNo: 1,
      size: 500
    }
    let listReq = {
      fromDate: '',
      toDate: '',
      keyWord: '',
      sortBy: '',
      sortOrder: '',
      pageNo: pageNo,
      size: 10,
      brandGame: false
    }

    dispatch(gamesActions.getKarachiResultListAdmin(listReq))
    dispatch(karachiGamesActions.getAllKarachiGames(gameReq))
  }, [pageNo])

  const inputChangeCreate = e => {
    const { name, value } = e.target
    setFieldsGame(prevState => ({ ...prevState, [name]: value }))
    setErrorsGame(prevState => ({ ...prevState, [name]: '' }))
  }

  const createGameSubmit = e => {
    e.preventDefault()
    if (handleValidationCreateGame()) {
      let gamePageRefresh = {
        fromDate: '',
        toDate: '',
        keyWord: '',
        sortBy: '',
        sortOrder: '',
        pageNo: pageNo,
        size: 10,
        brandGame: false
      }

      let obj = {
        gameId: karachiGamesList[0]._id,
        firstNumber: fieldsGame?.num,
        secondNumber1: fieldsGame?.num1,
        secondNumber2: fieldsGame?.num2,
        secondNumber3: fieldsGame?.num3,
        date: fieldsGame?.date,
        roleId: fieldsGame?.roleId
      }
      dispatch(gamesActions.karachiResultDeclare(obj, gamePageRefresh))
    }
  }

  const handleValidationCreateGame = () => {
    let formIsValid = true
    let errors = {}

    if (!fieldsGame.date || fieldsGame.date === '') {
      formIsValid = false
      errors.date = 'Date is required'
    }

    if (!fieldsGame.num || fieldsGame.num === '') {
      formIsValid = false
      errors.num = 'First result is required'
    }

    if (!fieldsGame.num1 || fieldsGame.num1 === '') {
      formIsValid = false
      errors.num1 = 'Second result is required'
    }

    if (!fieldsGame.num2 || fieldsGame.num2 === '') {
      formIsValid = false
      errors.num2 = 'Second result is required'
    }

    if (!fieldsGame.num3 || fieldsGame.num3 === '') {
      formIsValid = false
      errors.num3 = 'Second result is required'
    }

    setErrorsGame(errors)
    return formIsValid
  }

  const handlePageClick = data => {
    let offset = Math.ceil(data.selected * 10)
    setCurrentPage(data.selected)
    setOffset(offset)
    setPageNo(data.selected + 1)
    let pageReq = {
      fromDate: '',
      toDate: '',
      keyWord: '',
      sortBy: '',
      sortOrder: '',
      pageNo: data.selected + 1,
      size: 10,
      brandGame: false
    }
    dispatch(gamesActions.getAllGames(pageReq))
  }

  const ShowWinnerList2 = () => {
    let obj = {
      isJackPot: false,
      isStarLine: false,
      gameId: fieldsGame?.gameId,
      betNumber: fieldsGame?.num,
      isOpen: fieldsGame.isOpen.toString() === 'true' ? true : false,
      date: fieldsGame?.date
    }
    if (handleValidationCreateGame()) {
      dispatch(gamesActions.resultDeclare(obj))
    }
  }

  let handleViewHideModal = () => {
    setViewModal(false)
  }

  let { games } = selector ? selector : {}
  let { loading, karachiGameResultList, karachiGameResultTotal, winnerList } =
    games ? games : {}

  let optionsData =
    karachiGamesList &&
    karachiGamesList.length > 0 &&
    karachiGamesList.filter(e => !e?.brandGame)
  console.log("first", optionsData)
  const todayDate = moment.utc().startOf('day').valueOf()

  let resultCheck = false

  if (
    karachiGameResultList &&
    karachiGameResultList.some(
      ele =>
        ele.rDate === todayDate && [1, 100].includes(fieldsGame?.['roleId'])
    )
  ) {
    resultCheck = true
  } else {
    resultCheck = false
  }


  const handelClickOnRollBack = (id) => {
    let listReq = {
      fromDate: '',
      toDate: '',
      keyWord: '',
      sortBy: '',
      sortOrder: '',
      pageNo: pageNo,
      size: 10,
      brandGame: false
    }
    dispatch(gamesActions.rollbackKarachiResultDeclare({ id }, listReq))
  }

  return (
    <>
      <Loader loading={loading} />
      <div className='overflow-hidden overflow-y-auto'>
        <div className='relative'>
          <div className='relative z-20 flex min-h-screen'>
            <div className='flex w-full overflow-hidden '>
              <div className='flex flex-col flex-1 overflow-hidden'>
                <main className='relative flex-1 '>
                  <div className='p-3 2xl:p-2 sm:p-5'>
                    <div className='mx-auto max-w-screen-3xl space-y-1'>
                      <div className='text-left bg-white space-y-2 rounded-lg'> 
                        <div className='px-6 py-4 w-full'>
                          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-7 gap-4">
                            <div className='w-ful'>
                              <label
                                class='block text-gray-800 text-base font-semibold mb-1'                              >
                                Result Date
                              </label>
                              <input
                                className='w-full px-3 py-1 text-base bg-white border border-gray-400 rounded-md focus:outline-none uppercase'
                                id='date'
                                name='date'
                                placeholder='Enter Date'
                                // value={
                                //   fieldsGame && fieldsGame['date']
                                //     ? fieldsGame['date']
                                //     : ''
                                // }
                                value={fieldsGame && fieldsGame["date"] ? fieldsGame["date"] : new Date().toISOString().split("T")[0]}
                                max={new Date().toISOString().split("T")[0]}
                                type='date'
                                onChange={inputChangeCreate}
                              />
                              {errorsGame && errorsGame['date'] ? (
                                <div className='text-red-600 invalid-feedback'>
                                  {errorsGame['date']}
                                </div>
                              ) : null}
                            </div>
                            <div className='w-full'>
                              <label
                                class='block text-gray-800 text-base font-semibold mb-1'
                                for='name'
                              >
                                User Type :
                              </label>
                              <select
                                className='w-full px-3 py-2 capitalize text-base bg-white border border-gray-400 rounded focus:outline-none'
                                id='roleId'
                                name='roleId'
                                placeholder='name'
                                value={
                                  fieldsGame && fieldsGame['roleId']
                                    ? fieldsGame['roleId']
                                    : ''
                                }
                                type='text'
                                onChange={inputChangeCreate}
                              >
                                <option value=''>Select User type</option>
                                {roleList && roleList.length > 0
                                  ? roleList.map((element, index) => (
                                    <option
                                      value={
                                        element && element.id
                                          ? element.id
                                          : ''
                                      }
                                    >
                                      {' '}
                                      {element && element.name
                                        ? element.name
                                        : ''}
                                    </option>
                                  ))
                                  : null}
                              </select>
                              {errorsGame && errorsGame['roleId'] ? (
                                <div className='text-red-600 invalid-feedback'>
                                  {errorsGame['roleId']}
                                </div>
                              ) : null}
                            </div>
                        

                            <div className='w-full'>
                              <label className='block text-gray-800 text-base font-semibold mb-1'>
                                First Result
                              </label>
                              <Select
                                onChange={selectedOption =>
                                  inputChangeCreate({
                                    target: {
                                      name: 'num',
                                      value: selectedOption.value
                                    }
                                  })
                                }
                                name='num'
                                value={
                                  fieldsGame && fieldsGame['num']
                                    ? {
                                      value: fieldsGame['num'],
                                      label: fieldsGame['num']
                                    }
                                    : ''
                                }
                                options={
                                  numberList &&
                                  numberList.length > 0 &&
                                  numberList
                                    .filter(e => e.toString().length === 3)
                                    .map(option => ({
                                      value: option,
                                      label: option
                                    }))
                                }
                                classNamePrefix='react-select'
                                placeholder='Select Number'
                                className=' w-full'
                              />
                              {errorsGame && errorsGame['num'] ? (
                                <div className='text-red-600 invalid-feedback'>
                                  {errorsGame['num']}
                                </div>
                              ) : null}
                            </div>

                            <div className='w-full'>
                              <label className='block text-gray-800 text-base font-semibold mb-1'>
                                Second Result
                              </label>
                              <Select
                                onChange={selectedOption =>
                                  inputChangeCreate({
                                    target: {
                                      name: 'num1',
                                      value: selectedOption.value
                                    }
                                  })
                                }
                                name='num1'
                                value={
                                  fieldsGame && fieldsGame['num1']
                                    ? {
                                      value: fieldsGame['num1'],
                                      label: fieldsGame['num1']
                                    }
                                    : ''
                                }
                                options={
                                  numberList &&
                                  numberList.length > 0 &&
                                  numberList
                                    .filter(e => e.toString().length === 3)
                                    .map(option => ({
                                      value: option,
                                      label: option
                                    }))
                                }
                                classNamePrefix='react-select'
                                placeholder='Select Number'
                                className=' w-full'
                              />
                              {errorsGame && errorsGame['num1'] ? (
                                <div className='text-red-600 invalid-feedback'>
                                  {errorsGame['num1']}
                                </div>
                              ) : null}
                            </div>

                            <div className='w-full'>
                              <label className='block text-gray-800 text-base font-semibold mb-1'>
                                Second Result
                              </label>
                              <Select
                                onChange={selectedOption =>
                                  inputChangeCreate({
                                    target: {
                                      name: 'num2',
                                      value: selectedOption.value
                                    }
                                  })
                                }
                                name='num2'
                                value={
                                  fieldsGame && fieldsGame['num2']
                                    ? {
                                      value: fieldsGame['num2'],
                                      label: fieldsGame['num2']
                                    }
                                    : ''
                                }
                                options={
                                  numberList &&
                                  numberList.length > 0 &&
                                  numberList
                                    .filter(e => e.toString().length === 3)
                                    .map(option => ({
                                      value: option,
                                      label: option
                                    }))
                                }
                                classNamePrefix='react-select'
                                placeholder='Select Number'
                                className=' w-full'
                              />
                              {errorsGame && errorsGame['num2'] ? (
                                <div className='text-red-600 invalid-feedback'>
                                  {errorsGame['num2']}
                                </div>
                              ) : null}
                            </div>

                            <div className='w-full'>
                              <label className='block text-gray-800 text-base font-semibold mb-1'>
                                Second Result
                              </label>
                              <Select
                                onChange={selectedOption =>
                                  inputChangeCreate({
                                    target: {
                                      name: 'num3',
                                      value: selectedOption.value
                                    }
                                  })
                                }
                                name='num3'
                                value={
                                  fieldsGame && fieldsGame['num3']
                                    ? {
                                      value: fieldsGame['num3'],
                                      label: fieldsGame['num3']
                                    }
                                    : ''
                                }
                                options={
                                  numberList &&
                                  numberList.length > 0 &&
                                  numberList
                                    .filter(e => e.toString().length === 3)
                                    .map(option => ({
                                      value: option,
                                      label: option
                                    }))
                                }
                                classNamePrefix='react-select'
                                placeholder='Select Number'
                                className=' w-full'
                              />
                              {errorsGame && errorsGame['num3'] ? (
                                <div className='text-red-600 invalid-feedback'>
                                  {errorsGame['num3']}
                                </div>
                              ) : null}
                            </div> 
                            <div className='w-full flex items-center space-x-4'>
                              <div className='w-full xl:pt-4 lg:pt-0 md:pt-4'>
                                <button
                                  className={`flex-1 justify-center mt-2 w-full px-4 text-base text-white font-bold all-btn ${resultCheck
                                    ? 'opacity-50 cursor-not-allowed'
                                    : 'hover:bg-blue hover:text-white'
                                    } focus:outline-none focus:border-[#7D1970] focus:shadow-outline-yellow active:all-btn`}
                                  type='button'
                                  onClick={e => createGameSubmit(e)}
                                  disabled={resultCheck}
                                >
                                  {resultCheck ? 'Result Declare' : 'Save'}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                      <div className='relative overflow-x-auto shadow-md sm:rounded-lg'>
                        <table className='w-full text-base text-left rtl:text-right text-gray-500 '>
                          <thead className='text-gray-800 capitalize bg-red-100'>
                            <tr>
                              <th className='px-3 py-3 text-left'>#</th>
                              <th className='px-3 py-3 text-left'>
                                Result Date
                              </th>
                              <th className='px-3 py-3 text-left'>Type</th>
                              <th className='px-3 py-3 text-left'>Game Name</th>
                              <th className='px-3 py-3 text-left'>
                                First Result
                              </th>
                              <th className='px-3 py-3 text-left'>
                                Second Result
                              </th>
                              <th className='px-3 py-3 text-left'>
                                Delete
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {karachiGameResultList &&
                              karachiGameResultList.length > 0
                              ? karachiGameResultList.map((element, index) => (
                                <React.Fragment key={index}>
                                  <tr
                                    key={index}
                                    className={
                                      index % 2 === 0
                                        ? 'bg-white border-b text-black '
                                        : 'bg-gray-100 text-black border-b'
                                    }
                                  >
                                    <td className='px-2 lg:px-4 py-2 text-base text-left'>
                                      {offset + index + 1}
                                    </td>
                                    <td className='px-2 lg:px-4 py-2 text-base text-left'>
                                      {element && element.rDate
                                        ? new Date(
                                          element.rDate
                                        ).toLocaleDateString()
                                        : '-'}
                                    </td>
                                    <td className='px-2 lg:px-4 py-2 text-base text-left'>
                                      {element &&
                                        element.roleId &&
                                        element.roleId === 100
                                        ? 'Online'
                                        : element &&
                                          element.roleId &&
                                          element.roleId === 1
                                          ? 'Agent'
                                          : '-'}
                                    </td>
                                    <td className='px-2 lg:px-4 py-2 text-base text-left capitalize'>
                                      {element && element.karachiGamesId
                                        ? element.karachiGamesId?.name
                                        : '-'}
                                    </td>

                                    <td className='px-2 lg:px-4 py-2 text-base text-left'>
                                      {element &&
                                        element.firstResult?.toString()
                                        ? element.firstResult?.toString()
                                        : '-'}
                                    </td>
                                    <td className='px-2 lg:px-4 py-2 text-base text-left'>
                                      {element &&
                                        element.secondResult?.toString()
                                        ? element.secondResult?.toString()
                                        : '-'}
                                    </td>
                                    <td className='px-2 lg:px-4 py-2 text-md text-center cursor-pointer'>
                                      <MdDeleteForever onClick={() => handelClickOnRollBack(element._id)} size={24} color='#911212' />
                                    </td>
                                  </tr>
                                </React.Fragment>
                              ))
                              : null}
                          </tbody>
                        </table>
                      </div>
                      {karachiGameResultList && karachiGameResultTotal > 10 ? (
                        <ReactPaginate
                          previousLabel={'Prev'}
                          nextLabel={'Next'}
                          breakLabel={'...'}
                          breakClassName={'break-me'}
                          pageCount={Math.ceil(karachiGameResultTotal / 10)}
                          marginPagesDisplayed={0}
                          pageRangeDisplayed={10}
                          onPageChange={handlePageClick}
                          containerClassName={'pagination'}
                          pageClassName={'page-cls'}
                          activeClassName={'active'}
                          forcePage={currentPage}
                        />
                      ) : null}
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
          <ViewGameModal
            viewModal={viewModal}
            handleViewHideModal={handleViewHideModal}
            winnerList={winnerList}
            ShowWinnerList2={ShowWinnerList2}
          />
        </div>
      </div>
    </>
  )
}

export default KarachiDeclareResult
