/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { bankActions, gamesActions, marketActions, userActions, walletActions, } from "../../_actions";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Loader from "../../components/Loader/Loader";
import ViewUserModal from "./components/ViewUserModal/ViewUserModal";
import { BiArrowBack } from "react-icons/bi";
import { TfiWallet } from "react-icons/tfi";


const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userId } = useParams();
  let selector = useSelector((state) => state);
  const { userDetailsById } = useSelector((state) => state.users);
  const [rowData, setrowData] = useState({});
  const [errorsGame, setErrorsGame] = useState({});
  const [viewModal, setviewModal] = useState(false);
  const [fieldsUser, setFieldsUser] = useState({});
  const fromDate2 = ""
  const keyWord2 = ""
  const sortBy2 = "asc"
  const size = 10
  const size2 = 10
  const pageNo = 1;
  const toDate2 = ""
  const pageNo2 = 1;
  let { bank } = selector ? selector : {};
  let { bankDetailsByAdmin, loading } = bank ? bank : {};
  let [sessionData, setsessionData] = useState({});
  const getSessionData = async () => {
    const sessiondata = await JSON.parse(sessionStorage.getItem("adminuser"));
    setsessionData(sessiondata);
    return { sessiondata };
  };


  useEffect(() => {
    getSessionData();

    let gameReq = {
      fromDate: "",
      toDate: "",
      keyWord: "",
      sortBy: "",
      sortOrder: "",
      pageNo: pageNo,
      size: size,
    };
    dispatch(gamesActions.getAllGames(gameReq));
    dispatch(marketActions.getAllMarket());
    dispatch(userActions.getProfileforAdmin({ userId: userId }));
    dispatch(userActions.getAdminProfile());

    let paymentListReq = {
      type: "DEPOSIT",
      fromDate: "",
      toDate: "",
      keyWord: "",
      sortBy: "",
      sortOrder: "",
      pageNo: pageNo,
      size: size,
    };

    let myBetReq2 = {
      fromDate: fromDate2,
      toDate: toDate2,
      keyWord: keyWord2,
      sortBy: "",
      sortOrder: sortBy2,
      pageNo: pageNo2,
      size: size2,
      userId: userId,
    };

    dispatch(walletActions.getPaymentListForAdmin(paymentListReq));

    dispatch(bankActions.getClientBankDetail({ userId: userId }));

    dispatch(userActions.transactionHistory(myBetReq2));
  }, [userId, size2]);

  const { gamesList } = useSelector((state) => state.games);
  useEffect(() => {
    if (userDetailsById) {
      setFieldsUser(userDetailsById);
    }

  }, [userDetailsById, bankDetailsByAdmin, gamesList]);

  const inputChangeUser = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsUser((prevState) => ({ ...prevState, [name]: value }));
  };

  const updateUserSubmit = () => {
    if (handleValidationUserUpdate()) {
      const { userName, mobNo, _id, share, comm, name, address, betLimit } = fieldsUser;
      dispatch(
        userActions.updateUser({
          userName: userName && userName.trim(),
          mobNo: mobNo && mobNo.trim(),
          name: name && name.trim(),
          // email: email && email.trim(),
          address: address,
          betLimit: betLimit,
          share: share,
          comm: comm,
          userId: _id,
        })
      );
    }
  };
  const updateUserSubmitPassword = () => {
    // if (handleValidationUserUpdatess()) {
    const { password, _id } = fieldsUser;
    dispatch(
      userActions.changePasswordforAdmin({
        // userName: userName && userName.trim(), mobNo: mobNo && mobNo.trim(),
        password: password && password.trim(),
        // share:share,
        // comm:comm,
        userId: _id,
      })
    );
    // }
  };

  const handleValidationUserUpdate = () => {
    let formIsValid = true;
    let errors = {};

    if (!fieldsUser.userName || fieldsUser.userName.trim() === "") {
      formIsValid = false;
      errors.userName = "Enter userName";
    }

    if (!fieldsUser.mobNo || fieldsUser.mobNo.trim() === "") {
      formIsValid = false;
      errors.mobNo = "Enter mobile Number";
    }
    return formIsValid;
  };




  const createGameSubmit2 = (e, data) => { 
    setrowData({ ...e, key: data });
    setviewModal(true);
  };
  const createGameSubmit3 = (e, data) => { 
    setrowData({ ...e, key: data });
    setviewModal(true);
  };
  const createGameSubmit4 = (e, data) => { 
    setrowData({ ...e, key: data });
    setviewModal(true);
  };

  const handleChange = (e) => {
    setrowData({ ...rowData, [e.target.name]: e.target.value });
    setErrorsGame({ ...rowData, [e.target.name]: "" });
  };

  const handleFund = (e) => {
    e.preventDefault();

    if (handleValidationCreateGame()) {
      let obj = {
        userId: rowData._id,
        amount: rowData?.amount || 0,
      };

      if (rowData?.key === "Credit Reference") {
        dispatch(walletActions.creditReferenceAdd(obj, userId));
      } else if (rowData?.key === "Withdraw Fund") {
        dispatch(walletActions.withdrawBalanceByAdmin(obj, userId));
      } else {
        dispatch(walletActions.depositeBalanceByAdmin(obj, userId));
      }

      setrowData({});
      setviewModal(false);
    }
  };

  const handleViewHideModal = () => {
    setrowData({});
    setviewModal(false);
    setErrorsGame({});
    setFieldsUser({});
  };

  const handleValidationCreateGame = () => {
    let formIsValid = true;
    let errors = {};

    if (!rowData.amount || rowData.amount === "") {
      formIsValid = false;
      errors.amount = "Enter Amount";
    }

    setErrorsGame(errors);
    return formIsValid;
  };

  useEffect(() => {
    let myBetReq2 = {
      fromDate: fromDate2,
      toDate: toDate2,
      keyWord: keyWord2,
      sortBy: "",
      sortOrder: sortBy2,
      pageNo: pageNo2,
      size: size2,
      userId: userId,
    };
    dispatch(userActions.transactionHistory(myBetReq2));
  }, [keyWord2, size2]);





  const handledoubleAmount = (e, data) => {
    e.preventDefault();
    dispatch(userActions.doubleAmount({ userId: data?._id }));
  };



  return (
    <>
      <Loader loading={loading} />
      <div className="z-0 overflow-hidden overflow-y-auto focus:outline-none">
        <div className="relative">
          <div className="relative z-20 flex min-h-screen">
            <div className="flex w-full overflow-hidden ">
              <div className="flex flex-col flex-1 overflow-hidden">
                <main className="relative flex-1 ">

                  <div className="p-3 sm:p-5 space-y-4">

                    <div className="bg-white p-3 flex items-center space-x-3 rounded-lg">
                      <BiArrowBack className="w-7 h-7 rounded-full bg-gray-100 p-1 cursor-pointer" onClick={() => navigate(-1)} />
                      <span className="text-lg font-semibold capitalize">User Name: {userDetailsById?.userName || ""}</span>
                    </div>

                    <div className="bg-white p-3 grid xl:grid-cols-6 lg:grid-cols-5 gap-4 lg:space-x-3 space-x-0 rounded-lg">
                      <div className="flex items-center space-x-3 xl:col-span-3 lg:col-span-2">
                        <TfiWallet className="w-7 h-7 p-1" />
                        <span className="text-xl font-semibold capitalize">Available Balance:  {(userDetailsById?.wallet)?.toFixed(2)|| 0}</span>
                      </div>
                      <button className="w-full whitespace-nowrap flex justify-center items-center px-4 py-1.5 xl:text-lg lg:text-sm font-semibold text-white bg-green-600 rounded-full hover:bg-green-700 transition duration-150 ease-in-out" type="button" onClick={() => createGameSubmit2(userDetailsById, "Add Fund")}>
                        <span className="mr-2">+</span> Add Fund</button>
                      <button className="w-full whitespace-nowrap flex justify-center items-center px-4 py-1.5 xl:text-lg lg:text-sm font-semibold text-white bg-red-600 rounded-full hover:bg-red-700 transition duration-150 ease-in-out" type="button" onClick={() => createGameSubmit3(userDetailsById, "Withdraw Fund")}>
                        <span className="mr-2">-</span> Withdraw Fund</button>
                      <button className="w-full whitespace-nowrap flex justify-center items-center px-4 py-1.5 xl:text-lg lg:text-sm font-semibold text-white bg-purple-700 rounded-full hover:bg-purple-800 transition duration-150 ease-in-out" type="button" onClick={() => createGameSubmit4(userDetailsById, "Credit Reference")}>Credit Reference</button>
                    </div>

                    <div className="mx-auto max-w-screen-3xl space-y-3">


                      <div className="w-full p-4 bg-white rounded-2xl shadow-md">
                        <div className="flex justify-between items-center border-b pb-2">
                          <span className="text-xl font-bold text-gray-800">Personal Information</span>
                        </div>

                        <div className="grid grid-cols-1 xl:grid-cols-5 lg:grid-cols-2 gap-4 py-3">
                          <div className="w-full">
                            <label className="text-gray-700 text-base font-medium" htmlFor="userName">User Name:</label>
                            <input disabled className="w-full px-3 py-1 bg-gray-100 border rounded-lg focus:outline-none" id="userName" name="userName" value={fieldsUser?.userName || ""} type="text" onChange={inputChangeUser} />
                          </div>

                          <div className="w-full">
                            <label className="text-gray-700 text-base font-medium" htmlFor="name">Name:</label>
                            <input className="w-full px-3 py-1 bg-white border rounded-lg focus:outline-none" id="name" name="name" value={fieldsUser?.name || ""} type="text" onChange={inputChangeUser} />
                          </div>

                          <div className="w-full">
                            <label className="text-gray-700 text-base font-medium" htmlFor="betLimit">Bet Limit:</label>
                            <input className="w-full px-3 py-1 bg-white border rounded-lg focus:outline-none" id="betLimit" name="betLimit" value={fieldsUser?.betLimit || ""} type="text" onChange={inputChangeUser} />
                          </div>

                          <div className="w-full">
                            <label className="text-gray-700 text-base font-medium" htmlFor="address">Address:</label>
                            <input className="w-full px-3 py-1 bg-white border rounded-lg focus:outline-none" id="address" name="address" value={fieldsUser?.address || ""} type="text" onChange={inputChangeUser} />
                          </div>

                          <div className="w-full">
                            <label className="text-gray-700 text-base font-medium" htmlFor="mobNo">Mobile:</label>
                            <input className="w-full px-3 py-1 bg-white border rounded-lg focus:outline-none" id="mobNo" name="mobNo" value={fieldsUser?.mobNo || ""} type="text" onChange={inputChangeUser} />
                          </div>

                          <div className="w-full">
                            <label className="text-gray-700 text-base font-medium" htmlFor="createdAt">Creation Date:</label>
                            <input disabled className="w-full px-3 py-1 bg-gray-100 border rounded-lg focus:outline-none" id="createdAt" name="createdAt" value={fieldsUser?.createdAt ? new Date(fieldsUser.createdAt).toLocaleDateString() : ""} type="text" />
                          </div>
                          <div className="w-full lg:mt-6 mt-0">
                            <button className="w-full px-5 py-1 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-500 focus:outline-none transition" onClick={updateUserSubmit}>Update Info</button>
                          </div>
                          <div className="w-full">
                            <label className="text-gray-700 text-base font-medium" htmlFor="password">Change Password:</label>
                            <input className="w-full px-4 py-1 bg-white border rounded-lg focus:outline-none" id="password" name="password" type="password" onChange={inputChangeUser} />
                          </div>
                          <div className="w-full lg:mt-6 mt-0">
                            <button className="w-full px-5 py-1 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-500 focus:outline-none transition" onClick={updateUserSubmitPassword}>Save Password</button>
                          </div>
                        </div>
                      </div>



                      <div className="p-2 rounded-lg">
                        <div>
                          {userDetailsById?.blockedNumbers?.map(
                            (blockedNumber) => {
                              // Check if there are any blocked numbers in the current market
                              const hasBlockedNumbers =
                                blockedNumber.numbers.some(
                                  (numGroup) => numGroup.numbers.length > 0
                                );

                              if (!hasBlockedNumbers) {
                                return null; // Skip rendering if there are no blocked numbers in the current market
                              }

                              return (
                                <div
                                  key={blockedNumber._id}
                                  className="p-4 border-b border-gray-200"
                                >
                                  <h3 className="text-lg font-semibold">
                                    Market: {blockedNumber.marketId?.name}
                                  </h3>
                                  {blockedNumber.numbers.map((numberGroup) => (
                                    <p
                                      key={numberGroup._id}
                                      className="text-base mt-2"
                                    >
                                      Blocked Numbers:{" "}
                                      {numberGroup.numbers.join(", ")}
                                    </p>
                                  ))}
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>

                      {sessionData && sessionData.roleId === 4 && (
                        <div>
                          <input
                            type="checkbox"
                            checked={userDetailsById?.DoubleAmount}
                            onChange={(e) =>
                              handledoubleAmount(e, userDetailsById)
                            }
                            id="implementDouble"
                          />
                          <label htmlFor="implementDouble">
                            Implement Double
                          </label>
                        </div>
                      )}

                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ViewUserModal
        viewModal={viewModal}
        rowData={rowData}
        handleViewHideModal={handleViewHideModal}
        handleChange={handleChange}
        handleFund={handleFund}
        errorsGame={errorsGame}
      />
    </>
  );
};

export default Dashboard;
