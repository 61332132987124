import React from 'react'
import { Link } from 'react-router-dom'
export default function ViewGameModal (props) {
  let { viewModal, handleViewHideModal, winnerList, ShowWinnerList2 } = props
  return (
    <div
      className={
        viewModal
          ? 'fixed inset-0 z-50 flex items-center justify-center w-full overflow-hidden main-modal h-100 animated fadeIn faster'
          : 'hidden'
      }
      style={{ background: 'rgba(0,0,0,.7)' }}
    >
      <div className='z-50 md:w-7/12 w-[100vw]  h-[80vh]  mx-auto overflow-y-auto bg-white border border-teal-500 rounded shadow-lg modal-container '>
        <div className='px-6 py-4 text-left modal-content'>
          {/*Title*/}
          <div className='flex items-center justify-between bg-[#911212] px-1 m-1 py-2 text-white rounded-lg'>
            <p className='text-2xl font-bold'>Winner List</p>
            <div className='z-50 cursor-pointer modal-close'>
              <svg
                onClick={() => handleViewHideModal()}
                className='text-white fill-current'
                xmlns='http://www.w3.org/2000/svg'
                width={18}
                height={18}
                viewBox='0 0 18 18'
              >
                <path d='M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z'></path>
              </svg>
            </div>
          </div>
          <div class='relative overflow-x-auto shadow-md sm:rounded-lg'>
            <table class='w-full text-base text-left rtl:text-right text-gray-500'>
              <thead class='text-base text-gray-700 capitalize bg-gray-50 '>
                <tr>
                  <th scope='col' class='px-6 py-3'>
                    User
                  </th>
                  <th scope='col' class='px-6 py-3'>
                    Game
                  </th>
                  <th scope='col' class='px-6 py-3'>
                    Market Name
                  </th>
                  <th scope='col' class='px-6 py-3'>
                    Bid Type
                  </th>
                  <th scope='col' class='px-6 py-3'>
                    Bet Number
                  </th>
                  <th scope='col' class='px-6 py-3'>
                    Amount
                  </th>
                  <th scope='col' class='px-6 py-3'>
                    Result
                  </th>
                </tr>
              </thead>
              <tbody>
                {winnerList &&
                Array.isArray(winnerList) &&
                winnerList?.length > 0 ? (
                  winnerList.map((e, i) => {
                    return (
                      <tr key={i} class='    border-b '>
                        <th
                          scope='row'
                          class='px-6 py-4 font-medium text-blue-500 whitespace-nowrap '
                        >
                          <Link to={`/app/user/${e?.userId?._id}`}>
                            {' '}
                            {e?.userId?.userName || ''}{' '}
                          </Link>
                        </th>
                        <td class='px-6 py-4'>{e?.gameId?.name || ''}</td>
                        <td class='px-6 py-4'>{e?.marketId?.name || ''}</td>
                        <td class='px-6 py-4'>
                          {e?.isOpen === true ? 'Open' : 'Close'}
                        </td>
                        <td class='px-6 py-4'>
                          {e?.marketId?.betNumber || '0'}
                        </td>
                        <td class='px-6 py-4'>{e?.amount || ''}</td>
                        <td class='px-6 py-4'>
                          {e?.betStatus === 0 && (
                            <div className='text-yellow-500'>Pending</div>
                          )}
                          {e?.betStatus === 1 && (
                            <div className='text-green-500'>Win</div>
                          )}
                          {e?.betStatus === 2 && (
                            <div className='text-red-500'>Loss</div>
                          )}
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className=' p-5'>No Data Found</td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </>
                )}
              </tbody>
            </table>
            <div className='  flex justify-center items-center'>
              {winnerList && winnerList.length > 0 && (
                <button
                  className='flex justify-center w-44 px-4 py-2 text-base text-white transition duration-150 ease-in-out bg-pink-600 border border-pink-600 rounded-full xl hover:bg-blue hover:text-white focus:outline-none focus:border-pink-600 focus:shadow-outline-pink active:bg-pink-600'
                  type='button'
                  onClick={ShowWinnerList2}
                >
                  Declare Result
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
