import React from "react";
import Modal from 'react-modal';

export default function ViewUserModal(props) {

  let { viewModal, handleViewHideModal, rowData } = props;

  return (

    <Modal
      isOpen={viewModal}
    >

      <div className="fixed inset-0 z-50 flex items-center justify-center w-full overflow-hidden main-modal h-100 animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
        <div className="z-50 w-11/12 mx-auto overflow-y-auto bg-white border border-teal-500 rounded shadow-lg modal-container md:max-w-md">
          <div className="px-6 py-4 text-left modal-content">
            {/*Title*/}
            <div className="flex items-center justify-between pb-3">
              <p className="text-2xl font-bold">View Details</p>
              <div className="z-50 cursor-pointer modal-close">
                <svg onClick={() => handleViewHideModal()} className="text-black fill-current" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                  </path>
                </svg>
              </div>
            </div>
            {/*Body*/}
            <form autoComplete="off">
              <div className="{otpSent?'disableArea':''}">
                <div className="relative mt-1 shadow-sm">
                  <label class="block text-gray-700 text-base font-bold mb-2" for="username">userName :</label>
                  <input className={`w-full px-3 py-3 text-base transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring `}
                    id="userName" name="userName" placeholder="UserName"
                    value={rowData && rowData["userName"] ? rowData["userName"] : ""}
                    type="text" disabled
                  />
                </div>
              </div>


              <div >
                <div className="relative mt-1 shadow-sm">
                  <label class="block text-gray-700 text-base font-bold mb-2" for="username">mobNo :</label>
                  <input className={`w-full px-3 py-3 text-base transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring `}
                    id="mobNo" name="mobNo" placeholder="mobNo" value={rowData && rowData["mobNo"] ? rowData["mobNo"] : ""} type="text" disabled
                  />
                </div>
              </div>

              <div >
                <div className="relative mt-1 shadow-sm">
                  <label class="block text-gray-700 text-base font-bold mb-2" for="username">email :</label>
                  <input className={`w-full px-3 py-3 text-base transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring `}
                    id="email" name="email" placeholder="email" value={rowData && rowData["email"] ? rowData["email"] : ""} type="text" disabled
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>


    </Modal>



  );
}
