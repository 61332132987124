/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DashboardJSON from './AppSettingManagement.json'
import {
  bankActions,
  gamesActions,
  userActions
} from '../../_actions'
import CreateAppSettingModal from './components/CreateAppSettingModal/CreateAppSettingModal'
import UpdateAppSettingModal from './components/UpdateAppSettingModal/UpdateAppSettingModal'
import ViewAppSettingModal from './components/ViewAppSettingModal/ViewAppSettingModal'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import ReactPaginate from 'react-paginate'
import { isMobile } from 'react-device-detect'
import Loader from '../../components/Loader/Loader'
import { IoIosNotificationsOutline } from 'react-icons/io'
import { IoMdClose } from 'react-icons/io'

// import Table from '../../components/Atoms/Table/Table';

const Notification = () => {
  const dispatch = useDispatch()
  let addref = useRef(null)
  let selector = useSelector(state => state)
  const [currentPage3, setCurrentPage3] = useState(0)
  let [updateModal, setUpdateModal] = useState(false)
  let [createModal, setCreateModal] = useState(false)
  let [viewModal, setViewModal] = useState(false)
  const [fieldsUpdate, setFieldsUpdate] = useState({})
  const [errorsUpdate, setErrorsUpdate] = useState({})
  const [fieldsAppSetting, setFieldsAppSetting] = useState({})
  const [errorsAppSetting, setErrorsAppSetting] = useState({})
  const size = 10
  const [pageNo, setPageNo] = useState(1)
  const [selecteddata, setselecteddata] = useState([])

  useEffect(() => {
    let appsettingReq = {
      fromDate: '',
      toDate: '',
      keyWord: '',
      sortBy: '',
      sortOrder: '',
      pageNo: pageNo,
      size: size
    }
    dispatch(bankActions.getNotificationList(appsettingReq))
    dispatch(gamesActions.getAllUser())
  }, [])

  const inputChangeCreate2 = e => {
    setselecteddata(e)
  }
  const handleCreateOpenModal = () => {
    setCreateModal(true)
  }

  const handleCreateHideModal = data => {
    if (addref?.current) {
      addref.current.value = ''
    }
    setCreateModal(false)
    setFieldsAppSetting({})
    setErrorsAppSetting({})
  }


  const handleUpdateHideModal = data => {
    setUpdateModal(false)
    setErrorsUpdate({})
  }



  const handleViewHideModal = data => {
    setViewModal(false)
  }

  const inputChange = e => {
    e.preventDefault()
    const { name, value } = e.target
    setFieldsUpdate(prevState => ({ ...prevState, [name]: value }))
    setErrorsUpdate(prevState => ({ ...prevState, [name]: '' }))
  }

  const inputChangeCreate = e => {
    e.preventDefault()
    const { name, value } = e.target
    setFieldsAppSetting(prevState => ({ ...prevState, [name]: value }))
    setErrorsAppSetting(prevState => ({ ...prevState, [name]: '' }))
  }

  const updateAppSettingSubmit = e => {
    e.preventDefault()
    if (handleValidationUpdateAppSetting()) {
      let appsettingPageRefresh = {
        keyWord: '',
        pageNo: pageNo,
        size: size
      }
      let Object = {
        id: fieldsUpdate?._id,
        accountNo: fieldsUpdate?.accountNo && parseInt(fieldsUpdate?.accountNo),
        name: fieldsUpdate?.name.trim(),
        ifsc: fieldsUpdate?.ifsc.trim(),
        bankName: fieldsUpdate?.bankName.trim(),
        upiId: fieldsUpdate?.upiId.trim()
      }
      dispatch(bankActions.updateAdminBankDetail(Object, appsettingPageRefresh))
      handleUpdateHideModal()
    }
  }

  const handleValidationUpdateAppSetting = () => {
    let formIsValid = true
    let errors = {}

    if (!fieldsUpdate.name || fieldsUpdate.name.trim() === '') {
      formIsValid = false
      errors.name = 'Please Enter name'
    }

    if (!fieldsUpdate.bankName || fieldsUpdate.bankName.trim() === '') {
      formIsValid = false
      errors.bankName = 'Please Enter Message'
    }

    if (!fieldsUpdate.ifsc || fieldsUpdate.ifsc.trim() === '') {
      formIsValid = false
      errors.ifsc = 'Please Enter IFSC Code'
    }
    if (!fieldsUpdate.accountNo || fieldsUpdate.accountNo === '') {
      formIsValid = false
      errors.accountNo = 'Please Enter Account Number'
    }
    if (!fieldsUpdate.upiId || fieldsUpdate.upiId.trim() === '') {
      formIsValid = false
      errors.upiId = 'Please Enter UPI Id'
    }

    setErrorsUpdate(errors)
    return formIsValid
  }

  const createAppSettingSubmit = e => {
    e.preventDefault()
    let appsettingReq = {
      keyWord: '',
      pageNo: pageNo,
      size: size
    }

    let arrayOfuser =
      selecteddata && selecteddata.length > 0 && selecteddata?.map(e => e.value)
    if (handleValidationCreateAppSetting()) { 
      let appsettingPageRefresh = {
        title: (fieldsAppSetting && fieldsAppSetting.name.trim()) || '', 
        msg: (fieldsAppSetting && fieldsAppSetting.key.trim()) || '',
        userId: arrayOfuser && arrayOfuser.length > 0 ? arrayOfuser : []
      }
      dispatch(
        bankActions.createNotification(appsettingPageRefresh, appsettingReq)
      )
      setFieldsAppSetting({})
      setErrorsAppSetting({})
      handleCreateHideModal()
    }
  }

  const handleValidationCreateAppSetting = () => {
    let formIsValid = true
    let errors = {}

    if (!fieldsAppSetting.name || fieldsAppSetting.name.trim() === '') {
      formIsValid = false
      errors.name = DashboardJSON.ErrorMsg.name
    }

    if (!fieldsAppSetting.key || fieldsAppSetting.key.trim() === '') {
      formIsValid = false
      errors.key = DashboardJSON.ErrorMsg.key
    }

    setErrorsAppSetting(errors)
    return formIsValid
  }

  const deleteAppSetting = data => {
    let appsettingIdReq = {
      notificationId: data.id
    }

    let appsettingReq = {
      fromDate: '',
      toDate: '',
      keyWord: '',
      sortBy: '',
      sortOrder: '',
      pageNo: pageNo,
      size: size
    }

    confirmAlert({
      title: 'Confirm to Delete?',
      message: `Are you sure to delete ${data.title}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () =>
            dispatch(
              bankActions.deleteNotification(appsettingIdReq, appsettingReq)
            )
        },
        {
          label: 'No'
        }
      ]
    })
  }

  const handleFile = event => { 
    if (event.target.files[0]) {
      dispatch(userActions.uploadImage(event))
      // this.setState({ selectedFile: null });
    } else {
      console.log('No File To Upload!')
    }
  }

  const handlePageClick = data => {
    setCurrentPage3(data.selected)
    setPageNo(data.selected + 1)
    let pageReq = {
      keyWord: '',
      pageNo: data.selected + 1,
      size: size
    }
    dispatch(bankActions.getNotificationList(pageReq))
  }

  let { bank } = selector ? selector : {} 
  let { loading,  notificationTotal, notificationList } = bank
    ? bank
    : {}
  const { games } = selector ? selector : {}
  let { allUserList } = games ? games : {}

  return (
    <>
      <Loader loading={loading} />
      <div className='z-0 overflow-hidden overflow-y-auto focus:outline-none'>
        <div className='relative'>
          <div className='relative z-20 flex min-h-screen'>
            <div className='flex w-full overflow-hidden '>
              <div className='flex flex-col flex-1 overflow-hidden'>
                <main className='relative flex-1 '>
                  <div className='p-3 2xl:p-10 sm:p-5'>
                    <div className='mx-auto max-w-screen-3xl'>
                      <sectiion className='flex justify-between items-center my-4'>
                        <div></div>
                        <div>
                          <button
                            className='px-4 py-1 all-btn text-white font-bold'
                            onClick={() => handleCreateOpenModal()}
                          >
                            Create Notification
                          </button>
                        </div>
                      </sectiion>

                      <div>
                        {notificationList && notificationList.length > 0 ? (
                          notificationList.map((element, index) => (
                            <div class='w-full p-3 my-2 bg-white rounded shadow flex flex-shrink-0'>
                              <div class='focus:outline-none w-8 h-8 border rounded-full border-gray-400 flex flex-shrink-0 items-center justify-center'>
                                <IoIosNotificationsOutline className='w-5 h-5' />
                              </div>
                              <div class='pl-3 w-full'>
                                <div class='flex items-center justify-between w-full'>
                                  <p class='focus:outline-none text-base leading-none'>
                                    {element && element.title
                                      ? element.title
                                      : '-'}
                                  </p>
                                  <div
                                    onClick={() => deleteAppSetting(element)}
                                    title='Delete'
                                    class='cursor-pointer'
                                  >
                                    <IoMdClose className='w-5 h-5 text-slate-700 hover:text-red-600' />
                                  </div>
                                </div>
                                <p class='focus:outline-none text-base leading-3 pt-1 text-slate-700'>
                                  {element && element.body ? element.body : '-'}
                                </p>
                                <p class='focus:outline-none text-base leading-3 pt-1 text-gray-500'>
                                  2 hours ago
                                </p>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className='p-4 text-center bg-black/10 px-4 py-4 rounded'>
                            <p className='font-bold'> No Data Found</p>
                          </div>
                        )}

                        {/* <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                          <table className="w-full text-base text-left rtl:text-right text-gray-500 ">
                            <thead className="text-base text-gray-800 uppercase bg-gray-50  ">
                              <tr>
                                <th className="px-6 py-3">S.No.</th>
                                <th className="px-6 py-3">Title</th>
                                <th className="px-6 py-3 whitespace-nowrap">Message</th>
                                <th className="px-6 py-3 whitespace-nowrap flex justify-end items-center">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                notificationList && notificationList.length > 0 ?
                                  notificationList.map((element, index) => (
                                    <React.Fragment key={index}>
                                      <tr key={index} className={index % 2 === 0 ? "bg-white border-b" : "bg-gray-100  "}>
                                        <th scope="row" className="px-6 py-2 font-medium text-gray-500 whitespace-nowrap ">
                                          {offset + index + 1}
                                        </th>
                                        <th scope="row" className="px-6 py-2 font-medium text-gray-500 whitespace-nowrap ">
                                          {element && element.title ? element.title : "-"}
                                        </th>
                                        <th scope="row" className="px-6 py-2 font-medium text-gray-500 whitespace-nowrap ">
                                          {element && element.body ? element.body : "-"}
                                        </th>
                                        <td className="px-6 py-2 text-right justify-end items-end flex gap-1">
                                          <span className="relative ">
                                            <MdOutlineDelete onClick={() => deleteAppSetting(element)} title='Delete' className=' text-2xl text-red-600 cursor-pointer' />
                                          </span>
                                        </td>
                                      </tr>
                                    </React.Fragment>
                                  )) : <td> No Data Found</td>
                              }
                            </tbody>
                          </table>
                        </div> */}
                      </div>
                    </div>

                    {isMobile ? (
                      <nav className='relative z-0 flex justify-end w-76'>
                        {notificationTotal && notificationTotal > 10 ? (
                          <ReactPaginate
                            previousLabel={'Prev'}
                            nextLabel={'Next'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={notificationTotal / size}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={1}
                            onPageChange={handlePageClick}
                            containerClassName={'pagination'}
                            pageClassName={'page-cls'}
                            activeClassName={'active'}
                            forcePage={currentPage3}
                          />
                        ) : null}
                      </nav>
                    ) : (
                      <nav className='relative z-0 flex justify-end w-76'>
                        {notificationTotal && notificationTotal > 10 ? (
                          <ReactPaginate
                            previousLabel={'Previous'}
                            nextLabel={'Next'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={notificationTotal / size}
                            marginPagesDisplayed={3}
                            pageRangeDisplayed={3}
                            onPageChange={handlePageClick}
                            containerClassName={'pagination'}
                            pageClassName={'page-cls'}
                            activeClassName={'active'}
                            forcePage={currentPage3}
                          />
                        ) : null}
                      </nav>
                    )}
                  </div>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CreateAppSettingModal
        createModal={createModal}
        allUserList={allUserList}
        addref={addref}
        handleCreateHideModal={handleCreateHideModal}
        handleFile={handleFile}
        inputChangeCreate={inputChangeCreate}
        fieldsAppSetting={fieldsAppSetting}
        errorsAppSetting={errorsAppSetting}
        selecteddata={selecteddata}
        setselecteddata={setselecteddata}
        inputChangeCreate2={inputChangeCreate2}
        createAppSettingSubmit={createAppSettingSubmit}
      />

      <UpdateAppSettingModal
        updateModal={updateModal}
        handleUpdateHideModal={handleUpdateHideModal}
        handleFile={handleFile}
        inputChange={inputChange}
        fieldsUpdate={fieldsUpdate}
        errorsUpdate={errorsUpdate}
        updateAppSettingSubmit={updateAppSettingSubmit}
      />

      <ViewAppSettingModal
        viewModal={viewModal}
        handleViewHideModal={handleViewHideModal}
      />
    </>
  )
}

export default Notification
