import React, { useEffect, useState } from "react";
import { FaRegEyeSlash } from "react-icons/fa";
import { FaRegEye } from "react-icons/fa6";

export default function CreateUserModal(props) {

  let { userOpenModal, handleHideModal, inputChange, fieldsUser, errorsUser, createUserSubmit} = props;
  let [v, setv] = useState(false);
  let [v1, setv1] = useState(false);
  let [localStoragedata, setlocalStoragedata] = useState()
  useEffect(() => {
    const stateData = window.sessionStorage.getItem('adminuser')
    setlocalStoragedata(JSON.parse(stateData));

  }, [])

  return (


    <div className={userOpenModal ? `fixed inset-0 z-50 flex items-center justify-center w-full overflow-hidden main-modal h-100 animated fadeIn faster` : 'hidden'} style={{ background: 'rgba(0,0,0,.7)' }}>
      <div className="z-50 w-11/12 mx-auto overflow-y-auto bg-white border rounded shadow-lg modal-container md:max-w-md">
        <div className="px-6 py-4 text-left modal-content">
          {/*Title*/}
          <div className="flex items-center bg-[#911212] text-white mb-3  justify-between py-3 px-2 rounded-lg">
            <p className="text-2xl font-bold">

              {localStoragedata && localStoragedata.roleId === 0 && <span className="text-2xl   font-bold whitespace-nowrap ">ADMIN</span>}
              {localStoragedata && localStoragedata.roleId === 1 && <span className="text-2xl   font-bold whitespace-nowrap ">SUPER MASTER</span>}
              {localStoragedata && localStoragedata.roleId === 2 && <span className="text-2xl   font-bold whitespace-nowrap ">MASTER</span>}
              {localStoragedata && localStoragedata.roleId === 3 && <span className="text-2xl   font-bold whitespace-nowrap ">AGENT</span>}
              {localStoragedata && localStoragedata.roleId === 4 && <span className="text-2xl   font-bold whitespace-nowrap ">OPERATOR</span>}
              {localStoragedata && localStoragedata.roleId === 20 && <span className="text-2xl   font-bold whitespace-nowrap ">CLIENT</span>}


            </p>
            <div className="z-50 cursor-pointer modal-close">
              <svg onClick={() => handleHideModal()} className="text-white fill-current" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                </path>
              </svg>
            </div>
          </div>
          {/*Body*/}
          <form autoComplete="off">
            <div className="{otpSent?'disableArea':''}">
              <div className="relative mt-1 shadow-sm">
                <label class="block text-gray-700 text-base font-bold mb-2" for="username">

                  {localStoragedata && localStoragedata.roleId === 0 && <span className="  font-bold whitespace-nowrap ">Admin</span>}
                  {localStoragedata && localStoragedata.roleId === 1 && <span className="   font-bold whitespace-nowrap ">Super Master</span>}
                  {localStoragedata && localStoragedata.roleId === 2 && <span className="   font-bold whitespace-nowrap ">Master</span>}
                  {localStoragedata && localStoragedata.roleId === 3 && <span className="   font-bold whitespace-nowrap ">Agent</span>}
                  {localStoragedata && localStoragedata.roleId === 4 && <span className="   font-bold whitespace-nowrap ">Operator</span>}
                  {localStoragedata && localStoragedata.roleId === 20 && <span className="  font-bold whitespace-nowrap ">Client</span>}
                  Name :</label>
                <input className={`w-full px-3 py-3 text-base transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${errorsUser && !errorsUser["userName"] ? "border  placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                  id="userName" name="userName" placeholder="User Name"
                  // value={fieldsUser.userName}
                  value={fieldsUser && fieldsUser["userName"] ? fieldsUser["userName"] : ""}

                  type="text" onChange={inputChange} />
                {errorsUser && errorsUser["userName"] ?
                  <div className="text-red-600 invalid-feedback">
                    {errorsUser["userName"]}
                  </div>
                  : null}
              </div>
            </div>

            <div >
              <div className="relative mt-1 shadow-sm">
                <label class="block text-gray-700 text-base font-bold mb-2" for="username">Mobile no. :</label>
                <input className={`w-full px-3 py-3 text-base transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${errorsUser && !errorsUser["mobNo"] ? "border  placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                  id="mobNo" name="mobNo" placeholder="Mobile no." value={fieldsUser && fieldsUser["mobNo"] ? fieldsUser["mobNo"] : ""} type="number" onChange={inputChange} />
                {errorsUser && errorsUser["mobNo"] ?
                  <div className="text-red-600 invalid-feedback">
                    {errorsUser["mobNo"]}
                  </div>
                  : null}
              </div>
            </div>
            <div >
              <div className="relative mt-1 shadow-sm">
                <label class="block text-gray-700 text-base font-bold mb-2" for="username">Password :</label>
                <input className={`w-full px-3 py-3 text-base transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${errorsUser && !errorsUser["password"] ? "border  placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                  id="password" name="password" placeholder="Password" value={fieldsUser && fieldsUser["password"] ? fieldsUser["password"] : ""} type={v ? "text" : "password"} onChange={inputChange} />
                {errorsUser && errorsUser["password"] ?
                  <div className="text-red-600 invalid-feedback">
                    {errorsUser["password"]}
                  </div>
                  : null}
                {v ? <FaRegEye onClick={() => setv(prev => !prev)} className=" absolute cursor-pointer right-2 bottom-4" /> : <FaRegEyeSlash onClick={() => setv(prev => !prev)} className=" absolute right-2 cursor-pointer bottom-4" />}
              </div>
            </div>

            <div >
              <div className="relative mt-1 shadow-sm">
                <label class="block text-gray-700 text-base font-bold mb-2" for="username">Confirm Password :</label>
                <input className={`w-full px-3 py-3 text-base transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${errorsUser && !errorsUser["confirmPassword"] ? "border  placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                  id="confirmPassword" name="confirmPassword" placeholder="Confirm Password" value={fieldsUser && fieldsUser["confirmPassword"] ? fieldsUser["confirmPassword"] : ""} type={v1 ? "text" : "password"} onChange={inputChange} />
                {errorsUser && errorsUser["confirmPassword"] ?
                  <div className="text-red-600 invalid-feedback">
                    {errorsUser["confirmPassword"]}
                  </div>
                  : null}
                {v1 ? <FaRegEye onClick={() => setv1(prev => !prev)} className=" absolute cursor-pointer right-2 bottom-4" /> : <FaRegEyeSlash onClick={() => setv1(prev => !prev)} className=" absolute right-2 cursor-pointer bottom-4" />}
              </div>
            </div>


            <div className="w-64 mx-auto mt-4 sm:w-72">
              <button className="flex justify-center w-full px-4 py-2 mx-auto text-lg font-semibold text-white uppercase transition duration-150 ease-in-out bg-[#911212] border border-[#992941] rounded-full xl hover:bg-blue hover:text-white focus:outline-none focus:border-[#992941] focus:shadow-outline-yellow active:bg-[#911212]" type="button" onClick={createUserSubmit}>Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>


    // </Modal>



  );
}
