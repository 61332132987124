import React, { useEffect, useState } from "react";
import { FaRegEyeSlash } from "react-icons/fa";
import { FaRegEye } from "react-icons/fa6";
import { MdOutlineClose } from "react-icons/md";

export default function CreateUserModal(props) {

  let { userOpenModal, handleHideModal, inputChange, fieldsUser, errorsUser, createUserSubmit } = props;
  let [v, setv] = useState(false);
  let [v1, setv1] = useState(false);
  let [localStoragedata, setlocalStoragedata] = useState()
  useEffect(() => {
    const stateData = window.sessionStorage.getItem('adminuser')
    setlocalStoragedata(JSON.parse(stateData));

  }, [])

  return (


    <div className={userOpenModal ? `fixed inset-0 z-50 flex items-center justify-center w-full overflow-hidden main-modal h-100 animated fadeIn faster` : 'hidden'} style={{ background: 'rgba(0,0,0,.7)' }}>
      <div className="z-50 w-11/12 mx-auto overflow-y-auto bg-white border rounded shadow-lg modal-container md:max-w-xl">
        <div className="px-6 py-4 text-left modal-content">
          {/*Title*/}
          <div className="flex items-center text-black justify-between py-2 px-2 rounded-lg">
            <p className="text-xl font-bold">
              {localStoragedata && localStoragedata.roleId === 0 && <span className="text-xl font-bold whitespace-nowrap ">ADMIN</span>}
            </p>
            <div className="cursor-pointer modal-close" onClick={() => handleHideModal()}>
              <MdOutlineClose className="w-7 h-7 bg-gray-100 p-1 rounded-full hover:bg-red-500 hover:text-white" />
            </div>
          </div>
          {/*Body*/}

          <form autoComplete="off">
            <div className="grid grid-cols-2 gap-4 pb-2">
              <div className={false ? 'disableArea' : ''}>
                <div className="">
                  <label className="block text-[#212121] font-semibold text-base">
                    {localStoragedata?.roleId === 0 && <span className="font-bold"></span>} Name :
                  </label>
                  <input
                    className={`w-full px-2 py-2 text-base bg-white border border-gray-400 rounded-lg outline-none ${errorsUser?.name ? 'border-red-500' : 'border-gray-400'
                      }`}
                    id="name"
                    name="name"
                    placeholder="Enter User Name"
                    value={fieldsUser?.name || ''}
                    type="text"
                    autoComplete="off"
                    onChange={inputChange}
                  />
                  {errorsUser?.name && <div className="text-red-600 mt-1">{errorsUser["name"]}</div>}
                </div>
              </div>

              <div className="">
                {/* {localStoragedata?.roleId === 0 && <span className="font-bold"></span>}  */}
                <label className="block text-[#212121] font-semibold text-base">UserName :
                </label>
                <input
                  className={`w-full px-2 py-2 text-base bg-white border border-gray-400 rounded-lg outline-none ${errorsUser?.userName ? 'border-red-500' : 'border-gray-400'
                    }`}
                  name="userName"
                  placeholder="Enter User Name"
                  value={fieldsUser?.userName || ''}
                  type="text"
                  autoComplete="off"
                  onChange={inputChange}
                />
                {errorsUser?.userName && <div className="text-red-600 mt-1">{errorsUser["userName"]}</div>}
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 pb-2">
              <div className="">
                <label className="block text-[#212121] font-semibold text-base">K Comm. (%) :</label>
                <input
                  className={`w-full px-2 py-2 text-base bg-white border border-gray-400 rounded-lg outline-none ${errorsUser?.comm ? 'border-red-500' : 'border-gray-400'
                    }`}
                  id="karachiComm"
                  name="karachiComm"
                  placeholder="Enter Karachi Commission Percentage"
                  value={fieldsUser?.karachiComm || ''}
                  type="number"
                  autoComplete="off"
                  onChange={inputChange}
                />
                {errorsUser?.karachiComm && <div className="text-red-600 mt-1">{errorsUser["karachiComm"]}</div>}
              </div>


              <div className="">
                <label className="block text-[#212121] font-semibold text-base">K Hisha (%) :</label>
                <input
                  className={`w-full px-2 py-2 text-base bg-white border border-gray-400 rounded-lg outline-none ${errorsUser?.share ? 'border-red-500' : 'border-gray-400'
                    }`}
                  id="karachiShare"
                  name="karachiShare"
                  placeholder="Enter Karachi Share Percentage"
                  value={fieldsUser?.karachiShare || ''}
                  type="number"
                  autoComplete="off"
                  onChange={inputChange}
                />
                {errorsUser?.karachiShare && <div className="text-red-600 mt-1">{errorsUser["karachiShare"]}</div>}
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4 pb-2">
              <div className="">
                <label className="block text-[#212121] font-semibold text-base">Comm. (%) :</label>
                <input
                  className={`w-full px-2 py-2 text-base bg-white border border-gray-400 rounded-lg outline-none ${errorsUser?.comm ? 'border-red-500' : 'border-gray-400'
                    }`}
                  id="comm"
                  name="comm"
                  autoComplete="off"
                  placeholder="Enter Commission Percentage"
                  value={fieldsUser?.comm || ''}
                  type="number"
                  onChange={inputChange}
                />
                {errorsUser?.comm && <div className="text-red-600 mt-1">{errorsUser["comm"]}</div>}
              </div>

              <div className="">
                <label className="block text-[#212121] font-semibold text-base">Hisha (%) :</label>
                <input
                  className={`w-full px-2 py-2 text-base bg-white border border-gray-400 rounded-lg outline-none ${errorsUser?.share ? 'border-red-500' : 'border-gray-400'
                    }`}
                  id="share"
                  autoComplete="off"
                  name="share"
                  placeholder="Enter Share Percentage"
                  value={fieldsUser?.share || ''}
                  type="number"
                  onChange={inputChange}
                />
                {errorsUser?.share && <div className="text-red-600 mt-1">{errorsUser["share"]}</div>}
              </div>
            </div>

            {/* Single Rate and Double Rate */}
            <div className="grid grid-cols-2 gap-4 pb-2">
              <div className="">
                <label className="block text-[#212121] font-semibold text-base">Single Rate (%) :</label>
                <input
                  className={`w-full px-2 py-2 text-base bg-white border border-gray-400 rounded-lg outline-none ${errorsUser?.singleRate ? 'border-red-500' : 'border-gray-400'
                    }`}
                  id="singleRate"
                  name="singleRate"
                  autoComplete="off"
                  placeholder="Enter Single Rate"
                  value={fieldsUser?.singleRate || ''}
                  type="number"
                  onChange={inputChange}
                />
                {errorsUser?.singleRate && <div className="text-red-600 mt-1">{errorsUser["singleRate"]}</div>}
              </div>

              <div className="">
                <label className="block text-[#212121] font-semibold text-base">Double Rate (%) :</label>
                <input
                  className={`w-full px-2 py-2 text-base bg-white border border-gray-400 rounded-lg outline-none ${errorsUser?.doubleRate ? 'border-red-500' : 'border-gray-400'
                    }`}
                  id="doubleRate"
                  name="doubleRate"
                  autoComplete="off"
                  placeholder="Enter Double Rate"
                  value={fieldsUser?.doubleRate || ''}
                  type="number"
                  onChange={inputChange}
                />
                {errorsUser?.doubleRate && <div className="text-red-600 mt-1">{errorsUser["doubleRate"]}</div>}
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4 pb-2">
              <div className="">
                <label className="block text-[#212121] font-semibold text-base">Mobile no. :</label>
                <input
                  className={`w-full px-2 py-2 text-base bg-white border border-gray-400 rounded-lg outline-none ${errorsUser?.mobNo ? 'border-red-500' : 'border-gray-400'
                    }`}
                  id="mobNo"
                  autoComplete="off"
                  name="mobNo"
                  placeholder="Enter Mobile Number"
                  value={fieldsUser?.mobNo || ''}
                  type="number"
                  onChange={inputChange}
                />
                {errorsUser?.mobNo && <div className="text-red-600 mt-1">{errorsUser["mobNo"]}</div>}
              </div>

              <div className="">
                <label className="block text-[#212121] font-semibold text-base">Bet Limit :</label>
                <div className="flex items-center ">
                  <input
                    className={`w-full px-2 py-2 text-base bg-white border border-gray-400 rounded-lg outline-none ${errorsUser?.betLimit ? 'border-red-500' : 'border-gray-400'
                      }`}
                    id="betLimit"
                    name="betLimit"
                    autoComplete="off"
                    placeholder="Enter betLimit"
                    value={fieldsUser?.betLimit || ''}
                    type={v ? 'text' : 'betLimit'}
                    onChange={inputChange}
                  />
                </div>
                {errorsUser?.betLimit && <div className="text-red-600 mt-1">{errorsUser["betLimit"]}</div>}
              </div>
            </div>
            <div className="">
              <label className="block text-[#212121] font-semibold text-base">Credit Reference :</label>
              <input
                className={`w-full px-2 py-2 text-base bg-white border border-gray-400 rounded-lg outline-none ${errorsUser?.creditReference ? 'border-red-500' : 'border-gray-400'
                  }`}
                id="creditReference"
                autoComplete="off"
                name="creditReference"
                placeholder="Enter credit reference"
                value={fieldsUser?.creditReference || ''}
                type="number"
                onChange={inputChange}
              />
              {errorsUser?.creditReference && <div className="text-red-600 mt-1">{errorsUser["creditReference"]}</div>}
            </div>

            <div className="pb-2">
              <label className="block text-[#212121] font-semibold text-base">Address :</label>
              <div className="flex items-center ">
                <input
                  className={`w-full px-2 py-2 text-base bg-white border border-gray-400 rounded-lg outline-none ${errorsUser?.address ? 'border-red-500' : 'border-gray-400'
                    }`}
                  id="address"
                  name="address"
                  autoComplete="off"
                  placeholder="Enter address"
                  value={fieldsUser?.address || ''}
                  type={v ? 'text' : 'address'}
                  onChange={inputChange}
                />
              </div>
              {errorsUser?.address && <div className="text-red-600 mt-1">{errorsUser["address"]}</div>}
            </div>

            <div className="grid grid-cols-2 gap-4 pb-2">
              {/* Password Fields */}
              <div className="relative">
                <label className="block text-[#212121] font-semibold text-base">Password :</label>
                <div className="flex items-center ">
                  <input
                    className={`w-full px-2 py-2 text-base bg-white border border-gray-400 rounded-lg outline-none ${errorsUser?.password ? 'border-red-500' : 'border-gray-400'
                      }`}
                    id="password"
                    name="password"
                    autoComplete="off"
                    placeholder="Enter Password"
                    value={fieldsUser?.password || ''}
                    type={v ? 'text' : 'password'}
                    onChange={inputChange}
                  />
                  <span onClick={() => setv(prev => !prev)} className="absolute right-4 bottom-3 cursor-pointer">
                    {v ? <FaRegEye /> : <FaRegEyeSlash />}
                  </span>
                </div>
                {errorsUser?.password && <div className="text-red-600 mt-1">{errorsUser["password"]}</div>}
              </div>


              <div className="relative">
                <label className="block text-[#212121] font-semibold text-base">Conf. Pass :</label>
                <div className="flex items-center ">
                  <input
                    className={`w-full px-2 py-2 text-base bg-white border border-gray-400 rounded-lg outline-none ${errorsUser?.confirmPassword ? 'border-red-500' : 'border-gray-400'
                      }`}
                    id="confirmPassword"
                    name="confirmPassword"
                    autoComplete="off"
                    placeholder="Confirm Password"
                    value={fieldsUser?.confirmPassword || ''}
                    type={v1 ? 'text' : 'password'}
                    onChange={inputChange}
                  />
                  <span onClick={() => setv1(prev => !prev)} className="absolute right-4 bottom-3 cursor-pointer">
                    {v1 ? <FaRegEye /> : <FaRegEyeSlash />}
                  </span>
                </div>
                {errorsUser?.confirmPassword && <div className="text-red-600 mt-1">{errorsUser["confirmPassword"]}</div>}
              </div>
            </div>

            <div className="mt-4 text-center">
              <button
                className="w-64 py-2 text-lg font-semibold text-white bg-red-600 rounded-full transition duration-200 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-400"
                type="button"
                onClick={createUserSubmit}
              >
                Submit
              </button>
            </div>
          </form>



        </div>
      </div>
    </div>


    // </Modal>



  );
}
