/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { gamesActions, walletActions } from '../../../../_actions'
import moment from 'moment'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
const size = 10
const offset = 0
const pageNo = 1

const Dashboard = () => {
  const dispatch = useDispatch()
  const { paymentList } = useSelector(state => state.wallet)
  const [fieldsGame, setFieldsGame] = useState({})
  const [errorsGame, setErrorsGame] = useState({})

  useEffect(() => {
    let gameReq = {
      fromDate: '',
      toDate: '',
      keyWord: '',
      sortBy: '',
      sortOrder: '',
      pageNo: pageNo,
      size: size
    }
    dispatch(gamesActions.getAllGames(gameReq))
    let paymentListReq = {
      type: 'DEPOSIT',
      fromDate: '',
      toDate: '',
      keyWord: '',
      sortBy: '',
      sortOrder: '',
      pageNo: pageNo,
      size: size
    }
    dispatch(walletActions.getPaymentListForAdmin(paymentListReq))
  }, [])

  const inputChangeCreate = e => {
    e.preventDefault()
    const { name, value } = e.target
    setFieldsGame(prevState => ({ ...prevState, [name]: value }))
    setErrorsGame(prevState => ({ ...prevState, [name]: '' }))
  }


  const rejectRequest = data => {
    let reqData = {
      paymentId: data._id,
      status: 2
    }

    let gamePageRefresh = {
      type: 'DEPOSIT',
      fromDate: '',
      toDate: '',
      keyWord: '',
      sortBy: '',
      sortOrder: '',
      pageNo: pageNo,
      size: size
    }

    confirmAlert({
      title: 'Confirm to Reject?',
      message: `Are you sure to Rejected Request ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () =>
            dispatch(
              walletActions.approvePaymentByAdmin(reqData, gamePageRefresh)
            )
        },
        {
          label: 'No'
        }
      ]
    })
  }
  const approveRequest = data => {
    let reqData = {
      paymentId: data._id,
      status: 1
    }

    let gamePageRefresh = {
      type: 'DEPOSIT',
      fromDate: '',
      toDate: '',
      keyWord: '',
      sortBy: '',
      sortOrder: '',
      pageNo: pageNo,
      size: size
    }

    confirmAlert({
      title: 'Confirm to Approve?',
      message: `Are you sure to Accepted Request ?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () =>
            dispatch(
              walletActions.approvePaymentByAdmin(reqData, gamePageRefresh)
            )
        },
        {
          label: 'No'
        }
      ]
    })
  }

  // const handlePageClick = (data) => {
  //   let offset = Math.ceil(data.selected * size);
  //   setOffset(offset);
  //    setPageNo(data.selected+1);
  //   let pageReq = {
  //     "type": "DEPOSIT",
  //     "fromDate": "",
  //     "toDate": "",
  //     "keyWord": "",
  //     "sortBy": "",
  //     "sortOrder": "",
  //     "pageNo": data.selected + 1,
  //     "size": size
  //   }
  //   dispatch(walletActions.getPaymentListForAdmin(pageReq));
  // }

  const handleInputSearch = e => {
    e.preventDefault()
    const { name, value } = e.target
    setFieldsGame(prevState => ({ ...prevState, [name]: value }))
    setErrorsGame(prevState => ({ ...prevState, [name]: '' }))
    let pageReq = {
      type: 'DEPOSIT',
      keyWord: value,
      pageNo: 1,
      sortBy: 'slug',
      sortOrder: 'asc',
      fromDate: '',
      toDate: '',
      size: 100
    }
    dispatch(walletActions.getPaymentListForAdmin(pageReq))
  }

  const handleInputSize = e => {
    e.preventDefault()
    const { name, value } = e.target
    setFieldsGame(prevState => ({ ...prevState, [name]: value }))
    setErrorsGame(prevState => ({ ...prevState, [name]: '' }))
    let pageReq = {
      type: 'DEPOSIT',
      keyWord: '',
      pageNo: 1,
      sortBy: 'slug',
      sortOrder: 'asc',
      fromDate: '',
      toDate: '',
      size: value
    }
    dispatch(walletActions.getPaymentListForAdmin(pageReq))
  }

  return (
    <>
      <div className='z-0 overflow-hidden overflow-y-auto focus:outline-none'>
        <div className='relative'>
          <div className='relative z-20 flex min-h-screen'>
            <div className='flex w-full overflow-hidden '>
              <div className='flex flex-col flex-1 overflow-hidden'>
                <main className='relative flex-1 '>
                  <div className='p-3 2xl:p-10 sm:p-5'>
                    <div className='mx-auto max-w-screen-3xl space-y-3'>
                      <div className='w-full grid grid-cols-3 gap-3'>
                        <div className='w-full col-span-1 bg-white rounded-xl'>
                          <div className='bgcolor/70 rounded-t-xl p-4 pb-8 flex flex-col justify-start'>
                            <span className='text-[#7D1970] font-semibold'>
                              Welcome Back!
                            </span>
                            <span className='text-[#7D1970]'>
                              Admin Dashboard
                            </span>
                          </div>
                          <div className='w-full p-4 pb-6 flex justify-between items-start'>
                            <div className='relative w-full flex flex-col justify-start'>
                              <div className='absolute -top-8 bg-white w-28 h-28 p-1 rounded-full'>
                                <img
                                  src='/loginBg.png'
                                  className='rounded-full w-full h-full'
                                  alt='logo'
                                />
                              </div>
                            </div>
                            <div className='w-full flex flex-col justify-start'>
                              <span className='text-black font-semibold'>
                                352
                              </span>
                              <span className='text-black'>Approved Users</span>
                            </div>
                            <div className='w-full flex flex-col justify-start'>
                              <span className='text-black font-semibold'>
                                3
                              </span>
                              <span className='text-black'>
                                Unapproved Users
                              </span>
                            </div>
                          </div>
                          <div className='p-4 flex flex-col justify-start'>
                            <span className='text-black font-semibold'>
                              Taj777
                            </span>
                            <span className='text-black'>Admin</span>
                          </div>
                        </div>
                        <div className='w-full col-span-2 p-5 space-y-2 bg-white rounded-xl'>
                          <div className='flex justify-between items-center border-b border-gray-400 pb-3'>
                            <span className='text-lg text-left font-semibold text-gray-900'>
                              Personal Information
                            </span>
                          </div>
                          <div className='flex space-x-5 border-b border-gray-400'>
                            <div className='flex w-full'>
                              <label
                                class='block text-gray-700 text-base whitespace-nowrap'
                                for='name'
                              >
                                Full Name:
                              </label>
                              <input
                                className='w-full px-3 py-2 text-base bg-white focus:outline-none'
                                id='userName'
                                name='userName'
                                value={
                                  fieldsGame && fieldsGame['userName']
                                    ? fieldsGame['userName']
                                    : ''
                                }
                                type='text'
                                onChange={inputChangeCreate}
                              />
                              {errorsGame && errorsGame['userName'] ? (
                                <div className='text-yellow-600 invalid-feedback'>
                                  {errorsGame['userName']}
                                </div>
                              ) : null}
                            </div>
                            <div className='flex w-full'>
                              <label
                                class='block text-gray-700 text-base'
                                for='name'
                              >
                                Email:
                              </label>
                              <input
                                className='w-full px-3 py-2 text-base bg-white focus:outline-none'
                                id='email'
                                name='email'
                                value={
                                  fieldsGame && fieldsGame['email']
                                    ? fieldsGame['email']
                                    : ''
                                }
                                type='text'
                                onChange={inputChangeCreate}
                              />
                              {errorsGame && errorsGame['email'] ? (
                                <div className='text-yellow-600 invalid-feedback'>
                                  {errorsGame['email']}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className='flex space-x-5 border-b border-gray-400'>
                            <div className='flex w-full'>
                              <label
                                class='block text-gray-700 text-base'
                                for='name'
                              >
                                Mobile:
                              </label>
                              <input
                                className='w-full px-3 py-2 text-base bg-white focus:outline-none'
                                id='mobNo'
                                name='mobNo'
                                value={
                                  fieldsGame && fieldsGame['mobNo']
                                    ? fieldsGame['mobNo']
                                    : ''
                                }
                                type='text'
                                onChange={inputChangeCreate}
                              />
                              {errorsGame && errorsGame['mobNo'] ? (
                                <div className='text-yellow-600 invalid-feedback'>
                                  {errorsGame['mobNo']}
                                </div>
                              ) : null}
                            </div>
                            <div className='flex w-full'>
                              <label
                                class='block text-gray-700 text-base'
                                for='name'
                              >
                                Password:
                              </label>
                              <input
                                className='w-full px-3 py-2 text-base bg-white focus:outline-none'
                                id='password'
                                name='password'
                                value={
                                  fieldsGame && fieldsGame['password']
                                    ? fieldsGame['password']
                                    : ''
                                }
                                type='text'
                                onChange={inputChangeCreate}
                              />
                              {errorsGame && errorsGame['password'] ? (
                                <div className='text-yellow-600 invalid-feedback'>
                                  {errorsGame['password']}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className='flex w-full'>
                            <label
                              class='block text-gray-700 text-base whitespace-nowrap'
                              for='name'
                            >
                              Creation Date:
                            </label>
                            <input
                              className='w-full px-3 py-2 text-base bg-white focus:outline-none'
                              id='createdAt'
                              name='createdAt'
                              value={
                                fieldsGame && fieldsGame['createdAt']
                                  ? fieldsGame['createdAt']
                                  : ''
                              }
                              type='text'
                              onChange={inputChangeCreate}
                            />
                            {errorsGame && errorsGame['createdAt'] ? (
                              <div className='text-yellow-600 invalid-feedback'>
                                {errorsGame['createdAt']}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>

                      <div className='w-full p-5 space-y-2 bg-white rounded-xl'>
                        <div className='flex justify-between items-center border-b border-gray-400 pb-3'>
                          <span className='text-lg text-left font-semibold text-gray-900'>
                            Payment Information
                          </span>
                        </div>
                        <div className='flex space-x-5 border-b border-gray-400'>
                          <div className='flex w-full'>
                            <label
                              class='block text-gray-700 text-base whitespace-nowrap'
                              for='name'
                            >
                              Bank Name:
                            </label>
                            <input
                              className='w-full px-3 py-2 text-base bg-white focus:outline-none'
                              id='userName'
                              name='userName'
                              value={
                                fieldsGame && fieldsGame['userName']
                                  ? fieldsGame['userName']
                                  : ''
                              }
                              type='text'
                              onChange={inputChangeCreate}
                            />
                            {errorsGame && errorsGame['userName'] ? (
                              <div className='text-yellow-600 invalid-feedback'>
                                {errorsGame['userName']}
                              </div>
                            ) : null}
                          </div>
                          <div className='flex w-full'>
                            <label
                              class='block text-gray-700 text-base whitespace-nowrap'
                              for='name'
                            >
                              Branch Address:
                            </label>
                            <input
                              className='w-full px-3 py-2 text-base bg-white focus:outline-none'
                              id='email'
                              name='email'
                              value={
                                fieldsGame && fieldsGame['email']
                                  ? fieldsGame['email']
                                  : ''
                              }
                              type='text'
                              onChange={inputChangeCreate}
                            />
                            {errorsGame && errorsGame['email'] ? (
                              <div className='text-yellow-600 invalid-feedback'>
                                {errorsGame['email']}
                              </div>
                            ) : null}
                          </div>
                          <div className='w-full'></div>
                        </div>
                        <div className='flex space-x-5 border-b border-gray-400'>
                          <div className='flex w-full'>
                            <label
                              class='block text-gray-700 text-base whitespace-nowrap'
                              for='name'
                            >
                              A/C Holder Name:
                            </label>
                            <input
                              className='w-full px-3 py-2 text-base bg-white focus:outline-none'
                              id='mobNo'
                              name='mobNo'
                              value={
                                fieldsGame && fieldsGame['mobNo']
                                  ? fieldsGame['mobNo']
                                  : ''
                              }
                              type='text'
                              onChange={inputChangeCreate}
                            />
                            {errorsGame && errorsGame['mobNo'] ? (
                              <div className='text-yellow-600 invalid-feedback'>
                                {errorsGame['mobNo']}
                              </div>
                            ) : null}
                          </div>
                          <div className='flex w-full'>
                            <label
                              class='block text-gray-700 text-base whitespace-nowrap'
                              for='name'
                            >
                              A/C Number:
                            </label>
                            <input
                              className='w-full px-3 py-2 text-base bg-white focus:outline-none'
                              id='password'
                              name='password'
                              value={
                                fieldsGame && fieldsGame['password']
                                  ? fieldsGame['password']
                                  : ''
                              }
                              type='text'
                              onChange={inputChangeCreate}
                            />
                            {errorsGame && errorsGame['password'] ? (
                              <div className='text-yellow-600 invalid-feedback'>
                                {errorsGame['password']}
                              </div>
                            ) : null}
                          </div>
                          <div className='flex w-full'>
                            <label
                              class='block text-gray-700 text-base whitespace-nowrap'
                              for='name'
                            >
                              IFSC Code:
                            </label>
                            <input
                              className='w-full px-3 py-2 text-base bg-white focus:outline-none'
                              id='createdAt'
                              name='createdAt'
                              value={
                                fieldsGame && fieldsGame['createdAt']
                                  ? fieldsGame['createdAt']
                                  : ''
                              }
                              type='text'
                              onChange={inputChangeCreate}
                            />
                            {errorsGame && errorsGame['createdAt'] ? (
                              <div className='text-yellow-600 invalid-feedback'>
                                {errorsGame['createdAt']}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className='flex space-x-5 border-b border-gray-400'>
                          <div className='flex w-full'>
                            <label
                              class='block text-gray-700 text-base whitespace-nowrap'
                              for='name'
                            >
                              PhonePe No.:
                            </label>
                            <input
                              className='w-full px-3 py-2 text-base bg-white focus:outline-none'
                              id='mobNo'
                              name='mobNo'
                              value={
                                fieldsGame && fieldsGame['mobNo']
                                  ? fieldsGame['mobNo']
                                  : ''
                              }
                              type='text'
                              onChange={inputChangeCreate}
                            />
                            {errorsGame && errorsGame['mobNo'] ? (
                              <div className='text-yellow-600 invalid-feedback'>
                                {errorsGame['mobNo']}
                              </div>
                            ) : null}
                          </div>
                          <div className='flex w-full'>
                            <label
                              class='block text-gray-700 text-base whitespace-nowrap'
                              for='name'
                            >
                              GooglePe No.:
                            </label>
                            <input
                              className='w-full px-3 py-2 text-base bg-white focus:outline-none'
                              id='password'
                              name='password'
                              value={
                                fieldsGame && fieldsGame['password']
                                  ? fieldsGame['password']
                                  : ''
                              }
                              type='text'
                              onChange={inputChangeCreate}
                            />
                            {errorsGame && errorsGame['password'] ? (
                              <div className='text-yellow-600 invalid-feedback'>
                                {errorsGame['password']}
                              </div>
                            ) : null}
                          </div>
                          <div className='flex w-full'>
                            <label
                              class='block text-gray-700 text-base whitespace-nowrap'
                              for='name'
                            >
                              Paytm No.:
                            </label>
                            <input
                              className='w-full px-3 py-2 text-base bg-white focus:outline-none'
                              id='createdAt'
                              name='createdAt'
                              value={
                                fieldsGame && fieldsGame['createdAt']
                                  ? fieldsGame['createdAt']
                                  : ''
                              }
                              type='text'
                              onChange={inputChangeCreate}
                            />
                            {errorsGame && errorsGame['createdAt'] ? (
                              <div className='text-yellow-600 invalid-feedback'>
                                {errorsGame['createdAt']}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>

                      <div className='bg-white rounded-xl'>
                        <div className='flex justify-between items-center px-3 py-2 border-b'>
                          <span className='p-5 text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white dark:text-white dark:bg-gray-800'>
                            Fund Request Auto Deposit History
                          </span>
                        </div>
                        <div className='relative overflow-x-auto p-5'>
                          <div className='flex justify-between items-center px-3 py-2 border-b'>
                            <div>
                              <label
                                class='block text-gray-700 text-base mb-2'
                                for='name'
                              >
                                Show
                              </label>
                              <select
                                className={`w-full px-3 py-2 text-base bg-white border border-gray-400 rounded-md focus:outline-none`}
                                id='size'
                                name='size'
                                placeholder='name'
                                value={
                                  fieldsGame && fieldsGame['size']
                                    ? fieldsGame['size']
                                    : ''
                                }
                                type='text'
                                onChange={handleInputSize}
                              >
                                <option value='10'>10</option>
                                <option value='20'>20</option>
                                <option value='50'>50</option>
                              </select>
                              <label
                                class='block text-gray-700 text-base mb-2'
                                for='name'
                              >
                                entries
                              </label>
                            </div>
                            <div className='w-64 mt-1 shadow-sm'>
                              <label
                                class='block text-gray-700 text-base text-right mb-2'
                                for='gamename'
                              >
                                Search
                              </label>
                              <input
                                className={`w-full px-3 py-2 text-base bg-white border border-gray-400 rounded-md focus:outline-none`}
                                id='search'
                                name='search'
                                value={
                                  fieldsGame && fieldsGame['search']
                                    ? fieldsGame['search']
                                    : ''
                                }
                                type='text'
                                onChange={handleInputSearch}
                              />
                              {errorsGame && errorsGame['search'] ? (
                                <div className='text-yellow-600 invalid-feedback'>
                                  {errorsGame['search']}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <table className='w-full text-base text-left rtl:text-right text-gray-500 dark:text-gray-400'>
                            <thead className='text-gray-700 capitalize bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
                              <tr>
                                <th className='px-6 py-3'>S.No.</th>
                                <th className='px-6 py-3'>name</th>
                                <th className='px-6 py-3'>mobile</th>
                                <th className='px-6 py-3'>amount</th>
                                <th className='px-6 py-3'>Date</th>
                                <th className='px-6 py-3'>action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {paymentList && paymentList.length > 0
                                ? paymentList.map((element, index) => (
                                    <React.Fragment key={index}>
                                      <tr
                                        key={index}
                                        className='bg-white border-b border-gray-400'
                                      >
                                        <th
                                          scope='row'
                                          className='px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white'
                                        >
                                          {offset + index + 1}
                                        </th>
                                        <th
                                          scope='row'
                                          className='px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white'
                                        >
                                          {element &&
                                          element.userId &&
                                          element.userId.userName
                                            ? element.userId.userName
                                            : '-'}
                                        </th>
                                        <th
                                          scope='row'
                                          className='px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white'
                                        >
                                          {element &&
                                          element.userId &&
                                          element.userId.mobNo
                                            ? element.userId.mobNo
                                            : '-'}
                                        </th>
                                        <th
                                          scope='row'
                                          className='px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white'
                                        >
                                          {element && element.amount
                                            ? element.amount
                                            : '-'}
                                        </th>
                                        <th
                                          scope='row'
                                          className='px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white'
                                        >
                                          {/* {element && element.createdAt ? moment(element.createdAt * 1000).utcOffset("Asia/Kolkata +5:30").format("YYYY-MM-DD HH:mm:ss") : "NA"} */}
                                          {element && element.createdAt
                                            ? moment(element.createdAt).format(
                                                'YYYY-MM-DD HH:mm:ss'
                                              )
                                            : 'NA'}
                                        </th>
                                        <th
                                          scope='row'
                                          className='px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white'
                                        >
                                          {element && element.status === 1 ? (
                                            <span className='relative '>
                                              <button className='px-4 py-2 font-medium tracking-wider text-blue-100 bg-green-500 border rounded-full shadow-sm'>
                                                Accepted
                                              </button>
                                            </span>
                                          ) : element &&
                                            element.status === 2 ? (
                                            <span className='relative '>
                                              <button className='px-4 py-2 font-medium tracking-wider text-blue-100 bg-red-500 border rounded-full shadow-sm'>
                                                Rejected
                                              </button>
                                            </span>
                                          ) : (
                                            <span className='relative '>
                                              <button className='px-4 py-2 font-medium tracking-wider text-blue-100 bg-yellow-500 border rounded-full shadow-sm'>
                                                Pending
                                              </button>
                                            </span>
                                          )}
                                        </th>
                                        {element && element.status === 0 ? (
                                          <>
                                            <td className='px-6 py-4 text-right'>
                                              <span className='relative '>
                                                <button
                                                  className='px-4 py-2 font-medium tracking-wider text-blue-100 bg-green-500 border rounded-full hover:shadow-lg hover:bg-green-300'
                                                  onClick={() =>
                                                    approveRequest(element)
                                                  }
                                                >
                                                  Approve
                                                </button>
                                              </span>
                                              <span className='relative '>
                                                <button
                                                  className='px-4 py-2 font-medium tracking-wider text-blue-100 bg-red-500 border rounded-full hover:shadow-lg hover:bg-red-300'
                                                  onClick={() =>
                                                    rejectRequest(element)
                                                  }
                                                >
                                                  Reject
                                                </button>
                                              </span>
                                            </td>
                                          </>
                                        ) : (
                                          <>
                                            <td className='px-6 py-4 text-right'>
                                              <span className='relative '>
                                                <button className='px-4 py-2 font-medium tracking-wider text-blue-100 border rounded-full bg-green-300'>
                                                  Approve
                                                </button>
                                              </span>
                                              <span className='relative '>
                                                <button className='px-4 py-2 font-medium tracking-wider text-blue-100 border rounded-full bg-red-300'>
                                                  Reject
                                                </button>
                                              </span>
                                            </td>
                                          </>
                                        )}
                                      </tr>
                                    </React.Fragment>
                                  ))
                                : null}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Dashboard
