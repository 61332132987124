/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router'
import { IoChevronBack } from 'react-icons/io5'
import { karachiMarketActions, userActions } from '../../../../_actions'
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import DashboardJSON from '../../MarketManagement.json'
import Loader from '../../../../components/Loader/Loader'

const EditKarachiMarket = () => {
  const dispatch = useDispatch()
  const { marketId } = useParams()
  const navigate = useNavigate()
  let selector = useSelector(state => state)
  const { karachiMarketDataIdWise } = useSelector(state => state.karachiMarket)
  const [fieldsUpdateMarket, setFieldsUpdateMarket] = useState({})
  const [errorsUpdateMarket, setErrorsUpdateMarket] = useState({})
  useEffect(() => {
    let gameReq = {
      karachiMarketId: marketId
    }
    dispatch(
      karachiMarketActions.getKarachiMarketByKarachiMarketIdForAdmin(gameReq)
    )
  }, [])

  useEffect(() => {
    dispatch(userActions.clearImage())
    if (karachiMarketDataIdWise) {
      setFieldsUpdateMarket(karachiMarketDataIdWise)
    }
  }, [karachiMarketDataIdWise])

  const inputChangeCreate = e => {
    e.preventDefault()
    const { name, value } = e.target
    setFieldsUpdateMarket(prevState => ({ ...prevState, [name]: value }))
    setErrorsUpdateMarket(prevState => ({ ...prevState, [name]: '' }))
  }

  const updateMarketSubmit = e => {
    e.preventDefault()
    let { users } = selector ? selector : {}
    let { fileData } = users ? users : {}
    if (handleValidationUpdateMarket()) {
      const { name, point, sPoint, fPoint, shortName, _id } = fieldsUpdateMarket
      if (name === 'FS Ring') {
        dispatch(
          karachiMarketActions.updateKarachiMarket({
            name: name && name.trim(),
            image:
              fileData ||
              fieldsUpdateMarket.image ||
              'https://res.cloudinary.com/dnd2isyjo/image/upload/v1712923995/your_folder_name/cmbpkevpflbvf9l0c6v0.png',
            shortName: shortName && shortName.trim(),
            point: point,
            fPoint: fPoint,
            sPoint: sPoint,
            id: _id
          })
        )
      } else {
        dispatch(
          karachiMarketActions.updateKarachiMarket({
            name: name && name.trim(),
            image:
              fileData ||
              fieldsUpdateMarket.image ||
              'https://res.cloudinary.com/dnd2isyjo/image/upload/v1712923995/your_folder_name/cmbpkevpflbvf9l0c6v0.png',
            shortName: shortName && shortName.trim(),
            point: point,
            id: _id
          })
        )
      }
    }
  }

  const handleValidationUpdateMarket = () => {
    let formIsValid = true
    let errors = {}

    if (!fieldsUpdateMarket.name || fieldsUpdateMarket.name.trim() === '') {
      formIsValid = false
      errors.name = DashboardJSON.ErrorMsg.name
    }

    if (!fieldsUpdateMarket.point || fieldsUpdateMarket.point === '') {
      formIsValid = false
      errors.point = DashboardJSON.ErrorMsg.point
    }

    setErrorsUpdateMarket(errors)
    return formIsValid
  }

  let { users } = selector ? selector : {}
  let { loading } = users ? users : {}

  return (
    <>
      <Loader loading={loading} />
      <div className='z-0 overflow-hidden overflow-y-auto focus:outline-none'>
        <div className='relative'>
          <div className='relative z-20 flex min-h-screen'>
            <div className='flex w-full overflow-hidden '>
              <div className='flex flex-col flex-1 overflow-hidden'>
                <main className='relative flex-1 '>
                  <div className='p-6'>
                    <IoChevronBack
                      className='h-8 w-8 bg-black/20 hover:bg-red-100 rounded-full cursor-pointer'
                      onClick={() => navigate('/app/karachi-Management')}
                    />
                  </div>
                  <div>
                    <div className='p-5 2xl:p-10'>
                      <div className='max-w-5xl mx-auto space-y-5  shadow-lg p-4'>
                        <div className='flex justify-between items-center border-b border-gray-400 pb-3'>
                          <span className='text-lg text-left font-semibold text-gray-900'>
                            Edit Market
                          </span>
                        </div>
                        <div className='flex space-x-5 border-b border-gray-400'>
                          <div className='flex items-center w-full'>
                            <label
                              class='block text-gray-700 text-base whitespace-nowrap'
                              for='name'
                            >
                              Name :
                            </label>
                            <input
                              className='w-full px-3 py-2 text-base bg-white focus:outline-none'
                              id='name'
                              name='name'
                              value={
                                fieldsUpdateMarket && fieldsUpdateMarket['name']
                                  ? fieldsUpdateMarket['name']
                                  : ''
                              }
                              type='text'
                              onChange={inputChangeCreate}
                            />
                            {errorsUpdateMarket &&
                            errorsUpdateMarket['name'] ? (
                              <div className='text-red-600  whitespace-nowrap invalid-feedback'>
                                {errorsUpdateMarket['name']}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className='flex space-x-5 border-b border-gray-400'>
                          <div className='flex items-center w-full'>
                            <label
                              class='block text-gray-700 text-base whitespace-nowrap'
                              for='name'
                            >
                              Point :
                            </label>
                            <input
                              className='w-full px-3 py-2 text-base bg-white focus:outline-none'
                              id='point'
                              name='point'
                              value={
                                fieldsUpdateMarket &&
                                fieldsUpdateMarket['point']
                                  ? fieldsUpdateMarket['point']
                                  : ''
                              }
                              type='number'
                              onChange={inputChangeCreate}
                            />
                            {errorsUpdateMarket &&
                            errorsUpdateMarket['point'] ? (
                              <div className='text-red-600  whitespace-nowrap invalid-feedback'>
                                {errorsUpdateMarket['point']}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        {fieldsUpdateMarket &&
                          fieldsUpdateMarket['name'] &&
                          fieldsUpdateMarket['name'] === 'FS Ring' && (
                            <div className='flex space-x-5 border-b border-gray-400'>
                              <div className='flex items-center w-full'>
                                <label
                                  class='block text-gray-700 text-base whitespace-nowrap'
                                  for='name'
                                >
                                  First Point :
                                </label>
                                <input
                                  className='w-full px-3 py-2 text-sm bg-white focus:outline-none'
                                  id='fPoint'
                                  name='fPoint'
                                  value={
                                    fieldsUpdateMarket &&
                                    fieldsUpdateMarket['fPoint']
                                      ? fieldsUpdateMarket['fPoint']
                                      : ''
                                  }
                                  type='number'
                                  onChange={inputChangeCreate}
                                />
                                {errorsUpdateMarket &&
                                errorsUpdateMarket['fPoint'] ? (
                                  <div className='text-red-600  whitespace-nowrap invalid-feedback'>
                                    {errorsUpdateMarket['fPoint']}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          )}

                        {fieldsUpdateMarket &&
                          fieldsUpdateMarket['name'] &&
                          fieldsUpdateMarket['name'] === 'FS Ring' && (
                            <div className='flex space-x-5 border-b border-gray-400'>
                              <div className='flex items-center w-full'>
                                <label
                                  class='block text-gray-700 text-base whitespace-nowrap'
                                  for='name'
                                >
                                  Second Point :
                                </label>
                                <input
                                  className='w-full px-3 py-2 text-sm bg-white focus:outline-none'
                                  id='sPoint'
                                  name='sPoint'
                                  value={
                                    fieldsUpdateMarket &&
                                    fieldsUpdateMarket['sPoint']
                                      ? fieldsUpdateMarket['sPoint']
                                      : ''
                                  }
                                  type='number'
                                  onChange={inputChangeCreate}
                                />
                                {errorsUpdateMarket &&
                                errorsUpdateMarket['sPoint'] ? (
                                  <div className='text-red-600  whitespace-nowrap invalid-feedback'>
                                    {errorsUpdateMarket['sPoint']}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          )}
                        <div className='flex justify-center w-full'>
                          <button
                            className='px-6 py-2 font-medium text-white all-btn w-44'
                            onClick={e => updateMarketSubmit(e)}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EditKarachiMarket
