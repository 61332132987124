/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { gamesActions, reportActions, userActions } from '../../_actions'
import { confirmAlert } from 'react-confirm-alert'
import Loader from '../../components/Loader/Loader'
import { Link } from 'react-router-dom'
import moment from 'moment'
import ReactPaginate from 'react-paginate'
import { MdDelete } from 'react-icons/md'
const keyWord = ''
const fromDate = ''
const toDate = ''
const StarlineBidHistory = () => {
  let selector = useSelector(state => state)
  const dispatch = useDispatch()
  let { games } = selector ? selector : {}
  let { allMarket, gamesListb } = games ? games : {}
  const [fieldsGame, setFieldsGame] = useState({})
  const [errorsGame, setErrorsGame] = useState({})

  const {
    reportsList,
    reportsTotal,
    loading,
    amountsPlaced,
    totalWinAmount,
    totalLossAmount,
    downLineData
  } = useSelector(state => state.reports)
  const [size, setSize] = useState(10)
  const [offset, setOffset] = useState(0)
  const [pageNo, setPageNo] = useState(1)
  const [currentPage, setCurrentPage] = useState(0)
  const today = new Date().toISOString().split('T')[0]
  useEffect(() => {
    let gameReq = {
      fromDate: fromDate,
      toDate: toDate,
      keyWord: fieldsGame['Users'] || '',
      sortBy: '',
      sortOrder: '',
      pageNo: pageNo,
      size: size,
      userId: [],
      filter: 'brandGame'
    }
    let gameReq3 = {
      fromDate: '',
      toDate: '',
      keyWord: '',
      sortBy: '',
      sortOrder: '',
      pageNo: pageNo,
      size: size
    }

    dispatch(reportActions.getBetsListForAdmin(gameReq))
    dispatch(reportActions.getDownLineUsers())
    dispatch(gamesActions.getAllMarket())
    dispatch(gamesActions.getAllUser())
    dispatch(gamesActions.getAllbrandGame(gameReq3))
  }, [])


  const inputChangeCreate = e => {
    e.preventDefault()
    const { name, value } = e.target
    setFieldsGame(prevState => ({ ...prevState, [name]: value }))
    setErrorsGame(prevState => ({ ...prevState, [name]: '' }))
  }

  const createGameSubmit = e => {
    e.preventDefault()
    let gameReq2 = {
      fromDate: fieldsGame?.date || '',
      toDate: fieldsGame?.todate || '',
      keyWord: fieldsGame['Users'] || '',
      gameId: fieldsGame?.userId,
      marketId: fieldsGame?.userId2,
      pageNo: pageNo,
      size: size,
      userId: [],
      filter: 'brandGame'
    }
    dispatch(reportActions.getBetsListForAdmin(gameReq2))
  }

  const handlePageClick = data => {
    let offset = Math.ceil(data.selected * size)
    setOffset(offset)
    setCurrentPage(data.selected)
    setPageNo(data.selected + 1)
    let pageReq = {
      fromDate: fromDate,
      toDate: toDate,
      keyWord: fieldsGame['Users'] || keyWord,
      sortBy: '',
      sortOrder: '',
      pageNo: data.selected + 1,
      size: size,
      userId: [],
      filter: 'brandGame'
    }

    dispatch(reportActions.getBetsListForAdmin(pageReq))
  }

  const handleInputSize = e => {
    e.preventDefault()
    const { name, value } = e.target
    setFieldsGame(prevState => ({ ...prevState, [name]: value }))
    setErrorsGame(prevState => ({ ...prevState, [name]: '' }))
    setSize(e.target.value)
    let pageReq = {
      keyWord: fieldsGame['Users'] || keyWord,
      pageNo: 1,
      sortBy: 'slug',
      sortOrder: 'asc',
      fromDate: fromDate,
      toDate: toDate,
      size: value,
      userId:[],
      filter: 'brandGame'
    }
    dispatch(reportActions.getBetsListForAdmin(pageReq))
  }

  const handleDelete = data => {
    let gameReq = {
      fromDate: fromDate,
      toDate: toDate,
      keyWord: keyWord,
      sortBy: '',
      sortOrder: '',
      pageNo: pageNo,
      size: size,
      userId: []
    }

    confirmAlert({
      title: 'Confirm to Delete',
      message: 'Are you sure you want to Delete Bid',
      buttons: [
        {
          label: 'Yes',
          onClick: () => dispatch(userActions.DeleteBet(data, gameReq))
        },
        {
          label: 'No'
        }
      ]
    })
  }
  let sessiondata = JSON.parse(window.sessionStorage.getItem('adminuser'))

  let optionsData = gamesListb && gamesListb.length > 0 && gamesListb

  return (
    <>
      <Loader loading={loading} />
      <div className='z-0 overflow-hidden overflow-y-auto focus:outline-none'>
        <div className='relative'>
          <div className='relative z-20 flex min-h-screen'>
            <div className='flex w-full overflow-hidden '>
              <div className='flex flex-col flex-1 overflow-hidden'>
                <main className='relative flex-1 '>
                  <div className='p-3 sm:p-5'>
                    <div className='mx-auto max-w-screen-3xl space-y-2'>
                      <div className='px-4  text-left  space-y-2 rounded-lg'>
                        <div className='flex justify-between md:flex-nowrap  gap-3 flex-wrap items-end  w-full '>
                          <div className='mt-1 shadow-sm w-full'>
                            <label
                              class=' mb-1 block  text-base font-medium text-gray-900'
                              for='gamename'
                            >
                              From Date :
                            </label>
                            <input
                              className='w-full px-3 py-1.5 text-base bg-white border border-gray-400 rounded focus:outline-none'
                              id='date'
                              name='date'
                              placeholder='Enter date'
                              value={
                                fieldsGame && fieldsGame['date']
                                  ? fieldsGame['date']
                                  : ''
                              }
                              max={today}
                              type='date'
                              onChange={inputChangeCreate}
                            />
                            {errorsGame && errorsGame['date'] ? (
                              <div className='text-red-600 invalid-feedback'>
                                {errorsGame['date']}
                              </div>
                            ) : null}
                          </div>

                          <div className='mt-1 shadow-sm w-full'>
                            <label
                              class=' mb-1 block  text-base font-medium text-gray-900'
                              for='gamename'
                            >
                              To Date :
                            </label>
                            <input
                              className='w-full px-3 py-1.5 text-base bg-white border border-gray-400 rounded focus:outline-none'
                              id='date'
                              name='todate'
                              placeholder='Enter date'
                              value={
                                fieldsGame && fieldsGame['todate']
                                  ? fieldsGame['todate']
                                  : ''
                              }
                              max={today}
                              type='date'
                              onChange={inputChangeCreate}
                            />
                            {errorsGame && errorsGame['todate'] ? (
                              <div className='text-red-600 invalid-feedback'>
                                {errorsGame['todate']}
                              </div>
                            ) : null}
                          </div>

                          <div className='mt-1 shadow-sm w-full'>
                            <label
                              class=' mb-1 block  text-base font-medium text-gray-900'
                              for='name'
                            >
                              Game Name :
                            </label>
                            <select
                              className='w-full px-3 py-2  capitalize text-base bg-white border border-gray-400 rounded focus:outline-none'
                              id='userId'
                              name='userId'
                              placeholder='name'
                              value={
                                fieldsGame && fieldsGame['userId']
                                  ? fieldsGame['userId']
                                  : ''
                              }
                              type='text'
                              onChange={inputChangeCreate}
                            >
                              <option value=''>Select Game Name</option>
                              {optionsData && optionsData.length > 0
                                ? optionsData.map((element, index) => (
                                    <option
                                      value={
                                        element && element._id
                                          ? element._id
                                          : ''
                                      }
                                    >
                                      {element && element.name
                                        ? element.name
                                        : ''}
                                    </option>
                                  ))
                                : null}
                            </select>
                            {errorsGame && errorsGame['userId'] ? (
                              <div className='text-red-600 invalid-feedback'>
                                {errorsGame['userId']}
                              </div>
                            ) : null}
                          </div>

                          <div className='mt-1 shadow-sm w-full'>
                            <label
                              class=' mb-1 block  text-base font-medium text-gray-900'
                              for='name'
                            >
                              Game Type :
                            </label>
                            <select
                              className='w-full px-3 py-2 text-base bg-white border border-gray-400 rounded focus:outline-none'
                              id='userId2'
                              name='userId2'
                              placeholder='name'
                              value={
                                fieldsGame && fieldsGame['userId2']
                                  ? fieldsGame['userId2']
                                  : ''
                              }
                              type='text'
                              onChange={inputChangeCreate}
                            >
                              <option value=''>Select Game Type</option>
                              {allMarket && allMarket.length > 0
                                ? allMarket
                                    ?.filter(e => !e.isJackpot && e.isStarLine)
                                    ?.map((element, index) => (
                                      <option
                                        value={
                                          element && element._id
                                            ? element._id
                                            : ''
                                        }
                                      >
                                        {element && element.name
                                          ? element.name
                                          : ''}
                                      </option>
                                    ))
                                : null}
                            </select>
                            {errorsGame && errorsGame['userId2'] ? (
                              <div className='text-red-600 invalid-feedback'>
                                {errorsGame['userId2']}
                              </div>
                            ) : null}
                          </div>
                          <div className='mt-1 shadow-sm w-full'>
                            <label
                              class=' mb-1 block  text-base font-medium text-gray-900'
                              for='name'
                            >
                              User :
                            </label>
                            <select
                              className='w-full px-3 py-2 text-base bg-white border border-gray-400 rounded focus:outline-none'
                              id='Users'
                              name='Users'
                              placeholder='name'
                              value={
                                fieldsGame && fieldsGame['Users']
                                  ? fieldsGame['Users']
                                  : ''
                              }
                              type='text'
                              onChange={inputChangeCreate}
                            >
                              <option value=''>Select User</option>
                              {downLineData && downLineData.length > 0
                                ? downLineData.map((element, index) => (
                                    <option
                                      value={
                                        element && element._id
                                          ? element._id
                                          : ''
                                      }
                                    >
                                      {element && element.userName
                                        ? element.userName
                                        : ''}
                                    </option>
                                  ))
                                : null}
                            </select>
                            {errorsGame && errorsGame['Users'] ? (
                              <div className='text-red-600 invalid-feedback'>
                                {errorsGame['Users']}
                              </div>
                            ) : null}
                          </div>

                          <div className='w-full flex mt-2 md:mt-0 justify-start items-end'>
                            <button
                              className='flex w-full lg:w-auto justify-center px-4 py-1.5 text-base font-semibold text-white capitalize  transition duration-150 ease-in-out bgcolor border border-[#7D1970] rounded focus:outline-none'
                              type='button'
                              onClick={createGameSubmit}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className=' py-4 text-left bg-white space-y-1 rounded-lg'>
                        <div className='flex justify-between items-center px-6 border-b'>
                          <span className=' text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white '>
                            Bid History List
                          </span>
                          <div className='flex  justify-between place-items-center gap-1 items-center px-3 py-2 '>
                            <label
                              class=' mb-1 block  text-base font-medium text-gray-900'
                              for='name'
                            >
                              Data
                            </label>
                            <select
                              className={`w-full px-3 py-2 text-base bg-white border border-gray-400 rounded-md focus:outline-none`}
                              id='size'
                              name='size'
                              placeholder='name'
                              value={
                                fieldsGame && fieldsGame['size']
                                  ? fieldsGame['size']
                                  : ''
                              }
                              type='text'
                              onChange={handleInputSize}
                            >
                              <option value='10'>10</option>
                              <option value='20'>20</option>
                              <option value='50'>50</option>
                            </select>
                            {/* <label class="block text-gray-700 text-base mb-2" for="name">entries</label> */}
                          </div>
                        </div>
                        <div className='relative overflow-x-auto p-1 lg:p-5'>
                          <div>
                            <div class='w-full bg-white border border-gray-200 rounded-lg shadow '>
                              <ul
                                class=' text-base font-medium text-center text-gray-800 divide-x divide-gray-200 rounded-lg sm:flex rtl:divide-x-reverse'
                                id='fullWidthTab'
                                data-tabs-toggle='#fullWidthTabContent'
                                role='tablist'
                              >
                                <li class='w-full flex justify-center place-items-center gap-4'>
                                  <button
                                    id='stats-tab'
                                    data-tabs-target='#stats'
                                    type='button'
                                    role='tab'
                                    aria-controls='stats'
                                    aria-selected='true'
                                    class='inline-block w-full p-4 rounded-ss-lg bg-gray-50 hover:bg-gray-100 focus:outline-none '
                                  >
                                    {' '}
                                    Total Amount Placed :{' '}
                                    {(amountsPlaced &&
                                      amountsPlaced.toString()) ||
                                      ''}
                                  </button>
                                </li>
                                <li class='w-full flex justify-center place-items-center gap-4'>
                                  <button
                                    id='about-tab'
                                    data-tabs-target='#about'
                                    type='button'
                                    role='tab'
                                    aria-controls='about'
                                    aria-selected='false'
                                    class='inline-block w-full p-4 bg-gray-50 hover:bg-gray-100 focus:outline-none  '
                                  >
                                    {' '}
                                    Total Win Amount :{' '}
                                    {(totalWinAmount &&
                                      totalWinAmount.toString()) ||
                                      '0'}
                                  </button>
                                </li>
                                <li class='w-full flex justify-center place-items-center gap-4'>
                                  <button
                                    id='faq-tab'
                                    data-tabs-target='#faq'
                                    type='button'
                                    role='tab'
                                    aria-controls='faq'
                                    aria-selected='false'
                                    class='inline-block w-full p-4 rounded-se-lg bg-gray-50 hover:bg-gray-100 focus:outline-none  '
                                  >
                                    Total Loss Amount :{' '}
                                    {(totalLossAmount &&
                                      totalLossAmount.toString()) ||
                                      '0'}
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <table className='w-full text-base text-left rtl:text-right text-gray-500 shadow'>
                            <thead className='text-gray-900 capitalize bg-gray-50  '>
                              <tr>
                                <th className='px-4 py-3'>#</th>
                                <th className='px-4 py-3'>user name</th>
                                <th className='px-4 py-3'>amount</th>
                                <th className='px-4 py-3'>Bet Number</th>
                                <th className='px-4 py-3'>Game Name</th>
                                <th className='px-4 py-3'>Market Name</th>
                                <th className='px-4 py-3'>Bid Type</th>
                                <th className='px-4 py-3'>Date</th>
                                <th className='px-4 py-3'>Bet Status</th>
                                <th className='px-4 py-3'>Profit/Loss</th>
                                <th className='px-4 py-3'>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {reportsList && reportsList.length > 0 ? (
                                reportsList.map((element, index) => (
                                  <React.Fragment key={index}>
                                    <tr
                                      key={index}
                                      className='bg-white border-b border-gray-400 capitalize'
                                    >
                                      <th
                                        scope='row'
                                        className='px-4 py-2 font-medium text-gray-500 whitespace-nowrap '
                                      >
                                        {offset + index + 1}
                                      </th>
                                      <th
                                        scope='row'
                                        className='px-4 py-2  text-[#992941] font-semibold whitespace-nowrap '
                                      >
                                        <Link
                                          to={`/app/user/${element.userId?._id}`}
                                        >
                                          {element &&
                                          element.userId &&
                                          element.userId.userName
                                            ? element.userId.userName
                                            : '-'}
                                        </Link>
                                      </th>
                                      <th
                                        scope='row'
                                        className='px-4 py-2 font-medium text-gray-500 whitespace-nowrap '
                                      >
                                        {element && element.amount
                                          ? element.amount
                                          : '-'}
                                      </th>
                                      <th
                                        scope='row'
                                        className='px-4 py-2 font-medium text-gray-500 whitespace-nowrap '
                                      >
                                        {element && element.betNumber
                                          ? element.betNumber
                                          : '-'}
                                      </th>
                                      <th
                                        scope='row'
                                        className='px-4 py-2 font-medium text-gray-500 whitespace-nowrap '
                                      >
                                        {element &&
                                        element.gameId &&
                                        element.gameId.name
                                          ? element.gameId.name
                                          : '-'}
                                      </th>
                                      <th
                                        scope='row'
                                        className='px-4 py-2 font-medium text-gray-500 whitespace-nowrap '
                                      >
                                        {element &&
                                        element.marketId &&
                                        element.marketId.name
                                          ? element.marketId.name
                                          : '-'}
                                      </th>
                                      <th
                                        scope='row'
                                        className='px-4 py-2 font-medium text-gray-500 whitespace-nowrap '
                                      >
                                        {element &&
                                        element.isOpen &&
                                        element.isOpen
                                          ? 'Open'
                                          : 'Close'}
                                      </th>
                                      <th
                                        scope='row'
                                        className='px-4 py-2 font-medium text-gray-500 whitespace-nowrap '
                                      >
                                        {element && element.createdAt
                                          ? moment(
                                              new Date(
                                                parseInt(element.createdAt)
                                              )
                                            )
                                              .utcOffset('+05:30')
                                              .format('YYYY-MM-DD hh:mm:ss a')
                                          : '-'}
                                      </th>
                                      {element.betStatus === 0 && (
                                        <th
                                          scope='row'
                                          className='px-4 text-yellow-700 py-2 font-medium  whitespace-nowrap '
                                        >
                                          Pending
                                        </th>
                                      )}
                                      {element.betStatus === 1 && (
                                        <th
                                          scope='row'
                                          className='px-4 py-2 font-medium text-green-500 whitespace-nowrap '
                                        >
                                          Win
                                        </th>
                                      )}
                                      {element.betStatus === 2 && (
                                        <th
                                          scope='row'
                                          className='px-4 py-2 font-medium text-red-500 whitespace-nowrap '
                                        >
                                          Loss
                                        </th>
                                      )}

                                      <td className='px-4 py-2 text-center'>
                                        {element?.betStatus === 1 && (
                                          <div className=' text-green-500'>
                                            {element?.winAmount || '--'}
                                          </div>
                                        )}
                                        {element?.betStatus === 2 && (
                                          <div className=' text-red-500'>
                                            {element?.lossAmount || '--'}
                                          </div>
                                        )}
                                        {element?.betStatus === 0 && (
                                          <div className=' text-yellow-700'>
                                            --
                                          </div>
                                        )}
                                      </td>
                                      <td>
                                        {element?.betStatus === 0 &&
                                          sessiondata &&
                                          sessiondata.roleId === 0 && (
                                            <MdDelete
                                              onClick={() =>
                                                handleDelete(element)
                                              }
                                              className=' text-red-600 text-lg mx-auto cursor-pointer'
                                            />
                                          )}
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                ))
                              ) : (
                                <td>No Data Found</td>
                              )}
                            </tbody>
                          </table>
                        </div>
                        <nav className='relative z-0 flex justify-end mt-5 w-76'>
                          {reportsTotal && reportsTotal > size ? ( // Assuming `size` is the number of items per page
                            <ReactPaginate
                              previousLabel={'Prev'}
                              nextLabel={'Next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={Math.ceil(reportsTotal / size)} // Calculate total pages
                              marginPagesDisplayed={1} // Show 1 page before and after current page
                              pageRangeDisplayed={1} // Show up to 5 pages in pagination
                              onPageChange={handlePageClick}
                              containerClassName={'pagination'}
                              pageClassName={'page-cls'}
                              activeClassName={'active'}
                              forcePage={currentPage}
                            />
                          ) : null}
                        </nav>
                      </div>
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default StarlineBidHistory
