export const reportConstants = {

    GET_BETLIST_FOR_ADMIN_REQUEST: 'GET_BETLIST_FOR_ADMIN_REQUEST',
    GET_BETLIST_FOR_ADMIN_SUCCESS: 'GET_BETLIST_FOR_ADMIN_SUCCESS',
    GET_BETLIST_FOR_ADMIN_FAILURE: 'GET_BETLIST_FOR_ADMIN_FAILURE',

    GET_WIN_REQUEST: "GET_WIN_REQUEST",
    GET_WIN_SUCCESS: "GET_WIN_SUCCESS",
    GET_WIN_FAILURE: "GET_WIN_FAILURE",

    DELETE_KARACHI_BETS_REQUEST: "DELETE_KARACHI_BETS_REQUEST",
    DELETE_KARACHI_BETS_SUCCESS: "DELETE_KARACHI_BETS_SUCCESS",
    DELETE_KARACHI_BETS_FAILURE: "DELETE_KARACHI_BETS_FAILURE",

    GET_LEDFERS_REQUEST22: "GET_LEDFERS_REQUEST22",
    GET_LEDFERS_SUCCESS22: "GET_LEDFERS_SUCCESS22",
    GET_LEDFERS_FAILURE22: "GET_LEDFERS_FAILURE22",



    GET_BET_LISTSS_REQUEST: "GET_BET_LISTSS_REQUEST",
    GET_BET_LISTSS_SUCCESS: "GET_BET_LISTSS_SUCCESS",
    GET_BET_LISTSS_FAILURE: "GET_BET_LISTSS_FAILURE",

    GET_KARACHI_LISTSS_REQUEST: "GET_KARACHI_LISTSS_REQUEST",
    GET_KARACHI_LISTSS_SUCCESS: "GET_KARACHI_LISTSS_SUCCESS",
    GET_KARACHI_LISTSS_FAILURE: "GET_KARACHI_LISTSS_FAILURE",



    GET_NUMBER_KARACHI_WISE_MARKET_SUM_OPEN_REQUEST: "GET_NUMBER_KARACHI_WISE_MARKET_SUM_OPEN_REQUEST",
    GET_NUMBER_KARACHI_WISE_MARKET_SUM_OPEN_SUCCESS: "GET_NUMBER_KARACHI_WISE_MARKET_SUM_OPEN_SUCCESS",
    GET_NUMBER_KARACHI_WISE_MARKET_SUM_OPEN_FAILURE: "GET_NUMBER_KARACHI_WISE_MARKET_SUM_OPEN_FAILURE",


    GET_NUMBER_KARACHI_WISE_MARKET_SUM_CLOSE_REQUEST: "GET_NUMBER_KARACHI_WISE_MARKET_SUM_CLOSE_REQUEST",
    GET_NUMBER_KARACHI_WISE_MARKET_SUM_CLOSE_SUCCESS: "GET_NUMBER_KARACHI_WISE_MARKET_SUM_CLOSE_SUCCESS",
    GET_NUMBER_KARACHI_WISE_MARKET_SUM_CLOSE_FAILURE: "GET_NUMBER_KARACHI_WISE_MARKET_SUM_CLOSE_FAILURE",


    GET_NUMBER_KARACHI_WISE_MARKET_SUM_CENTER_REQUEST: "GET_NUMBER_KARACHI_WISE_MARKET_SUM_CENTER_REQUEST",
    GET_NUMBER_KARACHI_WISE_MARKET_SUM_CENTER_SUCCESS: "GET_NUMBER_KARACHI_WISE_MARKET_SUM_CENTER_SUCCESS",
    GET_NUMBER_KARACHI_WISE_MARKET_SUM_CENTER_FAILURE: "GET_NUMBER_KARACHI_WISE_MARKET_SUM_CENTER_FAILURE",


    GET_NUMBER_KARACHI_WISE_MARKET_SUM_JODI_REQUEST: "GET_NUMBER_KARACHI_WISE_MARKET_SUM_JODI_REQUEST",
    GET_NUMBER_KARACHI_WISE_MARKET_SUM_JODI_SUCCESS: "GET_NUMBER_KARACHI_WISE_MARKET_SUM_JODI_SUCCESS",
    GET_NUMBER_KARACHI_WISE_MARKET_SUM_JODI_FAILURE: "GET_NUMBER_KARACHI_WISE_MARKET_SUM_JODI_FAILURE",


    GET_NUMBER_KARACHI_WISE_MARKET_SUM_RING_REQUEST: "GET_NUMBER_KARACHI_WISE_MARKET_SUM_RING_REQUEST",
    GET_NUMBER_KARACHI_WISE_MARKET_SUM_RING_SUCCESS: "GET_NUMBER_KARACHI_WISE_MARKET_SUM_RING_SUCCESS",
    GET_NUMBER_KARACHI_WISE_MARKET_SUM_RING_FAILURE: "GET_NUMBER_KARACHI_WISE_MARKET_SUM_RING_FAILURE",



    GET_NUMBER_KARACHI_WISE_MARKET_SUM_REQUEST: "GET_NUMBER_KARACHI_WISE_MARKET_SUM_REQUEST",
    GET_NUMBER_KARACHI_WISE_MARKET_SUM_SUCCESS: "GET_NUMBER_KARACHI_WISE_MARKET_SUM_SUCCESS",
    GET_NUMBER_KARACHI_WISE_MARKET_SUM_FAILURE: "GET_NUMBER_KARACHI_WISE_MARKET_SUM_FAILURE",


    GET_KARACHI_BETLIST_FOR_ADMIN_REQUEST: "GET_KARACHI_BETLIST_FOR_ADMIN_REQUEST",
    GET_KARACHI_BETLIST_FOR_ADMIN_SUCCESS: "GET_KARACHI_BETLIST_FOR_ADMIN_SUCCESS",
    GET_KARACHI_BETLIST_FOR_ADMIN_FAILURE: "GET_KARACHI_BETLIST_FOR_ADMIN_FAILURE",


    GET_GAME_PAYMENTS_FOR_SUB_ADMIN_REQUEST: "GET_GAME_PAYMENTS_FOR_SUB_ADMIN_REQUEST",
    GET_GAME_PAYMENTS_FOR_SUB_ADMIN_SUCCESS: "GET_GAME_PAYMENTS_FOR_SUB_ADMIN_SUCCESS",
    GET_GAME_PAYMENTS_FOR_SUB_ADMIN_FAILURE: "GET_GAME_PAYMENTS_FOR_SUB_ADMIN_FAILURE",


    GET_GAME_PAYMENTS_FOR_SUB_ADMIN_1_REQUEST: "GET_GAME_PAYMENTS_FOR_SUB_ADMIN_1_REQUEST",
    GET_GAME_PAYMENTS_FOR_SUB_ADMIN_1_SUCCESS: "GET_GAME_PAYMENTS_FOR_SUB_ADMIN_1_SUCCESS",
    GET_GAME_PAYMENTS_FOR_SUB_ADMIN_1_FAILURE: "GET_GAME_PAYMENTS_FOR_SUB_ADMIN_1_FAILURE",

    GET_KARACHI_GAME_PAYMENTS_FOR_SUB_ADMIN_REQUEST: "GET_KARACHI_GAME_PAYMENTS_FOR_SUB_ADMIN_REQUEST",
    GET_KARACHI_GAME_PAYMENTS_FOR_SUB_ADMIN_SUCCESS: "GET_KARACHI_GAME_PAYMENTS_FOR_SUB_ADMIN_SUCCESS",
    GET_KARACHI_GAME_PAYMENTS_FOR_SUB_ADMIN_FAILURE: "GET_KARACHI_GAME_PAYMENTS_FOR_SUB_ADMIN_FAILURE",


    GET_KARACHI_GAME_PAYMENTS_FOR_SUB_ADMIN_1_REQUEST: "GET_KARACHI_GAME_PAYMENTS_FOR_SUB_ADMIN_1_REQUEST",
    GET_KARACHI_GAME_PAYMENTS_FOR_SUB_ADMIN_1_SUCCESS: "GET_KARACHI_GAME_PAYMENTS_FOR_SUB_ADMIN_1_SUCCESS",
    GET_KARACHI_GAME_PAYMENTS_FOR_SUB_ADMIN_1_FAILURE: "GET_KARACHI_GAME_PAYMENTS_FOR_SUB_ADMIN_1_FAILURE",


    GETBET_WISE_LEDGER_DATA_REQUEST: "GETBET_WISE_LEDGER_DATA_REQUEST",
    GETBET_WISE_LEDGER_DATA_SUCCESS: "GETBET_WISE_LEDGER_DATA_SUCCESS",
    GETBET_WISE_LEDGER_DATA_FAILURE: "GETBET_WISE_LEDGER_DATA_FAILURE",


    GET_RESULT_DECLARE_BY_ADMIN_REQUEST: "GET_RESULT_DECLARE_BY_ADMIN_REQUEST",
    GET_RESULT_DECLARE_BY_ADMIN_SUCCESS: "GET_RESULT_DECLARE_BY_ADMIN_SUCCESS",
    GET_RESULT_DECLARE_BY_ADMIN_FAILURE: "GET_RESULT_DECLARE_BY_ADMIN_FAILURE",


    GET_NUMBER_WISE_MARKET_SUM_REQUEST: "GET_NUMBER_WISE_MARKET_SUM_REQUEST",
    GET_NUMBER_WISE_MARKET_SUM_SUCCESS: "GET_NUMBER_WISE_MARKET_SUM_SUCCESS",
    GET_NUMBER_WISE_MARKET_SUM_FAILURE: "GET_NUMBER_WISE_MARKET_SUM_FAILURE",

    GET_ALL_ENTRIES_REQUEST: "GET_ALL_ENTRIES_REQUEST",
    GET_ALL_ENTRIES_SUCCESS: "GET_ALL_ENTRIES_SUCCESS",
    GET_ALL_ENTRIES_FAILURE: "GET_ALL_ENTRIES_FAILURE",

    GET_DOWN_LINE_REQUEST: "GET_DOWN_LINE_REQUEST",
    GET_DOWN_LINE_SUCCESS: "GET_DOWN_LINE_SUCCESS",
    GET_DOWN_LINE_FAILURE: "GET_DOWN_LINE_FAILURE",

    GET_LEDFERS_REQUEST: "GET_LEDFERS_REQUEST",
    GET_LEDFERS_SUCCESS: "GET_LEDFERS_SUCCESS",
    GET_LEDFERS_FAILURE: "GET_LEDFERS_FAILURE",

    GET_LEDFERS_ID_REQUEST: "GET_LEDFERS_ID_REQUEST",
    GET_LEDFERS_ID_SUCCESS: "GET_LEDFERS_ID_SUCCESS",
    GET_LEDFERS_ID_FAILURE: "GET_LEDFERS_ID_FAILURE",

    CLEAR_STATE_SUCCESS: "CLEAR_STATE_SUCCESS",

};
