export const userConstants = {
    PASSWORD_UP_REQUEST: "PASSWORD_UP_REQUEST",
    PASSWORD_UP_SUCCESS: "PASSWORD_UP_SUCCESS",
    PASSWORD_UP_FAILURE: "PASSWORD_UP_FAILURE",

    DOUBLE_AMOUNT_S_REQUEST: "DOUBLE_AMOUNT_S_REQUEST",
    DOUBLE_AMOUNT_S_SUCCESS: "DOUBLE_AMOUNT_S_SUCCESS",
    DOUBLE_AMOUNT_S_FAILURE: "DOUBLE_AMOUNT_S_FAILURE",



    CREDIT_REF_REQUEST: "CREDIT_REF_REQUEST",
    CREDIT_REF_SUCCESS: "CREDIT_REF_SUCCESS",
    CREDIT_REF_FAILURE: "CREDIT_REF_FAILURE",



    DELETE_BETS_REQUEST: "DELETE_BETS_REQUEST",
    DELETE_BETS_SUCCESS: "DELETE_BETS_SUCCESS",
    DELETE_BETS_FAILURE: "DELETE_BETS_FAILURE",

    BLOCK_NUMBERS_REQUEST: "BLOCK_NUMBERS_REQUEST",
    BLOCK_NUMBERS_SUCCESS: "BLOCK_NUMBERS_SUCCESS",
    BLOCK_NUMBERS_FAILURE: "BLOCK_NUMBERS_FAILURE",

    BLOCKED_GAME_REQUEST: "BLOCKED_GAME_REQUEST",
    BLOCKED_GAME_SUCCESS: "BLOCKED_GAME_SUCCESS",
    BLOCKED_GAME_FAILURE: "BLOCKED_GAME_FAILURE",


    PASSWORD_UPDATED_REQUEST: "PASSWORD_UPDATED_REQUEST",
    PASSWORD_UPDATED_SUCCESS: "PASSWORD_UPDATED_SUCCESS",
    PASSWORD_UPDATED_FAILURE: "PASSWORD_UPDATED_FAILURE",


    CLEAR_IMAGE: "CLEAR_IMAGE",
    GET_USER_OF_MASTER_REQUEST: "GET_USER_OF_MASTER_REQUEST",
    GET_USER_OF_MASTER_SUCCESS: "GET_USER_OF_MASTER_SUCCESS",
    GET_USER_OF_MASTER_FAILURE: "GET_USER_OF_MASTER_FAILURE",


    GET_TOTAL_COUNT_REQUEST: "GET_TOTAL_COUNT_REQUEST",
    GET_TOTAL_COUNT_SUCCESS: "GET_TOTAL_COUNT_SUCCESS",
    GET_TOTAL_COUNT_FAILURE: "GET_TOTAL_COUNT_FAILURE",

    GET_LOGIN_HISTORY_ID_REQUEST: "GET_LOGIN_HISTORY_ID_REQUEST",
    GET_LOGIN_HISTORY_ID_SUCCESS: "GET_LOGIN_HISTORY_ID_SUCCESS",
    GET_LOGIN_HISTORY_ID_FAILURE: "GET_LOGIN_HISTORY_ID_FAILURE",


    GET_TXN_LIST_USERID_REQUEST: "GET_TXN_LIST_USERID_REQUEST",
    GET_TXN_LIST_USERID_SUCCESS: "GET_TXN_LIST_USERID_SUCCESS",
    GET_TXN_LIST_USERID_FAILURE: "GET_TXN_LIST_USERID_FAILURE",


    GET_BANK_DETAILS_BY_ADMIN_REQUEST: "GET_BANK_DETAILS_BY_ADMIN_REQUEST",
    GET_BANK_DETAILS_BY_ADMIN_SUCCESS: "GET_BANK_DETAILS_BY_ADMIN_SUCCESS",
    GET_BANK_DETAILS_BY_ADMIN_FAILURE: "GET_BANK_DETAILS_BY_ADMIN_FAILURE",

    UPOLAD_IMAGE_REQUEST: "UPOLAD_IMAGE_REQUEST",
    UPOLAD_IMAGE_SUCCESS: "UPOLAD_IMAGE_SUCCESS",
    UPOLAD_IMAGE_FAILURE: "UPOLAD_IMAGE_FAILURE",

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    GET_BET_LIST_USERID_REQUEST: "GET_BET_LIST_USERID_REQUEST",
    GET_BET_LIST_USERID_SUCCESS: "GET_BET_LIST_USERID_SUCCESS",
    GET_BET_LIST_USERID_FAILURE: "GET_BET_LIST_USERID_FAILURE",


    GET_PROFILE_REQUEST: 'GET_PROFILE_REQUEST',
    GET_PROFILE_SUCCESS: 'GET_PROFILE_SUCCESS',
    GET_PROFILE_FAILURE: 'GET_PROFILE_FAILURE',

    USER_CREATE_BY_ADMIN_REQUEST: 'USER_CREATE_BY_ADMIN_REQUEST',
    USER_CREATE_BY_ADMIN_SUCCESS: 'USER_CREATE_BY_ADMIN_SUCCESS',
    USER_CREATE_BY_ADMIN_FAILURE: 'USER_CREATE_BY_ADMIN_FAILURE',

    GET_ADMIN_PROFILE_REQUEST: 'GET_ADMIN_PROFILE_REQUEST',
    GET_ADMIN_PROFILE_SUCCESS: 'GET_ADMIN_PROFILE_SUCCESS',
    GET_ADMIN_PROFILE_FAILURE: 'GET_ADMIN_PROFILE_FAILURE',

    GET_USER_BY_USERID_REQUEST: 'GET_USER_BY_USERID_REQUEST',
    GET_USER_BY_USERID_SUCCESS: 'GET_USER_BY_USERID_SUCCESS',
    GET_USER_BY_USERID_FAILURE: 'GET_USER_BY_USERID_FAILURE',

    GET_USER_LIST_REQUEST: 'GET_USER_LIST_REQUEST',
    GET_USER_LIST_SUCCESS: 'GET_USER_LIST_SUCCESS',
    GET_USER_LIST_FAILURE: 'GET_USER_LIST_FAILURE',


    GET_USER_LIST_REQUEST2: "GET_USER_LIST_REQUEST2",
    GET_USER_LIST_SUCCESS2: "GET_USER_LIST_SUCCESS2",
    GET_USER_LIST_FAILURE2: "GET_USER_LIST_FAILURE2",


    UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST',
    UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
    UPDATE_USER_FAILURE: 'UPDATE_USER_FAILURE',

    UPDATE_USER_STATUS_REQUEST: 'UPDATE_USER_STATUS_REQUEST',
    UPDATE_USER_STATUS_SUCCESS: 'UPDATE_USER_STATUS_SUCCESS',
    UPDATE_USER_STATUS_FAILURE: 'UPDATE_USER_STATUS_FAILURE',

    DELETE_USER_REQUEST: 'DELETE_USER_REQUEST',
    DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
    DELETE_USER_FAILURE: 'DELETE_USER_FAILURE',


    USER_LIST_LEAN_REQUEST: "USER_LIST_LEAN_REQUEST",
    USER_LIST_LEAN_SUCCESS: "USER_LIST_LEAN_SUCCESS",
    USER_LIST_LEAN_FAILURE: "USER_LIST_LEAN_FAILURE",

    LOGOUT: 'USERS_LOGOUT',

};
