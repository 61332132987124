import React from "react";
import Modal from 'react-modal';

export default function UpdateMarketModal(props) {

  let { updateModal, handleUpdateHideModal, inputChange, fieldsUpdate, errorsUpdate, updateMarketSubmit, handleFile, setCurrentIndex, inputAddUserChangeContent, addContentField, deleteContentField } = props;

  return (

    <Modal
      isOpen={updateModal}
    >

      <div className="fixed inset-0 z-50 flex items-center justify-center w-full overflow-hidden main-modal h-100 animated fadeIn faster" style={{ background: 'rgba(0,0,0,.7)' }}>
        <div className="z-50 w-11/12 mx-auto overflow-y-auto bg-white border border-teal-500 rounded shadow-lg modal-container md:max-w-md">
          <div className="px-6 py-4 text-left modal-content">
            {/*Title*/}
            <div className="flex items-center justify-between pb-3">
              <p className="text-2xl font-bold">Update Market</p>
              <div className="z-50 cursor-pointer modal-close">
                <svg onClick={() => handleUpdateHideModal()} className="text-black fill-current" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                  </path>
                </svg>
              </div>
            </div>
            {/*Body*/}
            <form autoComplete="off">
              <div className="">
                <div className="relative mt-1 shadow-sm">
                  <label class="block text-gray-700 text-base font-bold mb-2" >name :</label>
                  <input className={`w-full px-3 py-3 text-base transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${errorsUpdate && !errorsUpdate["name"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                    id="name" name="name" placeholder="MarketName"
                    // value={fieldsUpdate.name}
                    value={fieldsUpdate && fieldsUpdate["name"] ? fieldsUpdate["name"] : ""}

                    type="text" onChange={inputChange} />
                  {errorsUpdate && errorsUpdate["name"] ?
                    <div className="text-yellow-600 invalid-feedback">
                      {errorsUpdate["name"]}
                    </div>
                    : null}
                </div>
              </div>

              <div className="">
                <div className="relative mt-1 shadow-sm">
                  <label class="block text-gray-700 text-base font-bold mb-2" >shortName :</label>
                  <input className={`w-full px-3 py-3 text-base transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${errorsUpdate && !errorsUpdate["shortName"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                    id="shortName" name="shortName" placeholder="shortName"
                    value={fieldsUpdate && fieldsUpdate["shortName"] ? fieldsUpdate["shortName"] : ""}

                    type="text" onChange={inputChange} />
                  {errorsUpdate && errorsUpdate["shortName"] ?
                    <div className="text-yellow-600 invalid-feedback">
                      {errorsUpdate["shortName"]}
                    </div>
                    : null}
                </div>
              </div>

              <div className="w-full mt-1 mr-2 ">
                <img src={fieldsUpdate && fieldsUpdate.image ? fieldsUpdate.image : "NA"}
                  alt=" " height="20%" width="20%" />
              </div>



              <div className="flex flex-wrap w-full mt-2">
                <label htmlFor="image">Upload Image</label>
                <input
                  // style={{ display: 'none' }}
                  id="image"
                  name="image"
                  type="file"
                  onChange={handleFile}
                />
              </div>


              {
                fieldsUpdate && fieldsUpdate.numList && fieldsUpdate.numList.length > 0 ?
                  fieldsUpdate.numList.map((item, index) => (
                    <>

                      {/* {
                              JSON.stringify(item)
                            } */}

                      <div >
                        <div className="relative mt-1 shadow-sm">


                          <label class="block text-gray-700 text-base font-bold mb-2" for="username">Option description {index + 1} :</label>
                          <input className={`w-full mb-4 px-3 py-3 text-base transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring 
          
                          ` }


                            // ${this.state.errorsWebStory && !this.state.errorsWebStory["description"] ? "border border-red-500 placeholder-gray-500" : "border border-opacity-100 border-red-500 "}


                            id="description" name="description" placeholder="Description"
                            // value={item.description}
                            value={fieldsUpdate.numList[index]}


                            onClick={() => setCurrentIndex(index)}
                            type="text" onChange={inputAddUserChangeContent} />





                          {
                            index === 0 ?

                              <span className='border border-blue-400 bg-blue-500 px-8 py-2 rounded-xl text-white text-lg cursor-pointer' onClick={() => addContentField(index)}>Add</span> :

                              <>

                                <span className='border border-blue-400 bg-blue-500 px-8 py-2 rounded-xl text-white text-lg mr-2 cursor-pointer' onClick={() => addContentField(index)}>Add</span>
                                <span className='border border-red-400 bg-red-500 px-8 py-2 rounded-xl text-white text-lg cursor-pointer' onClick={() => deleteContentField(index)}>Delete</span>

                              </>
                          }


                        </div>
                      </div>
                    </>
                  )) : null
              }

              <div className="w-64 mx-auto mt-4 sm:w-72">
                <button className="flex justify-center w-full px-4 py-2 mx-auto text-lg font-semibold text-white uppercase transition duration-150 ease-in-out bg-blue-500 border border-blue-500 rounded-full xl hover:bg-blue hover:text-white focus:outline-none focus:border-blue-500 focus:shadow-outline-yellow active:bg-blue-500" type="button" onClick={updateMarketSubmit}>Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>


    </Modal>



  );
}
