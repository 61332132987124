/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { gamesActions, reportActions, userActions } from '../../_actions'
import ReactPaginate from 'react-paginate'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import moment from 'moment'
import { Link } from 'react-router-dom'
import Loader from '../../components/Loader/Loader'
import { MdOutlineDelete } from 'react-icons/md'
import { Tooltip } from 'react-tooltip'

const keyWord = ''
const fromDate = ''
const toDate = ''

const UserBidHistory = () => {
  const dispatch = useDispatch()
  const [fieldsGame, setFieldsGame] = useState({})
  const [errorsGame, setErrorsGame] = useState({})

  const {
    reportsList,
    reportsTotal,
    loading,
    amountsPlaced,
    totalWinAmount,
    totalLossAmount,
    downLineData
  } = useSelector(state => state.reports)
  const { gamesList } = useSelector(state => state.games)
  const [size, setSize] = useState(10)
  const [offset, setOffset] = useState(0)
  const [pageNo, setPageNo] = useState(1)
  const [currentPage, setCurrentPage] = useState(0)
  const today = new Date().toISOString().split('T')[0]
  useEffect(() => {
    let gameReq = {
      fromDate: fromDate,
      toDate: toDate,
      keyWord: fieldsGame['Users'] || '',
      sortBy: '',
      sortOrder: '',
      pageNo: pageNo,
      size: size,
      userId: []
    }

    let gameReq2 = {
      fromDate: fromDate,
      toDate: toDate,
      keyWord: fieldsGame['Users'] || '',
      sortBy: '',
      sortOrder: '',
      pageNo: 1,
      size: 500
    }
    dispatch(gamesActions.getAllGames(gameReq2))
    dispatch(reportActions.getBetsListForAdmin(gameReq))
    dispatch(reportActions.getDownLineUsers())
    dispatch(gamesActions.getAllMarket())
    dispatch(gamesActions.getAllUser())
  }, [])


  const inputChangeCreate = e => {
    e.preventDefault()
    const { name, value } = e.target
    setFieldsGame(prevState => ({ ...prevState, [name]: value }))
    setErrorsGame(prevState => ({ ...prevState, [name]: '' }))
  }

  const createGameSubmit = e => {
    e.preventDefault()
    let gameReq2 = {
      fromDate: fieldsGame?.date || '',
      toDate: fieldsGame?.todate || '',
      keyWord: fieldsGame['Users'] || '',
      gameId: fieldsGame?.userId,
      marketId: fieldsGame?.userId2,
      pageNo: pageNo,
      size: size,
      userId: []
    }
    dispatch(reportActions.getBetsListForAdmin(gameReq2))
  }

  const handlePageClick = data => {
    let offset = Math.ceil(data.selected * size)
    setOffset(offset)
    setCurrentPage(data.selected)
    setPageNo(data.selected + 1)
    let pageReq = {
      fromDate: fromDate,
      toDate: toDate,
      keyWord: fieldsGame['Users'] || keyWord,
      sortBy: '',
      sortOrder: '',
      pageNo: data.selected + 1,
      size: size,
      userId: []
    }
    dispatch(reportActions.getBetsListForAdmin(pageReq))
  }

  const handleInputSize = e => {
    e.preventDefault()
    const { name, value } = e.target
    setFieldsGame(prevState => ({ ...prevState, [name]: value }))
    setErrorsGame(prevState => ({ ...prevState, [name]: '' }))
    setSize(e.target.value)
    let pageReq = {
      keyWord: fieldsGame['Users'] || keyWord,
      pageNo: 1,
      sortBy: 'slug',
      sortOrder: 'asc',
      fromDate: fromDate,
      toDate: toDate,
      size: value,
      userId: []
    }
    dispatch(reportActions.getBetsListForAdmin(pageReq))
  }

  const handleDelete = data => {
    let gameReq = {
      fromDate: fromDate,
      toDate: toDate,
      keyWord: keyWord,
      sortBy: '',
      sortOrder: '',
      pageNo: pageNo,
      size: size,
      userId: []
    }

    confirmAlert({
      title: 'Confirm to Delete',
      message: 'Are you sure you want to Delete Bid',
      buttons: [
        {
          label: 'Yes',
          onClick: () => dispatch(userActions.DeleteBet(data, gameReq))
        },
        {
          label: 'No'
        }
      ]
    })
  }
  let sessiondata = JSON.parse(window.sessionStorage.getItem('adminuser'))

  return (
    <>
      <Loader loading={loading} />
      <div className='z-0 overflow-hidden overflow-y-auto focus:outline-none'>
        <div className='relative'>
          <div className='relative z-20 flex '>
            <div className='flex w-full overflow-hidden '>
              <div className='flex flex-col flex-1 overflow-hidden'>
                <main className='relative flex-1 '>
                  <div className='p-3 sm:p-5'>
                    <div className='mx-auto max-w-screen-3xl space-y-4'>
                      <div className='grid grid-cols-1 gap-4 md:grid-cols-3'>
                        <div className='flex items-center justify-between bg-red-100 border rounded-lg shadow-md px-4 py-3'>
                          <h3 className='text-base text-gray-800 font-semibold'>
                            Total Bet Amount :
                          </h3>
                          <p className='text-3xl'>
                            <span className='font-semibold text-red-800'>
                              {(amountsPlaced && amountsPlaced.toString()) ||
                                ''}
                            </span>
                          </p>
                        </div>
                        <div className='flex items-center justify-between bg-red-100 border rounded-lg shadow-md px-4 py-3'>
                          <h3 className='text-base text-gray-800 font-semibold'>
                            Total Win Amount :
                          </h3>
                          <p className='text-3xl'>
                            <span className='font-semibold text-red-800'>
                              {(totalWinAmount && totalWinAmount.toString()) ||
                                '0'}
                            </span>
                          </p>
                        </div>
                        <div className='flex items-center justify-between bg-red-100 border rounded-lg shadow-md px-4 py-3'>
                          <h3 className='text-base text-gray-800 font-semibold'>
                            Profit :{' '}
                          </h3>
                          <p className='text-3xl'>
                            <span className='font-semibold text-red-800'>
                              {(totalLossAmount &&
                                totalLossAmount.toString()) ||
                                '0'}
                            </span>
                          </p>
                        </div>
                      </div>

                      <div className='bg-red-100 p-3 text-left rounded-lg'>
                        <div className='grid xl:grid-cols-5 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4 mx-8'>
                          <div className='w-full'>
                            <label
                              className='block text-gray-800 text-base font-semibold mb-1'
                              for='gamename'
                            >
                              From Date :
                            </label>
                            <input
                              className='w-full px-3 py-1.5 text-base bg-white border border-gray-400 rounded focus:outline-none uppercase'
                              id='date'
                              name='date'
                              placeholder='Enter date'
                              value={
                                fieldsGame && fieldsGame['date']
                                  ? fieldsGame['date']
                                  : ''
                              }
                              max={today}
                              type='date'
                              onChange={inputChangeCreate}
                            />
                            {errorsGame && errorsGame['date'] ? (
                              <div className='text-red-600 invalid-feedback'>
                                {errorsGame['date']}
                              </div>
                            ) : null}
                          </div>

                          <div className='w-full'>
                            <label
                              className='block text-gray-800 text-base font-semibold mb-1'
                              for='gamename'
                            >
                              To Date :
                            </label>
                            <input
                              className='w-full px-3 py-1.5 text-base bg-white border border-gray-400 rounded focus:outline-none uppercase'
                              id='date'
                              name='todate'
                              placeholder='Enter date'
                              value={
                                fieldsGame && fieldsGame['todate']
                                  ? fieldsGame['todate']
                                  : ''
                              }
                              max={today}
                              type='date'
                              onChange={inputChangeCreate}
                            />
                            {errorsGame && errorsGame['todate'] ? (
                              <div className='text-red-600 invalid-feedback'>
                                {errorsGame['todate']}
                              </div>
                            ) : null}
                          </div>

                          <div className='w-full'>
                            <label
                              className='block text-gray-800 text-base font-semibold mb-1'
                              for='name'
                            >
                              Game Name :
                            </label>
                            <select
                              className='w-full px-3 py-2 capitalize text-base bg-white border border-gray-400 rounded focus:outline-none'
                              id='userId'
                              name='userId'
                              placeholder='name'
                              value={
                                fieldsGame && fieldsGame['userId']
                                  ? fieldsGame['userId']
                                  : ''
                              }
                              type='text'
                              onChange={inputChangeCreate}
                            >
                              <option value=''>Select Game Name</option>
                              {gamesList && gamesList.length > 0
                                ? gamesList.map((element, index) => (
                                  <option
                                    value={
                                      element && element._id
                                        ? element._id
                                        : ''
                                    }
                                  >
                                    {element && element.name
                                      ? element.name
                                      : ''}
                                  </option>
                                ))
                                : null}
                            </select>
                            {errorsGame && errorsGame['userId'] ? (
                              <div className='text-red-600 invalid-feedback'>
                                {errorsGame['userId']}
                              </div>
                            ) : null}
                          </div>
                          <div className='w-full'>
                            <label
                              className='block text-gray-800 text-base font-semibold mb-1'
                              for='name'
                            >
                              User :
                            </label>
                            <select
                              className='w-full px-3 py-2 text-base bg-white border border-gray-400 rounded focus:outline-none'
                              id='Users'
                              name='Users'
                              placeholder='name'
                              value={
                                fieldsGame && fieldsGame['Users']
                                  ? fieldsGame['Users']
                                  : ''
                              }
                              type='text'
                              onChange={inputChangeCreate}
                            >
                              <option value=''>Select User</option>
                              {downLineData && downLineData.length > 0
                                ? downLineData.map((element, index) => (
                                  <option
                                    value={
                                      element && element._id
                                        ? element._id
                                        : ''
                                    }
                                  >
                                    {element && element.userName
                                      ? element.userName
                                      : ''}
                                  </option>
                                ))
                                : null}
                            </select>
                            {errorsGame && errorsGame['Users'] ? (
                              <div className='text-red-600 invalid-feedback'>
                                {errorsGame['Users']}
                              </div>
                            ) : null}
                          </div>

                          <div className='w-full flex mt-2 md:mt-0 justify-start items-end'>
                            <button
                              className='flex w-full lg:w-48 justify-center px-4 py-1.5 text-base font-semibold text-white capitalize transition duration-150 ease-in-out all-btn rounded focus:outline-none'
                              type='button'
                              onClick={createGameSubmit}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className='text-left bg-white space-y-1 rounded-lg'>
                        <div className='flex justify-between items-center px-6 border-b'>
                          <span className=' text-lg font-semibold text-left rtl:text-right text-gray-800 bg-white'>
                            Bets History List
                          </span>
                          <div className='flex justify-between items-center gap-1 px-3 py-2'>
                            <label
                              className='block text-gray-800 text-base'
                              for='name'
                            >
                              Show
                            </label>
                            <select
                              className={`w-full px-3 py-1 text-base bg-white border border-gray-400 rounded-md focus:outline-none`}
                              id='size'
                              name='size'
                              placeholder='name'
                              value={
                                fieldsGame && fieldsGame['size']
                                  ? fieldsGame['size']
                                  : ''
                              }
                              type='text'
                              onChange={handleInputSize}
                            >
                              <option value='10'>10</option>
                              <option value='20'>20</option>
                              <option value='50'>50</option>
                            </select>
                          </div>
                        </div>
                        <div className='relative overflow-x-auto'>
                          <table className='w-full text-base text-left rtl:text-right text-gray-500 shadow'>
                            <thead className='text-gray-900 capitalize bg-red-100  '>
                              <tr className='whitespace-nowrap'>
                                <th className='px-4 py-3'>#</th>
                                <th className='px-4 py-3'>user name</th>
                                <th className='px-4 py-3'>Amt</th>
                                <th className='px-4 py-3'>P/L</th>
                                <th className='px-4 py-3'>No</th>
                                <th className='px-4 py-3'>Game Name</th>
                                <th className='px-4 py-3'>Type</th>
                                <th className='px-4 py-3'>Date/Time</th>
                                <th className='px-4 py-3'>Status</th>
                                <th className='px-4 py-3'>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {reportsList && reportsList.length > 0 ? (
                                reportsList.map((element, index) => (
                                  <React.Fragment key={index}>
                                    <tr
                                      key={index}
                                      className='bg-white border-b border-gray-400 capitalize'
                                    >
                                      <th className='px-4 py-2 font-normal text-[#202020] whitespace-nowrap'>
                                        {' '}
                                        {offset + index + 1}
                                      </th>
                                      <th className='px-4 py-2 font-normal text-[#202020] whitespace-nowrap'>
                                        <Link
                                          to={`/app/user/${element.userId?._id}`}
                                        >
                                          {element &&
                                            element.userId &&
                                            element.userId.userName
                                            ? element.userId.userName
                                            : '-'}
                                        </Link>
                                      </th>
                                      <th className='px-4 py-2 font-normal text-[#202020] whitespace-nowrap'>
                                        {element && element.amount
                                          ? element.amount
                                          : '-'}
                                      </th>
                                      <td className='px-4 py-2'>
                                        {element?.betStatus === 1 && (
                                          <div className=' text-green-500'>
                                            {element?.winAmount || '--'}
                                          </div>
                                        )}
                                        {element?.betStatus === 2 && (
                                          <div className=' text-red-500'>
                                            {element?.lossAmount || '--'}
                                          </div>
                                        )}
                                        {element?.betStatus === 0 && (
                                          <div className=' text-yellow-700'>
                                            --
                                          </div>
                                        )}
                                      </td>
                                      <th className='px-4 py-2 font-normal text-[#202020] whitespace-nowrap'>
                                        {element && element.betNumber
                                          ? element.betNumber
                                          : '-'}
                                      </th>
                                      <th className='px-4 py-2 font-normal text-[#202020] whitespace-nowrap'>
                                        {element &&
                                          element.gameId &&
                                          element.gameId.name
                                          ? element.gameId.name
                                          : '-'}
                                      </th>
                                      <th className='px-4 py-2 font-normal text-[#202020] whitespace-nowrap'>
                                        {element && element.localmarketname
                                          ? element.localmarketname
                                          : '-'}
                                      </th>
                                      <th className='px-4 py-2 font-normal text-[#202020] whitespace-nowrap'>
                                        {element && element.createdAt
                                          ? moment(
                                            new Date(
                                              parseInt(element.createdAt)
                                            )
                                          )
                                            .utcOffset('+05:30')
                                            .format('YYYY-MM-DD hh:mm:ss a')
                                          : '-'}
                                      </th>
                                      {element.betStatus === 0 && (
                                        <th className='px-4 text-yellow-600 py-2 font-medium  whitespace-nowrap'>
                                          Pending
                                        </th>
                                      )}
                                      {element.betStatus === 1 && (
                                        <th className='px-4 py-2 font-medium text-green-500 whitespace-nowrap'>
                                          Win
                                        </th>
                                      )}
                                      {element.betStatus === 2 && (
                                        <th className='px-4 py-2 font-medium text-red-500 whitespace-nowrap'>
                                          Loss
                                        </th>
                                      )}
                                      <td>
                                        {element?.betStatus === 0 &&
                                          sessiondata &&
                                          sessiondata.roleId === 0 && (
                                            <MdOutlineDelete
                                              onClick={() =>
                                                handleDelete(element)
                                              }
                                              className=' text-red-600 w-6 h-6 ml-6 cursor-pointer outline-none'
                                              data-tooltip-id='edit-tooltip'
                                              data-tooltip-content='Delete'
                                            />
                                          )}
                                        <Tooltip
                                          id='edit-tooltip'
                                          place='top'
                                          className='z-50'
                                        />
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                ))
                              ) : (
                                <td>No Data Found</td>
                              )}
                            </tbody>
                          </table>
                        </div>
                        <nav className='relative z-0 flex justify-end py-4 w-76'>
                          {reportsTotal && reportsTotal > size ? ( // Assuming `size` is the number of items per page
                            <ReactPaginate
                              previousLabel={'Prev'}
                              nextLabel={'Next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={Math.ceil(reportsTotal / size)} // Calculate total pages
                              marginPagesDisplayed={1} // Show 1 page before and after current page
                              pageRangeDisplayed={1} // Show up to 5 pages in pagination
                              onPageChange={handlePageClick}
                              containerClassName={'pagination'}
                              pageClassName={'page-cls'}
                              activeClassName={'active'}
                              forcePage={currentPage}
                            />
                          ) : null}
                        </nav>
                      </div>
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UserBidHistory
