/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import DashboardJSON from './WalletManagement.json'
import { gamesActions } from '../../_actions'
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import Loader from '../../components/Loader/Loader'
const size = 10
const pageNo = 1
const keyWord = ''
const fromDate = ''
const toDate = ''
const SingleDigits = () => {
  const dispatch = useDispatch()
  const selector = useSelector(state => state)
  const [fieldsGame, setFieldsGame] = useState({})
  const [errorsGame, setErrorsGame] = useState({})
  const { gamesList } = useSelector(state => state.games)

  useEffect(() => {
    let listReq = {
      fromDate: fromDate,
      toDate: toDate,
      keyWord: keyWord,
      sortBy: '',
      sortOrder: '',
      pageNo: pageNo,
      size: size
    }

    let gameReq = {
      fromDate: fromDate,
      toDate: toDate,
      keyWord: keyWord,
      sortBy: '',
      sortOrder: '',
      pageNo: 1,
      size: 500
    }
    dispatch(gamesActions.getResultListAdmin(listReq))
    dispatch(gamesActions.getAllGames(gameReq))
  }, [pageNo])

  const inputChangeCreate = e => {
    e.preventDefault()
    const { name, value } = e.target
    setFieldsGame(prevState => ({ ...prevState, [name]: value }))
    setErrorsGame(prevState => ({ ...prevState, [name]: '' }))
  }

  const createGameSubmit = e => {
    e.preventDefault()
    if (handleValidationCreateGame()) {
      let gamePageRefresh = {
        fromDate: fromDate,
        toDate: toDate,
        keyWord: keyWord,
        sortBy: '',
        sortOrder: '',
        pageNo: pageNo,
        size: size
      }

      let obj = {
        gameId: fieldsGame?.gameId,
        betNumber: fieldsGame?.num,
        isOpen: fieldsGame.isOpen === 'true' ? true : false,
        date: fieldsGame?.date
      }

      dispatch(gamesActions.saveResultAdmin(obj, gamePageRefresh))
      setFieldsGame({})
    }
  }

  const handleValidationCreateGame = () => {
    let formIsValid = true
    let errors = {}

    if (!fieldsGame.date || fieldsGame.date === '') {
      formIsValid = false
      errors.date = 'Date is required'
    }

    if (!fieldsGame.gameId || fieldsGame.gameId === '') {
      formIsValid = false
      errors.gameId = 'Game is Required'
    }

    if (!fieldsGame.isOpen || fieldsGame.isOpen === '') {
      formIsValid = false
      errors.isOpen = 'Select Sessions'
    }

    if (!fieldsGame.num || fieldsGame.num === '') {
      formIsValid = false
      errors.num = 'Empty fields'
    }

    setErrorsGame(errors)
    return formIsValid
  }

  // const handlePageClick = (data) => {
  //   let offset = Math.ceil(data.selected * size);
  //   setCurrentPage(data.selected)
  //   setOffset(offset);
  //   setPageNo(data.selected+1);
  //   let pageReq = {
  //     "fromDate": fromDate,
  //     "toDate": toDate,
  //     "keyWord": keyWord,
  //     "sortBy": "",
  //     "sortOrder": "",
  //     "pageNo": data.selected + 1,
  //     "size": size
  //   }
  //   dispatch(gamesActions.getAllGames(pageReq));
  // }

  let { games } = selector ? selector : {}
  let { loading } = games ? games : {}

  return (
    <>
      <Loader loading={loading} />
      <div className='z-0 overflow-hidden overflow-y-auto focus:outline-none'>
        <div className='relative'>
          <div className='relative z-20 flex min-h-screen'>
            <div className='flex w-full overflow-hidden '>
              <div className='flex flex-col flex-1 overflow-hidden'>
                <main className='relative flex-1 '>
                  <div className='p-3 2xl:p-10 sm:p-5'>
                    <div className='mx-auto max-w-screen-3xl space-y-5'>
                      <div className='text-left bg-white space-y-2 rounded-lg'>
                        <div className='px-6 py-4 flex items-center justify-between border-b'>
                          <span className=''>Digits</span>
                        </div>
                        <div className='px-6 py-4 w-full'>
                          <div className='flex gap-4'>
                            <div className=' mt-1 w-1/4 shadow-sm'>
                              <label
                                class='block text-gray-700 text-base mb-2'
                                for='name'
                              >
                                Game Name
                              </label>
                              <select
                                className={`w-full px-3 py-2 text-base bg-white border border-gray-400 rounded-md focus:outline-none`}
                                id='gameId'
                                name='gameId'
                                placeholder='name'
                                value={
                                  fieldsGame && fieldsGame['gameId']
                                    ? fieldsGame['gameId']
                                    : ''
                                }
                                type='text'
                                onChange={inputChangeCreate}
                              >
                                <option value=''>Select Game</option>
                                {gamesList && gamesList.length > 0
                                  ? gamesList.map((element, index) => (
                                      <option
                                        value={
                                          element && element._id
                                            ? element._id
                                            : ''
                                        }
                                      >
                                        {element && element.name
                                          ? element.name
                                          : ''}
                                      </option>
                                    ))
                                  : null}
                              </select>
                              {errorsGame && errorsGame['gameId'] ? (
                                <div className='text-red-600 invalid-feedback'>
                                  {errorsGame['gameId']}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className='flex justify-end w-full items-end '>
                            <button
                              className='flex justify-center w-44 px-4 py-2 text-base text-white transition duration-150 ease-in-out bgcolor border border-[#7D1970] rounded-full xl hover:bg-blue hover:text-white focus:outline-none focus:border-[#7D1970] focus:shadow-outline-yellow active:bgcolor'
                              type='button'
                              onClick={e => createGameSubmit(e)}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className='relative overflow-x-auto shadow-md sm:rounded-lg'>
                        {/* <table className="w-full text-base text-left rtl:text-right text-gray-500 ">
                          <thead className="text-gray-800 capitalize bg-gray-50  ">
                            <tr>
                              <th scope="col" className="px-6 py-3">#</th>
                              <th scope="col" className="px-6 py-3">Result Date</th>
                              <th scope="col" className="px-6 py-3">Game Name</th>
                              <th scope="col" className="px-6 py-3">Opening Number</th>
                              <th scope="col" className="px-6 py-3">Jodi Number</th>
                              <th scope="col" className="px-6 py-3">Closing Number</th>
                            </tr>
                          </thead>
                          <tbody>
                            {gameResultList && gameResultList.length > 0 ? gameResultList.map((element, index) => (
                              <React.Fragment key={index}>
                                <tr key={index} className={index % 2 === 0 ? "bg-white border-b  " : "bg-gray-100  "}>
                                  <td scope="row" className="px-6 py-4 font-medium text-gray-700 whitespace-nowrap ">
                                    {offset + index + 1}
                                  </td>
                                  <td scope="row" className="px-6 py-4 font-medium text-gray-700 whitespace-nowrap ">
                                    {element && element.rDate ?new Date(element.rDate).toLocaleDateString() : "-"}
                                  </td>
                                  <td scope="row" className="px-6 py-4 font-medium text-gray-700 whitespace-nowrap ">
                                    {element && element.gameId ? element.gameId?.name : "-"}
                                  </td>
                                  <td scope="row" className="px-6 py-4 font-medium text-gray-700 whitespace-nowrap ">
                                    {element && element.openNumber ? element.openNumber : "-"}
                                  </td>
                                  <td scope="row" className="px-6 py-4 font-medium text-gray-700 whitespace-nowrap ">
                                    {element && element.jodiNumber ? element.jodiNumber : "-"}
                                  </td>
                                  <td scope="row" className="px-6 py-4 font-medium text-gray-700 whitespace-nowrap ">
                                    {element && element.closeNumber ? element.closeNumber : "-"}
                                  </td>
                                </tr>
                              </React.Fragment>
                            )) : null
                            }
                          </tbody>
                        </table> */}
                      </div>
                      {/* {
                              gameResultList && gameResultTotal > 10 ? (
                                <ReactPaginate
                                  previousLabel={'Prev'}
                                  nextLabel={'Next'}
                                  breakLabel={'...'}
                                  breakClassName={'break-me'}
                                  pageCount={Math.ceil(gameResultTotal / size)}
                                  marginPagesDisplayed={0}
                                  pageRangeDisplayed={10}
                                  onPageChange={handlePageClick}
                                  containerClassName={'pagination'}
                                  pageClassName={'page-cls'}
                                  activeClassName={'active'}
                                  forcePage={currentPage}
                                />
                              ) : null
                            } */}
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SingleDigits
