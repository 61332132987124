import { karachiGamesConstants } from "../_constants";
import { alertActions } from "./alert.actions";
import {
  APIcallFunction,
  headerForPrivateAPI,
} from "../_helpers";
export const karachiGamesActions = {
  createKarachiGames,
  getKarachiGamesList,
  getAllKarachiGames,
  updateKarachiGames,
  getKarachiPlaceBetsByUserId,
  karachiPlaceBetByAdmin,
  karachiPlaceBetByAdminSequentially,
  karachiPlaceBetByFreeze,
  getPlaceBetsByUserIdLocalCall,
  
};
function karachiPlaceBetByAdminSequentially(data, pagination, placeData) {
  const credentials = {
    header: headerForPrivateAPI,
    method: "POST",
    body: data ?? {},
    endPoint: "/karachiPlaceBetByAdminSequentially",
  };
  return (dispatch) => {
    dispatch(request({ data }));
    APIcallFunction(credentials).then(
      (games) => {
        dispatch(success(games));
        dispatch(getKarachiPlaceBetsByUserId(pagination));
        dispatch(alertActions.success("Bet Placed"));


      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request() {
    return { type: karachiGamesConstants.KARACHI_PLACEBET_BY_ADMIN_SEQUENTIALLY_REQUEST };
  }
  function success(games) {
    return { type: karachiGamesConstants.KARACHI_PLACEBET_BY_ADMIN_SEQUENTIALLY_SUCCESS, games };
  }
  function failure(error) {
    return { type: karachiGamesConstants.KARACHI_PLACEBET_BY_ADMIN_SEQUENTIALLY_FAILURE, error };
  }
}


function getPlaceBetsByUserIdLocalCall(data) { 
  return (dispatch) => {
    dispatch(success(data));
  };

  function success(games) {
    return { type: karachiGamesConstants.PLACEBET_BY_ADMIN_KARACHI_FREEZE_SUCCESS, games };
  }
}



function karachiPlaceBetByAdmin(data, pagination, placeData) {
  const credentials = {
    header: headerForPrivateAPI,
    method: "POST",
    body: data ?? {},
    endPoint: "/karachiPlaceBetByAdmin",
  };
  return (dispatch) => {
    dispatch(request({ data }));
    APIcallFunction(credentials).then(
      (games) => {
        dispatch(success(games));
        // dispatch(getKarachiPlaceBetsByUserId(pagination));
        dispatch(getPlaceBetsByUserIdLocalCall({ data: [...placeData, data] }))
        // dispatch(alertActions.success("Bet Placed"));

      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request(games) {
    return { type: karachiGamesConstants.KARACHI_PLACEBET_BY_ADMIN_BRAND_REQUEST, games };
  }
  function success(games) {
    return { type: karachiGamesConstants.KARACHI_PLACEBET_BY_ADMIN_BRAND_SUCCESS, games };
  }
  function failure(error) {
    return { type: karachiGamesConstants.KARACHI_PLACEBET_BY_ADMIN_BRAND_FAILURE, error };
  }
}
function getKarachiPlaceBetsByUserId(data, pagination) {
  const credentials = {
    header: headerForPrivateAPI,
    method: "POST",
    body: data ?? {},
    endPoint: "/getKarachiPlaceBetsByUserId",
  };
  return (dispatch) => {
    dispatch(request({ data }));
    APIcallFunction(credentials).then(
      (games) => {
        dispatch(success(games));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request(games) {
    return { type: karachiGamesConstants.PLACEBET_BY_ADMIN_KARACHI_FREEZE_REQUEST, games };
  }
  function success(games) {
    return { type: karachiGamesConstants.PLACEBET_BY_ADMIN_KARACHI_FREEZE_SUCCESS, games };
  }
  function failure(error) {
    return { type: karachiGamesConstants.PLACEBET_BY_ADMIN_KARACHI_FREEZE_FAILURE, error };
  }
}
function createKarachiGames(data, gamePageRefresh) {
  const credentials = {
    header: headerForPrivateAPI,
    method: "POST",
    body: data ?? {},
    endPoint: "/createKarachiGames",
  };
  return (dispatch) => {
    dispatch(request({ data }));
    APIcallFunction(credentials).then(
      (games) => {
        dispatch(success(games));

        dispatch(getAllKarachiGames(gamePageRefresh));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request(games) {
    return { type: karachiGamesConstants.CREATE_KARACHI_GAMES_REQUEST, games };
  }
  function success(games) {
    return { type: karachiGamesConstants.CREATE_KARACHI_GAMES_SUCCESS, games };
  }
  function failure(error) {
    return { type: karachiGamesConstants.CREATE_KARACHI_GAMES_FAILURE, error };
  }
}
function getKarachiGamesList(data) {
  const credentials = {
    header: headerForPrivateAPI,
    method: "POST",
    body: data ?? {},
    endPoint: "/getKarachiGamesList",
  };
  return (dispatch) => {
    dispatch(request({ data }));
    APIcallFunction(credentials).then(
      (games) => {
        dispatch(success(games));
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request(games) {
    return { type: karachiGamesConstants.GET_KARACHI_GAMES_LIST_REQUEST, games };
  }
  function success(games) {
    return { type: karachiGamesConstants.GET_KARACHI_GAMES_LIST_SUCCESS, games };
  }
  function failure(error) {
    return { type: karachiGamesConstants.GET_KARACHI_GAMES_LIST_FAILURE, error };
  }
}
function getAllKarachiGames(data) {
  const credentials = {
    header: headerForPrivateAPI,
    method: "POST",
    body: data ?? {},
    endPoint: "/getAllKarachiGames",
  };
  return (dispatch) => {
    dispatch(request({ data }));
    APIcallFunction(credentials).then(
      (games) => {
        dispatch(success(games));
      },
      (error) => {
        dispatch(failure(error));
        // dispatch(alertActions.error(error));
      }
    );
  };
  function request(games) {
    return { type: karachiGamesConstants.GET_ALL_KARACHI_GAMES_REQUEST, games };
  }
  function success(games) {
    return { type: karachiGamesConstants.GET_ALL_KARACHI_GAMES_SUCCESS, games };
  }
  function failure(error) {
    return { type: karachiGamesConstants.GET_ALL_KARACHI_GAMES_FAILURE, error };
  }
}
function updateKarachiGames(data, setUpdateModal, gamePageRefresh) {
  const credentials = {
    header: headerForPrivateAPI,
    method: "POST",
    body: data ?? {},
    endPoint: "/updateKarachiGames",
  };
  return (dispatch) => {
    dispatch(request({ data }));
    APIcallFunction(credentials).then(
      (games) => {
        dispatch(success(games));
        dispatch(getAllKarachiGames(gamePageRefresh));
        setUpdateModal(false);
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request(games) {
    return { type: karachiGamesConstants.UPDATE_KARACHI_GAMES_REQUEST, games };
  }
  function success(games) {
    return { type: karachiGamesConstants.UPDATE_KARACHI_GAMES_SUCCESS, games };
  }
  function failure(error) {
    return { type: karachiGamesConstants.UPDATE_KARACHI_GAMES_FAILURE, error };
  }
}


function karachiPlaceBetByFreeze(data, pagination) {
  const credentials = {
    header: headerForPrivateAPI,
    method: "POST",
    body: data ?? {},
    endPoint: "/deleteKarachiBetsById",
  };
  return (dispatch) => {
    dispatch(request({ data }));
    APIcallFunction(credentials).then(
      (games) => {
        dispatch(success(games));
        dispatch(getKarachiPlaceBetsByUserId(pagination));

      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
  function request(games) {
    return { type: karachiGamesConstants.KARACHI_PLACEBET_BY_ADMIN_FREEZE_REQUEST, games };
  }
  function success(games) {
    return { type: karachiGamesConstants.KARACHI_PLACEBET_BY_ADMIN_FREEZE_SUCCESS, games };
  }
  function failure(error) {
    return { type: karachiGamesConstants.KARACHI_PLACEBET_BY_ADMIN_FREEZE_FAILURE, error };
  }
}