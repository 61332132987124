/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DashboardJSON from './GameManagement.json'
import { gamesActions, marketActions, userActions } from '../../_actions'
import CreateGameModal from './components/CreateGameModal/CreateGameModal'
import UpdateGameModal from './components/UpdateGameModal/UpdateGameModal'
import ViewGameModal from './components/ViewGameModal/ViewGameModal'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import Loader from '../../components/Loader/Loader'
import { MdOutlineDelete } from 'react-icons/md'
import { FiEdit } from 'react-icons/fi'
const size = 10
const offset = 0
const pageNo = 1
const Brand = () => {
  let addref = useRef(null)
  let addref2 = useRef(null)
  const [resetKey, setResetKey] = useState(0)
  const dispatch = useDispatch()
  let [selectedFile, setSelectedFile] = useState(null) 
  let [createModal, setCreateModal] = useState(false)
  let [updateModal, setUpdateModal] = useState(false)
  let [viewModal, setViewModal] = useState(false)
  const [marketSelected, setMarketSelected] = useState([])
  const [fieldsUpdate, setFieldsUpdate] = useState({})
  const [errorsUpdate, setErrorsUpdate] = useState({})
  const [fieldsGame, setFieldsGame] = useState({})
  const [errorsGame, setErrorsGame] = useState({}) 
  const { loading, allBrand } = useSelector(state => state.games)
  const { allMarket } = useSelector(state => state.markets)
  const { allgame } = useSelector(state => state.games)

  useEffect(() => {
    let gameReq = {
      fromDate: '',
      toDate: '',
      keyWord: '',
      sortBy: '',
      sortOrder: '',
      pageNo: pageNo,
      size: 1000
    }
    dispatch(gamesActions.getAllBrand(gameReq))
    dispatch(marketActions.getAllMarket())
    dispatch(gamesActions.getAllGamesForAdmin())
  }, [])

  const handleCreateOpenModal = () => {
    setMarketSelected([])
    if (addref?.current?.value) {
      addref.current.value = ''
    }
    setCreateModal(true)
    dispatch(userActions.clearImage())
  }

  const handleCreateHideModal = () => {
    if (addref?.current?.value) {
      addref.current.value = ''
    }
    if (addref2?.current?.value) {
      addref2.current.value = ''
    }
    setCreateModal(false)
    setFieldsGame({})
    setErrorsGame({})
    setMarketSelected([])
    dispatch(userActions.clearImage())
  }

  const handleUpdateOpenModal = data => {
    let datas = data.gameList.map(e => ({ name: e.name, id: e._id }))
    setMarketSelected(datas)
    setFieldsUpdate(data)
    setUpdateModal(true)
  }

  const handleUpdateHideModal = data => {
    if (addref2?.current?.value) {
      addref2.current.value = ''
    }
    dispatch(userActions.clearImage())
    setUpdateModal(false)
    setErrorsUpdate({})
    setMarketSelected([])
  }

  const handleViewHideModal = data => {
    setViewModal(false)
  }

  const inputChangeUpdate = e => {
    e.preventDefault()
    const { name, value } = e.target
    setFieldsUpdate(prevState => ({ ...prevState, [name]: value }))
    setErrorsUpdate(prevState => ({ ...prevState, [name]: '' }))
  }

  const inputChangeCreate = e => {
    e.preventDefault()
    const { name, value } = e.target
    setFieldsGame(prevState => ({ ...prevState, [name]: value }))
    setErrorsGame(prevState => ({ ...prevState, [name]: '' }))
  }

  const updateGameSubmit = e => {
    e.preventDefault()
    if (handleValidationUpdateGame()) {
      let selectedmarketData =
        marketSelected && marketSelected.length > 0
          ? marketSelected.map(element => element.id)
          : null
      let { users } = selector ? selector : {}
      let { fileData } = users ? users : {}
      let gamePageRefresh = {
        fromDate: '',
        toDate: '',
        keyWord: '',
        sortBy: '',
        sortOrder: '',
        pageNo: pageNo,
        size: 1000
      }

      const { name, priority, min, max, _id } = fieldsUpdate
      dispatch(
        gamesActions.updateBrand(
          {
            id: _id,
            name: name && name.trim(),
            min: min,
            max: max,
            priority: priority,
            gameList: selectedmarketData,
            image: fileData || fieldsUpdate?.image
          },
          gamePageRefresh
        )
      )
      if (addref2?.current?.value) {
        addref2.current.value = ''
      }

      setUpdateModal(false)
      setFieldsUpdate({})
      setErrorsUpdate({})
    }
  }

  let selector = useSelector(state => state)

  const createGameSubmit = e => {
    let { users } = selector ? selector : {}
    let { fileData } = users ? users : {}
    e.preventDefault()
    if (handleValidationCreateGame()) {
      let selectedmarketData =
        marketSelected && marketSelected.length > 0
          ? marketSelected.map(element => element.id)
          : null

      let gamePageRefresh = {
        fromDate: '',
        toDate: '',
        keyWord: '',
        sortBy: '',
        sortOrder: '',
        pageNo: pageNo,
        size: size
      }

      const { name, priority } = fieldsGame
      dispatch(
        gamesActions.createBrand(
          {
            name: name && name.trim(),
            max: 1,
            min: 10000,
            priority: priority,
            backgroundColor: '',
            gameList: selectedmarketData,
            image: fileData ? fileData : ' '
          },
          gamePageRefresh
        )
      )
      handleCreateHideModal()
    }
  }

  const handleValidationUpdateGame = () => {
    let formIsValid = true
    let errors = {}
    let selectedmarketData =
      marketSelected && marketSelected.length > 0
        ? marketSelected.map(element => element._id)
        : null

    if (!selectedmarketData || selectedmarketData.length === 0) {
      formIsValid = false
      errors.marketSelected = 'Select Game '
    }
    if (!fieldsUpdate.name || fieldsUpdate.name.trim() === '') {
      formIsValid = false
      errors.name = DashboardJSON.ErrorMsg.name
    }
    if (
      !fieldsUpdate.min ||
      fieldsUpdate.min === '' ||
      parseInt(fieldsUpdate.min) <= 0
    ) {
      formIsValid = false
      errors.min = 'Amount should be greater than 0 and not in decimal '
    }
    if (
      !fieldsUpdate.max ||
      fieldsUpdate.max === '' ||
      parseInt(fieldsUpdate.min) <= 0
    ) {
      formIsValid = false
      errors.max = 'Amount should be greater than 0 and not in decimal '
    }

    if (!fieldsUpdate.priority || fieldsUpdate.priority === '') {
      formIsValid = false
      errors.priority = DashboardJSON.ErrorMsg.priority
    }
    setErrorsUpdate(errors)
    return formIsValid
  }

  const handleValidationCreateGame = () => {
    let formIsValid = true
    let errors = {}
    let selectedmarketData =
      marketSelected && marketSelected.length > 0
        ? marketSelected.map(element => element._id)
        : null
    if (!selectedmarketData || selectedmarketData.length === 0) {
      formIsValid = false
      errors.selectedmarketData = 'Selected market data'
    }
    if (!fieldsGame.name || fieldsGame.name.trim() === '') {
      formIsValid = false
      errors.name = DashboardJSON.ErrorMsg.name
    }
    if (!fieldsGame.priority || fieldsGame.priority === '') {
      formIsValid = false
      errors.priority = DashboardJSON.ErrorMsg.priority
    }
    setErrorsGame(errors)
    return formIsValid
  }

  const disableGame = data => {
    let gameIdReq = {
      id: data._id
    }
    let gamePageRefresh = {
      fromDate: '',
      toDate: '',
      keyWord: '',
      sortBy: '',
      sortOrder: '',
      pageNo: pageNo,
      size: size
    }
    confirmAlert({
      title: data?.isDisable ? `Confirm to Enable ` : 'Confirm to Disable',
      message: data?.isDisable
        ? `Are you sure you want to Enable ${data.name}?`
        : `Are you sure you want to Disable ${data.name}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () =>
            dispatch(gamesActions.updateBrandStatus(gameIdReq, gamePageRefresh))
        },
        {
          label: 'No'
        }
      ]
    })
  }

  const deleteGame = data => {
    let gameIdReq = {
      id: data._id
    }

    let gamePageRefresh = {
      fromDate: '',
      toDate: '',
      keyWord: '',
      sortBy: '',
      sortOrder: '',
      pageNo: pageNo,
      size: size
    }

    confirmAlert({
      title: 'Confirm to Delete?',
      message: `Are you sure you want  to delete ${data.name}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () =>
            dispatch(gamesActions.deleteBrand(gameIdReq, gamePageRefresh))
        },
        {
          label: 'No'
        }
      ]
    })
  }

  const handleFile = event => {
    setSelectedFile(event.target.files[0])

    if (event.target.files[0]) {
      dispatch(userActions.uploadImage(event))
      setErrorsGame(prevState => ({ ...prevState, img: '' }))
    } else {
      console.log('No File To Upload!')
    }
  }

  const onSelect = (selectedList, selectedItem) => {
    let data = selectedList.map(e => ({ name: e.name, id: e._id || e.id }))

    setMarketSelected(data)
    setErrorsGame(prevState => ({ ...prevState, selectedmarketData: '' }))
  }

  const onRemove = (selectedList, removedItem) => {
    let filtervalue =
      selectedList && selectedList.filter(item => item.id !== removedItem.id)
    setMarketSelected(filtervalue)
  }

  return (
    <>
      <Loader loading={loading} />
      <div className='z-0 overflow-hidden overflow-y-auto focus:outline-none'>
        <div className='relative'>
          <div className='relative z-20 flex min-h-screen'>
            <div className='flex w-full overflow-hidden '>
              <div className='flex flex-col flex-1 overflow-hidden'>
                <main className='relative flex-1 '>
                  <div className='p-3 2xl:p-10 sm:p-5'>
                    <div className='mx-auto max-w-screen-3xl'>
                      <sectiion className='flex justify-between my-4 '>
                        <div>
                          <p className='text-2xl font-bold'>Brand Management</p>
                        </div>
                        <div>
                          <span className='relative '>
                            <button
                              className='px-4 py-2 font-medium tracking-wider text-white bg-blue-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-blue-400'
                              onClick={() => handleCreateOpenModal()}
                            >
                              Add
                            </button>
                          </span>
                        </div>
                      </sectiion>

                      <div>
                        <div className='relative overflow-x-auto shadow-md sm:rounded-lg'>
                          <table className='w-full text-base text-left rtl:text-right text-gray-500 '>
                            <thead className='text-base text-gray-900 font-bold capitalize bg-gray-50  '>
                              <tr>
                                <th
                                  scope='col'
                                  className='px-6 whitespace-nowrap py-3'
                                >
                                  S.No.
                                </th>
                                <th
                                  scope='col'
                                  className='px-6 whitespace-nowrap py-3'
                                >
                                  Brand name
                                </th>
                                <th
                                  scope='col'
                                  className='px-6 whitespace-nowrap  text-center py-3 '
                                >
                                  action
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {allBrand && allBrand.length > 0
                                ? allBrand.map((element, index) => (
                                    <React.Fragment key={index}>
                                      <tr
                                        key={index}
                                        className={
                                          index % 2 === 0
                                            ? 'bg-white border-b  '
                                            : 'bg-gray-100  '
                                        }
                                      >
                                        <th
                                          scope='row'
                                          className='px-6 py-4 font-medium  whitespace-nowrap '
                                        >
                                          {offset + index + 1}
                                        </th>
                                        <th
                                          scope='row'
                                          className='px-6 py-4 capitalize font-medium  whitespace-nowrap '
                                        >
                                          {element && element.name
                                            ? element.name
                                            : '-'}
                                        </th>

                                        <td className='px-6 py-4 text-right justify-center items-center flex gap-1'>
                                          &nbsp;
                                          <span className='relative '>
                                            <button
                                              className={
                                                element.isDisable === false
                                                  ? 'px-2 py-1 border border-green-700  bg-green-700 text-white rounded'
                                                  : 'px-2 py-1 border border-red-700 text-white bg-red-700 rounded'
                                              }
                                              onClick={() =>
                                                disableGame(element)
                                              }
                                            >
                                              {element &&
                                              element.isDisable === false
                                                ? 'Yes'
                                                : 'No'}
                                            </button>
                                          </span>
                                          <span className='relative '>
                                            <FiEdit
                                              onClick={() =>
                                                handleUpdateOpenModal(element)
                                              }
                                              title='Edit'
                                              className=' text-2xl text-blue-600 cursor-pointer'
                                            />
                                          </span>{' '}
                                          &nbsp;
                                          <span className='relative '>
                                            <MdOutlineDelete
                                              onClick={() =>
                                                deleteGame(element)
                                              }
                                              title='Delete'
                                              className=' text-2xl text-red-600 cursor-pointer'
                                            />
                                          </span>{' '}
                                          &nbsp;
                                        </td>
                                      </tr>
                                    </React.Fragment>
                                  ))
                                : null}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CreateGameModal
        createModal={createModal}
        handleCreateHideModal={handleCreateHideModal}
        handleFile={handleFile}
        selectedMarket={marketSelected}
        inputChangeCreate={inputChangeCreate}
        fieldsGame={fieldsGame}
        setMarketSelected={setMarketSelected}
        errorsGame={errorsGame}
        allMarket={allgame}
        onSelect={onSelect}
        resetKey={resetKey}
        setResetKey={setResetKey}
        onRemove={onRemove}
        createGameSubmit={createGameSubmit}
        addref={addref}
      />

      <UpdateGameModal
        updateModal={updateModal}
        handleUpdateHideModal={handleUpdateHideModal}
        handleFile={handleFile}
        inputChangeUpdate={inputChangeUpdate}
        fieldsUpdate={fieldsUpdate}
        addref2={addref2}
        errorsUpdate={errorsUpdate}
        allMarket={allMarket}
        allgame={allgame}
        resetKey={resetKey}
        setResetKey={setResetKey}
        marketSelected={marketSelected}
        onSelect={onSelect}
        onRemove={onRemove}
        updateGameSubmit={updateGameSubmit}
      />

      <ViewGameModal
        viewModal={viewModal}
        handleViewHideModal={handleViewHideModal}
      />
    </>
  )
}

export default Brand
