/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { gamesActions } from '../../_actions'
import { MdDeleteOutline, MdOutlineNavigateNext } from 'react-icons/md'
import { useParams } from 'react-router-dom'
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import moment from 'moment'
import { useTime } from 'react-timer-hook'

const PlaceBet = () => {
  const dispatch = useDispatch()
  const { games } = useSelector(state => state) || {}
  const { allMarket, gamesList, loading } = games || {}
  const { id, name } = useParams()
  let [localStoragedata, setlocalStoragedata] = useState()
  let [isDisable, setIsDisable] = useState(false)
  const [fieldsGame, setFieldsGame] = useState({})
  const [errorsGame, setErrorsGame] = useState({})
  const [userName, setUserName] = useState({})
  const [gameName, setGameName] = useState({})
  const { subAdminData, betsByUserId } = useSelector(state => state.games)
  const [isBetPrime, setIsBetPrime] = useState(false)
  const [gameNameRes, setGameNameRes] = useState(null)
  const [stateKey, setStateKey] = useState(null)
  let [options, setoptions] = useState([])
  const [totalAmount, setTotalAmount] = useState(0)
  const [errors, setErrors] = useState({})
  const [selectedCountry, setSelectedCountry] = useState('')
  const [savedAmount, setSavedAmount] = useState('') // Value saved from the API call
  const [amount, setAmount] = useState('')
  const [isAmountBlank, setIsAmountBlank] = useState(false)

  const [savedBetNumber, setSavedBetNumber] = useState('') // Value saved from the API call
  const [betNumber, setBetNumber] = useState('')
  const [isBetNumberBlank, setIsBetNumberBlank] = useState(false)

  const userPalyerName =
    games?.betsByUserId &&
      games?.betsByUserId?.[0]?.userId &&
      games?.betsByUserId?.[0]?.userId?.userName
      ? games?.betsByUserId?.[0]?.userId?.userName
      : userName && userName?.userName
        ? userName?.userName
        : null

  const handleKeyDown1 = event => {
    if (event.key.toLowerCase() === 'l') {
      setIsBetPrime(prevState => !prevState) // Toggle the status
    }
  }

  const [enterPressCount, setEnterPressCount] = useState(0) // Track Enter presses
  const betNumberInputRef = useRef(null)
  const amountInputRef = useRef(null) // Reference for the amount input
  const scrollContainerRef = useRef(null)

  useEffect(() => {
    return () => {
      dispatch(gamesActions.getPlaceBetsByUserIdLocalCall([]))
    }
  }, [dispatch])

  useEffect(() => {
    if (scrollContainerRef.current) {
      setTimeout(() => {
        scrollContainerRef.current.scrollTop =
          scrollContainerRef.current.scrollHeight
      }, 100)
    }
    const totalAmountRes =
      betsByUserId && betsByUserId.length > 0
        ? betsByUserId.reduce((sum, record) => {
          if (
            ['Single Open', 'Single Close'].includes(record?.localmarketname)
          ) {
            return sum + Number(record.amount) * 10 || 0
          }
          if (
            ['Single Kenchi', 'Double Kenchi'].includes(
              record?.localmarketname
            )
          ) {
            return sum + Number(record.amount) * record?.packet?.length || 0
          }
          return sum + Number(record.amount) || 0
        }, 0)
        : 0
    setTotalAmount(totalAmountRes)
  }, [betsByUserId])

  const handleKeyDown = async e => {
    if (['ArrowUp', 'ArrowDown'].includes(e.key)) {
      e.preventDefault()
      return
    }

    if (e.key === 'Enter') {
      e.preventDefault()
      const { name } = e.target

      if (!e.target.value && e.target.name === 'betNumber') {
        setErrors(prev => {
          return {
            ...prev,
            betNumber: 'Bet Number is Required'
          }
        })
        return
      }

      if (name === 'betNumber') {
        if (
          currentOption.toShow === 'Single Open' &&
          String(e.target.value).length === 2
        ) {
          const findData = options.findIndex(ele => ele.toShow === 'Jodi')
          setCurrentIndex(() => (findData === -1 ? 0 : findData))
        } else if (
          currentOption.toShow === 'Single Open' &&
          String(e.target.value).length === 1
        ) {
          setCurrentIndex(() => 0)
        }
      }
      let error = validate(e.target.name, e.target.value)
      if (name === 'betNumber' && error) {
        setErrors(prev => {
          return {
            ...prev,
            betNumber: error
          }
        })
      }
      if (name === 'amount' && error) {
        setErrors(prev => {
          return {
            ...prev,
            amount: error
          }
        })
        return
      }
      if (error) {
        return
      }
      if (
        ['F9'].includes(stateKey) &&
        enterPressCount === 0 &&
        name === 'betNumber'
      ) {
        amountInputRef.current?.focus()
        setEnterPressCount(prevCount => prevCount + 1)
      }
      if (['F9'].includes(stateKey) && enterPressCount === 1) {
        await handleSubmit()
        setEnterPressCount(0)
        betNumberInputRef.current?.focus()
        return
      }

      if (enterPressCount === 0 && name === 'betNumber') {
        amountInputRef.current?.focus()
        setEnterPressCount(prevCount => prevCount + 1)
      }
      if (enterPressCount === 1 || enterPressCount === 2) {
        const amount = amountInputRef.current.value.trim()
        if (amount === '') {
          return
        }
        const betNumber = betNumberInputRef.current.value.trim()
        if (betNumber === '') {
          return
        }
        setIsAmountBlank(true)
        setSavedAmount(amount)
        setIsBetNumberBlank(true)
        setSavedBetNumber(betNumber)

        await handleSubmit()
        setEnterPressCount(0)
        betNumberInputRef.current?.focus()
        return
      }
    }
  }
  const getPacketByMarketData = (marketData, betNumber) => {
    switch (marketData) {
      case 'Single Open':
        return getSingleOpenCombinations(betNumber)
      case 'Single Close':
        return getSingleCloseCombinations(betNumber)
      case 'Jodi':
        return [betNumber]
      case 'Single Kenchi':
        return getUniqueTwoDigitCombinations(betNumber)
      case 'Double Kenchi':
        return getTwoDigitCombinations(betNumber)
      default:
        return []
    }
  }

  const validateBet = (marketData, betNumber) => {
    if (
      ['Single Close', 'Single Open'].includes(marketData) &&
      betNumber.length > 1
    )
      return false
    if (
      ['Single Kenchi', 'Double Kenchi'].includes(marketData) &&
      (betNumber.length < 2 || betNumber.length > 7)
    )
      return false
    if (marketData === 'Jodi' && betNumber.length !== 2) return false
    return true
  }

  const resetFormState = setStateFunctions => {
    const {
      setStateKey,
      setBetNumber,
      setCurrentIndex,
      setIsBetPrime,
      setErrors,
      setFormData
    } = setStateFunctions

    setStateKey(null)
    setBetNumber('')
    setCurrentIndex(0)
    setIsBetPrime(false)
    setErrors({})
    setFormData(prevData => ({ ...prevData, betNumber: '' }))
  }

  const handleSubmit = async e => {
    try {
      if (amount < 5 || !betNumber) return

      let packet = []
      let isOpen = false
      const marketId = currentOption

      if (['F9'].includes(stateKey) && betNumber.toString() === '11') {
        const JodiRes = [
          '00',
          '11',
          '22',
          '33',
          '44',
          '55',
          '66',
          '77',
          '88',
          '99'
        ]
        const resData = [
          {
            ...formData,
            isOpen,
            marketId,
            games: currentOption2,
            localmarketname: 'Jodi',
            isBetPrime
          }
        ]
        const gameId = gameName && gameName['_id'] ? gameName['_id'] : id
        const res = {
          gameId,
          userId: fieldsGame?.userId || null
        }

        resData.forEach(games => {
          const obj = {
            userId: fieldsGame?.userId || null,
            amount,
            marketId: games?.marketId?._id,
            gameId,
            isBetPrime: games?.isBetPrime,
            isOpen: games?.isOpen,
            localmarketname: 'Jodi',
            packet: JodiRes
          }
          dispatch(gamesActions.placeBetByAdminRes(obj, res))
        })

        resetFormState({
          setStateKey,
          setBetNumber,
          setCurrentIndex,
          setIsBetPrime,
          setErrors,
          setFormData
        })
        return
      }

      const marketData = ['Single Open'].includes(marketId?.toShow)
        ? betNumber.length === 1
          ? 'Single Open'
          : betNumber.length === 2
            ? 'Jodi'
            : marketId?.toShow
        : marketId?.toShow

      if (!validateBet(marketData, betNumber)) return

      if (marketData === 'Single Open') isOpen = true

      packet = getPacketByMarketData(marketData, betNumber)

      const resData = [
        {
          betNumber,
          packet,
          isOpen,
          marketId,
          games: currentOption2,
          localmarketname: marketData,
          isBetPrime
        }
      ]

      const gameId = gameName && gameName['_id'] ? gameName['_id'] : id
      const res = {
        gameId,
        userId: fieldsGame?.userId || null
      }

      resData.forEach(games => {
        const amountRes = ['Single Open', 'Single Close'].includes(
          games?.localmarketname
        )
          ? (Number(amount) / 10).toString()
          : amount.toString()

        const obj = {
          userId: fieldsGame?.userId || null,
          betNumber: games?.betNumber,
          amount: amountRes,
          marketId: games?.marketId?._id,
          gameId,
          isBetPrime: games?.isBetPrime,
          isOpen: games?.isOpen,
          localmarketname: games?.localmarketname,
          packet: games?.packet
        }

        dispatch(
          gamesActions.placeBetByAdmin(
            obj,
            res,
            betsByUserId?.length > 0 ? betsByUserId : []
          )
        )
      })

      resetFormState({
        setStateKey,
        setBetNumber,
        setCurrentIndex,
        setIsBetPrime,
        setErrors,
        setFormData
      })
    } catch (err) {
      console.error('Error in handleSubmit:', err)
    }
  }
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown1)
    return () => {
      document.removeEventListener('keydown', handleKeyDown1)
    }
  }, [])

  let isControlPressed = false

  const handleKeyDown4 = useCallback(
    event => {
      if (event.key === 'Control') {
        isControlPressed = true
      }
      if (isControlPressed && (event.key === 'q' || event.key === 'Q')) {
        const index = betsByUserId?.length - 1
        const userData = betsByUserId?.[index]
        if (userData && (gameName['_id'] || id || fieldsGame['userId'])) {
          const res = {
            gameId: gameName['_id'] ? gameName['_id'] : id,
            userId:
              fieldsGame && fieldsGame['userId'] ? fieldsGame['userId'] : null
          }
          dispatch(
            gamesActions.placeBetByFreeze(
              { id: userData?._id },
              res,
              betsByUserId
            )
          )
        }
      }
    },
    [betsByUserId]
  )

  const handleKeyUp = useCallback(event => {
    if (event.key === 'Control') {
      isControlPressed = false
    }
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown4)
    document.addEventListener('keyup', handleKeyUp)

    return () => {
      document.removeEventListener('keydown', handleKeyDown4)
      document.removeEventListener('keyup', handleKeyUp)
    }
  }, [handleKeyDown4, handleKeyUp])

  useEffect(() => {
    if (!isDisable) {
      window.addEventListener('keydown', handleKeyDown2)
      return () => {
        window.removeEventListener('keydown', handleKeyDown2)
      }
    }
    if (fieldsGame) {
      if (!isDisable) {
        window.addEventListener('keydown', handleKeyDown)
        return () => {
          window.removeEventListener('keydown', handleKeyDown)
        }
      }
    }
  }, [options, isDisable, fieldsGame])

  useEffect(() => {
    if (!isDisable) {
      window.addEventListener('keydown', handleKeyDown3)
      return () => {
        window.removeEventListener('keydown', handleKeyDown3)
      }
    }
  }, [isDisable || fieldsGame])

  const [formData, setFormData] = useState({})

  let [options2, setoptions2] = useState([])
  let currentOption2 =
    Array.isArray(options2) && options2.length > 0 ? options2[0] : null

  const [currentIndex, setCurrentIndex] = useState(0)
  let currentOption =
    Array.isArray(options) && options.length > 0 ? options[currentIndex] : null

  useEffect(() => {
    if (name) {
      setGameNameRes(name)
    }

    const stateData = window.sessionStorage.getItem('adminuser')
    setlocalStoragedata(JSON.parse(stateData))
    dispatch(gamesActions.getAllMarket())
    dispatch(gamesActions.getAllGames())
    const stateManage = JSON.parse(stateData)
    if (stateManage?.roleId === 0) {
      setIsDisable(true)
      dispatch(gamesActions.getSubAdminList())
    }
  }, [dispatch])

  useEffect(() => {
    if (fieldsGame?.userId) {
      dispatch(
        gamesActions.getPlaceBetsByUserId({
          gameId: gameName?._id,
          userId: fieldsGame?.userId
        })
      )
    } else if (!fieldsGame.userId) {
      dispatch(
        gamesActions.getPlaceBetsByUserId({
          gameId: id
        })
      )
    }
  }, [fieldsGame, dispatch])

  function getSingleOpenCombinations(number) {
    const digits = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
    const combinations = []

    for (let j = 0; j < digits.length; j++) {
      combinations.push(number + digits[j])
    }
    return combinations
  }
  function getSingleCloseCombinations(number) {
    const digits = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
    const combinations = []

    for (let j = 0; j < digits.length; j++) {
      combinations.push(digits[j] + number)
    }
    return combinations
  }

  function getUniqueTwoDigitCombinations(number) {
    const digits = [...new Set(number?.toString().split(''))]
    const combinations = []

    for (let i = 0; i < digits.length; i++) {
      for (let j = 0; j < digits.length; j++) {
        if (i !== j) {
          combinations.push(digits[i] + digits[j])
        }
      }
    }
    return combinations
  }

  function getTwoDigitCombinations(number) {
    const digits = [...new Set(number?.toString().split(''))]
    const combinations = []

    for (let i = 0; i < digits.length; i++) {
      for (let j = 0; j < digits.length; j++) {
        combinations.push(digits[i] + digits[j])
      }
    }
    return combinations
  }

  const handleChange = e => {
    const { name, value } = e.target
    if (name === 'betNumber') {
      if (
        currentOption.toShow === 'Single Open' &&
        e.target.value.toString().length === 2
      ) {
        const findData = options.findIndex(ele => ele.toShow === 'Jodi')
        setCurrentIndex(() => (findData === -1 ? 0 : findData))
      } else if (
        currentOption.toShow === 'Single Open' &&
        e.target.value.toString().length === 1
      ) {
        setCurrentIndex(() => 0)
      }
    }
    const error = validate(name, value)
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: error
    }))

    const updateFormDataAndErrors = (key, val, err) => {
      setFormData(prev => ({
        ...prev,
        [key]: val
      }))
    }

    if (name === 'betNumber') {
      if (savedBetNumber === '') {
        setBetNumber(value)
        setIsBetNumberBlank(false)
      } else if (value !== savedBetNumber && isBetNumberBlank) {
        updateFormDataAndErrors(name, value, error)
        setBetNumber(e.target.value)
        setIsBetNumberBlank(false)
      } else {
        setBetNumber(value)
      }

      if (error) {
        return
      } else {
        setEnterPressCount(0)
      }
    }

    if (name === 'amount') {
      if (savedAmount === '') {
        setAmount(value)
        setIsAmountBlank(false)
      } else if (value !== savedAmount && isAmountBlank) {
        updateFormDataAndErrors(name, value, error)

        const lastCharacter = value[value.length - 1] || ''
        amountInputRef.current.value = lastCharacter
        setAmount(e.target.value)
        setIsAmountBlank(false)
      } else {
        setAmount(value)
      }
      if (error) {
        return
      }
      setEnterPressCount(1)
    }
  }

  const validate = (name, value = '') => {
    let error = ''

    // Check if value is empty
    if (!value) {
      error = 'Please enter a value.'
    }
    if (value === null || value === undefined || value === '') {
      error = 'Please enter a value.'
    }

    if (name === 'amount') {
      if (value < 5) {
        error = 'Amount must be greater than 5.'
      }
    }

    let indexChange = currentIndex
    if (name === 'betNumber') {
      if (
        currentOption.toShow === 'Single Open' &&
        value.toString().length === 2
      ) {
        const findData = options.findIndex(ele => ele.toShow === 'Jodi')
        setCurrentIndex(() => (findData === -1 ? 0 : findData))
        indexChange = findData === -1 ? 0 : findData
      } else if (
        currentOption.toShow === 'Single Open' &&
        value.toString().length === 1
      ) {
        setCurrentIndex(() => 0)
        indexChange = 0
      }
      const marketData =
        Array.isArray(options) && options.length > 0
          ? options[indexChange]
          : null
      if (!marketData) {
        error = 'Market data is unavailable.'
      } else {
        error = errorRespondsFunction(marketData.toShow, value.toString())
      }
    }
    return error
  }

  function errorRespondsFunction(marketName, value) {
    let error = ''
    if (
      ['Single Close', 'Single Open'].includes(marketName) &&
      value.length > 1
    ) {
      error = 'Only a single digit is allowed for Single Close.'
    } else if (['Single Kenchi', 'Double Kenchi'].includes(marketName)) {
      if (value.length < 3 || value.length > 7) {
        error =
          'Please enter between 3 to 7 unique digits for Single Kenchi or Double Kenchi.'
      } else if (new Set(value.split('')).size !== value.length) {
        error =
          'Duplicate digits are not allowed for Single Kenchi or Double Kenchi.'
      }
    } else if (['Jodi'].includes(marketName)) {
      if (value.length !== 2) {
        error = 'Jodi and Single Open require exactly 2 digits.'
      }
    }
    return error
  }

  const handleDelete = data => {
    const res = {
      gameId: gameName?._id,
      userId: fieldsGame?.userId
    }
    dispatch(gamesActions.placeBetByFreeze({ id: data._id }, res, betsByUserId))
  }
  let singleId =
    allMarket &&
    allMarket?.length > 0 &&
    allMarket.find(e => e?.shortName === 'SINGLEDIGIT')
  let JodiId =
    allMarket &&
    allMarket?.length > 0 &&
    allMarket.find(e => e?.shortName === 'JODI')

  useEffect(() => {
    if (!selectedCountry) {
      const availableGames = gamesList?.filter(
        game => game?.status === 'ACTIVE'
      )

      if (availableGames?.length > 0) {
        setGameNameRes(availableGames?.[0]?.name)
        setGameName(availableGames?.[0])
      }

      let data = [
        { ...JodiId, toShow: 'Single Open' },
        { ...JodiId, toShow: 'Single Close' },
        { ...JodiId, toShow: 'Jodi' },
        { ...JodiId, toShow: 'Single Kenchi' },
        { ...JodiId, toShow: 'Double Kenchi' }
      ].filter(item => item !== null)

      if (
        ['akc.close'].includes(availableGames?.[0]?.name) &&
        !['F9'].includes(stateKey)
      ) {
        data = [{ ...JodiId, toShow: 'Single Close' }].filter(
          item => item !== null
        )
      } else if (['ak open'].includes(availableGames?.[0]?.name)) {
        data = [
          { ...JodiId, toShow: 'Single Open' },
          { ...JodiId, toShow: 'Jodi' },
          { ...JodiId, toShow: 'Single Kenchi' },
          { ...JodiId, toShow: 'Double Kenchi' }
        ].filter(item => item !== null)
      }
      setoptions(data)
      setoptions2(gamesList)
    }
  }, [stateKey, fieldsGame, gameNameRes, singleId, JodiId, gamesList, games])

  const handleNext = e => {
    e.preventDefault()
    setCurrentIndex(prevIndex =>
      prevIndex === options?.length - 1 ? 0 : prevIndex + 1
    )
  }

  const handleKeyDown2 = event => {
    if (event.key === 'ArrowUp') {
      setCurrentIndex(prevIndex => {
        const singleKenchiIndex = options.findIndex(
          item => item.toShow === 'Single Kenchi'
        )
        return singleKenchiIndex >= 0 ? singleKenchiIndex : prevIndex
      })
    }

    if (event.key === 'ArrowRight') {
      setCurrentIndex(prevIndex => {
        const singleCloseIndex = options.findIndex(
          item => item.toShow === 'Single Close'
        )
        return singleCloseIndex >= 0 ? singleCloseIndex : prevIndex
      })
    }

    if (event.key === 'ArrowLeft') {
      setCurrentIndex(prevIndex => {
        const singleOpenIndex = options.findIndex(
          item => item.toShow === 'Single Open'
        )
        return singleOpenIndex >= 0 ? singleOpenIndex : prevIndex
      })
    }

    if (event.key === 'ArrowDown') {
      setCurrentIndex(prevIndex => {
        const doubleKenchiIndex = options.findIndex(
          item => item.toShow === 'Double Kenchi'
        )
        return doubleKenchiIndex >= 0 ? doubleKenchiIndex : prevIndex
      })
    }
  }

  const handleKeyDown3 = event => {
    if (['F9'].includes(event.key) && !['akc.close'].includes(gameNameRes)) {
      setStateKey(event.key)
      const findData = options.findIndex(ele => ele.toShow === 'Jodi')
      setCurrentIndex(() => (findData === -1 ? 0 : findData))
      setBetNumber('11')
    }
  }

  const inputChangeCreate = e => {
    e.preventDefault()
    setStateKey(null)
    const { name, value } = e.target
    setFieldsGame(prevState => ({ ...prevState, [name]: value }))
    setErrorsGame(prevState => ({ ...prevState, [name]: '' }))
    if (name === 'userId' && gameNameRes) {
      setTimeout(() => {
        betNumberInputRef.current?.focus()
      }, 10)
      const user = subAdminData?.find(ele => ele?._id === value)
      setUserName(user)
      setIsDisable(false)
    }
  }

  const { seconds, minutes, hours, ampm } = useTime({ format: '12-hour' })
  const handleChangeSelect = event => {
    setSelectedCountry(event.target.value)
    const availableGames = gamesList?.find(
      game => game?._id === event.target.value
    )

    dispatch(
      gamesActions.getPlaceBetsByUserId({
        gameId: event.target.value,
        userId: fieldsGame?.userId
      })
    )


    let data = [
      { ...JodiId, toShow: 'Single Open' },
      { ...JodiId, toShow: 'Single Close' },
      { ...JodiId, toShow: 'Jodi' },
      { ...JodiId, toShow: 'Single Kenchi' },
      { ...JodiId, toShow: 'Double Kenchi' }
    ].filter(item => item !== null)

    if (
      ['akc.close'].includes(availableGames?.name) &&
      !['F9'].includes(stateKey)
    ) {
      data = [{ ...JodiId, toShow: 'Single Close' }].filter(
        item => item !== null
      )
    } else if (['ak open'].includes(availableGames?.name)) {
      data = [
        { ...JodiId, toShow: 'Single Open' },
        { ...JodiId, toShow: 'Jodi' },
        { ...JodiId, toShow: 'Single Kenchi' },
        { ...JodiId, toShow: 'Double Kenchi' }
      ].filter(item => item !== null)
    }
    setoptions(data)
    setoptions2(gamesList)

    setGameNameRes(availableGames?.name)
    setGameName(availableGames)
  }


  return (
    <div className='w-full bg-white h-screen overflow-hidden overflow-y-auto'>
      <div className='relative'>
        <div className='justify-between flex flex-col bg-white rounded-md border'>
          {localStoragedata && localStoragedata.roleId === 0 && (
            <div className='flex justify-between w-full py-4 px-4'>
              <div className='w-full flex gap-2 items-center'>
                <select
                  className='w-56 px-3 py-1.5 text-base bg-white border border-gray-400 rounded-md focus:outline-none capitalize'
                  id='userId'
                  name='userId'
                  value={fieldsGame?.userId || ''}
                  onChange={e => inputChangeCreate(e)}
                >
                  <option value=''>Select Agent Name</option>
                  {subAdminData && subAdminData.length > 0
                    ? subAdminData.map((element, index) => (
                      <option key={index} value={element?._id || ''}>
                        {element?.userName || ''}
                      </option>
                    ))
                    : null}
                </select>
                {errorsGame?.userId && (
                  <div className='text-red-600 invalid-feedback'>
                    {errorsGame['userId']}
                  </div>
                )}
                <div className='w-full flex gap-2 items-center'>
                  <select
                    id='countries'
                    className='w-56 px-3 py-1.5 text-base bg-white border border-gray-400 rounded-md focus:outline-none capitalize'
                    value={selectedCountry}
                    onChange={handleChangeSelect}
                  >
                    <option value=''>Choose a Inactive Game</option>
                    {(
                      (Array.isArray(games?.gamesList) &&
                        games?.gamesList?.filter(
                          game => game?.status === 'CLOSED'
                        )) ||
                      []
                    )?.map(ele => {
                      return <option value={ele._id}>{ele.name}</option>
                    })}
                  </select>
                </div>
              </div>
              <div className='w-64'>
                <div className='flex items-center gap-2 w-full bg-red-100 rounded-lg p-1'>
                  <p className='text-base font-bold ml-4'>
                    Time:-
                  </p>
                  <div className='flex justify-center font-bold '>
                    {String(hours).padStart(2, '0')} : {String(minutes).padStart(2, '0')} : {String(seconds).padStart(2, '0')}
                  </div>
                  <span className='text-lg font-semibold px-1 py-1 rounded-md uppercase space-x-2'>
                    {ampm}
                  </span>
                </div>
              </div>
            </div>
          )}


          <div className='w-full px-4'>
            <div className='text-black capitalize w-full flex justify-between px-4 pt-3 py-2'>
              <div className='text-base flex gap-1 font-semibold items-center bg-red-100 p-2 rounded-lg'>
                <p>Game Name:</p>
                <div className='text-base font-semibold '>
                  {gameNameRes}
                </div>
              </div>
              <p className='text-base flex gap-1 items-center font-semibold bg-red-100 p-2 rounded-lg'>
                User Name:{' '}
                <span className='text-base font-semibold'>{userPalyerName ?? "N/A"}</span>
              </p>
              <p className='text-base flex gap-1 items-center font-semibold bg-red-100 p-2 rounded-lg'>
                Total Bet Amount:{' '}
                <span className='text-base font-semibold'>
                  {totalAmount ?? 0}
                </span>
              </p>
            </div>

            <div
              className='h-[36rem] overflow-x-auto overflow-y-auto'
              ref={scrollContainerRef}
            >
              <table className='min-w-full border border-gray-300 text-sm sm:text-base'>
                <thead className='sticky top-0 bg-red-100 z-10'>
                  <tr className='text-gray-800 font-semibold'>
                    <th className='p-3 uppercase'>#</th>
                    {localStoragedata && localStoragedata?.roleId === 0 && (
                      <>
                        <th className='p-3 uppercase'>Agent</th>
                        <th className='p-3 uppercase'>Game</th>
                      </>
                    )}
                    <th className='p-3 uppercase'>Market</th>
                    <th className='p-3 uppercase'>Number</th>
                    <th className='p-3 uppercase'>Amount</th>
                    <th className='p-3 uppercase'>Packet</th>
                    <th className='p-3 uppercase'>Date/Time</th>
                    <th className='p-3 uppercase'>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {betsByUserId && betsByUserId.length > 0 ? (
                    betsByUserId.map((bet, index) => (
                      <tr
                        key={index}
                        className='border-b bg-white hover:bg-gray-50 transition duration-200 text-gray-700'
                      >
                        <td className='p-3 border border-gray-200 text-center'>
                          {index + 1}
                        </td>
                        {localStoragedata && localStoragedata?.roleId === 0 && (
                          <>
                            <td className='p-3 border border-gray-200 text-center capitalize'>
                              {userPalyerName ?? ''}
                            </td>
                            <td className='p-3 border border-gray-200 text-center font-medium capitalize'>
                              {gameNameRes ?? ''}
                            </td>
                          </>
                        )}
                        <td className='p-3 border border-gray-200 text-center capitalize'>
                          {bet?.localmarketname}
                        </td>
                        {bet.isBetPrime ? (
                          <>
                            <td className='p-3 text-red-600 font-bold border border-gray-200 text-center capitalize'>
                              {bet.betNumber}
                            </td>
                            <td className='p-3 text-red-600 font-bold border border-gray-200 text-center capitalize'>
                              {['Single Open', 'Single Close'].includes(
                                bet?.localmarketname
                              )
                                ? bet?.amount * 10
                                : bet?.amount}
                            </td>
                          </>
                        ) : (
                          <>
                            <td className='p-3 border border-gray-200 text-center font-bold capitalize'>
                              {bet.betNumber}
                            </td>
                            <td className='p-3 border border-gray-200 text-center font-bold capitalize'>
                              {['Single Open', 'Single Close'].includes(
                                bet?.localmarketname
                              )
                                ? bet?.amount * 10
                                : bet?.amount}
                            </td>
                          </>
                        )}

                        <td className='p-3 border border-gray-200 text-center capitalize'>
                          {['Single Open', 'Single Close'].includes(
                            bet?.marketId?.name
                          )
                            ? bet?.packet?.length * 10
                            : bet?.packet?.length}
                        </td>
                        <td className='p-3 border border-gray-200 text-center capitalize'>
                          {moment(bet?.createdAt).format('DD-MM-YYYY HH:mm')}
                        </td>
                        <td className='p-3 text-center capitalize' title='Delete'>
                          <button
                            onClick={() => handleDelete(bet)}
                            className='text-red-500 hover:text-red-600 font-medium'
                          >
                            <MdDeleteOutline size={25} />
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan='8'
                        className='text-center px-4 py-4 text-gray-500'
                      >
                        No bets available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <form
              disabled={isDisable}
              className='p-4 bg-gray-100 space-y-6 lg:space-y-0 w-full z-10'
            >
              <div className='flex flex-col lg:flex-row items-center gap-6 w-full'>
                {/* Display Current Option */}
                <div className='flex items-center gap-3 w-full lg:w-auto'>
                  <div className='text-gray-800 border border-gray-300 rounded-lg px-6 py-2 text-center w-full lg:w-auto'>
                    {currentOption?.toShow}
                  </div>
                  <button
                    onClick={e => handleNext(e)}
                    disabled={isDisable}
                    className='bg-blue-500 hover:bg-blue-600 text-white p-3 rounded-lg transition duration-300 flex items-center justify-center'
                  >
                    <MdOutlineNavigateNext size={20} />
                  </button>
                </div>

                {/* Bet Number Input */}
                <div className='w-full lg:w-auto'>
                  <input
                    type='text'
                    ref={betNumberInputRef}
                    value={betNumber || ''}
                    onChange={e => {
                      const value = e.target.value
                      if (/^\d*$/.test(value)) {
                        handleChange(e)
                      }
                    }}
                    autoComplete='off'
                    onKeyDown={handleKeyDown}
                    disabled={isDisable}
                    name='betNumber'
                    className='bg-white border border-gray-300 focus:border-red-500 focus:ring focus:ring-red-200 text-gray-700 text-lg 
                    rounded-lg w-full px-4 py-2 transition duration-300'
                    placeholder='Enter a number'
                  />
                  {errors?.betNumber && (
                    <span className='text-red-500 text-sm mt-1 block'>
                      {errors?.betNumber}
                    </span>
                  )}
                </div>

                {/* Amount Input */}
                <div className='w-full lg:w-auto'>
                  <input
                    type='number'
                    ref={amountInputRef}
                    value={amount}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    disabled={isDisable}
                    name='amount'
                    autoComplete='off'
                    className='bg-white border border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-200 text-gray-700 text-lg rounded-lg w-full px-4 py-2 transition duration-300'
                    placeholder='Enter an amount'
                    required
                  />
                  {errors?.amount && (
                    <span className='text-red-500 text-sm mt-1 block'>
                      {errors?.amount}
                    </span>
                  )}
                </div>
              </div>

              {/* Loading Indicator */}
              {loading && (
                <div className='absolute inset-0 flex justify-center items-center bg-white/60 z-40'>
                  <div className='w-8 h-8 border-4 border-blue-500 border-solid border-t-transparent rounded-full animate-spin'></div>
                </div>
              )}
            </form>
          </div>

        </div>
      </div>
    </div>
  )
}

export default PlaceBet
