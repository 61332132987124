/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { bankActions } from '../../_actions'
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import ReactPaginate from 'react-paginate'
import { isMobile } from 'react-device-detect'
import Loader from '../../components/Loader/Loader'
import { useParams } from 'react-router'
import { IoArrowBackOutline } from 'react-icons/io5'

const size = 10
const Charts = () => {
  const dispatch = useDispatch()
  let selector = useSelector(state => state)
  const [currentPage3, setCurrentPage3] = useState(0)
  const [pageNo, setPageNo] = useState(1)
  const navigate = useNavigate()
  let parmas = useParams()

  useEffect(() => {
    let appsettingReq = {
      keyWord: '',
      pageNo: pageNo,
      size: size
    }
    dispatch(bankActions.getAdminBankDetailList(appsettingReq))
    dispatch(bankActions.calander({ gameId: parmas?.gameId }))
  }, [])

  const handlePageClick = data => {
    setCurrentPage3(data.selected)
    setPageNo(data.selected + 1)
    let pageReq = {
      keyWord: '',
      pageNo: data.selected + 1,
      size: size
    }
    dispatch(bankActions.getAdminBankDetailList(pageReq))
  }

  let { bank } = selector ? selector : {}
  let { loading, adminBankDetail, chartdata } = bank ? bank : {}
  let { total } = adminBankDetail ? adminBankDetail : {}

  const handleGoBack = () => {
    navigate(-1) // Navigates to the previous page
  }

  return (
    <>
      <Loader loading={loading} />
      <div className='z-0 overflow-hidden overflow-y-auto focus:outline-none'>
        <div className='relative'>
          <div className='relative z-20 flex min-h-screen'>
            <div className='flex w-full overflow-hidden '>
              <div className='flex flex-col flex-1 overflow-hidden'>
                <main className='relative flex-1 '>
                  <div className='p-3 2xl:p-10 sm:p-5'>
                    <div className='mx-auto max-w-screen-3xl'>
                      <div>
                        <div className='flex justify-center items-center w-full'>
                          <a
                            className=' bg-[#911212] w-40 text-center mb-3 py-1 px-1 text-white font-bold rounded-md'
                            href='#top'
                            id='bottom'
                          >
                            {' '}
                            GO TO BOTTOM{' '}
                          </a>
                        </div>
                        <div className='overflow-hidden overflow-x-auto bg-gray-50 p-4 rounded-md'>
                          <div className='flex items-center space-x-3 pb-3'>
                            <IoArrowBackOutline
                              className='w-8 h-8 bg-gray-200 text-gray-500 cursor-pointer texthover p-1 rounded-full'
                              onClick={handleGoBack}
                            />
                            <p className='text-2xl font-bold capitalize'>
                              {parmas?.gameName}
                            </p>
                          </div>
                          <table class='min-w-full text-base md:text-base  divide-y divide-gray-200 '>
                            <thead>
                              <tr class='bg-gray-50'>
                                <th class='text-center md:text-base border text-base font-medium text-[#17273B] uppercase tracking-wider'>
                                  Date
                                </th>
                                <th class='text-center md:text-base border text-base font-medium text-[#17273B] uppercase tracking-wider'>
                                  MON
                                </th>
                                <th class='text-center md:text-base border text-base font-medium text-[#17273B] uppercase tracking-wider'>
                                  TUE
                                </th>
                                <th class='text-center md:text-base border text-base font-medium text-[#17273B] uppercase tracking-wider'>
                                  WED
                                </th>
                                <th class='text-center md:text-base border text-base font-medium text-[#17273B] uppercase tracking-wider'>
                                  THU
                                </th>
                                <th class='text-center md:text-base border text-base font-medium text-[#17273B] uppercase tracking-wider'>
                                  FRI
                                </th>
                                <th class='text-center md:text-base border text-base font-medium text-[#17273B] uppercase tracking-wider'>
                                  SAT
                                </th>
                                <th class='text-center md:text-base border text-base font-medium text-[#17273B] uppercase tracking-wider'>
                                  SUN
                                </th>
                              </tr>
                            </thead>
                            <tbody class='bg-white divide-y divide-gray-200'>
                              {chartdata &&
                              chartdata.length > 0 &&
                              chartdata ? (
                                chartdata?.map((week, index) => {
                                  const startDate = new Date(week.rWeek)
                                  // Calculate end date by adding 6 days to start date
                                  const endDate = new Date(
                                    startDate.getTime() +
                                      6 * 24 * 60 * 60 * 1000
                                  )
                                  // Format dates as YYYY-MM-DD strings
                                  const formattedStartDate = startDate
                                    .toISOString()
                                    .split('T')[0]
                                  const formattedEndDate = endDate
                                    .toISOString()
                                    .split('T')[0]

                                  return (
                                    <tr key={index} class='bg-white border'>
                                      <td class='px-1 whitespace-nowrap border text-xs md:text-base'>
                                        <div className='flex flex-col text-xs md:text-base justify-center items-center'>
                                          <div>{formattedStartDate}</div>to
                                          <div>{formattedEndDate}</div>
                                        </div>
                                      </td>
                                      <td class='px-1 whitespace-nowrap border text-xs md:text-base'>
                                        {
                                          <div className=' flex md:gap-3 gap-1 justify-center items-center '>
                                            <div>
                                              <div>
                                                {(week?.MON?.openNumber &&
                                                  week?.MON?.openNumber.toString()[0]) ||
                                                  '*'}
                                              </div>
                                              <div>
                                                {(week?.MON?.openNumber &&
                                                  week?.MON?.openNumber.toString()[1]) ||
                                                  '*'}
                                              </div>
                                              <div>
                                                {(week?.MON?.openNumber &&
                                                  week?.MON?.openNumber.toString()[2]) ||
                                                  '*'}
                                              </div>
                                            </div>
                                            <div className=' text-red-500'>
                                              {(week?.MON?.jodiNumber &&
                                                week?.MON?.jodiNumber.toString()[0]) ||
                                                '*'}{' '}
                                              {(week?.MON?.jodiNumber &&
                                                week?.MON?.jodiNumber[1]) ||
                                                '*'}
                                            </div>
                                            <div className=' '>
                                              <div>
                                                {(week?.MON?.closeNumber &&
                                                  week?.MON?.closeNumber.toString()[0]) ||
                                                  '*'}
                                              </div>
                                              <div>
                                                {(week?.MON?.closeNumber &&
                                                  week?.MON?.closeNumber.toString()[1]) ||
                                                  '*'}
                                              </div>
                                              <div>
                                                {(week?.MON?.closeNumber &&
                                                  week?.MON?.closeNumber.toString()[2]) ||
                                                  '*'}
                                              </div>
                                            </div>
                                          </div>
                                        }
                                      </td>

                                      <td class='px-1 whitespace-nowrap border text-xs md:text-base'>
                                        {
                                          <div className=' flex md:gap-3 gap-1 justify-center items-center '>
                                            <div>
                                              <div>
                                                {(week?.TUE?.openNumber &&
                                                  week?.TUE?.openNumber.toString()[0]) ||
                                                  '*'}
                                              </div>
                                              <div>
                                                {(week?.TUE?.openNumber &&
                                                  week?.TUE?.openNumber.toString()[1]) ||
                                                  '*'}
                                              </div>
                                              <div>
                                                {(week?.TUE?.openNumber &&
                                                  week?.TUE?.openNumber.toString()[2]) ||
                                                  '*'}
                                              </div>
                                            </div>
                                            <div className=' text-red-500'>
                                              {(week?.TUE?.jodiNumber &&
                                                week?.TUE?.jodiNumber.toString()[0]) ||
                                                '*'}{' '}
                                              {(week?.TUE?.jodiNumber &&
                                                week?.TUE?.jodiNumber[1]) ||
                                                '*'}
                                            </div>
                                            <div className=' '>
                                              <div>
                                                {(week?.TUE?.closeNumber &&
                                                  week?.TUE?.closeNumber.toString()[0]) ||
                                                  '*'}
                                              </div>
                                              <div>
                                                {(week?.TUE?.closeNumber &&
                                                  week?.TUE?.closeNumber.toString()[1]) ||
                                                  '*'}
                                              </div>
                                              <div>
                                                {(week?.TUE?.closeNumber &&
                                                  week?.TUE?.closeNumber.toString()[2]) ||
                                                  '*'}
                                              </div>
                                            </div>
                                          </div>
                                        }
                                      </td>

                                      <td class='px-1 whitespace-nowrap border text-xs md:text-base'>
                                        {
                                          <div className=' flex md:gap-3 gap-1 justify-center items-center '>
                                            <div>
                                              <div>
                                                {(week?.WED?.openNumber &&
                                                  week?.WED?.openNumber.toString()[0]) ||
                                                  '*'}
                                              </div>
                                              <div>
                                                {(week?.WED?.openNumber &&
                                                  week?.WED?.openNumber.toString()[1]) ||
                                                  '*'}
                                              </div>
                                              <div>
                                                {(week?.WED?.openNumber &&
                                                  week?.WED?.openNumber.toString()[2]) ||
                                                  '*'}
                                              </div>
                                            </div>
                                            <div className=' text-red-500'>
                                              {(week?.WED?.jodiNumber &&
                                                week?.WED?.jodiNumber.toString()[0]) ||
                                                '*'}{' '}
                                              {(week?.WED?.jodiNumber &&
                                                week?.WED?.jodiNumber[1]) ||
                                                '*'}
                                            </div>
                                            <div className=' '>
                                              <div>
                                                {(week?.WED?.closeNumber &&
                                                  week?.WED?.closeNumber.toString()[0]) ||
                                                  '*'}
                                              </div>
                                              <div>
                                                {(week?.WED?.closeNumber &&
                                                  week?.WED?.closeNumber.toString()[1]) ||
                                                  '*'}
                                              </div>
                                              <div>
                                                {(week?.WED?.closeNumber &&
                                                  week?.WED?.closeNumber.toString()[2]) ||
                                                  '*'}
                                              </div>
                                            </div>
                                          </div>
                                        }
                                      </td>

                                      <td class='px-1 whitespace-nowrap border text-xs md:text-base'>
                                        {
                                          <div className=' flex md:gap-3 gap-1 justify-center items-center '>
                                            <div>
                                              <div>
                                                {(week?.THU?.openNumber &&
                                                  week?.THU?.openNumber.toString()[0]) ||
                                                  '*'}
                                              </div>
                                              <div>
                                                {(week?.THU?.openNumber &&
                                                  week?.THU?.openNumber.toString()[1]) ||
                                                  '*'}
                                              </div>
                                              <div>
                                                {(week?.THU?.openNumber &&
                                                  week?.THU?.openNumber.toString()[2]) ||
                                                  '*'}
                                              </div>
                                            </div>
                                            <div className=' text-red-500'>
                                              {(week?.THU?.jodiNumber &&
                                                week?.THU?.jodiNumber.toString()[0]) ||
                                                '*'}{' '}
                                              {(week?.THU?.jodiNumber &&
                                                week?.THU?.jodiNumber[1]) ||
                                                '*'}
                                            </div>
                                            <div className=' '>
                                              <div>
                                                {(week?.THU?.closeNumber &&
                                                  week?.THU?.closeNumber.toString()[0]) ||
                                                  '*'}
                                              </div>
                                              <div>
                                                {(week?.THU?.closeNumber &&
                                                  week?.THU?.closeNumber.toString()[1]) ||
                                                  '*'}
                                              </div>
                                              <div>
                                                {(week?.THU?.closeNumber &&
                                                  week?.THU?.closeNumber.toString()[2]) ||
                                                  '*'}
                                              </div>
                                            </div>
                                          </div>
                                        }
                                      </td>

                                      <td class='px-1 whitespace-nowrap border text-xs md:text-base'>
                                        {
                                          <div className=' flex md:gap-3 gap-1 justify-center items-center '>
                                            <div>
                                              <div>
                                                {(week?.FRI?.openNumber &&
                                                  week?.FRI?.openNumber.toString()[0]) ||
                                                  '*'}
                                              </div>
                                              <div>
                                                {(week?.FRI?.openNumber &&
                                                  week?.FRI?.openNumber.toString()[1]) ||
                                                  '*'}
                                              </div>
                                              <div>
                                                {(week?.FRI?.openNumber &&
                                                  week?.FRI?.openNumber.toString()[2]) ||
                                                  '*'}
                                              </div>
                                            </div>
                                            <div className=' text-red-500'>
                                              {(week?.FRI?.jodiNumber &&
                                                week?.FRI?.jodiNumber.toString()[0]) ||
                                                '*'}{' '}
                                              {(week?.FRI?.jodiNumber &&
                                                week?.FRI?.jodiNumber[1]) ||
                                                '*'}
                                            </div>
                                            <div className=' '>
                                              <div>
                                                {(week?.FRI?.closeNumber &&
                                                  week?.FRI?.closeNumber.toString()[0]) ||
                                                  '*'}
                                              </div>
                                              <div>
                                                {(week?.FRI?.closeNumber &&
                                                  week?.FRI?.closeNumber.toString()[1]) ||
                                                  '*'}
                                              </div>
                                              <div>
                                                {(week?.FRI?.closeNumber &&
                                                  week?.FRI?.closeNumber.toString()[2]) ||
                                                  '*'}
                                              </div>
                                            </div>
                                          </div>
                                        }
                                      </td>

                                      <td class='px-1 whitespace-nowrap border text-xs md:text-base'>
                                        {
                                          <div className=' flex md:gap-3 gap-1 justify-center items-center '>
                                            <div>
                                              <div>
                                                {(week?.SAT?.openNumber &&
                                                  week?.SAT?.openNumber.toString()[0]) ||
                                                  '*'}
                                              </div>
                                              <div>
                                                {(week?.SAT?.openNumber &&
                                                  week?.SAT?.openNumber.toString()[1]) ||
                                                  '*'}
                                              </div>
                                              <div>
                                                {(week?.SAT?.openNumber &&
                                                  week?.SAT?.openNumber.toString()[2]) ||
                                                  '*'}
                                              </div>
                                            </div>
                                            <div className=' text-red-500'>
                                              {(week?.SAT?.jodiNumber &&
                                                week?.SAT?.jodiNumber.toString()[0]) ||
                                                '*'}{' '}
                                              {(week?.SAT?.jodiNumber &&
                                                week?.SAT?.jodiNumber[1]) ||
                                                '*'}
                                            </div>
                                            <div className=' '>
                                              <div>
                                                {(week?.SAT?.closeNumber &&
                                                  week?.SAT?.closeNumber.toString()[0]) ||
                                                  '*'}
                                              </div>
                                              <div>
                                                {(week?.SAT?.closeNumber &&
                                                  week?.SAT?.closeNumber.toString()[1]) ||
                                                  '*'}
                                              </div>
                                              <div>
                                                {(week?.SAT?.closeNumber &&
                                                  week?.SAT?.closeNumber.toString()[2]) ||
                                                  '*'}
                                              </div>
                                            </div>
                                          </div>
                                        }
                                      </td>

                                      <td class='px-1 whitespace-nowrap border text-xs md:text-base'>
                                        {
                                          <div className=' flex md:gap-3 gap-1 justify-center items-center '>
                                            <div>
                                              <div>
                                                {(week?.SUN?.openNumber &&
                                                  week?.SUN?.openNumber.toString()[0]) ||
                                                  '*'}
                                              </div>
                                              <div>
                                                {(week?.SUN?.openNumber &&
                                                  week?.SUN?.openNumber.toString()[1]) ||
                                                  '*'}
                                              </div>
                                              <div>
                                                {(week?.SUN?.openNumber &&
                                                  week?.SUN?.openNumber.toString()[2]) ||
                                                  '*'}
                                              </div>
                                            </div>
                                            <div className=' text-red-500'>
                                              {(week?.SUN?.jodiNumber &&
                                                week?.SUN?.jodiNumber.toString()[0]) ||
                                                '*'}{' '}
                                              {(week?.SUN?.jodiNumber &&
                                                week?.SUN?.jodiNumber[1]) ||
                                                '*'}
                                            </div>
                                            <div className=' '>
                                              <div>
                                                {(week?.SUN?.closeNumber &&
                                                  week?.SUN?.closeNumber.toString()[0]) ||
                                                  '*'}
                                              </div>
                                              <div>
                                                {(week?.SUN?.closeNumber &&
                                                  week?.SUN?.closeNumber.toString()[1]) ||
                                                  '*'}
                                              </div>
                                              <div>
                                                {(week?.SUN?.closeNumber &&
                                                  week?.SUN?.closeNumber.toString()[2]) ||
                                                  '*'}
                                              </div>
                                            </div>
                                          </div>
                                        }
                                      </td>
                                    </tr>
                                  )
                                })
                              ) : (
                                <td> No Data Found</td>
                              )}
                            </tbody>
                          </table>
                        </div>

                        <div className='flex justify-center items-center w-full'>
                          <a
                            className=' bg-[#911212] w-40 text-center mt-3 py-1 px-1 text-white font-bold rounded-md'
                            href='#bottom'
                            id='bottom'
                          >
                            GO TO Top
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  {isMobile ? (
                    <nav className='relative z-0 flex justify-end mt-5 w-76'>
                      {total && total > 10 ? (
                        <ReactPaginate
                          previousLabel={'Prev'}
                          nextLabel={'Next'}
                          breakLabel={'...'}
                          breakClassName={'break-me'}
                          pageCount={total / size}
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={1}
                          onPageChange={handlePageClick}
                          containerClassName={'pagination'}
                          pageClassName={'page-cls'}
                          activeClassName={'active'}
                          forcePage={currentPage3}
                        />
                      ) : null}
                    </nav>
                  ) : (
                    <nav className='relative z-0 flex justify-end mt-5 w-76'>
                      {total && total > 10 ? (
                        <ReactPaginate
                          previousLabel={'Previous'}
                          nextLabel={'Next'}
                          breakLabel={'...'}
                          breakClassName={'break-me'}
                          pageCount={total / size}
                          marginPagesDisplayed={3}
                          pageRangeDisplayed={3}
                          onPageChange={handlePageClick}
                          containerClassName={'pagination'}
                          pageClassName={'page-cls'}
                          activeClassName={'active'}
                          forcePage={currentPage3}
                        />
                      ) : null}
                    </nav>
                  )}
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Charts
