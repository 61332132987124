/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactPaginate from 'react-paginate'
import { useNavigate, useParams } from 'react-router-dom'
import { BiArrowBack } from 'react-icons/bi'
import { reportActions } from '../../_actions'
const keyWord = ''
const fromDate = ''
const toDate = ''
const size = 10
const Ledger4 = () => {
  let selector = useSelector(state => state)
  const dispatch = useDispatch()

  let { games, reports } = selector ? selector : {}
  let { getBetWiseReport, getLedgerTotal } = reports ? reports : {}
  let { allUserList } = games ? games : {}
  const [fieldsGame, setFieldsGame] = useState({})
  const [errorsGame, setErrorsGame] = useState({})
  const navigate = useNavigate()
  const [offset, setOffset] = useState(0)
  const [pageNo, setPageNo] = useState(1)
  const [currentPage, setCurrentPage] = useState(0)
  const [Options, setOptions] = useState([])
  const today = new Date().toISOString().split('T')[0]
  let params = useParams()
  useEffect(() => {
    dispatch(reportActions.betwiseLedger({ userId: params?.id }))
  }, [params?.id])

  useEffect(() => {
    if (allUserList) {
      const options = allUserList?.map(user => ({
        label: user.userName,
        value: user._id
      }))
      setOptions(options)
    }
  }, [allUserList])

  const inputChangeCreate = e => {
    e.preventDefault()
    const { name, value } = e.target
    setFieldsGame(prevState => ({ ...prevState, [name]: value }))
    setErrorsGame(prevState => ({ ...prevState, [name]: '' }))
  }

  const createGameSubmit = e => {
    e.preventDefault()
    let gameReq2 = {
      fromDate: fieldsGame?.date || '',
      toDate: fieldsGame?.todate || '',
      keyWord: fieldsGame?.keyWord || '',
      gameId: fieldsGame?.userId,
      marketId: fieldsGame?.userId2,
      pageNo: pageNo,
      size: size,
      userId: params?.id
    }
    dispatch(reportActions.betwiseLedger(gameReq2))
  }

  const handlePageClick = data => {
    let offset = Math.ceil(data.selected * size)
    setOffset(offset)
    setCurrentPage(data.selected)
    setPageNo(data.selected + 1)
    let pageReq = {
      fromDate: fromDate,
      toDate: toDate,
      keyWord: keyWord,
      sortBy: '',
      sortOrder: '',
      pageNo: data.selected + 1,
      size: size,
      userId: params?.id
    }
    dispatch(reportActions.betwiseLedger(pageReq))
  }

  return (
    <>
      {/* <Loader loading={loading} /> */}
      <div className='z-0 overflow-hidden overflow-y-auto focus:outline-none'>
        <div className='relative'>
          <div className='relative z-20 flex min-h-screen'>
            <div className='flex w-full overflow-hidden '>
              <div className='flex flex-col flex-1 overflow-hidden'>
                <main className='relative flex-1 '>
                  <div className='p-3 sm:p-5'>
                    <div className='mx-auto max-w-screen-3xl space-y-4'>
                      <div className='bg-white p-3 flex items-center space-x-3 rounded-lg'>
                        <BiArrowBack
                          className='w-7 h-7 rounded-full bg-gray-100 p-1 cursor-pointer'
                          onClick={() => navigate(-1)}
                        />
                        <span className='text-lg font-semibold capitalize'>
                          User Name: {params?.name || ''}
                        </span>
                      </div>

                      <div className='px-6 py-2 text-left bg-white rounded-lg'>
                        <div className='flex justify-between md:flex-nowrap  gap-3 flex-wrap items-end  w-full '>
                          <div className='shadow-sm w-full'>
                            <label
                              class='block text-gray-700 text-base mb-2'
                              for='gamename'
                            >
                              From Date :
                            </label>
                            <input
                              className='w-full px-3 py-2 text-base bg-white border border-gray-400 rounded-lg focus:outline-none'
                              id='date'
                              name='date'
                              placeholder='Enter date'
                              value={
                                fieldsGame && fieldsGame['date']
                                  ? fieldsGame['date']
                                  : ''
                              }
                              max={today}
                              type='date'
                              onChange={inputChangeCreate}
                            />
                            {errorsGame && errorsGame['date'] ? (
                              <div className='text-red-600 invalid-feedback'>
                                {errorsGame['date']}
                              </div>
                            ) : null}
                          </div>

                          <div className='shadow-sm w-full'>
                            <label
                              class='block text-gray-700 text-base mb-2'
                              for='gamename'
                            >
                              To Date :
                            </label>
                            <input
                              className='w-full px-3 py-2 text-base bg-white border border-gray-400 rounded-lg focus:outline-none'
                              id='date'
                              name='todate'
                              placeholder='Enter date'
                              value={
                                fieldsGame && fieldsGame['todate']
                                  ? fieldsGame['todate']
                                  : ''
                              }
                              max={today}
                              type='date'
                              onChange={inputChangeCreate}
                            />
                            {errorsGame && errorsGame['todate'] ? (
                              <div className='text-red-600 invalid-feedback'>
                                {errorsGame['todate']}
                              </div>
                            ) : null}
                          </div>
                          <div className='w-full flex mt-2 md:mt-0 justify-start items-end'>
                            <button
                              className='flex justify-center px-4 py-2 text-base font-semibold text-white capitalize  transition duration-150 ease-in-out bgcolor border border-[#7D1970] rounded-xl focus:outline-none'
                              type='button'
                              onClick={createGameSubmit}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className='text-left bg-white space-y-2 rounded-lg'>
                        <div className='flex justify-between items-center px-3 py-2 border-b'>
                          <span className='text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white '>
                            Ledger List
                          </span>
                        </div>
                        <div className='relative overflow-x-auto p-5'>
                          <table className='w-full text-base text-left rtl:text-right text-gray-500 '>
                            <thead className='text-gray-900 capitalize bg-gray-50 border'>
                              <tr>
                                <th className='px-6 py-2'>S.No.</th>
                                <th className='px-6 py-2'>Date</th>
                                <th className='px-6 py-2'>Game Name</th>
                                <th className='px-6 py-2'>credit</th>
                                <th className='px-6 py-2'>Debit</th>
                                <th className='px-6 py-2'>Balance</th>
                                {/* <th className="px-6 py-3">Date</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {getBetWiseReport &&
                                getBetWiseReport.length > 0 ? (
                                getBetWiseReport.map((element, index) => (
                                  <React.Fragment key={index}>
                                    <tr
                                      key={index}
                                      className='bg-white border-b border-gray-400'
                                    >
                                      <th
                                        scope='row'
                                        className='px-6 py-2 font-medium text-gray-500 whitespace-nowrap '
                                      >
                                        {offset + index + 1}
                                      </th>

                                      <th
                                        scope='row'
                                        className='px-6 py-2 font-medium text-gray-500 whitespace-nowrap '
                                      >
                                        {element && element.date && element.date
                                          ? element.date
                                          : '-'}
                                      </th>

                                      <th
                                        scope='row'
                                        className='px-6 py-2 font-medium text-gray-500 whitespace-nowrap '
                                      >
                                        {element &&
                                          element.gameName &&
                                          element.gameName
                                          ? element.gameName
                                          : '-'}
                                      </th>

                                      <th
                                        scope='row'
                                        className='px-6 py-2 font-medium text-gray-500 whitespace-nowrap '
                                      >
                                        {element && element.netAmount >= 0
                                          ? element.netAmount
                                          : '-'}
                                      </th>
                                      <th
                                        scope='row'
                                        className='px-6 py-2 font-medium text-gray-500 whitespace-nowrap '
                                      >
                                        {element && element.netAmount < 0
                                          ? element.netAmount
                                          : '-'}
                                      </th>

                                      <th
                                        scope='row'
                                        className='px-6 py-2 font-medium text-gray-500 whitespace-nowrap '
                                      >
                                        {element &&
                                          element.balance &&
                                          element.balance
                                          ? element.balance
                                          : '-'}
                                      </th>
                                    </tr>
                                  </React.Fragment>
                                ))
                              ) : (
                                <td
                                  colSpan={6}
                                  className='text-center py-3 text-gray-700 border'
                                >
                                  No Data Found
                                </td>
                              )}
                            </tbody>
                          </table>
                        </div>
                        <nav className='relative z-0 flex justify-end mt-5 w-76'>
                          {getLedgerTotal && getLedgerTotal > size ? ( // Assuming `size` is the number of items per page
                            <ReactPaginate
                              previousLabel={'Prev'}
                              nextLabel={'Next'}
                              breakLabel={'...'}
                              breakClassName={'break-me'}
                              pageCount={Math.ceil(getLedgerTotal / size)} // Calculate total pages
                              marginPagesDisplayed={2} // Show 1 page before and after current page
                              pageRangeDisplayed={1} // Show up to 5 pages in pagination
                              onPageChange={handlePageClick}
                              containerClassName={'pagination'}
                              pageClassName={'page-cls'}
                              activeClassName={'active'}
                              forcePage={currentPage}
                            />
                          ) : null}
                        </nav>
                      </div>
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Ledger4
