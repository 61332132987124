// import { Hidden } from "@mui/material";
import React from 'react'

export default function ViewUserModal (props) {
  let {
    viewModal,
    handleViewHideModal,
    rowData,
    handleChange,
    errorsGame,
    handleFund
  } = props

  return (
    <div
      className={
        viewModal
          ? 'fixed inset-0 z-50 flex items-center justify-center w-full overflow-hidden main-modal h-100 animated fadeIn faster'
          : 'hidden'
      }
      style={{ background: 'rgba(0,0,0,.7)' }}
    >
      <div className='z-50 w-11/12 mx-auto overflow-y-auto bg-white border border-teal-500 rounded shadow-lg modal-container md:max-w-md'>
        <div className='px-6 py-4 text-left modal-content'>
          {/*Title*/}
          <div className='flex items-center justify-between bg-[#911212] px-1 rounded-lg py-2 text-white'>
            <p className='text-2xl font-bold'>{rowData?.key || ''}</p>
            <div className='z-50 cursor-pointer modal-close'>
              <svg
                onClick={() => handleViewHideModal()}
                className='text-white fill-current'
                xmlns='http://www.w3.org/2000/svg'
                width={18}
                height={18}
                viewBox='0 0 18 18'
              >
                <path d='M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z'></path>
              </svg>
            </div>
          </div>
          {/*Body*/}
          <form autoComplete='off'>
            <div className="{otpSent?'disableArea':''}">
              <div className='relative mt-1 shadow-sm'>
                <label
                  class='block text-gray-700 text-base font-bold mb-2'
                  for='username'
                >
                  User Name :
                </label>
                <input
                  disabled
                  className={`w-full px-3 py-3 text-base transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring `}
                  id='userName'
                  name='userName'
                  placeholder='UserName'
                  value={
                    rowData && rowData['userName'] ? rowData['userName'] : ''
                  }
                  type='text'
                />
              </div>
            </div>

            <div>
              <div className='relative mt-1 shadow-sm'>
                <label
                  class='block text-gray-700 text-base font-bold mb-2'
                  for='username'
                >
                  Amount :
                </label>
                <input
                  onChange={handleChange}
                  className={`w-full px-3 py-3 text-base transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring `}
                  id='amount'
                  name='amount'
                  placeholder='Amount'
                  value={rowData && rowData['amount'] ? rowData['amount'] : ''}
                  type='number'
                />
                {errorsGame && errorsGame['amount'] && (
                  <div className=' text-red-500'>{errorsGame['amount']}</div>
                )}
              </div>
            </div>

            <button
              onClick={handleFund}
              className=' text-white text-xl bg-[#911212] justify-center items-center p-2 w-full rounded-lg mt-3'
            >
              {' '}
              Submit{' '}
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}
