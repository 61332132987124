import React, { useState } from "react";
import { useSelector } from "react-redux";
import { FaRegEyeSlash } from "react-icons/fa";
import { FaRegEye } from "react-icons/fa6";

export default function CreateUserModal(props) {

  let { userOpenModal, handleHideModal, inputChange, fieldsUser, errorsUser, createUserSubmit, handleFile, addRef } = props;
  let selector = useSelector(state => state);
  let { users } = selector ? selector : {}
  let { fileData, loading } = users ? users : {}
  let [v, setv] = useState(false);
  let [v1, setv1] = useState(false);

  return (

    // <Modal
    //   isOpen={userOpenModal}
    // >

    <div className={userOpenModal ? `fixed inset-0 z-50 flex items-center justify-center w-full overflow-hidden main-modal h-100 animated fadeIn faster` : 'hidden'} style={{ background: 'rgba(0,0,0,.7)' }}>
      <div className="z-50 w-11/12 mx-auto overflow-y-auto bg-white border rounded shadow-lg modal-container md:max-w-md">
        <div className="px-6 py-4 text-left modal-content">
          {/*Title*/}
          <div className="flex items-center bgcolor text-white mb-3  justify-between py-3 px-2 rounded-lg">
            <p className="text-2xl font-bold">Add User</p>
            <div className="z-50 cursor-pointer modal-close">
              <svg onClick={() => handleHideModal()} className="text-white fill-current" xmlns="http://www.w3.org/2000/svg" width={18} height={18} viewBox="0 0 18 18">
                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z">
                </path>
              </svg>
            </div>
          </div>
          {/*Body*/}
          <form autoComplete="off">
            <div className="{otpSent?'disableArea':''}">
              <div className="relative mt-1 shadow-sm">
                <label class="block text-gray-700 text-base font-bold mb-2" for="username">User Name :</label>
                <input className={`w-full px-3 py-3 text-base transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${errorsUser && !errorsUser["userName"] ? "border  placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                  id="userName" name="userName" placeholder="User Name"
                  // value={fieldsUser.userName}
                  value={fieldsUser && fieldsUser["userName"] ? fieldsUser["userName"] : ""}

                  type="text" onChange={inputChange} />
                {errorsUser && errorsUser["userName"] ?
                  <div className="text-red-600 invalid-feedback">
                    {errorsUser["userName"]}
                  </div>
                  : null}
              </div>
            </div>

            <div >
              <div className="relative mt-1 shadow-sm">
                <label class="block text-gray-700 text-base font-bold mb-2" for="username">Mobile no. :</label>
                <input className={`w-full px-3 py-3 text-base transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${errorsUser && !errorsUser["mobNo"] ? "border  placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                  id="mobNo" name="mobNo" placeholder="Mobile no." value={fieldsUser && fieldsUser["mobNo"] ? fieldsUser["mobNo"] : ""} type="number" onChange={inputChange} />
                {errorsUser && errorsUser["mobNo"] ?
                  <div className="text-red-600 invalid-feedback">
                    {errorsUser["mobNo"]}
                  </div>
                  : null}
              </div>
            </div>

            <div >
              <div className="relative mt-1 shadow-sm">
                <label class="block text-gray-700 text-base font-bold mb-2" for="username">Email :</label>
                <input className={`w-full px-3 py-3 text-base transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${errorsUser && !errorsUser["email"] ? "border  placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                  id="email" name="email" placeholder="Email" value={fieldsUser && fieldsUser["email"] ? fieldsUser["email"] : ""} type="text" onChange={inputChange} />
                {errorsUser && errorsUser["email"] ?
                  <div className="text-red-600 invalid-feedback">
                    {errorsUser["email"]}
                  </div>
                  : null}
              </div>
            </div>

            <div >
              <div className="relative mt-1 shadow-sm">
                <label class="block text-gray-700 text-base font-bold mb-2" for="username">Password :</label>
                <input className={`w-full px-3 py-3 text-base transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${errorsUser && !errorsUser["password"] ? "border  placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                  id="password" name="password" placeholder="Password" value={fieldsUser && fieldsUser["password"] ? fieldsUser["password"] : ""} type={v ? "text" : "password"} onChange={inputChange} />
                {errorsUser && errorsUser["password"] ?
                  <div className="text-red-600 invalid-feedback">
                    {errorsUser["password"]}
                  </div>
                  : null}
                {v ? <FaRegEye onClick={() => setv(prev => !prev)} className=" absolute cursor-pointer right-2 bottom-4" /> : <FaRegEyeSlash onClick={() => setv(prev => !prev)} className=" absolute right-2 cursor-pointer bottom-4" />}
              </div>
            </div>

            <div >
              <div className="relative mt-1 shadow-sm">
                <label class="block text-gray-700 text-base font-bold mb-2" for="username">Confirm Password :</label>
                <input className={`w-full px-3 py-3 text-base transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${errorsUser && !errorsUser["confirmPassword"] ? "border  placeholder-gray-500" : "border border-opacity-100 border-red-500 "}`}
                  id="confirmPassword" name="confirmPassword" placeholder="Confirm Password" value={fieldsUser && fieldsUser["confirmPassword"] ? fieldsUser["confirmPassword"] : ""} type={v1 ? "text" : "password"} onChange={inputChange} />
                {errorsUser && errorsUser["confirmPassword"] ?
                  <div className="text-red-600 invalid-feedback">
                    {errorsUser["confirmPassword"]}
                  </div>
                  : null}
                {v1 ? <FaRegEye onClick={() => setv1(prev => !prev)} className=" absolute cursor-pointer right-2 bottom-4" /> : <FaRegEyeSlash onClick={() => setv1(prev => !prev)} className=" absolute right-2 cursor-pointer bottom-4" />}
              </div>
            </div>
            <div className=" relative">


              <img src={fileData ? fileData : '/Img/profile.png'} className=" w-12 h-12 mt-2" alt="" />
              {loading && <div role="status" className=" absolute top-2">
                <svg
                  aria-hidden="true"
                  className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span className="sr-only">Loading...</span>
              </div>}

            </div>


            <div className="flex flex-wrap w-full mt-2">
              <label htmlFor="image">Upload Image</label>
              <input
                ref={addRef}
                id="image"
                name="image"
                type="file"
                onChange={handleFile}

              />
            </div>


            <div className="w-64 mx-auto mt-4 sm:w-72">
              <button className="flex justify-center w-full px-4 py-2 mx-auto text-lg font-semibold text-white uppercase transition duration-150 ease-in-out bgcolor border border-[#7D1970] rounded-full xl hover:bg-blue hover:text-white focus:outline-none focus:border-[#7D1970] focus:shadow-outline-yellow active:bgcolor" type="button" onClick={createUserSubmit}>Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>


    // </Modal>



  );
}
