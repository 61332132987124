/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { IoChevronBack } from "react-icons/io5";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import DashboardJSON from '../../MarketManagement.json'
import Loader from '../../../../components/Loader/Loader';
import { marketActions, userActions } from '../../../../_actions';

const EditMarket = () => {
  const dispatch = useDispatch();
  const { marketId } = useParams();
  const navigate = useNavigate();
  let selector = useSelector(state => state);
  const { marketDataIdWise } = useSelector(state => state.markets);
  const [fieldsUpdateMarket, setFieldsUpdateMarket] = useState({});
  const [errorsUpdateMarket, setErrorsUpdateMarket] = useState({});

  useEffect(() => {
    let gameReq = {
      "marketId": marketId
    }
    dispatch(marketActions.getMarketByMarketIdForAdmin(gameReq));
  }, []);


  useEffect(() => {
    dispatch(userActions.clearImage())
    if (marketDataIdWise) {
      setFieldsUpdateMarket(marketDataIdWise)
    }
  }, [marketDataIdWise])



  const inputChangeCreate = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsUpdateMarket(prevState => ({ ...prevState, [name]: value }));
    setErrorsUpdateMarket(prevState => ({ ...prevState, [name]: "" }));
  };

  const updateMarketSubmit = (e) => {
    e.preventDefault();
    let { users } = selector ? selector : {}
    let { fileData } = users ? users : {} 
    if (handleValidationUpdateMarket()) { 
      const { name, point, singlePoint, shortName, _id, min, max } = fieldsUpdateMarket;
      dispatch(marketActions.updateMarket({ name: name && name.trim(), image: fileData || fieldsUpdateMarket.image || "https://res.cloudinary.com/dnd2isyjo/image/upload/v1712923995/your_folder_name/cmbpkevpflbvf9l0c6v0.png", shortName: shortName && shortName.trim(), point: point, singlePoint: singlePoint, id: _id, min, max })); 
    }
  };


  const handleValidationUpdateMarket = () => {
    let formIsValid = true;
    let errors = {};

    if (!fieldsUpdateMarket.name || fieldsUpdateMarket.name.trim() === "") {
      formIsValid = false;
      errors.name = DashboardJSON.ErrorMsg.name;
    }

    if (!fieldsUpdateMarket.shortName || fieldsUpdateMarket.shortName.trim() === "") {
      formIsValid = false;
      errors.shortName = DashboardJSON.ErrorMsg.shortName;
    }

    if (!fieldsUpdateMarket.point || fieldsUpdateMarket.point === "") {
      formIsValid = false;
      errors.point = DashboardJSON.ErrorMsg.point;
    }

    if (!fieldsUpdateMarket.singlePoint || fieldsUpdateMarket.singlePoint === "") {
      formIsValid = false;
      errors.singlePoint = DashboardJSON.ErrorMsg.singlePoint;
    }

    setErrorsUpdateMarket(errors);
    return formIsValid;
  };


  let { users } = selector ? selector : {}
  let { loading } = users ? users : {}


  return (
    <>
      <Loader loading={loading} />
      <div className="z-0 overflow-hidden focus:outline-none">
        <div className="relative">
          <div className="relative z-20 flex min-h-screen">
            <div className="flex w-full">
              <main className="flex-1">
                <div className="p-6">
                  <IoChevronBack
                    className="h-8 w-8 bg-black/20 hover:bg-red-100 rounded-full cursor-pointer"
                    onClick={() => navigate('/app/market')}
                  />
                </div>
                <div className="p-5 2xl:p-10">
                  <div className="max-w-5xl mx-auto space-y-5">
                    <div className="p-6 shadow-lg">
                      <div className="border-b border-gray-300 pb-3 mb-5">
                        <h2 className="text-xl font-semibold text-gray-900">
                          Edit Market
                        </h2>
                      </div>
                      {/* Form Fields */}
                      <div className="space-y-4">
                        {/* Input Field Component */}
                        {[
                          { label: "Name", name: "name", type: "text" },
                          { label: "Min Amount", name: "min", type: "number" },
                          { label: "Max Amount", name: "max", type: "number" },
                          { label: "Point", name: "point", type: "number" },
                          { label: "Single Point", name: "singlePoint", type: "number" },
                        ].map(({ label, name, type }) => (
                          <div className="flex items-center space-x-3" key={name}>
                            <label
                              className="text-gray-700 font-medium whitespace-nowrap w-1/3"
                              htmlFor={name}
                            >
                              {label}:
                            </label>
                            <div className="w-2/3">
                              <input
                                className="w-full px-3 py-2 text-base bg-white border border-gray-300 rounded-md focus:ring focus:ring-blue-300"
                                id={name}
                                name={name}
                                type={type}
                                value={fieldsUpdateMarket?.[name] || ""}
                                onChange={inputChangeCreate}
                              />
                              {errorsUpdateMarket?.[name] && (
                                <p className="text-red-600 text-sm mt-1">
                                  {errorsUpdateMarket[name]}
                                </p>
                              )}
                            </div>
                          </div>
                        ))}

                        {/* Image Upload */}
                        {/* <div className="flex items-center space-x-3">
                          <label className="text-gray-700 font-medium w-1/3">
                            Image:
                          </label>
                          <div className="flex items-center space-x-3">
                            <img
                              src={
                                fileData ||
                                fieldsUpdateMarket?.image ||
                                "https://via.placeholder.com/90"
                              }
                              alt="Selected file preview"
                              className="h-20 w-20 rounded-md border"
                            />
                            <input
                              type="file"
                              id="image"
                              name="image"
                              onChange={handleFile}
                              className="file-input text-sm"
                            />
                          </div>
                        </div> */}

                        {/* Submit Button */}
                        <div className="flex justify-end w-full">
                          <button
                            className="px-6 py-2 font-medium text-white all-btn w-44"
                            onClick={(e) => updateMarketSubmit(e)}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditMarket;
