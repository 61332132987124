/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gamesActions } from "../../_actions";
import Select from "react-select";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import ReactPaginate from "react-paginate";
import Loader from "../../components/Loader/Loader";
import ViewGameModal from "../gameManagement/components/ViewGameModal/ViewGameModal";
import { confirmAlert } from "react-confirm-alert";
import { AiOutlineRollback } from "react-icons/ai";

const DeclareResultJackPot = () => {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  let [viewModal, setViewModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const size = 10
  const [fieldsGame, setFieldsGame] = useState({});
  const [errorsGame, setErrorsGame] = useState({});
  const [offset, setOffset] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const keyWord = "";
  const fromDate = "";
  const toDate = "";

  const today = new Date().toISOString().split("T")[0]


  useEffect(() => {
    let gameReq = {
      fromDate: fromDate,
      toDate: toDate,
      keyWord: keyWord,
      sortBy: "",
      sortOrder: "",
      pageNo: 1,
      size: 500,
    };
    let listReq = {
      fromDate: fromDate,
      toDate: toDate,
      keyWord: keyWord,
      sortBy: "",
      sortOrder: "",
      pageNo: pageNo,
      size: size,
      JodiGame: true,
    };

    dispatch(gamesActions.marketNumberInSingle());
    dispatch(gamesActions.getResultListAdmin(listReq));
    dispatch(gamesActions.getAllJodiGame(gameReq));
    dispatch(gamesActions.marketNumberThreeDigit());

  }, [pageNo]);

  const inputChangeCreate = (e) => {
    // e.preventDefault();
    const { name, value } = e.target;
    setFieldsGame((prevState) => ({ ...prevState, [name]: value }));
    setErrorsGame((prevState) => ({ ...prevState, [name]: "" }));
  };

  const createGameSubmit = (e) => {
    e.preventDefault();
    if (handleValidationCreateGame()) {
      let gamePageRefresh = {
        fromDate: fromDate,
        toDate: toDate,
        keyWord: keyWord,
        sortBy: "",
        sortOrder: "",
        pageNo: pageNo,
        size: size,
        JodiGame: true,
      };

      let obj = {
        gameId: fieldsGame?.gameId,
        betNumber: fieldsGame?.num,
        isOpen: true,
        JodiGame: true,
        date: fieldsGame?.date,
      };

      dispatch(gamesActions.saveResultAdmin(obj, gamePageRefresh, ShowWinnerList2));
      // setFieldsGame({})
    }
  };



  const handleValidationCreateGame = () => {
    let formIsValid = true;
    let errors = {};

    if (!fieldsGame.date || fieldsGame.date === "") {
      formIsValid = false;
      errors.date = "Date is required";
    }

    if (!fieldsGame.gameId || fieldsGame.gameId === "") {
      formIsValid = false;
      errors.gameId = "Game is Required";
    }

    // if (!fieldsGame.isOpen || fieldsGame.isOpen === "") {
    //   formIsValid = false;
    //   errors.isOpen = "Select Sessions";
    // }

    if (!fieldsGame.num || fieldsGame.num === "") {
      formIsValid = false;
      errors.num = "Empty fields";
    }

    setErrorsGame(errors);
    return formIsValid;
  };

  const handlePageClick = (data) => {
    let offset = Math.ceil(data.selected * size);
    setCurrentPage(data.selected);
    setOffset(offset);
    setPageNo(data.selected + 1);
    let pageReq = {
      fromDate: fromDate,
      toDate: toDate,
      keyWord: keyWord,
      sortBy: "",
      sortOrder: "",
      pageNo: data.selected + 1,
      size: size,
      JodiGame: true,
    };
    dispatch(gamesActions.getAllGames(pageReq));
  };

  const ShowWinnerList = () => {
    let obj = {
      gameId: fieldsGame?.gameId,
      betNumber: fieldsGame?.num?.toString() === "000" ? "0" : fieldsGame?.num,
      isOpen: true,
      date: fieldsGame?.date,
    };
    if (handleValidationCreateGame()) {
      dispatch(gamesActions.getWinnerListByBetNumber(obj));
      setViewModal(true);
    }
  };
  const ShowWinnerList2 = () => {
    let obj = {
      isJackPot: true,
      isStarLine: false,
      gameId: fieldsGame?.gameId,
      betNumber: fieldsGame?.num,
      isOpen: true,
      date: fieldsGame?.date,
    };
    if (handleValidationCreateGame()) {
      dispatch(gamesActions.resultDeclare(obj));
      // setViewModal(true)
    }
  };

  let handleViewHideModal = () => {
    setViewModal(false);
  };

  const rollback = (e) => {
    let obj = {
      gameId: e?.gameId?._id,
      closeNumber: null,
      jodiNumber: e?.jodiNumber,
      openNumber: e?.openNumber === 0 ? "000" : e?.openNumber,
      date: e && e.rDate && new Date(e.rDate).toISOString().split("T")[0],
    };

    let listReq = {
      fromDate: fromDate,
      toDate: toDate,
      keyWord: keyWord,
      sortBy: "",
      sortOrder: "",
      pageNo: pageNo,
      size: size,
      JodiGame: true,
    };

    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure you want to Rollback",
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            dispatch(gamesActions.rollbackResultDeclare(obj, listReq)),
        },
        {
          label: "No",
        },
      ],
    });
  };

  let { games } = selector ? selector : {};
  let {
    loading,
    gameResultList,
    gameResultTotal,
    winnerList,
    allJodi,

  } = games ? games : {};
  let optionsData =
    allJodi && allJodi.length > 0 && allJodi;

  let numberList = [
    "00", "01", "02", "03", "04", "05", "06", "07", "08", "09",
    "10", "11", "12", "13", "14", "15", "16", "17", "18", "19",
    "20", "21", "22", "23", "24", "25", "26", "27", "28", "29",
    "30", "31", "32", "33", "34", "35", "36", "37", "38", "39",
    "40", "41", "42", "43", "44", "45", "46", "47", "48", "49",
    "50", "51", "52", "53", "54", "55", "56", "57", "58", "59",
    "60", "61", "62", "63", "64", "65", "66", "67", "68", "69",
    "70", "71", "72", "73", "74", "75", "76", "77", "78", "79",
    "80", "81", "82", "83", "84", "85", "86", "87", "88", "89",
    "90", "91", "92", "93", "94", "95", "96", "97", "98", "99"
  ]


  return (
    <>
      <Loader loading={loading} />
      <div className="z-0 overflow-hidden overflow-y-auto focus:outline-none">
        <div className="relative">
          <div className="relative z-20 flex min-h-screen">
            <div className="flex w-full overflow-hidden ">
              <div className="flex flex-col flex-1 overflow-hidden">
                <main className="relative flex-1 ">
                  <div className="p-3 2xl:p-10 sm:p-5">
                    <div className="mx-auto max-w-screen-3xl space-y-5">
                      <div className="text-left bg-white space-y-2 rounded-lg">
                        <div className="px-6 py-4 flex items-center justify-between border-b">
                          <span className=" text-lg font-bold">
                            Save Result (JackPot Game)
                          </span>
                        </div>
                        <div className="px-6 py-2 w-full">
                          <div className="flex flex-col lg:flex-row justify-start place-items-end gap-4 ">
                            <div className="w-full mt-1 ">
                              <label
                                class="block text-gray-700 text-base mb-2 font-bold"
                                for="gamename"
                              >
                                Result Date
                              </label>
                              <input
                                className={`w-full px-3 py-1.5 text-base bg-white border border-gray-400 rounded-md focus:outline-none`}
                                id="date"
                                name="date"
                                placeholder="Enter Date"
                                value={
                                  fieldsGame && fieldsGame["date"]
                                    ? fieldsGame["date"]
                                    : ""
                                }
                                max={today}
                                type="date"
                                onChange={inputChangeCreate}
                              />
                              {errorsGame && errorsGame["date"] ? (
                                <div className="text-red-600 invalid-feedback">
                                  {errorsGame["date"]}
                                </div>
                              ) : null}
                            </div>

                            <div className="w-full mt-1 ">
                              <label
                                class="block text-gray-700 text-base mb-2 whitespace-nowrap font-bold"
                                for="name"
                              >
                                Game Name
                              </label>
                              <select
                                className={`w-full capitalize px-3 py-2 text-base bg-white border border-gray-400 rounded-md focus:outline-none`}
                                id="gameId"
                                name="gameId"
                                placeholder="name"
                                value={
                                  fieldsGame && fieldsGame["gameId"]
                                    ? fieldsGame["gameId"]
                                    : ""
                                }
                                type="text"
                                onChange={inputChangeCreate}
                              >
                                <option value="">Select Game</option>
                                {optionsData && optionsData.length > 0
                                  ? optionsData.map((element, index) => (
                                    <option
                                      value={
                                        element && element._id
                                          ? element._id
                                          : ""
                                      }
                                    >
                                      {element && element.name
                                        ? element.name
                                        : ""}
                                    </option>
                                  ))
                                  : null}
                              </select>
                              {errorsGame && errorsGame["gameId"] ? (
                                <div className="text-red-600 invalid-feedback">
                                  {errorsGame["gameId"]}
                                </div>
                              ) : null}
                            </div>

                            {/* <div className=" mt-1 ">
                              <label class="block text-gray-700 text-base font-bold mb-2">Session</label>
                              <select
                                onChange={inputChangeCreate}
                                name="isOpen"
                                value={fieldsGame && fieldsGame["isOpen"] ? fieldsGame["isOpen"] : ""}
                                className='px-3 py-2 text-base bg-transparent border border-gray-900 rounded md:w-60 w-full focus:outline-none text-[#495057] placeholder:text-gray-500 block'>
                                <option value="">Select Session</option>
                                <option value={false}>Close</option>
                                <option value={true}>Open</option>
                              </select>
                              {errorsGame && errorsGame["isOpen"] ?
                                <div className="text-red-600 invalid-feedback">
                                  {errorsGame["isOpen"]}
                                </div>
                                : null}
                            </div> */}

                            {/* <div className=" mt-1 w-full ">
                              <label class="block text-gray-700 text-base font-bold mb-2">Number</label>
                              <select
                                onChange={inputChangeCreate}
                                name="num"
                                value={fieldsGame && fieldsGame["num"] ? fieldsGame["num"] : ""}
                                className='px-3 py-2 text-base bg-transparent border border-gray-900 rounded md:w-60 w-full focus:outline-none text-[#495057] placeholder:text-gray-500 block'>
                                <option value="">Select Number</option>
                                {numberArray && numberArray.length > 0 && numberArray.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                              {errorsGame && errorsGame["num"] ?
                                <div className="text-red-600 invalid-feedback">
                                  {errorsGame["num"]}
                                </div>
                                : null}
                            </div> */}
                            <div className="mt-1 w-full  ">
                              <label className="block text-gray-700 text-base font-bold mb-2">
                                Number
                              </label>
                              <Select
                                onChange={(selectedOption) =>
                                  inputChangeCreate({
                                    target: {
                                      name: "num",
                                      value: selectedOption.value,
                                    },
                                  })
                                }
                                name="num"
                                value={
                                  fieldsGame && fieldsGame["num"]
                                    ? {
                                      value: fieldsGame["num"],
                                      label: fieldsGame["num"],
                                    }
                                    : ""
                                }
                                options={
                                  numberList &&
                                  numberList.length > 0 &&
                                  numberList.map((option) => ({
                                    value: option?.toString(),
                                    label: option?.toString(),
                                  }))
                                }
                                classNamePrefix="react-select"
                                placeholder="Select Number"
                                className="w-full"
                              />
                              {errorsGame && errorsGame["num"] ? (
                                <div className="text-red-600 invalid-feedback">
                                  {errorsGame["num"]}
                                </div>
                              ) : null}
                            </div>
                            <div className="w-full lg:w-auto">
                              <button
                                className=" justify-center font-semibold  w-full lg:w-auto px-4  py-2 text-base text-white transition duration-150 ease-in-out bgcolor border border-[#7D1970] rounded hover:bg-blue hover:text-white focus:outline-none focus:border-[#7D1970] focus:shadow-outline-yellow active:bgcolor"
                                type="button"
                                onClick={(e) => createGameSubmit(e)}
                              >
                                Save
                              </button>
                            </div>
                            <div className="w-full ">
                              <button
                                className=" justify-center font-semibold px-4 w-full lg:w-auto  py-2 text-base text-white transition duration-150 ease-in-out bg-yellow-600 border border-yellow-600 rounded hover:bg-blue hover:text-white focus:outline-none focus:border-yellow-600 focus:shadow-outline-yellow active:bg-yellow-600"
                                type="button"
                                onClick={(e) => ShowWinnerList(e)}
                              >
                                Show Winner List
                              </button>
                            </div>




                            {/* <button
                                className="flex justify-center md:w-44  w-full    px-4 py-2 text-base text-white transition duration-150 ease-in-out bg-pink-600 border border-pink-600 rounded-full xl hover:bg-blue hover:text-white focus:outline-none focus:border-pink-600 focus:shadow-outline-pink active:bg-pink-600"
                                type="button"
                                onClick={(e) => ShowWinnerList2(e)}
                              >
                                Declare Result
                              </button> */}

                          </div>


                        </div>
                      </div>
                      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                        <table className="w-full text-base text-left rtl:text-right text-gray-500 ">
                          <thead className="text-gray-800 capitalize bg-gray-50  ">
                            <tr>
                              <th scope="col" className="px-2 lg:px-4 text-[10px] lg:text-[15px] py-3 text-left">
                                #
                              </th>
                              <th scope="col" className="px-2 lg:px-4 text-[10px] lg:text-[15px] py-3 text-left">
                                Result Date
                              </th>
                              <th scope="col" className="px-2 lg:px-4 text-[10px] lg:text-[15px] py-3 text-left">
                                Game Name
                              </th>
                              <th scope="col" className="px-2 lg:px-4 text-[10px] lg:text-[15px] py-3 text-left">
                                Opening Ank
                              </th>

                              <th scope="col" className="px-2 lg:px-4 text-[10px] lg:text-[15px] py-3 text-left">
                                Jodi Number
                              </th>
                              <th scope="col" className="px-2 lg:px-4 text-[10px] lg:text-[15px] py-3 text-left">
                                Close Ank
                              </th>
                              <th scope="col" className="px-2 lg:px-4 text-[10px] lg:text-[15px] py-3 text-left">
                                RollBack
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {gameResultList && gameResultList.length > 0
                              ? gameResultList.map((element, index) => (
                                <React.Fragment key={index}>
                                  <tr
                                    key={index}
                                    className={
                                      index % 2 === 0
                                        ? "bg-white border-b  "
                                        : "bg-gray-100  "
                                    }
                                  >
                                    <td
                                      className="px-2 lg:px-4 py-3 text-[10px] lg:text-[15px]  text-gray-700 whitespace-nowrap  text-left "
                                    >
                                      {offset + index + 1}
                                    </td>
                                    <td
                                      className="px-2 lg:px-4 py-3 text-[10px] lg:text-[15px]  text-gray-700 whitespace-nowrap  text-left "
                                    >
                                      {element && element.rDate
                                        ? new Date(
                                          element.rDate
                                        ).toLocaleDateString()
                                        : "-"}
                                    </td>
                                    <td
                                      className="px-2 lg:px-4 py-3 capitalize text-[10px] lg:text-[15px]  text-gray-700 whitespace-nowrap  text-left "
                                    >
                                      {element && element.gameId
                                        ? element.gameId?.name
                                        : "-"}
                                    </td>
                                    <td
                                      className="px-2 lg:px-4 py-3 text-[10px] lg:text-[15px]  text-gray-700 whitespace-nowrap  text-left "
                                    >
                                      {element &&
                                        element.openNumber?.toString()
                                        ? element.openNumber?.toString()
                                        : "0"}
                                    </td>
                                    <td
                                      className="px-2 lg:px-4 py-3 text-[10px] lg:text-[15px]  text-gray-700 whitespace-nowrap  text-left "
                                    >
                                      {element &&
                                        element.jodiNumber?.toString()
                                        ? element.jodiNumber?.toString()
                                        : "0"}
                                    </td>
                                    <td
                                      className="px-2 lg:px-4 py-3 text-[10px] lg:text-[15px]  text-gray-700 whitespace-nowrap  text-left "
                                    >
                                      {element &&
                                        element.closeAnk?.toString()
                                        ? element.closeAnk?.toString()
                                        : "-"}
                                    </td>

                                    <td
                                      className="px-2 lg:px-4 py-3 text-[10px] lg:text-[15px]  text-gray-700 whitespace-nowrap  text-left "
                                    >
                                      {element.jodiNumber &&
                                        element.jodiNumber &&
                                        element.jodiNumber.length === 2 ? (
                                        <span
                                          className=" text-red-500 cursor-pointer"
                                          onClick={() => rollback(element)}
                                        >
                                          <span className="block lg:hidden"><AiOutlineRollback className="text-lg" />
                                          </span>  <span className="hidden lg:block">RoleBack</span>
                                        </span>
                                      ) : (
                                        "-"
                                      )}
                                    </td>


                                  </tr>
                                </React.Fragment>
                              ))
                              : null}
                          </tbody>
                        </table>
                      </div>
                      {gameResultList && gameResultTotal > 10 ? (
                        <ReactPaginate
                          previousLabel={"Prev"}
                          nextLabel={"Next"}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={Math.ceil(gameResultTotal / size)}
                          marginPagesDisplayed={0}
                          pageRangeDisplayed={10}
                          onPageChange={handlePageClick}
                          containerClassName={"pagination"}
                          pageClassName={"page-cls"}
                          activeClassName={"active"}
                          forcePage={currentPage}
                        />
                      ) : null}
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
          <ViewGameModal
            viewModal={viewModal}
            handleViewHideModal={handleViewHideModal}
            winnerList={winnerList}
            ShowWinnerList2={ShowWinnerList2}
          />
        </div>
      </div>
    </>
  );
};

export default DeclareResultJackPot;
