/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { gamesActions, walletActions } from '../../_actions'
import { TfiEye } from 'react-icons/tfi'
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import ReactPaginate from 'react-paginate'
import { isMobile } from 'react-device-detect'
import ViewGameModal from './components/ViewGameModal/ViewGameModal'
import Loader from '../../components/Loader/Loader'

const size = 10

const AddFundReport = () => {
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(0)
  let [rowData, setRowData] = useState(null)
  let [viewModal, setViewModal] = useState(false)
  const [errorsGame, setErrorsGame] = useState({})
  const [offset, setOffset] = useState(0)
  const [pageNo, setPageNo] = useState(1)
  const [fromDate, setfromDate] = useState('')
  const [toDate, settoDate] = useState('')
  const [keyWord, setkeyWord] = useState('')

  const { paymentList, totalPayment, loading } = useSelector(
    state => state.wallet
  )

  const selector = useSelector(state => state)
  const { games } = selector ? selector : {}
  let { allUserList } = games ? games : {}

  useEffect(() => {
    let gameReq = {
      fromDate: fromDate,
      toDate: toDate,
      keyWord: keyWord,
      sortBy: '',
      sortOrder: '',
      type: 'DEPOSIT',
      pageNo: pageNo,
      size: size
    }
    dispatch(walletActions.getApproveRejectPaymentListForAdmin(gameReq))
    dispatch(gamesActions.getAllUser())
  }, [])

  const handleViewOpenModal = data => {
    setViewModal(true)
    setRowData(data)
  }
  const handleViewHideModal = data => {
    setViewModal(false)
    setRowData({})
  }

  const createGameSubmit2 = () => {
    let gameReq = {
      fromDate: fromDate,
      toDate: toDate,
      keyWord: keyWord,
      sortBy: '',
      sortOrder: '',
      type: 'DEPOSIT',
      pageNo: pageNo,
      size: size
    }
    if (
      (fromDate !== '' && toDate !== '') ||
      (fromDate === '' && toDate === '')
    ) {
      dispatch(walletActions.getApproveRejectPaymentListForAdmin(gameReq))
    }
  } 

  const handlePageClick = data => {
    let offset = Math.ceil(data.selected * size)
    setCurrentPage(data.selected)
    setOffset(offset)
    setPageNo(data.selected + 1)
    let pageReq = {
      fromDate: '',
      toDate: '',
      keyWord: keyWord,
      sortBy: '',
      sortOrder: '',
      type: 'DEPOSIT',
      pageNo: data.selected + 1,
      size: size
    }
    dispatch(walletActions.getApproveRejectPaymentListForAdmin(pageReq))
  }

  return (
    <>
      <Loader loading={loading} />
      <div className='z-0 overflow-hidden overflow-y-auto focus:outline-none'>
        <div className='relative'>
          <div className='relative z-20 flex min-h-screen'>
            <div className='flex w-full overflow-hidden '>
              <div className='flex flex-col flex-1 overflow-hidden'>
                <main className='relative flex-1 '>
                  <div className='p-3 2xl:p-10 sm:p-5'>
                    <div className='mx-auto max-w-screen-3xl space-y-5'>
                      <sectiion className='flex justify-between my-4 '>
                        <div className='text-xl font-semibold'>
                          Dashboard /{' '}
                          <span className='text-[#7D1970]'>
                            Add Fund Report
                          </span>
                        </div>
                      </sectiion>

                      <div className='px-6 py-4 text-left bg-white space-y-2 rounded-lg'>
                        <div className='flex justify-between items-center px-3 border-b'>
                          <span className='p-5 text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white  '>
                            Add Fund Report
                          </span>
                        </div>

                        <div className='flex  justify-end w-full space-x-5'>
                          <div className='flex flex-wrap md:flex-nowrap justify-between items-end gap-3'>
                            <div className='mt-1 shadow-sm w-full'>
                              <label
                                class='block text-gray-700 text-base font-bold mb-2'
                                for='name'
                              >
                                Select User :
                              </label>
                              <select
                                className='w-full px-3 py-2 text-base bg-white border border-gray-400 rounded-lg focus:outline-none'
                                id='userId'
                                name='userId'
                                placeholder='name'
                                value={keyWord && keyWord ? keyWord : ''}
                                type='text'
                                onChange={e => setkeyWord(e.target.value)}
                              >
                                <option value=''>Select User</option>

                                {allUserList && allUserList.length > 0
                                  ? allUserList.map((element, index) => (
                                      <option
                                        value={
                                          element && element._id
                                            ? element._id
                                            : ''
                                        }
                                      >
                                        {element && element.userName
                                          ? element.userName
                                          : ''}
                                      </option>
                                    ))
                                  : null}
                              </select>
                              {errorsGame && errorsGame['userId'] ? (
                                <div className='text-yellow-600 invalid-feedback'>
                                  {errorsGame['userId']}
                                </div>
                              ) : null}
                            </div>

                            <div className='w-full mt-1 shadow-sm'>
                              <label
                                class='block text-gray-700 text-base font-bold mb-2'
                              >
                                From:
                              </label>
                              <input
                                className='w-full px-3 py-2 text-base bg-white border border-gray-400 rounded-lg focus:outline-none'
                                id='date'
                                name='date'
                                placeholder='Enter date'
                                value={fromDate}
                                type='date'
                                onChange={e => setfromDate(e.target.value)}
                              />
                              {errorsGame && errorsGame['date'] ? (
                                <div className='text-yellow-600 invalid-feedback'>
                                  {errorsGame['date']}
                                </div>
                              ) : null}
                            </div>
                            <div className='w-full mt-1 shadow-sm'>
                              <label
                                class='block text-gray-700 text-base font-bold mb-2'
                              >
                                To:
                              </label>
                              <input
                                className='w-full px-3 py-2 text-base bg-white border border-gray-400 rounded-lg focus:outline-none'
                                id='date'
                                name='date'
                                placeholder='Enter date'
                                value={toDate}
                                type='date'
                                onChange={e => settoDate(e.target.value)}
                              />
                              {errorsGame && errorsGame['date'] ? (
                                <div className='text-yellow-600 invalid-feedback'>
                                  {errorsGame['date']}
                                </div>
                              ) : null}
                            </div>
                            <div className='w-full flex justify-start items-end'>
                              <button
                                className='flex justify-center px-4 py-2 text-base font-semibold text-white capitalize  transition duration-150 ease-in-out bg-green-500 border border-green-500 rounded-xl focus:outline-none'
                                type='button'
                                onClick={createGameSubmit2}
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className='relative overflow-x-auto shadow-md sm:rounded-lg'>
                          <table className='w-full text-base text-left rtl:text-right text-gray-500 '>
                            <thead className='text-gray-900 capitalize bg-gray-50  '>
                              <tr>
                                <th scope='col' className='px-6 py-3'>
                                  S.No.
                                </th>
                                <th scope='col' className='px-6 py-3'>
                                  user name
                                </th>
                                <th scope='col' className='px-6 py-3'>
                                  amount
                                </th>
                                <th scope='col' className='px-6 py-3'>
                                  Reciept Image
                                </th>
                                <th scope='col' className='px-6 py-3'>
                                  Date
                                </th>
                                <th scope='col' className='px-6 py-3'>
                                  Status
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {paymentList && paymentList.length > 0
                                ? paymentList.map((element, index) => {
                                    if (element.status !== 0) {
                                      return (
                                        <React.Fragment key={index}>
                                          <tr
                                            key={index}
                                            className={
                                              element.status !== 0 &&
                                              `bg-white border-b border-gray-400`
                                            }
                                          >
                                            <th
                                              scope='row'
                                              className='px-6 py-4 font-medium text-gray-500 whitespace-nowrap '
                                            >
                                              {offset + index + 1}
                                            </th>
                                            <th
                                              scope='row'
                                              className='px-6 py-4 font-medium text-gray-500 whitespace-nowrap '
                                            >
                                              {element && element.userId
                                                ? element?.userId?.userName
                                                : '-'}
                                            </th>
                                            <th
                                              scope='row'
                                              className='px-6 py-4 font-medium text-gray-500 whitespace-nowrap '
                                            >
                                              {element && element.amount
                                                ? element.amount
                                                : '-'}
                                            </th>
                                            <td className='px-6 flex justify-start py-4'>
                                              <TfiEye
                                                onClick={() =>
                                                  handleViewOpenModal(element)
                                                }
                                                className=' text-3xl text-[#7D1970] cursor-pointer'
                                              /> 
                                            </td>
                                            <th
                                              scope='row'
                                              className='px-6 py-4 font-medium text-gray-500 whitespace-nowrap '
                                            >
                                              {element && element.createdAt
                                                ? new Date(
                                                    element.createdAt
                                                  ).toLocaleString()
                                                : '-'}
                                            </th>
                                            <th
                                              scope='row'
                                              className='px-6 py-4 font-medium text-gray-500 whitespace-nowrap '
                                            >
                                              {element && element.status === 1 && (
                                                <span className='relative '>
                                                  <button className='px-4 py-2 font-medium tracking-wider text-white bg-green-900 border rounded-md shadow-sm'>
                                                    Approved
                                                  </button>
                                                </span>
                                              )}
                                              {element && element.status === 2 && (
                                                <span className='relative '>
                                                  <button className='px-4 py-2 font-medium tracking-wider text-white bg-red-900 border rounded-md shadow-sm'>
                                                    Rejected
                                                  </button>
                                                </span>
                                              )} 
                                            </th> 
                                          </tr>
                                        </React.Fragment>
                                      )
                                    }
                                  })
                                : null}
                            </tbody>
                          </table>
                          {isMobile ? (
                            <nav className='relative z-0 flex justify-end mt-5 w-76'>
                              {totalPayment && totalPayment > 10 ? (
                                <ReactPaginate
                                  previousLabel={'Prev'}
                                  nextLabel={'Next'}
                                  breakLabel={'...'}
                                  breakClassName={'break-me'}
                                  pageCount={totalPayment / size}
                                  marginPagesDisplayed={1}
                                  pageRangeDisplayed={1}
                                  onPageChange={handlePageClick}
                                  containerClassName={'pagination'}
                                  pageClassName={'page-cls'}
                                  activeClassName={'active'}
                                  forcePage={currentPage}
                                />
                              ) : null}
                            </nav>
                          ) : (
                            <nav className='relative z-0 flex justify-end mt-5 w-76'>
                              {totalPayment && totalPayment > 10 ? (
                                <ReactPaginate
                                  previousLabel={'Previous'}
                                  nextLabel={'Next'}
                                  breakLabel={'...'}
                                  breakClassName={'break-me'}
                                  pageCount={totalPayment / size}
                                  marginPagesDisplayed={3}
                                  pageRangeDisplayed={3}
                                  onPageChange={handlePageClick}
                                  containerClassName={'pagination'}
                                  pageClassName={'page-cls'}
                                  activeClassName={'active'}
                                  forcePage={currentPage}
                                />
                              ) : null}
                            </nav>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <ViewGameModal
                    rowData={rowData}
                    viewModal={viewModal}
                    handleViewHideModal={handleViewHideModal}
                  />
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddFundReport
