import { marketConstants } from '../_constants';
import { alertActions } from './alert.actions';
import {
    APIcallFunction,
    headerForPrivateAPI, APIcallFunctionForImage, headerForPrivateMediaAPI
} from '../_helpers';
export const marketActions = {
    createMarket,
    getMarketList,
    getAllMarket,
    updateMarket,
    updateMarketStatus,
    getMarketByMarketIdForAdmin,
    deleteMarket,
    uploadImageApi,
    uploadImageClear,
};

function createMarket(data, setCreateModal, marketPageRefresh) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/createMarket'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                market => {
                    dispatch(success(market));
                    dispatch(getMarketList(marketPageRefresh));
                    setCreateModal(false);
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(market) { return { type: marketConstants.CREATE_MARKET_REQUEST, market } }
    function success(market) { return { type: marketConstants.CREATE_MARKET_SUCCESS, market } }
    function failure(error) { return { type: marketConstants.CREATE_MARKET_FAILURE, error } }
}
function getMarketList(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getMarketList'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                market => {
                    dispatch(success(market));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(market) { return { type: marketConstants.GET_MARKET_LIST_REQUEST, market } }
    function success(market) { return { type: marketConstants.GET_MARKET_LIST_SUCCESS, market } }
    function failure(error) { return { type: marketConstants.GET_MARKET_LIST_FAILURE, error } }
}

function updateMarket(data, navigate
    // , marketPageRefresh
) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/updateMarket'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                market => {
                    dispatch(success(market));
                    dispatch(alertActions.success("Updated"));
             
     
               
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(market) { return { type: marketConstants.UPDATE_MARKET_REQUEST, market } }
    function success(market) { return { type: marketConstants.UPDATE_MARKET_SUCCESS, market } }
    function failure(error) { return { type: marketConstants.UPDATE_MARKET_FAILURE, error } }
}

function updateMarketStatus(data, userPageRefresh) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/updateMarketStatus'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                market => {
                    dispatch(success(market));
                    dispatch(getMarketList(userPageRefresh));
                    dispatch(alertActions.success("Market Status Update Successfully"));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(market) { return { type: marketConstants.UPDATE_MARKET_STATUS_REQUEST, market } }
    function success(market) { return { type: marketConstants.UPDATE_MARKET_STATUS_SUCCESS, market } }
    function failure(error) { return { type: marketConstants.UPDATE_MARKET_STATUS_FAILURE, error } }
}

function getMarketByMarketIdForAdmin(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getMarketByMarketIdForAdmin'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                market => { 
                    dispatch(success(market));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(market) { return { type: marketConstants.GET_MARKET_BY_MARKETID_FOR_ADMIN_REQUEST, market } }
    function success(market) { return { type: marketConstants.GET_MARKET_BY_MARKETID_FOR_ADMIN_SUCCESS, market } }
    function failure(error) { return { type: marketConstants.GET_MARKET_BY_MARKETID_FOR_ADMIN_FAILURE, error } }
}

function deleteMarket(data, userPageRefresh) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/deleteMarket'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                market => {
                    dispatch(success(market));
                    dispatch(getMarketList(userPageRefresh));
                    dispatch(alertActions.success("Market delete Successfully"));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(market) { return { type: marketConstants.DELETE_MARKET_REQUEST, market } }
    function success(market) { return { type: marketConstants.DELETE_MARKET_SUCCESS, market } }
    function failure(error) { return { type: marketConstants.DELETE_MARKET_FAILURE, error } }
}

function getAllMarket(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getAllMarket'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                market => {
                    dispatch(success(market));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(market) { return { type: marketConstants.GET_ALL_MARKET_REQUEST, market } }
    function success(market) { return { type: marketConstants.GET_ALL_MARKET_SUCCESS, market } }
    function failure(error) { return { type: marketConstants.GET_ALL_MARKET_FAILURE, error } }
}

function uploadImageApi(filedata) {
    var formdata = new FormData();
    formdata.append('image', filedata);
    const credentials = {
        header: headerForPrivateMediaAPI,
        method: "POST",
        body: formdata,
        endPoint: '/upload'
    }
    return dispatch => {
        APIcallFunctionForImage(credentials)
            .then(
                market => {
                    dispatch(success(market));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function success(market) { return { type: marketConstants.FILE_UPLOAD_STATUS_SUCCESS, market } }
    function failure(error) { return { type: marketConstants.FILE_UPLOAD_STATUS_FAILURE, error } }
}

function uploadImageClear() {
    return dispatch => {
        dispatch(success());
    };
    function success(uploadImage) { return { type: marketConstants.FILE_UPLOAD_CLEAR_SUCCESS, uploadImage } }
}