import { sliderConstants } from '../_constants';
import { alertActions } from './alert.actions';
import { APIcallFunction, headerForPrivateAPI } from '../_helpers';
export const sliderActions = {
    createSlider,
    getSliderList,
    getAllSlider,
    updateSlider,
    updateSliderStatus,
    deleteSlider,
};

function createSlider(data, setCreateModal, sliderPageRefresh) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/createSlider'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                slider => {
                    dispatch(success(slider));
                    dispatch(getSliderList(sliderPageRefresh));
                    setCreateModal(false);
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(slider) { return { type: sliderConstants.CREATE_SLIDER_REQUEST, slider } }
    function success(slider) { return { type: sliderConstants.CREATE_SLIDER_SUCCESS, slider } }
    function failure(error) { return { type: sliderConstants.CREATE_SLIDER_FAILURE, error } }
}
function getSliderList(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getSliderList'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                slider => {
                    dispatch(success(slider));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(slider) { return { type: sliderConstants.GET_SLIDER_LIST_REQUEST, slider } }
    function success(slider) { return { type: sliderConstants.GET_SLIDER_LIST_SUCCESS, slider } }
    function failure(error) { return { type: sliderConstants.GET_SLIDER_LIST_FAILURE, error } }
}

function updateSlider(data, setUpdateModal, sliderPageRefresh) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/updateSlider'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                slider => {
                    dispatch(success(slider));
                    dispatch(getSliderList(sliderPageRefresh));
                    setUpdateModal(false);
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(slider) { return { type: sliderConstants.UPDATE_SLIDER_REQUEST, slider } }
    function success(slider) { return { type: sliderConstants.UPDATE_SLIDER_SUCCESS, slider } }
    function failure(error) { return { type: sliderConstants.UPDATE_SLIDER_FAILURE, error } }
}

function updateSliderStatus(data, sliderPageRefresh) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/updateSliderStatus'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                slider => {
                    dispatch(success(slider));
                    dispatch(getSliderList(sliderPageRefresh));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(slider) { return { type: sliderConstants.UPDATE_SLIDER_STATUS_REQUEST, slider } }
    function success(slider) { return { type: sliderConstants.UPDATE_SLIDER_STATUS_SUCCESS, slider } }
    function failure(error) { return { type: sliderConstants.UPDATE_SLIDER_STATUS_FAILURE, error } }
}

function deleteSlider(data, sliderPageRefresh) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/deleteSlider'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                slider => {
                    dispatch(success(slider));
                    dispatch(getSliderList(sliderPageRefresh));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(slider) { return { type: sliderConstants.DELETE_SLIDER_REQUEST, slider } }
    function success(slider) { return { type: sliderConstants.DELETE_SLIDER_SUCCESS, slider } }
    function failure(error) { return { type: sliderConstants.DELETE_SLIDER_FAILURE, error } }
}

function getAllSlider(data) {
    const credentials = {
        header: headerForPrivateAPI,
        method: "POST",
        body: data ?? {},
        endPoint: '/getAllSlider'
    }
    return dispatch => {
        dispatch(request({ data }));
        APIcallFunction(credentials)
            .then(
                slider => {
                    dispatch(success(slider));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };
    function request(slider) { return { type: sliderConstants.GET_ALL_SLIDER_REQUEST, slider } }
    function success(slider) { return { type: sliderConstants.GET_ALL_SLIDER_SUCCESS, slider } }
    function failure(error) { return { type: sliderConstants.GET_ALL_SLIDER_FAILURE, error } }
}


