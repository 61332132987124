/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  karachiGamesActions,
  gamesActions,
  karachiMarketActions
} from '../../_actions'
import { MdOutlineNavigateNext } from 'react-icons/md'
import { useParams } from 'react-router-dom'
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import moment from 'moment'
import { useTime } from 'react-timer-hook'

const KarachiPlaceBet = () => {
  const dispatch = useDispatch()
  const { games, karachiGame, karachiMarket } = useSelector(state => state) || {}
  const { karachiGamesList, karachiPlaceBet, loading } = karachiGame || {}
  const { allKarachiMarket } = karachiMarket || {}

  const { id, name } = useParams()

  let [localStoragedata, setlocalStoragedata] = useState()
  let [isDisable, setIsDisable] = useState(false)
  const [fieldsGame, setFieldsGame] = useState({})
  const [errorsGame, setErrorsGame] = useState({})
  const [userName, setUserName] = useState({})
  const [gameName, setGameName] = useState({})
  const { subAdminData } = useSelector(state => state.games)
  const [isBetPrime, setIsBetPrime] = useState(false)
  const [gameNameRes, setGameNameRes] = useState(null)
  const [stateKey, setStateKey] = useState(null)
  let [options, setoptions] = useState([])
  const [totalAmount, setTotalAmount] = useState(0)

  const [savedAmount, setSavedAmount] = useState('') // Value saved from the API call
  const [amount, setAmount] = useState('')
  const [isAmountBlank, setIsAmountBlank] = useState(false)

  const [savedBetNumber, setSavedBetNumber] = useState('') // Value saved from the API call
  const [betNumber, setBetNumber] = useState('')
  const [isBetNumberBlank, setIsBetNumberBlank] = useState(false)

  const userPalyerName = games?.karachiPlaceBet &&
    games?.karachiPlaceBet?.[0]?.userId &&
    games?.karachiPlaceBet?.[0]?.userId?.userName
    ? games?.karachiPlaceBet?.[0]?.userId?.userName
    : userName && userName?.userName
      ? userName?.userName
      : null

  const handleKeyDown1 = event => {
    if (event.key.toLowerCase() === 'l') {
      setIsBetPrime(prevState => !prevState) // Toggle the status
    }
  }

  const scrollContainerRef = useRef(null)

  useEffect(() => {
    return () => {
      dispatch(karachiGamesActions.getPlaceBetsByUserIdLocalCall([])) // Example cleanup action
    }
  }, [dispatch])

  useEffect(() => {
    if (scrollContainerRef.current) {
      setTimeout(() => {
        scrollContainerRef.current.scrollTop =
          scrollContainerRef.current.scrollHeight
      }, 100)
    }
    const totalAmountRes =
      karachiPlaceBet && karachiPlaceBet.length > 0
        ? karachiPlaceBet.reduce((sum, record) => {
          if (
            ['Single Open', 'Single Close'].includes(record?.localmarketname)
          ) {
            return sum + Number(record.amount) * 10 || 0
          }
          if (
            ['Single Kenchi', 'Double Kenchi'].includes(
              record?.localmarketname
            )
          ) {
            return sum + Number(record.amount) * record?.packet?.length || 0
          }
          return sum + Number(record.amount) || 0
        }, 0)
        : 0
    setTotalAmount(totalAmountRes)
  }, [karachiPlaceBet])

  const [enterPressCount, setEnterPressCount] = useState(0) // Track Enter presses
  const betNumberInputRef = useRef(null)
  const amountInputRef = useRef(null) // Reference for the amount input

  const handleKeyDown = async e => {
    if (['ArrowUp', 'ArrowDown'].includes(e.key)) {
      e.preventDefault()
      return
    }
    if (e.key === 'Enter') {
      const { name } = e.target
      if (!e.target.value && e.target.name === 'betNumber') {
        setErrors(prev => {
          return {
            ...prev,
            betNumber: 'Bet Number is Required'
          }
        })
        return
      }

      if (name === 'betNumber') {
        if (
          currentOption.toShow === 'Open' &&
          String(e.target.value).length === 2
        ) {
          setCurrentIndex(() => 7)
        } else if (
          currentOption.toShow === 'Open' &&
          String(e.target.value).length === 1
        ) {
          setCurrentIndex(() => 0)
        }
      }
      let error = validate(e.target.name, e.target.value.toString())
      if (name === 'betNumber' && error) {
        setErrors(prev => {
          return {
            ...prev,
            betNumber: error
          }
        })
      }
      if (name === 'amount' && error) {
        setErrors(prev => {
          return {
            ...prev,
            amount: error
          }
        })
        return
      }
      if (error) {
        return
      }

      if (
        ['F9'].includes(stateKey) &&
        enterPressCount === 0 &&
        name === 'betNumber'
      ) {
        amountInputRef.current?.focus()
        setEnterPressCount(prevCount => prevCount + 1)
      }

      if (['F9'].includes(stateKey) && enterPressCount === 1) {
        await handleSubmit()
        setEnterPressCount(0)
        betNumberInputRef.current?.focus()
        return
      }

      if (
        !['F9'].includes(stateKey) &&
        enterPressCount === 0 &&
        name === 'betNumber'
      ) {
        amountInputRef.current?.focus()
        setEnterPressCount(prevCount => prevCount + 1)
      }
      if (enterPressCount === 1) {
        const amount = amountInputRef.current.value.trim()
        if (amount === '') {
          return
        }
        setIsAmountBlank(true)
        setSavedAmount(amount)
        setAmount(e.target.value)
        const betNumber = betNumberInputRef.current.value.trim()
        if (betNumber === '') {
          return
        }
        setIsBetNumberBlank(true)
        setSavedBetNumber(betNumber)
        await handleSubmit()
        setEnterPressCount(0)
        betNumberInputRef.current?.focus()
        return
      }
    }
  }
  const handleSubmit = async e => {
    try {
      if (amount < 5 || !betNumber) {
        return
      }

      let packet = []
      let isOpen = false
      let marketId = currentOption
      if (['F9'].includes(stateKey) && betNumber.toString() === '11') {
        const resData = [
          {
            ...formData,
            isOpen: isOpen,
            marketId,
            games: currentOption2,
            localmarketname: 'First Akda',
            isBetPrime: isBetPrime
          }
        ]
        const gameId = fieldsGame && gameName?._id ? gameName?._id : id
        const res = {
          gameId: gameId,
          userId: fieldsGame && fieldsGame['userId'] ? fieldsGame['userId'] : null
        }
        const JodiRes = [
          '00',
          '11',
          '22',
          '33',
          '44',
          '55',
          '66',
          '77',
          '88',
          '99'
        ]
        resData.forEach(games => {
          const obj = {
            userId: fieldsGame?.userId || null,
            amount: amount,
            marketId: games?.marketId?._id,
            gameId: gameId,
            isBetPrime: games?.isBetPrime,
            isOpen: games?.isOpen,
            localmarketname: 'First Akda',
            packet: JodiRes
          }
          dispatch(
            karachiGamesActions.karachiPlaceBetByAdminSequentially(obj, res)
          )
        })
        setStateKey(null)
        setBetNumber('')
        setIsBetPrime(false)
        setEnterPressCount(0)
        setErrors({})
        setCurrentIndex(0)
        setFormData(prevData => ({
          ...prevData,
          betNumber: ''
        }))
      }
      if (
        marketId &&
        currentOption2 &&
        currentOption &&
        betNumber &&
        amount
      ) {
        const marketData = ['Open', 'First Akda'].includes(marketId?.toShow)
          ? betNumber?.length === 1
            ? 'Open'
            : betNumber?.length === 2
              ? 'First Akda'
              : marketId?.toShow
          : marketId?.toShow
        if (
          ['Close', 'Open', 'Center'].includes(marketData) &&
          betNumber?.length > 1
        ) {
          return
        }
        if (
          ['First TND', 'Second TND', 'FS Ring'].includes(marketData) &&
          betNumber?.length > 3
        ) {
          return
        }
        if (['Second Akda'].includes(marketData) && betNumber?.length !== 2) {
          return
        }
        if (
          ['Single Kenchi', 'Double Kenchi'].includes(marketData) &&
          (betNumber?.length < 3 || betNumber?.length > 7)
        ) {
          return
        }
        switch (marketData) {
          case 'Open':
            packet = [betNumber]
            break
          case 'Close':
            packet = [betNumber]
            break
          case 'Center':
            packet = [betNumber]
            break
          case 'First TND':
            packet = [betNumber]
            break
          case 'Second TND':
            packet = [betNumber]
            break
          case 'First Akda':
            packet = [betNumber]
            break
          case 'Second Akda':
            packet = [betNumber]
            break
          case 'FS Ring':
            packet = [betNumber]
            break
          case 'Single Kenchi':
            packet = getUniqueTwoDigitCombinations(betNumber)
            break
          case 'Double Kenchi':
            packet = getTwoDigitCombinations(betNumber)
            break
          default:
            break
        }

        const resData = {
          betNumber: betNumber,
          packet,
          isOpen: isOpen,
          marketId,
          games: currentOption2,
          localmarketname: marketData,
          isBetPrime: isBetPrime
        }
        const gameId = fieldsGame && gameName?._id ? gameName?._id : id
        const res = {
          gameId: gameId,
          userId:
            fieldsGame && fieldsGame['userId'] ? fieldsGame['userId'] : null
        }

        resData['amount'] = amount
        resData['gameId'] = gameId
        resData['userId'] =
          fieldsGame && fieldsGame['userId'] ? fieldsGame['userId'] : null
        const userData = subAdminData.find(
          ele => ele._id === fieldsGame['userId']
        )

        dispatch(
          karachiGamesActions.karachiPlaceBetByAdmin(
            { ...resData, userData },
            res,
            karachiPlaceBet
          )
        )
        setIsBetPrime(false)
        setEnterPressCount(0)
        setBetNumber('')
        setStateKey(null)
        setErrors({})
        setCurrentIndex(0)
        setFormData(prevData => ({
          ...prevData,
          betNumber: ''
        }))
      }
    } catch (err) {
      console.error('Error in handleSubmit:', err)
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown1)
    return () => {
      document.removeEventListener('keydown', handleKeyDown1)
    }
  }, [])

  useEffect(() => {
    if (!isDisable) {
      window.addEventListener('keydown', handleKeyDown2)
      return () => {
        window.removeEventListener('keydown', handleKeyDown2)
      }
    }
  }, [options, isDisable, fieldsGame])

  useEffect(() => {
    if (!isDisable) {
      // Add the event listener
      window.addEventListener('keydown', handleKeyDown3)

      // Cleanup the event listener on component unmount
      return () => {
        window.removeEventListener('keydown', handleKeyDown3)
      }
    }
  }, [isDisable || fieldsGame])

  const [formData, setFormData] = useState({})
  const [errors, setErrors] = useState({})
  const [options2, setoptions2] = useState([])
  let currentOption2 =
    Array.isArray(options2) && options2.length > 0 ? options2[0] : null

  const [currentIndex, setCurrentIndex] = useState(0)
  let currentOption =
    Array.isArray(options) && options.length > 0 ? options[currentIndex] : null

  useEffect(() => {
    if (name) {
      setGameNameRes(name)
    }
    const stateData = window.sessionStorage.getItem('adminuser')
    if (stateData) {
      setFieldsGame((pre) => ({ ...pre, userId: stateData?.userId }))
    }
    setlocalStoragedata(JSON.parse(stateData))
    dispatch(karachiMarketActions.getAllKarachiMarket())
    dispatch(karachiGamesActions.getAllKarachiGames())
    const stateManage = JSON.parse(stateData)
    if (stateManage?.roleId === 0) {
      setIsDisable(true)
      dispatch(gamesActions.getSubAdminList())
    }
  }, [dispatch])

  useEffect(() => {
    if (fieldsGame?.userId && gameName?._id) {
      dispatch(
        karachiGamesActions.getKarachiPlaceBetsByUserId({
          gameId: gameName?._id,
          userId: fieldsGame.userId
        })
      )
    }
  }, [fieldsGame, dispatch])

  // Single Kachi: Unique Two-Digit Combinations
  function getUniqueTwoDigitCombinations(number) {
    const digits = [...new Set(number?.toString().split(''))]
    const combinations = []

    for (let i = 0; i < digits.length; i++) {
      for (let j = 0; j < digits.length; j++) {
        if (i !== j) {
          combinations.push(digits[i] + digits[j])
        }
      }
    }
    return combinations
  }

  function getTwoDigitCombinations(number) {
    const digits = [...new Set(number?.toString().split(''))]
    const combinations = []

    for (let i = 0; i < digits.length; i++) {
      for (let j = 0; j < digits.length; j++) {
        combinations.push(digits[i] + digits[j])
      }
    }
    return combinations
  }

  const handleChange = e => {
    e.preventDefault()
    const { name, value } = e.target

    if (name === 'betNumber') {
      if (
        currentOption.toShow === 'Open' &&
        e.target.value.toString().length === 2
      ) {
        setCurrentIndex(() => 7)
      } else if (
        currentOption.toShow === 'Open' &&
        e.target.value.toString().length === 1
      ) {
        setCurrentIndex(() => 0)
      }
    }

    const error = validate(e.target.name, e.target.value)

    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: error
    }))

    // Common logic for updating form data and errors
    const updateFormDataAndErrors = (key, val, err) => {
      setFormData(prev => ({
        ...prev,
        [key]: val
      }))
    }

    if (name === 'betNumber') {
      if (savedBetNumber === '') {
        setBetNumber(value)
        setIsBetNumberBlank(false)
      } else if (value !== savedBetNumber && isBetNumberBlank) {
        updateFormDataAndErrors(name, value, error)
        setBetNumber(e.target.value)
        setIsBetNumberBlank(false)
      } else {
        setBetNumber(value)
      }

      if (error) {
        return
      } else {
        setEnterPressCount(0)
      }
    }

    if (name === 'amount') {
      if (savedAmount === '') {
        // Initial amount logic
        setAmount(value)
        setIsAmountBlank(false)
      } else if (value !== savedAmount && isAmountBlank) {
        updateFormDataAndErrors(name, value, error)
        const lastCharacter = value[value.length - 1] || ''
        amountInputRef.current.value = lastCharacter
        setAmount(e.target.value)
        setIsAmountBlank(false)
      } else {
        setAmount(value)
      }
      if (error) {
        return
      }
      setEnterPressCount(1) // Set enter press count for `amount`
    }
  }

  const validate = (name, value) => {
    let error = ''

    if (name === 'amount') {
      if (value < 5) {
        error = 'Amount must be greater than 5.'
      }
    } else if (name === 'betNumber') {
      let indexChange = currentIndex

      if (currentOption.toShow === 'Open' && value.toString().length === 2) {
        setCurrentIndex(() => 7)
        indexChange = 7
      } else if (
        currentOption.toShow === 'Open' &&
        value.toString().length === 1
      ) {
        setCurrentIndex(() => 0)
        indexChange = 0
      }
      const marketData =
        Array.isArray(options) && options.length > 0
          ? options[indexChange]
          : null
      error = ErrorResponseFunction(
        marketData?.toShow || null,
        value.toString()
      )
    }

    return error
  }

  function ErrorResponseFunction(marketData, value = '') {
    let error

    if (!marketData) {
      error = 'Market Data is required'
      return error
    }

    if (['Close', 'Center', 'Open'].includes(marketData)) {
      if (value.length !== 1) {
        error = 'Only a single digit is allowed for Close, Center, or Open.'
      }
    } else if (['Single Kenchi', 'Double Kenchi'].includes(marketData)) {
      if (value.length < 3 || value.length > 7) {
        error =
          'Please enter between 3 to 7 unique digits for Single Kenchi or Double Kenchi.'
      } else if (new Set(value).size !== value.length) {
        error =
          'Duplicate digits are not allowed for Single Kenchi or Double Kenchi.'
      }
    } else if (['Second Akda'].includes(marketData)) {
      if (value.length !== 2) {
        error = 'Second Akda requires exactly 2 digits.'
      }
    } else if (['First TND', 'Second TND', 'FS Ring'].includes(marketData)) {
      if (value.length !== 3) {
        error = 'First TND, Second TND, and FS Ring require exactly 3 digits.'
      }
    } else if (marketData === 'First Akda') {
      if (value.length !== 2) {
        error = 'First Akda requires exactly 2 digits.'
      }
    }

    return error
  }
  const handleDelete = data => {
    const res = {
      gameId: data?.karachiGameId?._id ?? null,
      userId: data?.userId._id ?? null
    }
    dispatch(karachiGamesActions.karachiPlaceBetByFreeze({ id: data._id }, res))
  }

  let isOpen =
    allKarachiMarket &&
    allKarachiMarket?.length > 0 &&
    allKarachiMarket.find(e => e?.name === 'Open')
  let isClose =
    allKarachiMarket &&
    allKarachiMarket?.length > 0 &&
    allKarachiMarket.find(e => e?.name === 'Close')
  let isCenter =
    allKarachiMarket &&
    allKarachiMarket?.length > 0 &&
    allKarachiMarket.find(e => e?.name === 'Center')
  let isSingleKahchee =
    allKarachiMarket &&
    allKarachiMarket?.length > 0 &&
    allKarachiMarket.find(e => e?.name === 'Single Kenchi')
  let isDoubalKahchee =
    allKarachiMarket &&
    allKarachiMarket?.length > 0 &&
    allKarachiMarket.find(e => e?.name === 'Double Kenchi')
  let isFirstAkda =
    allKarachiMarket &&
    allKarachiMarket?.length > 0 &&
    allKarachiMarket.find(e => e?.name === 'First Akda')
  let isSecondAkda =
    allKarachiMarket &&
    allKarachiMarket?.length > 0 &&
    allKarachiMarket.find(e => e?.name === 'Second Akda')
  let isFirstTND =
    allKarachiMarket &&
    allKarachiMarket?.length > 0 &&
    allKarachiMarket.find(e => e?.name === 'First TND')
  let isSecondTND =
    allKarachiMarket &&
    allKarachiMarket?.length > 0 &&
    allKarachiMarket.find(e => e?.name === 'Second TND')
  let isFSRing =
    allKarachiMarket &&
    allKarachiMarket?.length > 0 &&
    allKarachiMarket.find(e => e?.name === 'FS Ring')

  useEffect(() => {
    const currentTime =
      moment().hours() * 3600 + moment().minutes() * 60 + moment().seconds()
    const availableGames = karachiGamesList?.filter(
      game =>
        currentTime >= game?.openTime &&
        currentTime <= game?.closingTime &&
        !game.isGameClose
    )

    if (availableGames?.length > 0) {
      setGameNameRes(availableGames?.[0]?.name)
      setGameName(availableGames?.[0])
    }

    let data = [
      { ...isOpen, toShow: 'Open' },
      { ...isClose, toShow: 'Close' },
      { ...isCenter, toShow: 'Center' },
      { ...isSingleKahchee, toShow: 'Single Kenchi' },
      { ...isDoubalKahchee, toShow: 'Double Kenchi' },
      { ...isFirstTND, toShow: 'First TND' },
      { ...isSecondTND, toShow: 'Second TND' },
      { ...isFirstAkda, toShow: 'First Akda' },
      { ...isSecondAkda, toShow: 'Second Akda' },
      { ...isFSRing, toShow: 'FS Ring' }
    ].filter(item => item !== null)
    setoptions(data)
    setoptions2(karachiGamesList)
  }, [
    stateKey,
    fieldsGame,
    gameNameRes,
    isOpen,
    isClose,
    isCenter,
    isSingleKahchee,
    isDoubalKahchee,
    isFirstAkda,
    isSecondAkda,
    isFirstTND,
    isSecondTND,
    isFSRing,
    karachiGamesList,
    games
  ])

  const handleNext = e => {
    e.preventDefault()
    setCurrentIndex(prevIndex =>
      prevIndex === options?.length - 1 ? 0 : prevIndex + 1
    )
  }

  const handleKeyDown2 = event => {
    const keyMap = {
      ArrowUp: 'Single Kenchi', // Move to Single Kenchi
      ArrowDown: 'Double Kenchi', // Move to Double Kenchi
      ArrowLeft: 'Open', // Move to Single Open
      ArrowRight: 'Close', // Move to Single Close
      F: 'First TND', // Move to First TND
      f: 'First TND', // Move to First TND
      S: 'Second TND', // Move to Second TND
      s: 'Second TND', // Move to Second TND
      C: 'Center', // Move to Center
      c: 'Center', // Move to Center
      R: 'FS Ring', // Move to FS Ring
      r: 'FS Ring', // Move to FS Ring
      F10: 'Second Akda' // Move to Second Akda
      // F11: 'First Akda', // Move to Second Akda
    }

    const action = keyMap[event.key]

    if (
      [
        'ArrowUp',
        'ArrowDown',
        'ArrowLeft',
        'ArrowRight',
        'F',
        'f',
        'S',
        's',
        'C',
        'c',
        'R',
        'r',
        'F10'
      ].includes(event.key)
    ) {
      event.preventDefault()
    }

    if (action) {
      setCurrentIndex(prevIndex => {
        const targetIndex = options.findIndex(item => item.toShow === action)
        return targetIndex >= 0 ? targetIndex : prevIndex
      })
    }
  }

  const handleKeyDown3 = event => {
    if (['F9'].includes(event.key) && !['akc.close'].includes(gameNameRes)) {
      setStateKey(event.key)
      setBetNumber('11')
      setCurrentIndex(7)
    }
  }

  const inputChangeCreate = e => {
    e.preventDefault()
    setStateKey(null)
    const { name, value } = e.target
    setFieldsGame(prevState => ({ ...prevState, [name]: value }))
    setErrorsGame(prevState => ({ ...prevState, [name]: '' }))

    if (name === 'userId' && gameName?._id) {
      const user = subAdminData?.find(ele => ele?._id === value)
      setUserName(user)
      setIsDisable(false)
      setTimeout(() => {
        betNumberInputRef.current?.focus()
      }, 0)
    }
  }

  const { seconds, minutes, hours, ampm } = useTime({ format: '12-hour' })

  let isControlPressed = false

  const handleKeyDown4 = useCallback(
    event => {
      if (event.key === 'Control') {
        isControlPressed = true
      }

      if (isControlPressed && (event.key === 'q' || event.key === 'Q')) {
        const index = karachiPlaceBet?.length - 1
        const userData = karachiPlaceBet?.[index]
        if (userData && (gameName['_id'] || id || fieldsGame['userId'])) {
          const res = {
            gameId: gameName && gameName['_id'] ? gameName['_id'] : id,
            userId:
              fieldsGame && fieldsGame['userId'] ? fieldsGame['userId'] : localStoragedata?.userId
          }
          dispatch(
            karachiGamesActions.karachiPlaceBetByFreeze(
              { id: userData?._id },
              res
            )
          )
        }
      }
    },
    [karachiPlaceBet]
  )

  // Keyup handler
  const handleKeyUp = useCallback(event => {
    if (event.key === 'Control') {
      isControlPressed = false
    }
  }, [])

  useEffect(() => {
    // Attach event listeners
    document.addEventListener('keydown', handleKeyDown4)
    document.addEventListener('keyup', handleKeyUp)

    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener('keydown', handleKeyDown4)
      document.removeEventListener('keyup', handleKeyUp)
    }
  }, [handleKeyDown4, handleKeyUp])

  return (
    <div className='w-full bg-white h-screen overflow-hidden overflow-y-auto'>
      <div className='relative bg-white'>
        <div className='justify-between flex flex-col bg-white rounded-md border'>
          {localStoragedata && localStoragedata.roleId === 0 && (
            <div className='text-left bg-white space-y-2 rounded-md w-full p-4'>
              <div className='w-full flex md:flex-row flex-col items-center justify-start gap-3'>
                <div className='md:w-56 w-full'>
                  <select
                    className='md:w-56 w-full px-3 py-1.5 text-base bg-white border border-gray-400 rounded-md outline-none capitalize'
                    id='userId'
                    name='userId'
                    placeholder='name'
                    value={fieldsGame['userId']}
                    type='text'
                    onChange={inputChangeCreate}
                  >
                    <option value=''>Select User Name</option>
                    {subAdminData && subAdminData.length > 0
                      ? subAdminData.map((element, index) => (
                        <option
                          value={element && element._id ? element._id : ''}
                        >
                          {element && element.userName
                            ? element.userName
                            : ''}
                        </option>
                      ))
                      : null}
                  </select>
                  {errorsGame && errorsGame['userId'] ? (
                    <div className='text-red-600 invalid-feedback'>
                      {errorsGame['userId']}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          )}

          <div className='bg-gray-50 w-full px-4'>
            <div className='text-black capitalize text-xl w-full flex justify-between px-4 pt-3 pb-2'>
              <p className='font-bold'>Karachi Bet Table</p>
              <p className='text-base flex gap-1 items-center font-semibold bg-red-100 p-2 rounded-lg'>
                Game Name:{' '}
                <span className='text-base font-semibold'>{gameNameRes}</span>
              </p>
              <p className='text-base flex gap-1 items-center font-semibold bg-red-100 p-2 rounded-lg'>
                User Name:{' '}
                <span className='text-base font-semibold'>
                  {userPalyerName ?? 'N/A'}
                </span>
              </p>
              <p className='text-base flex gap-1 items-center font-semibold bg-red-100 p-2 rounded-lg'>
                Total Amount:{' '}
                <span className='text-base font-semibold'>
                  {totalAmount ?? 0}
                </span>
              </p>
              <div className='w-64'>
                <div className='flex items-center gap-2 w-full bg-red-100 rounded-lg p-1'>
                  <p className='text-base font-bold ml-6'>Time:-</p>
                  <div className='flex justify-center font-bold '>
                    {String(hours).padStart(2, '0')} :{' '}
                    {String(minutes).padStart(2, '0')} :{' '}
                    {String(seconds).padStart(2, '0')}
                  </div>
                  <span className='text-lg font-semibold px-1 py-1 rounded-md uppercase space-x-2'>
                    {ampm}
                  </span>
                </div>
              </div>
            </div>

            <div
              className='h-[33rem] overflow-x-auto overflow-y-auto'
              ref={scrollContainerRef}
            >
              <table className='min-w-full border border-gray-300 text-sm sm:text-base'>
                <thead className='sticky top-0 bg-red-100 z-10'>
                  <tr className='text-gray-800 font-semibold'>
                    <th className='p-3 uppercase'>#</th>
                    {localStoragedata && localStoragedata?.roleId === 0 && (
                      <>
                        <th className='p-3 uppercase'>User</th>
                        <th className='p-3 uppercase'>Game</th>
                      </>
                    )}
                    <th className='p-3 uppercase'>Number</th>
                    <th className='p-3 uppercase'>Amount</th>
                    <th className='p-3 uppercase'>Market</th>
                    <th className='p-3 uppercase'>Packet</th>
                    <th className='p-3 uppercase'>Date/Time</th>
                    <th className='p-3 uppercase'>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {karachiPlaceBet && karachiPlaceBet?.length > 0 ? (
                    karachiPlaceBet.map((bet, index) => (
                      <tr
                        key={index}
                        className='border text-2xl last:border-none last:bg-green-300 bg-white text-gray-700'
                      >
                        <td className='p-2 border border-gray-200 text-center'>
                          {index + 1}
                        </td>
                        {localStoragedata && localStoragedata?.roleId === 0 && (
                          <>
                            <td className='p-2 border border-gray-200 text-center'>
                              {(bet?.userId?.userName ||
                                bet?.userData?.userName) ??
                                ''}
                            </td>
                            <td className='p-2 font-medium border border-gray-200 text-center'>
                              {(bet?.karachiGameId?.name || bet?.games?.name) ??
                                ''}
                            </td>
                          </>
                        )}
                        {bet.isBetPrime ? (
                          <>
                            <td className='p-2 text-red-600 border bordered-500 text-center font-bold'>
                              {bet.betNumber}
                            </td>
                            <td className='p-2 text-red-600 border bordered-500 text-center font-bold'>
                              {['Single Open', 'Single Close'].includes(
                                bet?.localmarketname
                              )
                                ? bet?.amount * 10
                                : bet?.amount}
                            </td>
                          </>
                        ) : (
                          <>
                            <td className='p-2  border border-gray-200 text-center font-bold'>
                              {bet.betNumber}
                            </td>
                            <td className='p-2 border border-gray-200 text-center font-bold'>
                              {['Single Open', 'Single Close'].includes(
                                bet?.localmarketname
                              )
                                ? bet?.amount * 10
                                : bet?.amount}
                            </td>
                          </>
                        )}
                        <td className='p-2 border border-gray-200 text-center'>
                          {bet?.localmarketname}
                        </td>
                        <td className='p-2 border border-gray-200 text-center'>
                          {['Single Open', 'Single Close'].includes(
                            bet?.marketId?.name
                          )
                            ? bet?.packet?.length * 10
                            : bet?.packet?.length}
                        </td>
                        <td className='p-2 border border-gray-200 text-center'>
                          {moment(bet?.createdAt).format('DD-MM-YYYY HH:mm')}
                        </td>
                        <td className='p-2 text-center'>
                          <button
                            onClick={() => handleDelete(bet)}
                            className='text-red-500 hover:text-red-600 font-semibold text-base '
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className='border border-gray-300'>
                      <td
                        colSpan='8'
                        className='text-center px-4 py-2 text-base text-gray-500'
                      >
                        No bets available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <form
            disabled={isDisable}
            className='p-4 bg-gray-100 rounded-t-lg shadow-md space-y-4 lg:space-y-0 lg:space-x-4 lg:flex-row lg:justify-between 
            lg:items-center w-full z-10 flex flex-col justify-between items-center rounded-lg'
          >
            <div className='flex flex-col lg:flex-row items-center gap-4 w-full'>
              <div className='flex items-center gap-2 pb-2'>
                <div className='text-gray-800 border border-gray-300 rounded-lg px-4 py-2 w-full text-center'>
                  {currentOption?.toShow}
                </div>
                <button
                  onClick={e => handleNext(e)}
                  disabled={isDisable}
                  type='button'
                  className='text-white border bg-blue-600 hover:bg-blue-500 cursor-pointer rounded-lg px-4 py-2 w-full text-center'
                >
                  <MdOutlineNavigateNext size={22} />
                </button>
              </div>

              <div className='flex items-center gap-2 pb-2'>
                <input
                  type='text'
                  ref={betNumberInputRef} // Reference for shifting focus back
                  value={betNumber}
                  onChange={e => {
                    const value = e.target.value
                    if (/^\d*$/.test(value)) {
                      handleChange(e) // Call your existing handleChange function
                    }
                  }}
                  onKeyDown={handleKeyDown}
                  disabled={isDisable}
                  name='betNumber'
                  autoComplete='off'
                  className='bg-white border border-gray-300  text-gray-700 text-xl 
                  rounded-lg block w-full p-1.5 transition duration-300'
                  placeholder='Enter a number'
                />
                {errors.betNumber && (
                  <span className='text-red-500 text-base mt-1'>
                    {errors.betNumber}
                  </span>
                )}
              </div>

              {/* Amount Input */}
              <div className='flex items-center gap-2 pb-2'>
                <input
                  type='number'
                  ref={amountInputRef} // Reference for shifting focus
                  value={amount}
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                  disabled={isDisable}
                  name='amount'
                  id='amount'
                  autoComplete='off'
                  className='bg-white border border-gray-300  text-gray-700 text-xl rounded-lg block w-full p-1.5 transition duration-300'
                  placeholder='Enter an amount'
                />
                {errors.amount && (
                  <span className='text-red-500 text-base mt-1'>
                    {errors.amount}
                  </span>
                )}
              </div>

              <div className='w-full lg:w-auto h-14'>
                {loading ? (
                  <div className='absolute inset-0 flex justify-center items-center bg-white/50 z-40'>
                    <div className='w-8 h-8 border-4 border-blue-500 border-solid border-t-transparent rounded-full animate-spin'></div>
                  </div>
                ) : null}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default KarachiPlaceBet
