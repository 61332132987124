export const gamesConstants = {

    PLACEBET_BY_ADMIN_BRAND_REQUEST: "PLACEBET_BY_ADMIN_BRAND_REQUEST",
    PLACEBET_BY_ADMIN_BRAND_SUCCESS: "PLACEBET_BY_ADMIN_BRAND_SUCCESS",
    PLACEBET_BY_ADMIN_BRAND_FAILURE: "PLACEBET_BY_ADMIN_BRAND_FAILURE",

    PLACEBET_BY_ADMIN_BRAND_SHEETLOCK_REQUEST: "PLACEBET_BY_ADMIN_BRAND_SHEETLOCK_REQUEST",
    PLACEBET_BY_ADMIN_BRAND_SHEETLOCK_SUCCESS: "PLACEBET_BY_ADMIN_BRAND_SHEETLOCK_SUCCESS",
    PLACEBET_BY_ADMIN_BRAND_SHEETLOCK_FAILURE: "PLACEBET_BY_ADMIN_BRAND_SHEETLOCK_FAILURE",

    DELETE_SHEETLOCK_REQUEST: "DELETE_SHEETLOCK_REQUEST",
    DELETE_SHEETLOCK_SUCCESS: "DELETE_SHEETLOCK_SUCCESS",
    DELETE_SHEETLOCK_FAILURE: "DELETE_SHEETLOCK_FAILURE",

    GET_ALL_USER_REQUEST: "GET_ALL_USER_REQUEST",
    GET_ALL_USER_SUCCESS: "GET_ALL_USER_SUCCESS",
    GET_ALL_USER_FAILURE: "GET_ALL_USER_FAILURE",


    RESULT_BY_ADMIN_REQUEST: "RESULT_BY_ADMIN_REQUEST",
    RESULT_BY_ADMIN_SUCCESS: "RESULT_BY_ADMIN_SUCCESS",
    RESULT_BY_ADMIN_FAILURE: "RESULT_BY_ADMIN_FAILURE",

    GET_KARACHI_ALL_GAMES_REQUEST: "GET_KARACHI_ALL_GAMES_REQUEST",
    GET_KARACHI_ALL_GAMES_SUCCESS: "GET_KARACHI_ALL_GAMES_SUCCESS",
    GET_KARACHI_ALL_GAMES_FAILURE: "GET_KARACHI_ALL_GAMES_FAILURE",


    KARACHI_RESULT_BY_ADMIN_REQUEST: "KARACHI_RESULT_BY_ADMIN_REQUEST",
    KARACHI_RESULT_BY_ADMIN_SUCCESS: "KARACHI_RESULT_BY_ADMIN_SUCCESS",
    KARACHI_RESULT_BY_ADMIN_FAILURE: "KARACHI_RESULT_BY_ADMIN_FAILURE",


    NUMBER_REQUEST: "NUMBER_REQUEST",
    NUMBER_SUCCESS: "NUMBER_SUCCESS",
    NUMBER_FAILURE: "NUMBER_FAILURE",

    GET_KARACHI_RESULT_LIST_ADMIN_REQUEST: "GET_KARACHI_RESULT_LIST_ADMIN_REQUEST",
    GET_KARACHI_RESULT_LIST_ADMIN_SUCCESS: "GET_KARACHI_RESULT_LIST_ADMIN_SUCCESS",
    GET_KARACHI_RESULT_LIST_ADMIN_FAILURE: "GET_KARACHI_RESULT_LIST_ADMIN_FAILURE",

    ONOFF_BRAND_REQUEST: "ONOFF_BRAND_REQUEST",
    ONOFF_BRAND_SUCCESS: "ONOFF_BRAND_SUCCESS",
    ONOFF_BRAND_FAILURE: "ONOFF_BRAND_FAILURE",

    GET_ALL_GAMESBRAND_REQUEST: "GET_ALL_GAMESBRAND_REQUEST",
    GET_ALL_GAMESBRAND_SUCCESS: "GET_ALL_GAMESBRAND_SUCCESS",
    GET_ALL_GAMESBRAND_FAILURE: "GET_ALL_GAMESBRAND_FAILURE",


    GET_ALL_GAMES_JODI_REQUEST: "GET_ALL_GAMES_JODI_REQUEST",
    GET_ALL_GAMES_JODI_SUCCESS: "GET_ALL_GAMES_JODI_SUCCESS",
    GET_ALL_GAMES_JODI_FAILURE: "GET_ALL_GAMES_JODI_FAILURE",


    DELETE_BRAND_REQUEST: "DELETE_BRAND_REQUEST",
    DELETE_BRAND_SUCCESS: "DELETE_BRAND_SUCCESS",
    DELETE_BRAND_FAILURE: "DELETE_BRAND_FAILURE",

    CREATE_BRAND_REQUEST: "CREATE_BRAND_REQUEST",
    CREATE_BRAND_SUCCESS: "CREATE_BRAND_SUCCESS",
    CREATE_BRAND_FAILURE: "CREATE_BRAND_FAILURE",



    UPDATE_BRAND_STATUS_REQUEST: "UPDATE_BRAND_STATUS_REQUEST",
    UPDATE_BRAND_STATUS_SUCCESS: "UPDATE_BRAND_STATUS_SUCCESS",
    UPDATE_BRAND_STATUS_FAILURE: "UPDATE_BRAND_STATUS_FAILURE",



    GETALL_GAME_ADMIN_REQUEST: "GETALL_GAME_ADMIN_REQUEST",
    GETALL_GAME_ADMIN_SUCCESS: "GETALL_GAME_ADMIN_SUCCESS",
    GETALL_GAME_ADMIN_FAILURE: "GETALL_GAME_ADMIN_FAILURE",

    UPDATE_BRAND_REQUEST: "UPDATE_BRAND_REQUEST",
    UPDATE_BRAND_SUCCESS: "UPDATE_BRAND_SUCCESS",
    UPDATE_BRAND_FAILURE: "UPDATE_BRAND_FAILURE",
    GET_ALL_BRAND_REQUEST: "GET_ALL_BRAND_REQUEST",
    GET_ALL_BRAND_SUCCESS: "GET_ALL_BRAND_SUCCESS",
    GET_ALL_BRAND_FAILURE: "GET_ALL_BRAND_FAILURE",


    NUMBER_ARRAY_REQUEST: "NUMBER_ARRAY_REQUEST",
    NUMBER_ARRAY_SUCCESS: "NUMBER_ARRAY_SUCCESS",
    NUMBER_ARRAY_FAILURE: "NUMBER_ARRAY_FAILURE",

    ROLL_BACK_BACK_REQUEST: "ROLL_BACK_BACK_REQUEST",
    ROLL_BACK_BACK_SUCCESS: "ROLL_BACK_BACK_SUCCESS",
    ROLL_BACK_BACK_FAILURE: "ROLL_BACK_BACK_FAILURE",


    GET_WINNER_LIST_REQUEST: "GET_WINNER_LIST_REQUEST",
    GET_WINNER_LIST_SUCCESS: "GET_WINNER_LIST_SUCCESS",
    GET_WINNER_LIST_FAILURE: "GET_WINNER_LIST_FAILURE",

    GET_PAYMENT_ADMIN1_REQUEST: "GET_PAYMENT_ADMIN1_REQUEST",
    GET_PAYMENT_ADMIN1_SUCCESS: "GET_PAYMENT_ADMIN1_SUCCESS",
    GET_PAYMENT_ADMIN1_FAILURE: "GET_PAYMENT_ADMIN1_FAILURE",
    SAVE_ADMIN_RESULT_REQUEST: "SAVE_ADMIN_RESULT_REQUEST",
    SAVE_ADMIN_RESULT_SUCCESS: "SAVE_ADMIN_RESULT_SUCCESS",
    SAVE_ADMIN_RESULT_FAILURE: "SAVE_ADMIN_RESULT_FAILURE",

    GET_RESULT_LIST_ADMIN_REQUEST: "GET_RESULT_LIST_ADMIN_REQUEST",
    GET_RESULT_LIST_ADMIN_SUCCESS: "GET_RESULT_LIST_ADMIN_SUCCESS",
    GET_RESULT_LIST_ADMIN_FAILURE: "GET_RESULT_LIST_ADMIN_FAILURE",

    GET_ALL_MARKETS_REQUEST: "GET_ALL_MARKETS_REQUEST",
    GET_ALL_MARKETS_SUCCESS: "GET_ALL_MARKETS_SUCCESS",
    GET_ALL_MARKETS_FAILURE: "GET_ALL_MARKETS_FAILURE",

    CREATE_GAMES_REQUEST: 'CREATE_GAMES_REQUEST',
    CREATE_GAMES_SUCCESS: 'CREATE_GAMES_SUCCESS',
    CREATE_GAMES_FAILURE: 'CREATE_GAMES_FAILURE',

    GET_ALL_GAMES_REQUEST: 'GET_ALL_GAMES_REQUEST',
    GET_ALL_GAMES_SUCCESS: 'GET_ALL_GAMES_SUCCESS',
    GET_ALL_GAMES_FAILURE: 'GET_ALL_GAMES_FAILURE',

    UPADATE_SHEET_LOCK_REQUEST: 'UPADATE_SHEET_LOCK_REQUEST',
    UPADATE_SHEET_LOCK_SUCCESS: 'UPADATE_SHEET_LOCK_SUCCESS',
    UPADATE_SHEET_LOCK_FAILURE: 'UPADATE_SHEET_LOCK_FAILURE',

    GET_SHEET_LOCKUSER_ID_REQUEST: 'GET_SHEET_LOCKUSER_ID_REQUEST',
    GET_SHEET_LOCKUSER_ID_SUCCESS: 'GET_SHEET_LOCKUSER_ID_SUCCESS',
    GET_SHEET_LOCKUSER_ID_FAILURE: 'GET_SHEET_LOCKUSER_ID_FAILURE',

    GET_LIST_SHEET_LOCKUSER_USERID_REQUEST: 'GET_LIST_SHEET_LOCKUSER_USERID_REQUEST',
    GET_LIST_SHEET_LOCKUSER_USERID_SUCCESS: 'GET_LIST_SHEET_LOCKUSER_USERID_SUCCESS',
    GET_LIST_SHEET_LOCKUSER_USERID_FAILURE: 'GET_LIST_SHEET_LOCKUSER_USERID_FAILURE',

    GET_GAMES_LIST_REQUEST: 'GET_GAMES_LIST_REQUEST',
    GET_GAMES_LIST_SUCCESS: 'GET_GAMES_LIST_SUCCESS',
    GET_GAMES_LIST_FAILURE: 'GET_GAMES_LIST_FAILURE',

    UPDATE_GAMES_REQUEST: 'UPDATE_GAMES_REQUEST',
    UPDATE_GAMES_SUCCESS: 'UPDATE_GAMES_SUCCESS',
    UPDATE_GAMES_FAILURE: 'UPDATE_GAMES_FAILURE',

    UPDATE_GAMES_STATUS_REQUEST: 'UPDATE_GAMES_STATUS_REQUEST',
    UPDATE_GAMES_STATUS_SUCCESS: 'UPDATE_GAMES_STATUS_SUCCESS',
    UPDATE_GAMES_STATUS_FAILURE: 'UPDATE_GAMES_STATUS_FAILURE',

    DELETE_GAMES_REQUEST: 'DELETE_GAMES_REQUEST',
    DELETE_GAMES_SUCCESS: 'DELETE_GAMES_SUCCESS',
    DELETE_GAMES_FAILURE: 'DELETE_GAMES_FAILURE',

    GET_SUB_ADMIN_LIST_REQUEST: 'GET_SUB_ADMIN_LIST_REQUEST',
    GET_SUB_ADMIN_LIST_SUCCESS: 'GET_SUB_ADMIN_LIST_SUCCESS',
    GET_SUB_ADMIN_LIST_FAILURE: 'GET_SUB_ADMIN_LIST_FAILURE',

    GET_PLACE_BETS_BY_USER_ID_REQUEST: 'GET_PLACE_BETS_BY_USER_ID_REQUEST',
    GET_PLACE_BETS_BY_USER_ID_SUCCESS: 'GET_PLACE_BETS_BY_USER_ID_SUCCESS',
    GET_PLACE_BETS_BY_USER_ID_FAILURE: 'GET_PLACE_BETS_BY_USER_ID_FAILURE',

    PLACEBET_BY_ADMIN_FREEZE_REQUEST: 'PLACEBET_BY_ADMIN_FREEZE_REQUEST',
    PLACEBET_BY_ADMIN_FREEZE_SUCCESS: 'PLACEBET_BY_ADMIN_FREEZE_SUCCESS',
    PLACEBET_BY_ADMIN_FREEZE_FAILURE: 'PLACEBET_BY_ADMIN_FREEZE_FAILURE',

    RESULT_BY_ROLL_BACK_ADMIN_REQUEST: "RESULT_BY_ROLL_BACK_ADMIN_REQUEST",
    RESULT_BY_ROLL_BACK_ADMIN_SUCCESS: "RESULT_BY_ROLL_BACK_ADMIN_SUCCESS",
    RESULT_BY_ROLL_BACK_ADMIN_FAILURE: "RESULT_BY_ROLL_BACK_ADMIN_FAILURE",

    RESULT_BY_ROLL_BACK_ADMIN_KARACHI_REQUEST: "RESULT_BY_ROLL_BACK_ADMIN_KARACHI_REQUEST",
    RESULT_BY_ROLL_BACK_ADMIN_KARACHI_SUCCESS: "RESULT_BY_ROLL_BACK_ADMIN_KARACHI_SUCCESS",
    RESULT_BY_ROLL_BACK_ADMIN_KARACHI_FAILURE: "RESULT_BY_ROLL_BACK_ADMIN_KARACHI_FAILURE",

};
