import {
  gamesConstants
} from '../_constants';

export default function games(state = {}, action) {
  switch (action.type) {

    case gamesConstants.PLACEBET_BY_ADMIN_FREEZE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case gamesConstants.PLACEBET_BY_ADMIN_FREEZE_SUCCESS:
      return {
        ...state,
        loading: false,

      };
    case gamesConstants.PLACEBET_BY_ADMIN_FREEZE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case gamesConstants.PLACEBET_BY_ADMIN_BRAND_REQUEST:
      return {
        ...state,
        loading: true
      };
    case gamesConstants.PLACEBET_BY_ADMIN_BRAND_SUCCESS:
      return {
        ...state,
        loading: true,

      };
    case gamesConstants.PLACEBET_BY_ADMIN_BRAND_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case gamesConstants.DELETE_SHEETLOCK_REQUEST:
      return {
        ...state,
        loading: true
      };
    case gamesConstants.DELETE_SHEETLOCK_SUCCESS:
      return {
        ...state,
        loading: false,

      };
    case gamesConstants.DELETE_SHEETLOCK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case gamesConstants.PLACEBET_BY_ADMIN_BRAND_SHEETLOCK_REQUEST:
      return {
        ...state,
        loading: true
      };
    case gamesConstants.PLACEBET_BY_ADMIN_BRAND_SHEETLOCK_SUCCESS:
      return {
        ...state,
        loading: false,

      };
    case gamesConstants.PLACEBET_BY_ADMIN_BRAND_SHEETLOCK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case gamesConstants.GET_ALL_GAMES_JODI_REQUEST:
      return {
        ...state,
        loading: true
      };
    case gamesConstants.GET_ALL_GAMES_JODI_SUCCESS:
      return {
        ...state,
        loading: false,
        allJodi: action?.games?.data?.list
      };
    case gamesConstants.GET_ALL_GAMES_JODI_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case gamesConstants.DELETE_BRAND_REQUEST:
      return {
        ...state,
        loading: true
      };
    case gamesConstants.DELETE_BRAND_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case gamesConstants.DELETE_BRAND_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case gamesConstants.CREATE_BRAND_REQUEST:
      return {
        ...state,
        loading: true
      };
    case gamesConstants.CREATE_BRAND_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case gamesConstants.CREATE_BRAND_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case gamesConstants.UPDATE_BRAND_STATUS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case gamesConstants.UPDATE_BRAND_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        // allgame:action?.games?.data
      };
    case gamesConstants.UPDATE_BRAND_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case gamesConstants.GETALL_GAME_ADMIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case gamesConstants.GETALL_GAME_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        allgame: action?.games?.data
      };
    case gamesConstants.GETALL_GAME_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case gamesConstants.ROLL_BACK_BACK_REQUEST:
      return {
        ...state,
        loading: true
      };
    case gamesConstants.ROLL_BACK_BACK_SUCCESS:
      return {
        ...state,
        loading: false,

      };
    case gamesConstants.ROLL_BACK_BACK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };










    case gamesConstants.UPDATE_BRAND_REQUEST:
      return {
        ...state,
        loading: true
      };
    case gamesConstants.UPDATE_BRAND_SUCCESS:
      return {
        ...state,
        loading: false,

      };
    case gamesConstants.UPDATE_BRAND_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case gamesConstants.GET_ALL_BRAND_REQUEST:
      return {
        ...state,
        loading: true
      };
    case gamesConstants.GET_ALL_BRAND_SUCCESS:
      return {
        ...state,
        loading: false,
        allBrand: action?.games?.data?.list
      };
    case gamesConstants.GET_ALL_BRAND_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case gamesConstants.NUMBER_ARRAY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case gamesConstants.NUMBER_ARRAY_SUCCESS:
      return {
        ...state,
        loading: false,
        numberArray: action?.games?.data
      };
    case gamesConstants.NUMBER_ARRAY_FAILURE:
      return {
        ...state,
        loading: false,
        resultList: [],
        error: action.error
      };


    case gamesConstants.NUMBER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case gamesConstants.NUMBER_SUCCESS:
      return {
        ...state,
        loading: false,
        numberList: action?.games?.data
      };
    case gamesConstants.NUMBER_FAILURE:
      return {
        ...state,
        loading: false,
        resultList: [],
        error: action.error
      };


    case gamesConstants.RESULT_BY_ADMIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case gamesConstants.RESULT_BY_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        resultList: action?.games?.data
      };
    case gamesConstants.RESULT_BY_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        resultList: [],
        error: action.error
      };


    case gamesConstants.KARACHI_RESULT_BY_ADMIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case gamesConstants.KARACHI_RESULT_BY_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        karachiResultList: action?.games?.data
      };
    case gamesConstants.KARACHI_RESULT_BY_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        resultList: [],
        error: action.error
      };


    case gamesConstants.GET_WINNER_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case gamesConstants.GET_WINNER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        winnerList: action?.games?.data
      };
    case gamesConstants.GET_WINNER_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        winnerList: [],
        error: action.error
      };
    case gamesConstants.GET_ALL_USER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case gamesConstants.GET_ALL_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        allUserList: action?.games?.data
      };
    case gamesConstants.GET_ALL_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case gamesConstants.GET_RESULT_LIST_ADMIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case gamesConstants.GET_RESULT_LIST_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        gameResultList: action?.games?.data?.list,
        gameResultTotal: action?.games?.data?.total
      };
    case gamesConstants.GET_RESULT_LIST_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case gamesConstants.GET_KARACHI_RESULT_LIST_ADMIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case gamesConstants.GET_KARACHI_RESULT_LIST_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        karachiGameResultList: action?.games?.data?.list,
        karachiGameResultTotal: action?.games?.data?.total
      };
    case gamesConstants.GET_KARACHI_RESULT_LIST_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };







    case gamesConstants.GET_ALL_MARKETS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case gamesConstants.GET_ALL_MARKETS_SUCCESS:
      return {
        ...state,
        loading: false,
        allMarket: action?.games?.data
      };
    case gamesConstants.GET_ALL_MARKETS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case gamesConstants.SAVE_ADMIN_RESULT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case gamesConstants.SAVE_ADMIN_RESULT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case gamesConstants.SAVE_ADMIN_RESULT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case gamesConstants.GET_PAYMENT_ADMIN1_REQUEST:
      return {
        ...state,
        loading: true
      };
    case gamesConstants.GET_PAYMENT_ADMIN1_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case gamesConstants.GET_PAYMENT_ADMIN1_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case gamesConstants.CREATE_GAMES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case gamesConstants.CREATE_GAMES_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case gamesConstants.CREATE_GAMES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case gamesConstants.GET_GAMES_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case gamesConstants.GET_GAMES_LIST_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        gamesList: action.games.data.list,
        gamesTotal: action.games.data.total,
        loading: false,
      };
    case gamesConstants.GET_GAMES_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case gamesConstants.ONOFF_BRAND_REQUEST:
      return {
        ...state,
        loading: true
      };
    case gamesConstants.ONOFF_BRAND_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case gamesConstants.ONOFF_BRAND_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case gamesConstants.GET_ALL_GAMESBRAND_REQUEST:
      return {
        ...state,
        loading: true
      };
    case gamesConstants.GET_ALL_GAMESBRAND_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        gamesListb: action?.games?.data?.list,
        gamesTotalb: action?.games?.data?.total,
        loading: false,
      };
    case gamesConstants.GET_ALL_GAMESBRAND_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };



    case gamesConstants.GET_ALL_GAMES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case gamesConstants.GET_ALL_GAMES_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        gamesList: action.games.data.list,
        gamesTotal: action.games.data.total,
        loading: false,
      };
    case gamesConstants.GET_ALL_GAMES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case gamesConstants.GET_KARACHI_ALL_GAMES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case gamesConstants.GET_KARACHI_ALL_GAMES_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        karachiGamesList: action.games.data.list,
        karachiGamesTotal: action.games.data.total,
        loading: false,
      };
    case gamesConstants.GET_KARACHI_ALL_GAMES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case gamesConstants.GET_SUB_ADMIN_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case gamesConstants.GET_SUB_ADMIN_LIST_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        subAdminData: action.games.data,
        loading: false,
      };
    case gamesConstants.GET_SUB_ADMIN_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case gamesConstants.UPDATE_GAMES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case gamesConstants.UPDATE_GAMES_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case gamesConstants.UPDATE_GAMES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case gamesConstants.UPDATE_GAMES_STATUS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case gamesConstants.UPDATE_GAMES_STATUS_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case gamesConstants.UPDATE_GAMES_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case gamesConstants.DELETE_GAMES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case gamesConstants.DELETE_GAMES_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case gamesConstants.DELETE_GAMES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };


    case gamesConstants.GET_SHEET_LOCKUSER_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case gamesConstants.GET_SHEET_LOCKUSER_ID_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        sheetById: action.games.data,
        loading: false,
      };
    case gamesConstants.GET_SHEET_LOCKUSER_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case gamesConstants.GET_LIST_SHEET_LOCKUSER_USERID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case gamesConstants.GET_LIST_SHEET_LOCKUSER_USERID_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        sheetLockList: action.games.data.list,
        sheetLockTotal: action.games.data.total,
        loading: false,
      };
    case gamesConstants.GET_LIST_SHEET_LOCKUSER_USERID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case gamesConstants.GET_PLACE_BETS_BY_USER_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case gamesConstants.GET_PLACE_BETS_BY_USER_ID_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        betsByUserId: action.games.data,
        loading: false,
      };
    case gamesConstants.GET_PLACE_BETS_BY_USER_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case gamesConstants.UPADATE_SHEET_LOCK_REQUEST:
      return {
        ...state,
        loading: true
      };
    case gamesConstants.UPADATE_SHEET_LOCK_SUCCESS:
      return {
        ...state,
        addUserSuccess: false,
        loading: false,
      };
    case gamesConstants.UPADATE_SHEET_LOCK_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

    case gamesConstants.RESULT_BY_ROLL_BACK_ADMIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case gamesConstants.RESULT_BY_ROLL_BACK_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
        resultListData: action?.games?.data
      };
    case gamesConstants.RESULT_BY_ROLL_BACK_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        resultList: [],
        error: action.error
      };
    case gamesConstants.RESULT_BY_ROLL_BACK_ADMIN_KARACHI_REQUEST:
      return {
        ...state,
        loading: true
      };
    case gamesConstants.RESULT_BY_ROLL_BACK_ADMIN_KARACHI_SUCCESS:
      return {
        ...state,
        loading: false,
        resultListKarachiData: action?.games?.data
      };
    case gamesConstants.RESULT_BY_ROLL_BACK_ADMIN_KARACHI_FAILURE:
      return {
        ...state,
        loading: false,
        resultList: [],
        error: action.error
      };

    default:
      return state
  }
}