import React from 'react'
export default function UpdateAppSettingModal (props) {
  let {
    updateModal,
    handleUpdateHideModal,
    inputChange,
    fieldsUpdate,
    errorsUpdate,
    updateAppSettingSubmit
  } = props
  return (
    <div
      className={
        updateModal
          ? 'fixed inset-0 z-50 flex items-center justify-center w-full overflow-hidden main-modal h-100 animated fadeIn faster'
          : 'hidden'
      }
      style={{ background: 'rgba(0,0,0,.7)' }}
    >
      <div className='z-50 w-11/12 mx-auto overflow-y-auto bg-white border border-teal-500 rounded shadow-lg modal-container md:max-w-md'>
        <div className='px-6 py-4 text-left modal-content'>
          {/*Title*/}
          <div className='flex items-center justify-between px-1 py-2 rounded-lg bgcolor text-white'>
            <p className='text-2xl font-bold'>Update AppSetting</p>
            <div className='z-50 cursor-pointer modal-close'>
              <svg
                onClick={() => handleUpdateHideModal()}
                className='text-white fill-current'
                xmlns='http://www.w3.org/2000/svg'
                width={18}
                height={18}
                viewBox='0 0 18 18'
              >
                <path d='M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z'></path>
              </svg>
            </div>
          </div>
          {/*Body*/}
          <form autoComplete='off'>
            <div className="{otpSent?'disableArea':''}">
              <div className='relative mt-1 shadow-sm'>
                <label class='block text-gray-700 text-base font-bold mb-2'>
                  Name :
                </label>
                <input
                  className={`w-full px-3 py-3 text-base transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${
                    errorsUpdate && !errorsUpdate['name']
                      ? 'border  placeholder-gray-500'
                      : 'border border-opacity-100 border-red-500 '
                  }`}
                  id='name'
                  name='name'
                  placeholder='Name'
                  // value={fieldsUpdate.name}
                  value={
                    fieldsUpdate && fieldsUpdate['name']
                      ? fieldsUpdate['name']
                      : ''
                  }
                  type='text'
                  onChange={inputChange}
                />
                {errorsUpdate && errorsUpdate['name'] ? (
                  <div className='text-red-600 invalid-feedback'>
                    {errorsUpdate['name']}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="{otpSent?'disableArea':''}">
              <div className='relative mt-1 shadow-sm'>
                <label class='block text-gray-700 text-base font-bold mb-2'>
                  Key :
                </label>
                <input
                  className={`w-full px-3 py-3 text-base transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${
                    errorsUpdate && !errorsUpdate['key']
                      ? 'border  placeholder-gray-500'
                      : 'border border-opacity-100 border-red-500 '
                  }`}
                  id='key'
                  name='key'
                  placeholder='Key'
                  value={
                    fieldsUpdate && fieldsUpdate['key']
                      ? fieldsUpdate['key']
                      : ''
                  }
                  type='text'
                  onChange={inputChange}
                />
                {errorsUpdate && errorsUpdate['key'] ? (
                  <div className='text-red-600 invalid-feedback'>
                    {errorsUpdate['key']}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="{otpSent?'disableArea':''}">
              <div className='relative mt-1 shadow-sm'>
                <label class='block text-gray-700 text-base font-bold mb-2'>
                  Value :
                </label>
                <input
                  className={`w-full px-3 py-3 text-base transition-all duration-150 ease-linear bg-white border border-gray-400 rounded shadow form-select border-1 placeholder-blueGray-400 text-blueGray-600 focus:outline-none focus:ring ${
                    errorsUpdate && !errorsUpdate['value']
                      ? 'border  placeholder-gray-500'
                      : 'border border-opacity-100 border-red-500 '
                  }`}
                  id='value'
                  name='value'
                  placeholder='Value'
                  value={
                    fieldsUpdate && fieldsUpdate['value']
                      ? fieldsUpdate['value']
                      : ''
                  }
                  type='text'
                  onChange={inputChange}
                />
                {errorsUpdate && errorsUpdate['value'] ? (
                  <div className='text-red-600 invalid-feedback'>
                    {errorsUpdate['value']}
                  </div>
                ) : null}
              </div>
            </div>

            <div className='w-64 mx-auto mt-4 sm:w-72'>
              <button
                className='flex justify-center w-full px-4 py-2 mx-auto text-lg font-semibold text-white uppercase transition duration-150 ease-in-out bgcolor border border-[#7D1970] rounded-full xl hover:bg-blue hover:text-white focus:outline-none focus:border-[#7D1970] focus:shadow-outline-yellow active:bgcolor'
                type='button'
                onClick={updateAppSettingSubmit}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
