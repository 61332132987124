/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DashboardJSON from './AppSettingManagement.json'
import { appSettingActions } from '../../_actions'
import CreateAppSettingModal from './components/CreateAppSettingModal/CreateAppSettingModal'
import UpdateAppSettingModal from './components/UpdateAppSettingModal/UpdateAppSettingModal'
import ViewAppSettingModal from './components/ViewAppSettingModal/ViewAppSettingModal'
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import Loader from '../../components/Loader/Loader'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const size = 1000
const AppSettingManagement = () => {
  const dispatch = useDispatch()
  let [selectedFile, setSelectedFile] = useState(null)
  let [rowData, setRowData] = useState(null)
  let [updateModal, setUpdateModal] = useState(false)
  let [createModal, setCreateModal] = useState(false)
  let [viewModal, setViewModal] = useState(false)
  const [fieldsUpdate, setFieldsUpdate] = useState({})
  const [errorsUpdate, setErrorsUpdate] = useState({})
  const [fieldsAppSetting, setFieldsAppSetting] = useState({})
  const [errorsAppSetting, setErrorsAppSetting] = useState({})
  const [value, setValue] = useState('')
  const [value2, setValue2] = useState('')
  const [value3, setValue3] = useState('')
  const [value4, setValue4] = useState('')
  const [value5, setValue5] = useState('')
  const [value6, setValue6] = useState('')
  const { appSettingList, loading } = useSelector(state => state.appSettings)

  useEffect(() => {
    let appsettingReq = {
      keyWord: '',
      pageNo: 1,
      size: size
    }
    dispatch(appSettingActions.getAppSettingList(appsettingReq))
  }, [])

  const handleCreateHideModal = () => {
    setCreateModal(false)
    setFieldsAppSetting({})
    setErrorsAppSetting({})
  }

  const handleUpdateHideModal = () => {
    setUpdateModal(false)
    setErrorsUpdate({})
  }

  const handleViewHideModal = () => {
    setViewModal(false)
  }

  const inputChange = e => {
    e.preventDefault()
    const { name, value } = e.target
    setFieldsUpdate(prevState => ({ ...prevState, [name]: value }))
    setErrorsUpdate(prevState => ({ ...prevState, [name]: '' }))
  }

  const inputChangeCreate = e => {}

  const updateAppSettingSubmit = e => {
    e.preventDefault()
    if (handleValidationUpdateAppSetting()) {
      let appsettingPageRefresh = {
        keyWord: '',
        pageNo: 1,
        size: size
      }

      const { name, key, value, _id } = fieldsUpdate
      dispatch(
        appSettingActions.updateAppSetting(
          {
            name: name,
            key: key,
            value: value,
            id: _id
          },
          setUpdateModal,
          appsettingPageRefresh
        )
      )
    }
  }

  const handleValidationUpdateAppSetting = () => {
    let formIsValid = true
    let errors = {}

    if (!fieldsUpdate.name || fieldsUpdate.name === '') {
      formIsValid = false
      errors.name = DashboardJSON.ErrorMsg.name
    }

    if (!fieldsUpdate.key || fieldsUpdate.key === '') {
      formIsValid = false
      errors.key = DashboardJSON.ErrorMsg.key
    }

    if (!fieldsUpdate.value || fieldsUpdate.value === '') {
      formIsValid = false
      errors.value = DashboardJSON.ErrorMsg.value
    }

    setErrorsUpdate(errors)
    return formIsValid
  }

  const createAppSettingSubmit = e => {
    e.preventDefault()
    if (handleValidationCreateAppSetting()) {
      let appsettingPageRefresh = {
        keyWord: '',
        pageNo: 1,
        size: size
      }

      const { name, key, value, _id } = fieldsAppSetting
      dispatch(
        appSettingActions.createAppSetting(
          {
            name: name,
            key: key,
            value: value,
            id: _id
          },
          setCreateModal,
          setFieldsAppSetting,
          appsettingPageRefresh
        )
      )
    }
  }

  const handleValidationCreateAppSetting = () => {
    let formIsValid = true
    let errors = {}

    if (!fieldsAppSetting.name || fieldsAppSetting.name === '') {
      formIsValid = false
      errors.name = DashboardJSON.ErrorMsg.name
    }

    if (!fieldsAppSetting.key || fieldsAppSetting.key === '') {
      formIsValid = false
      errors.key = DashboardJSON.ErrorMsg.key
    }

    if (!fieldsAppSetting.value || fieldsAppSetting.value === '') {
      formIsValid = false
      errors.value = DashboardJSON.ErrorMsg.value
    }

    setErrorsAppSetting(errors)
    return formIsValid
  }

  const handleFile = event => {
    setSelectedFile(event.target.files[0])

    if (event.target.files[0]) {
      dispatch(
        appSettingActions.uploadImage(
          event.target.files[event.target.files.length - 1]
        )
      )
      setSelectedFile(null)
    } else {
      console.log('No File To Upload!')
    }
  }

  const [state1, setstate1] = useState('')
  const [state2, setstate2] = useState('')
  const [state3, setstate3] = useState('')
  const [state4, setstate4] = useState('')
  const [state5, setstate5] = useState('')
  const [state6, setstate6] = useState('')
  const [state7, setstate7] = useState('')
  const [state8, setstate8] = useState('')
  const [state9, setstate9] = useState('')
  const [state10, setstate10] = useState('')
  const [state11, setstate11] = useState('')
  const [state12, setstate12] = useState('')
  const [state13, setstate13] = useState('')

  useEffect(() => {
    if (appSettingList && appSettingList.length > 0) {
      appSettingList.forEach(element => {
        if (element.key === 'YoutubeLink') {
          setstate1(element.value)
        }
        if (element.key === 'Email') {
          setstate4(element.value)
        }
        if (element.key === 'WhatsApp') {
          setstate3(element.value)
        }
        if (element.key === 'Marquee') {
          setstate6(element.value)
        }
        if (element.key === 'Deposit_Max') {
          setstate7(element.value)
        }
        if (element.key === 'Withdraw_Min') {
          setstate8(element.value)
        }
        if (element.key === 'Withdraw_Max') {
          setstate9(element.value)
        }
        if (element.key === 'Withdrawal_Time_Open') {
          setstate10(element.value)
        }
        if (element.key === 'Withdrawal_Time_Close') {
          setstate11(element.value)
        }
        if (element.key === 'Deposit_Time_Open') {
          setstate12(element.value)
        }
        if (element.key === 'Deposit_Time_Close') {
          setstate13(element.value)
        }
        if (element.key === 'Marquee') {
          setstate2(element.value)
        }
        if (element.key === 'TelegramLink') {
          setstate5(element.value)
        }
        if (element.key === 'HowToPlay') {
          setValue(element.value)
        }
        if (element.key === 'NOTICE') {
          setValue2(element.value)
        }
        if (element.key === 'Deposit_Notice') {
          setValue3(element.value)
        }
        if (element.key === 'FAQ') {
          setValue4(element.value)
        }
        if (element.key === 'TESTIMONIAL') {
          setValue5(element.value)
        }
        if (element.key === 'NOTICE_RESULT') {
          setValue6(element.value)
        }
      })
    }
  }, [appSettingList])

  const saveInfo = async e => {
    let dataofvalue
    if (e.key === 'NOTICE') {
      dataofvalue = value2
    }
    if (e.key === 'Deposit_Notice') {
      dataofvalue = value3
    }
    if (e.key === 'FAQ') {
      dataofvalue = value4
    }
    if (e.key === 'TESTIMONIAL') {
      dataofvalue = value5
    }
    if (e.key === 'NOTICE_RESULT') {
      dataofvalue = value6
    }
    if (e.key === 'TelegramLink') {
      dataofvalue = state5
    }
    if (e.key === 'YoutubeLink') {
      dataofvalue = state1
    }
    if (e.key === 'Email') {
      dataofvalue = state4
    }
    if (e.key === 'WhatsApp') {
      dataofvalue = state3
    }
    if (e.key === 'Marquee') {
      dataofvalue = state2
    }
    if (e.key === 'Marquee') {
      dataofvalue = state6
    }
    if (e.key === 'Deposit_Max') {
      dataofvalue = state7
    }
    if (e.key === 'Withdraw_Min') {
      dataofvalue = state8
    }
    if (e.key === 'Withdraw_Max') {
      dataofvalue = state9
    }
    if (e.key === 'Withdrawal_Time_Open') {
      dataofvalue = state10
    }
    if (e.key === 'Withdrawal_Time_Close') {
      dataofvalue = state11
    }
    if (e.key === 'Deposit_Time_Open') {
      dataofvalue = state12
    }
    if (e.key === 'Deposit_Time_Close') {
      dataofvalue = state13
    }
    if (e.key === 'HowToPlay') {
      dataofvalue = value
    }
    let appsettingPageRefresh = {
      keyWord: '',
      pageNo: 1,
      size: size
    }
    let Obj = {
      name: e?.name,
      key: e?.key,
      value: dataofvalue,
      id: e?._id
    }
    dispatch(appSettingActions.updateAppSetting(Obj, appsettingPageRefresh))
  }

  return (
    <>
      <Loader loading={loading} />
      <div className='z-0 overflow-hidden overflow-y-auto focus:outline-none'>
        <div className='relative'>
          <div className='relative z-20 flex min-h-screen'>
            <div className='flex w-full overflow-hidden '>
              <div className='flex flex-col flex-1 overflow-hidden'>
                <main className='relative flex-1 '>
                  <div className='p-3  sm:p-5'>
                    <div className='mx-auto max-w-screen-3xl'>
                      <div> 
                        <div className='relative flex flex-col gap-5 rounded-md'>
                          <div className='p-3 bg-white rounded-md gap-5'>
                            <div className='grid grid-cols-3 gap-4'>
                              {appSettingList && appSettingList.length > 0
                                ? appSettingList.map((element, index) => {
                                    if (element.key === 'Marquee') {
                                      return (
                                        <form class='w-full mt-2'>
                                          <div className=' w-full relative'>
                                            <label
                                              for='text'
                                              class='block mb-1 text-base font-medium text-gray-900'
                                            >
                                              Marquee
                                            </label>
                                            <input
                                              value={state2}
                                              onChange={e =>
                                                setstate2(e.target.value)
                                              }
                                              type='text'
                                              id='text'
                                              class='bg-gray-50  border border-gray-300 text-gray-900 text-sm rounded-lg block w-full px-4 py-2'
                                              required
                                            />
                                          </div>
                                          <div className='flex justify-end mt-1'>
                                            <button
                                              title='Save'
                                              type='button'
                                              onClick={e =>
                                                saveInfo(element, e)
                                              }
                                              className='text-white bgcolor px-3 py-0.5 rounded cursor-pointer text-sm'
                                            >
                                              {' '}
                                              Save
                                            </button>
                                          </div>
                                        </form>
                                      )
                                    }
                                    if (element.key === 'WhatsApp') {
                                      return (
                                        <form class='w-full mt-2'>
                                          <label
                                            for='text'
                                            class='block mb-1 text-base font-medium text-gray-900'
                                          >
                                            WhatsApp
                                          </label>
                                          <input
                                            value={state3}
                                            onChange={e =>
                                              setstate3(e.target.value)
                                            }
                                            type='text'
                                            id='text'
                                            class='bg-gray-50  border border-gray-300 text-gray-900 text-sm rounded-lg block w-full px-4 py-2'
                                            required
                                          />
                                          <div className='flex justify-end'>
                                            <button
                                              title='Save'
                                              type='button'
                                              onClick={e =>
                                                saveInfo(element, e)
                                              }
                                              className=' text-white bgcolor px-3 py-0.5 mt-1 rounded cursor-pointer text-sm'
                                            >
                                              {' '}
                                              Save
                                            </button>
                                          </div>
                                        </form>
                                      )
                                    }
                                  })
                                : null}

                              {appSettingList && appSettingList.length > 0
                                ? appSettingList.map((element, index) => {
                                    if (element.key === 'Marquee') {
                                      return (
                                        <form class='w-full mt-2'>
                                          <label
                                            for='text'
                                            class='block mb-1 text-base font-medium text-gray-900'
                                          >
                                            User Panal Marquee
                                          </label>
                                          <input
                                            value={state6}
                                            onChange={e =>
                                              setstate6(e.target.value)
                                            }
                                            type='text'
                                            id='text'
                                            class='bg-gray-50  border border-gray-300 text-gray-900 text-sm rounded-lg block w-full px-4 py-2'
                                            required
                                          />
                                          <div className='flex justify-end'>
                                            <button
                                              title='Save'
                                              type='button'
                                              onClick={e =>
                                                saveInfo(element, e)
                                              }
                                              className='text-white bgcolor px-3 py-0.5 mt-1 rounded cursor-pointer text-sm'
                                            >
                                              {' '}
                                              Save
                                            </button>
                                          </div>
                                        </form>
                                      )
                                    }
                                  })
                                : null}
                            </div>
                          </div>

                          <div className='flex-1 h-full bg-white border p-3 rounded-md'>
                            {appSettingList && appSettingList.length > 0
                              ? appSettingList.map((element, index) => {
                                  if (
                                    element &&
                                    element.key &&
                                    element.key === 'HowToPlay'
                                  ) {
                                    return (
                                      <div className=''>
                                        <div className='mb-1'>How To Play</div>
                                        <ReactQuill
                                          className='h-56 w-full pb-14'
                                          theme='snow'
                                          value={value}
                                          onChange={setValue}
                                        />
                                        <div className='flex justify-end'>
                                          <button
                                            className='bgcolor text-white mt-2 rounded px-3 py-0.5 text-base'
                                            onClick={e => saveInfo(element, e)}
                                          >
                                            Save
                                          </button>
                                        </div>
                                      </div>
                                    )
                                  }
                                })
                              : null}
                          </div>

                          <div className='flex-1 h-full bg-white border p-3 rounded-md'>
                            {appSettingList && appSettingList.length > 0
                              ? appSettingList.map((element, index) => {
                                  if (
                                    element &&
                                    element.key &&
                                    element.key === 'NOTICE'
                                  ) {
                                    return (
                                      <div className=''>
                                        <div className=' mb-1'>
                                          Withdraw Notice
                                        </div>
                                        <ReactQuill
                                          className='h-56 w-full pb-14'
                                          theme='snow'
                                          value={value2}
                                          onChange={setValue2}
                                        />
                                        <div className='flex justify-end'>
                                          <button
                                            className='bgcolor text-white mt-2 rounded px-3 py-0.5 text-base'
                                            onClick={e => saveInfo(element, e)}
                                          >
                                            Save
                                          </button>
                                        </div>
                                      </div>
                                    )
                                  }
                                })
                              : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CreateAppSettingModal
        createModal={createModal}
        handleCreateHideModal={handleCreateHideModal}
        handleFile={handleFile}
        inputChangeCreate={inputChangeCreate}
        fieldsAppSetting={fieldsAppSetting}
        errorsAppSetting={errorsAppSetting}
        createAppSettingSubmit={createAppSettingSubmit}
      />

      <UpdateAppSettingModal
        updateModal={updateModal}
        handleUpdateHideModal={handleUpdateHideModal}
        handleFile={handleFile}
        inputChange={inputChange}
        fieldsUpdate={fieldsUpdate}
        errorsUpdate={errorsUpdate}
        updateAppSettingSubmit={updateAppSettingSubmit}
      />

      <ViewAppSettingModal
        viewModal={viewModal}
        handleViewHideModal={handleViewHideModal}
        rowData={rowData}
      />
    </>
  )
}

export default AppSettingManagement
