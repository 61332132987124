/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { walletActions, userActions } from '../../_actions';
import 'react-confirm-alert/src/react-confirm-alert.css';


// import Table from '../../components/Atoms/Table/Table';


const AddFundReport = () => {
  const dispatch = useDispatch();;
  const [fieldsGame, setFieldsGame] = useState({});
  const [errorsGame, setErrorsGame] = useState({});
  const size = 10
  const pageNo = 1
  const { userList } = useSelector(state => state.users);


  useEffect(() => {
    let userReq = {
      "keyWord": "",
      "pageNo": pageNo,
      "sortBy": "slug",
      "sortOrder": "asc",
      "fromDate": "",
      "toDate": "",
      "size": size
    }
    dispatch(userActions.getUserList(userReq));
  }, []);

  const inputChangeCreate = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsGame(prevState => ({ ...prevState, [name]: value }));
    setErrorsGame(prevState => ({ ...prevState, [name]: "" }));
  };

  const createGameSubmit = (e) => {
    e.preventDefault();
    if (handleValidationCreateGame()) {
      const { userId, amount } = fieldsGame;
      dispatch(walletActions.createGames({
        userId: userId,
        amount: amount,
      }));
    }
  };

  const handleValidationCreateGame = () => {
    let formIsValid = true;
    let errors = {};

    if (!fieldsGame.userId || fieldsGame.userId === "") {
      formIsValid = false;
      errors.userId = "Select User";
    }

    if (!fieldsGame.amount || fieldsGame.amount === "") {
      formIsValid = false;
      errors.amount = "Enter amount";
    }

    setErrorsGame(errors);
    return formIsValid;
  };

  return (
    <>
      <div className="z-0 overflow-hidden overflow-y-auto focus:outline-none">
        <div className="relative">
          <div className="relative z-20 flex min-h-screen">
            <div className="flex w-full overflow-hidden ">
              <div className="flex flex-col flex-1 overflow-hidden">
                <main className="relative flex-1 ">
                  <div className="p-3 2xl:p-10 sm:p-5">
                    <div className="mx-auto max-w-screen-3xl">
                      <sectiion className="flex justify-between my-4 ">
                        <div className='text-xl font-semibold'>Dashboard{" "}/{" "}<span className='text-[#7D1970]'>Bid Revert</span></div>
                      </sectiion>

                      <div className="px-6 py-4 text-left bg-white space-y-2 rounded-lg">
                        <div className="flex items-center justify-between">
                          <span className="">Bid Revert</span>
                        </div>
                        <div className='flex justify-between w-full space-x-6'>
                          <div className="w-full mt-1 shadow-sm">
                            <label class="block text-gray-700 text-base mb-2" for="date">Date :</label>
                            <input className="w-full px-3 py-2 text-base bg-white border border-gray-400 rounded-lg focus:outline-none"
                              id="date" name="date" placeholder="Enter date" value={fieldsGame && fieldsGame["date"] ? fieldsGame["date"] : ""} type="date" onChange={inputChangeCreate} />
                            {errorsGame && errorsGame["date"] ?
                              <div className="text-yellow-600 invalid-feedback">
                                {errorsGame["date"]}
                              </div>
                              : null}
                          </div>
                          <div className="mt-1 shadow-sm w-full">
                            <label class="block text-gray-700 text-base mb-2" for="name">Game Name :</label>
                            <select className="w-full px-3 py-2 text-base bg-white border border-gray-400 rounded-lg focus:outline-none"
                              id="userId" name="userId" placeholder="name"
                              value={fieldsGame && fieldsGame["userId"] ? fieldsGame["userId"] : ""} type="text" onChange={inputChangeCreate} >
                              {userList && userList.length > 0 ? userList.map((element, index) => (
                                <option value={element && element._id ? element._id : ""}>{element && element.userName ? element.userName : ""}</option>
                              )) : null}
                            </select>
                            {errorsGame && errorsGame["userId"] ?
                              <div className="text-yellow-600 invalid-feedback">
                                {errorsGame["userId"]}
                              </div>
                              : null}
                          </div>
                          <div className="w-full flex justify-start items-end">
                            <button className="flex justify-center px-4 py-2 text-base font-semibold text-white capitalize  transition duration-150 ease-in-out bgcolor border border-[#7D1970] rounded-xl focus:outline-none" type="button" onClick={createGameSubmit}>Submit</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddFundReport;