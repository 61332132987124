/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IoWalletOutline } from "react-icons/io5";
import { reportActions, userActions, walletActions } from "../../_actions";
import ReactPaginate from "react-paginate";
import { BiEdit } from "react-icons/bi";
import ViewUserModal from "../userManagement/components/ViewUserModal/ViewUserModal";
import withdraw from "./withdraw.png";

const Ledgers = () => {
  let selector = useSelector((state) => state);
  const dispatch = useDispatch();
  let { games, reports } = selector ? selector : {};

  let { allUserList } = games ? games : {};
  const [errorsGame, setErrorsGame] = useState({});
  const size = 10;
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const keyWord = "";
  const fromDate = "";
  const toDate = "";
  const [Options, setOptions] = useState([]);
  useEffect(() => { 
    dispatch(reportActions.getLedger());
    dispatch(userActions.getAdminProfile());
  }, []);

  useEffect(() => {
    if (allUserList) {
      const options = allUserList?.map((user) => ({
        label: user.userName,
        value: user._id,
      }));
      setOptions(options);
    }
  }, [allUserList]); 
  const handlePageClick = (data) => {
    let offset = Math.ceil(data.selected * size);
    setOffset(offset);
    setCurrentPage(data.selected);
    let pageReq = {
      fromDate: fromDate,
      toDate: toDate,
      keyWord: keyWord,
      sortBy: "",
      sortOrder: "",
      pageNo: data.selected + 1,
      size: size,
      userId: [],
    };
    dispatch(reportActions.getAllEntries(pageReq));
  };



  let { getLedgerTotal, crdt } = reports ? reports : {};
  const [rowData, setrowData] = useState({});
  const [viewModal, setviewModal] = useState(false);
  const createGameSubmit4 = (e, data) => {
    setrowData({ ...e, key: data });

    setviewModal(true);
  };
  const handleViewHideModal = () => {
    setrowData({});
    setviewModal(false);
  };
  const handleChange = (e) => {
    setrowData({ ...rowData, [e.target.name]: e.target.value });
    setErrorsGame({ ...rowData, [e.target.name]: "" });
  };

  const handleFund = (e) => {
    e.preventDefault();

    if (handleValidationCreateGame()) {
      let obj = {
        userId: rowData?.userId,
        amount: rowData?.amount || 0,
      };
      if (rowData?.key === "Credit Reference") {
        dispatch(walletActions.creditReferenceAdd(obj));
      } else if (rowData?.key === "Withdraw Fund") {
        dispatch(walletActions.withdrawBalanceByAdmin(obj));
      } else {
        dispatch(walletActions.depositeBalanceByAdmin(obj));
      }

      setrowData({});
      setviewModal(false);
    }
  };

  const handleValidationCreateGame = () => {
    let formIsValid = true;
    let errors = {};

    if (!rowData.amount || rowData.amount === "") {
      formIsValid = false;
      errors.amount = "Enter Amount";
    }

    setErrorsGame(errors);
    return formIsValid;
  };

  let sessiondata = JSON.parse(window.sessionStorage.getItem("adminuser"));
  let { users } = selector ? selector : {};
  let { adminProfileDetails } = users ? users : {};
  let { balance } = adminProfileDetails ? adminProfileDetails : {};

  return (
    <>
      {/* <Loader loading={loading} /> */}
      <div className="z-0 overflow-hidden overflow-y-auto focus:outline-none">
        <div className="relative">
          <div className="relative z-20 flex min-h-screen">
            <div className="flex w-full overflow-hidden ">
              <div className="flex flex-col flex-1 overflow-hidden">
                <main className="relative flex-1 ">
                  <div className="p-3 2xl:p-10 sm:p-5">
                    <div className="mx-auto max-w-screen-3xl space-y-10">


                      <div className="px-6 py-4 text-left bg-white space-y-2 rounded-lg">
                        <div className="flex justify-between items-center px-3 py-2 border-b">
                          <span className="p-5 text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white ">
                            Ledger List
                          </span>
                          {sessiondata && sessiondata?.roleId !== 0 ? (
                            <span>
                              <span className=" flex  justify-center items-center bg-[#911212] text-white p-3 rounded-lg">
                                <IoWalletOutline className=" text-2xl" />
                                {(balance && balance) || "0"}
                              </span>
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="relative overflow-x-auto p-5">
                          <div className="flex justify-between items-center px-3 py-2 border-b">

                          </div>
                          <table className="w-full text-base text-left rtl:text-right text-gray-500 ">
                            <thead className="text-gray-900 capitalize bg-gray-50  ">
                              <tr>
                                <th className="px-6 py-3">S.No.</th>
                                {sessiondata && sessiondata?.roleId === 20 ? (
                                  <th className="px-6 py-3">Username </th>
                                ) : (
                                  <th className="px-6 py-3">
                                    Username (Share){" "}
                                  </th>
                                )}
                                <th className="px-6 py-3">Credit Reference</th>
                                {sessiondata && sessiondata?.roleId === 20 ? (
                                  <th className="px-6 py-3">Balance + Exp </th>
                                ) : (
                                  <th className="px-6 py-3">
                                    Balance + Exp + Downline Balance{" "}
                                  </th>
                                )}
                                {/* <th className="px-6 py-3">Profit/Loss ((Balance + Exp)  - Credit Reference)</th> */}
                                <th className="px-6 py-3">Profit/Loss</th>
                                <th className="px-6 py-3"></th>
                                {/* <th className="px-6 py-3">Exp</th> */}
                                {/* <th className="px-6 py-3">Date</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {crdt && crdt?.length > 0 && crdt ? (
                                crdt?.map((element, index) => (
                                  <React.Fragment key={index}>
                                    <tr
                                      key={index}
                                      className="bg-white border-b border-gray-400"
                                    >
                                      <th
                                        scope="row"
                                        className="px-6 py-4 font-medium text-gray-500 whitespace-nowrap "
                                      >
                                        {offset + index + 1}
                                      </th>

                                      <th
                                        scope="row"
                                        className="px-6 py-4 font-medium text-gray-500 whitespace-nowrap "
                                      >
                                        {sessiondata &&
                                          sessiondata?.roleId === 20 ? (
                                          <div className=" flex ">
                                            {element &&
                                              element.userName &&
                                              element?.userName
                                              ? element?.userName
                                              : "-"}
                                          </div>
                                        ) : (
                                          <div className=" flex ">
                                            {element &&
                                              element.userName &&
                                              element?.userName
                                              ? element?.userName
                                              : "-"}{" "}
                                            ({" "}
                                            {element &&
                                              element.share &&
                                              element?.share
                                              ? element?.share
                                              : "0"}
                                            )
                                          </div>
                                        )}
                                      </th>

                                      <th
                                        scope="row"
                                        className="px-6 py-4 font-medium text-gray-500 whitespace-nowrap "
                                      >
                                        <div className=" flex  ">
                                          {element &&
                                            element.creditReference &&
                                            element.creditReference
                                            ? element.creditReference
                                            : "0"}
                                          <BiEdit
                                            className=" text-lg ml-5"
                                            title="Credit Reference"
                                            onClick={() =>
                                              createGameSubmit4(
                                                element,
                                                "Credit Reference"
                                              )
                                            }
                                          />
                                        </div>
                                      </th>
                                      {sessiondata &&
                                        sessiondata.roleId === 20 ? (
                                        <th
                                          scope="row"
                                          className="px-6 py-4 font-medium text-gray-500 whitespace-nowrap "
                                        >
                                          {element &&
                                            element.balance &&
                                            element.balance
                                            ? element.balance
                                            : "0"}{" "}
                                          +{" "}
                                          {element && element.exp.toString()
                                            ? element?.exp.toString()
                                            : "0"}
                                        </th>
                                      ) : (
                                        <th
                                          scope="row"
                                          className="px-6 py-4 font-medium text-gray-500 whitespace-nowrap "
                                        >
                                          {element &&
                                            element.balance &&
                                            element.balance
                                            ? element.balance
                                            : "0"}{" "}
                                          +{" "}
                                          {element && element.exp.toString()
                                            ? element?.exp.toString()
                                            : "0"}{" "}
                                          +{" "}
                                          {element &&
                                            element.totalAmount &&
                                            element.totalAmount
                                            ? element.totalAmount
                                            : "0"}
                                        </th>
                                      )}

                                      {sessiondata &&
                                        sessiondata.roleId === 20 ? (
                                        <th
                                          scope="row"
                                          className="px-6 py-4 font-medium text-gray-500 whitespace-nowrap "
                                        >
                                          {element &&
                                            Number(element.balance ?? 0) +
                                            Number(element.exp ?? 0) -
                                            Number(
                                              element.creditReference ?? 0
                                            )}
                                        </th>
                                      ) : (
                                        <th
                                          scope="row"
                                          className="px-6 py-4 font-medium text-gray-500 whitespace-nowrap "
                                        >
                                          {element &&
                                            Number(element.balance ?? 0) +
                                            Number(element.exp ?? 0) +
                                            Number(element.totalAmount ?? 0) -
                                            Number(
                                              element.creditReference ?? 0
                                            )}
                                        </th>
                                      )}
                                      <th
                                        scope="row"
                                        className="px-6 py-4 font-medium text-guserNameray-500 whitespace-nowrap "
                                      >
                                        <div className=" flex  gap-3">
                                          {/* <img
                                            title="Add Fund"
                                            alt=""
                                            className=" cursor-pointer"
                                            onClick={() =>
                                              createGameSubmit4(
                                                element,
                                                "Add Fund"
                                              )
                                            }
                                            src={upload}
                                          /> */}
                                          <img
                                            title="Withdraw"
                                            alt=""
                                            className=" cursor-pointer"
                                            onClick={() =>
                                              createGameSubmit4(
                                                element,
                                                "Withdraw Fund"
                                              )
                                            }
                                            src={withdraw}
                                          />
                                        </div>
                                      </th>
                                    </tr>
                                  </React.Fragment>
                                ))
                              ) : (
                                <td>No Data Found</td>
                              )}
                            </tbody>
                          </table>
                        </div>
                        <nav className="relative z-0 flex justify-end mt-5 w-76">
                          {getLedgerTotal && getLedgerTotal > size ? ( // Assuming `size` is the number of items per page
                            <ReactPaginate
                              previousLabel={"Prev"}
                              nextLabel={"Next"}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={Math.ceil(getLedgerTotal / size)} // Calculate total pages
                              marginPagesDisplayed={1} // Show 1 page before and after current page
                              pageRangeDisplayed={1} // Show up to 5 pages in pagination
                              onPageChange={handlePageClick}
                              containerClassName={"pagination"}
                              pageClassName={"page-cls"}
                              activeClassName={"active"}
                              forcePage={currentPage}
                            />
                          ) : null}
                        </nav>
                      </div>
                    </div>
                  </div>
                </main>
              </div>
            </div>
          </div>
        </div>
        <ViewUserModal
          viewModal={viewModal}
          rowData={rowData}
          handleViewHideModal={handleViewHideModal}
          handleFund={handleFund}
          errorsGame={errorsGame}
          handleChange={handleChange}
        />
      </div>
    </>
  );
};

export default Ledgers;
