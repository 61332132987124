/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DashboardJSON from './AppSettingManagement.json'
import { bankActions } from '../../_actions'
import CreateAppSettingModal from './components/CreateAppSettingModal/CreateAppSettingModal'
import UpdateAppSettingModal from './components/UpdateAppSettingModal/UpdateAppSettingModal'
import ViewAppSettingModal from './components/ViewAppSettingModal/ViewAppSettingModal'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import ReactPaginate from 'react-paginate'
import { isMobile } from 'react-device-detect'
import Loader from '../../components/Loader/Loader'
import { MdOutlineDelete } from 'react-icons/md'
import { FiEdit } from 'react-icons/fi'

const size = 10
const BankDetails = () => {
  const dispatch = useDispatch()
  let selector = useSelector(state => state)
  const [currentPage3, setCurrentPage3] = useState(0)
  let [updateModal, setUpdateModal] = useState(false)
  let [createModal, setCreateModal] = useState(false)
  let [viewModal, setViewModal] = useState(false)
  const [fieldsUpdate, setFieldsUpdate] = useState({})
  const [errorsUpdate, setErrorsUpdate] = useState({})
  const [fieldsAppSetting, setFieldsAppSetting] = useState({})
  const [errorsAppSetting, setErrorsAppSetting] = useState({})
  const [offset, setOffset] = useState(0)
  const [pageNo, setPageNo] = useState(1)

  useEffect(() => {
    let appsettingReq = {
      keyWord: '',
      pageNo: pageNo,
      size: size
    }
    dispatch(bankActions.getAdminBankDetailList(appsettingReq))
  }, [])

  const handleCreateOpenModal = () => {
    setCreateModal(true)
  }

  const handleCreateHideModal = data => {
    setCreateModal(false)
    setFieldsAppSetting({})
    setErrorsAppSetting({})
  }

  const handleUpdateOpenModal = data => {
    setFieldsUpdate(data)
    setUpdateModal(true)
  }

  const handleUpdateHideModal = data => {
    setUpdateModal(false)
    setErrorsUpdate({})
  }
  const handleViewHideModal = data => {
    setViewModal(false)
  }

  const inputChange = e => {
    e.preventDefault()
    const { name, value } = e.target
    setFieldsUpdate(prevState => ({ ...prevState, [name]: value }))
    setErrorsUpdate(prevState => ({ ...prevState, [name]: '' }))
  }

  const inputChangeCreate = e => {
    e.preventDefault()
    const { name, value } = e.target
    setFieldsAppSetting(prevState => ({ ...prevState, [name]: value }))
    setErrorsAppSetting(prevState => ({ ...prevState, [name]: '' }))
  }

  const updateAppSettingSubmit = e => {
    e.preventDefault()
    if (handleValidationUpdateAppSetting()) {
      let appsettingPageRefresh = {
        keyWord: '',
        pageNo: pageNo,
        size: size
      }
      let Object = {
        id: fieldsUpdate?._id,
        accountNo: fieldsUpdate?.accountNo && parseInt(fieldsUpdate?.accountNo),
        name: fieldsUpdate?.name.trim(),
        ifsc: fieldsUpdate?.ifsc.trim(),
        bankName: fieldsUpdate?.bankName.trim(),
        upiId: fieldsUpdate?.upiId.trim()
      }
      dispatch(bankActions.updateAdminBankDetail(Object, appsettingPageRefresh))
      handleUpdateHideModal()
    }
  }

  const handleValidationUpdateAppSetting = () => {
    let formIsValid = true
    let errors = {}

    if (!fieldsUpdate.name || fieldsUpdate.name.trim() === '') {
      formIsValid = false
      errors.name = "Please enter account holder's name"
    }

    if (!fieldsUpdate.bankName || fieldsUpdate.bankName.trim() === '') {
      formIsValid = false
      errors.bankName = 'Please Enter Bank name'
    }

    if (!fieldsUpdate.ifsc || fieldsUpdate.ifsc.trim() === '') {
      formIsValid = false
      errors.ifsc = 'Please Enter IFSC Code'
    }
    if (!fieldsUpdate.accountNo || fieldsUpdate.accountNo === '') {
      formIsValid = false
      errors.accountNo = 'Please Enter Account Number'
    }
    if (!fieldsUpdate.upiId || fieldsUpdate.upiId.trim() === '') {
      formIsValid = false
      errors.upiId = 'Please Enter UPI Id'
    }

    setErrorsUpdate(errors)
    return formIsValid
  }

  const createAppSettingSubmit = e => {
    e.preventDefault()
    let appsettingReq = {
      keyWord: '',
      pageNo: pageNo,
      size: size
    }

    if (handleValidationCreateAppSetting()) {
      let appsettingPageRefresh = {
        accountNo:
          (fieldsAppSetting &&
            fieldsAppSetting.acno &&
            parseInt(fieldsAppSetting.acno)) ||
          0,
        name: (fieldsAppSetting && fieldsAppSetting.name.trim()) || '',
        ifsc: (fieldsAppSetting && fieldsAppSetting.value.trim()) || '',
        bankName: (fieldsAppSetting && fieldsAppSetting.key.trim()) || '',
        upiId: (fieldsAppSetting && fieldsAppSetting.upi.trim()) || ''
      }
      dispatch(
        bankActions.addAdminBankDetail(appsettingPageRefresh, appsettingReq)
      )
      setFieldsAppSetting({})
      setErrorsAppSetting({})
      handleCreateHideModal()
    }
  }

  const handleValidationCreateAppSetting = () => {
    let formIsValid = true
    let errors = {}

    if (!fieldsAppSetting.name || fieldsAppSetting.name.trim() === '') {
      formIsValid = false
      errors.name = DashboardJSON.ErrorMsg.name
    }

    if (!fieldsAppSetting.key || fieldsAppSetting.key.trim() === '') {
      formIsValid = false
      errors.key = DashboardJSON.ErrorMsg.key
    }

    if (!fieldsAppSetting.value || fieldsAppSetting.value.trim() === '') {
      formIsValid = false
      errors.value = DashboardJSON.ErrorMsg.value
    }
    if (!fieldsAppSetting.acno || fieldsAppSetting.acno === '') {
      formIsValid = false
      errors.acno = 'Please enter Account No.'
    }
    if (!fieldsAppSetting.upi || fieldsAppSetting.upi.trim() === '') {
      formIsValid = false
      errors.upi = 'Please enter UPI Id.'
    }

    setErrorsAppSetting(errors)
    return formIsValid
  }

  const disableAppSetting = data => {
    let appsettingIdReq = {
      id: data._id
    }
    let appsettingPageRefresh = {
      keyWord: '',
      pageNo: pageNo,
      size: size
    }
    confirmAlert({
      title: 'Confirm to disable Bank?',
      message: `Are you sure you want to disable ${data.name}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () =>
            dispatch(
              bankActions.bankstatusUpdate(
                appsettingIdReq,
                appsettingPageRefresh
              )
            )
        },
        {
          label: 'No'
        }
      ]
    })
  }

  const deleteAppSetting = data => {
    let appsettingIdReq = {
      id: data._id
    }

    let appsettingPageRefresh = {
      keyWord: '',
      pageNo: pageNo,
      size: size
    }

    confirmAlert({
      title: 'Confirm to Delete?',
      message: `Are you sure you want  to delete ${data.name}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () =>
            dispatch(
              bankActions.deleteAdminBankDetail(
                appsettingIdReq,
                appsettingPageRefresh
              )
            )
        },
        {
          label: 'No'
        }
      ]
    })
  }

  const handleFile = event => {
    if (event.target.files[0]) {
      dispatch(
        bankActions.uploadImage(
          event.target.files[event.target.files.length - 1]
        )
      )
    } else {
      console.log('No File To Upload!')
    }
  }

  const handlePageClick = data => {
    let offset = Math.ceil(data.selected * size)
    setOffset(offset)
    setCurrentPage3(data.selected)
    setPageNo(data.selected + 1)
    let pageReq = {
      keyWord: '',
      pageNo: data.selected + 1,
      size: size
    }
    dispatch(bankActions.getAdminBankDetailList(pageReq))
  }

  let { bank } = selector ? selector : {}
  let { loading, adminBankDetail } = bank ? bank : {}
  let { total, list } = adminBankDetail ? adminBankDetail : {}

  return (
    <>
      <Loader loading={loading} />
      <div className='z-0 overflow-hidden overflow-y-auto focus:outline-none'>
        <div className='relative'>
          <div className='relative z-20 flex min-h-screen'>
            <div className='flex w-full overflow-hidden '>
              <div className='flex flex-col flex-1 overflow-hidden'>
                <main className='relative flex-1 '>
                  <div className='p-3 2xl:p-10 sm:p-5'>
                    <div className='mx-auto max-w-screen-3xl'>
                      <sectiion className='flex justify-between my-4 '>
                        <div>
                          <p className='text-2xl font-bold'>Bank Management</p>
                        </div>
                        <div>
                          <span className='relative '>
                            <button
                              className='px-4 py-2 font-medium tracking-wider text-white bg-blue-700 border rounded-md shadow-sm hover:shadow-lg hover:bg-blue-400'
                              onClick={() => handleCreateOpenModal()}
                            >
                              Add
                            </button>
                          </span>{' '}
                          &nbsp;
                        </div>
                      </sectiion>

                      <div>
                        <div className='relative overflow-x-auto shadow-md sm:rounded-lg'>
                          <table className='w-full text-base text-left rtl:text-right text-gray-500 '>
                            {/* <caption className="p-5 text-lg font-semibold text-left rtl:text-right text-gray-900 bg-white  ">
                              Our products
                            </caption> */}
                            <thead className='text-base text-gray-800 uppercase bg-gray-50  '>
                              <tr>
                                <th scope='col' className='px-6 py-3'>
                                  S.No.
                                </th>
                                <th scope='col' className='px-6 py-3'>
                                  Account Holder Name
                                </th>
                                <th
                                  scope='col'
                                  className='px-6 py-3 whitespace-nowrap'
                                >
                                  Bank Name
                                </th>
                                <th scope='col' className='px-6 py-3'>
                                  IFSC
                                </th>
                                <th scope='col' className='px-6 py-3'>
                                  Upi Id
                                </th>
                                {/* <th scope="col" className="px-6 py-3">Upi Id</th> */}
                                <th
                                  scope='col'
                                  className='px-6 py-3 text-right'
                                >
                                  action
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {list && list.length > 0
                                ? list.map((element, index) => (
                                    <React.Fragment key={index}>
                                      <tr
                                        key={index}
                                        className={
                                          index % 2 === 0
                                            ? 'bg-white border-b'
                                            : 'bg-gray-100  '
                                        }
                                      >
                                        <th
                                          scope='row'
                                          className='px-6 py-4 font-medium text-gray-500 whitespace-nowrap '
                                        >
                                          {offset + index + 1}
                                        </th>
                                        <th
                                          scope='row'
                                          className='px-6 py-4 font-medium text-gray-500 whitespace-nowrap '
                                        >
                                          {element && element.name
                                            ? element.name
                                            : '-'}
                                        </th>
                                        <th
                                          scope='row'
                                          className='px-6 py-4 font-medium text-gray-500 whitespace-nowrap '
                                        >
                                          {element && element.bankName
                                            ? element.bankName
                                            : '-'}
                                        </th>
                                        <th
                                          scope='row'
                                          className='px-6 py-4 font-medium text-gray-500 whitespace-nowrap '
                                        >
                                          {element && element.ifsc
                                            ? element.ifsc
                                            : '-'}
                                        </th>
                                        <th
                                          scope='row'
                                          className='px-6 py-4 font-medium text-gray-500 whitespace-nowrap '
                                        >
                                          {element && element.upiId
                                            ? element.upiId
                                            : '-'}
                                        </th>
                                        <td className='px-6 py-4 flex justify-end items-center   gap-1'>
                                          <span className='relative '>
                                            <button
                                              className={
                                                element.isDisable === false
                                                  ? 'px-4 py-2 border border-green-700  bg-green-700 text-white rounded-2xl'
                                                  : 'px-4 py-2 border border-red-700 text-white bg-red-700 rounded-2xl'
                                              }
                                              onClick={() =>
                                                disableAppSetting(element)
                                              }
                                            >
                                              {element &&
                                              element.isDisable === false
                                                ? 'Yes'
                                                : 'No'}
                                            </button>
                                          </span>
                                          <span className='relative '>
                                            <FiEdit
                                              onClick={() =>
                                                handleUpdateOpenModal(element)
                                              }
                                              title='Edit'
                                              className=' text-2xl text-blue-600 cursor-pointer'
                                            />
                                          </span>{' '}
                                          &nbsp; &nbsp;
                                          <span className='relative '>
                                            <MdOutlineDelete
                                              onClick={() =>
                                                deleteAppSetting(element)
                                              }
                                              title='Delete'
                                              className=' text-2xl text-red-600 cursor-pointer'
                                            />
                                          </span>{' '}
                                          &nbsp;
                                        </td>
                                      </tr>
                                    </React.Fragment>
                                  ))
                                : null}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  {isMobile ? (
                    <nav className='relative z-0 flex justify-end mt-5 w-76'>
                      {total && total > 10 ? (
                        <ReactPaginate
                          previousLabel={'Prev'}
                          nextLabel={'Next'}
                          breakLabel={'...'}
                          breakClassName={'break-me'}
                          pageCount={total / size}
                          marginPagesDisplayed={1}
                          pageRangeDisplayed={1}
                          onPageChange={handlePageClick}
                          containerClassName={'pagination'}
                          pageClassName={'page-cls'}
                          activeClassName={'active'}
                          forcePage={currentPage3}
                        />
                      ) : null}
                    </nav>
                  ) : (
                    <nav className='relative z-0 flex justify-end mt-5 w-76'>
                      {total && total > 10 ? (
                        <ReactPaginate
                          previousLabel={'Previous'}
                          nextLabel={'Next'}
                          breakLabel={'...'}
                          breakClassName={'break-me'}
                          pageCount={total / size}
                          marginPagesDisplayed={3}
                          pageRangeDisplayed={3}
                          onPageChange={handlePageClick}
                          containerClassName={'pagination'}
                          pageClassName={'page-cls'}
                          activeClassName={'active'}
                          forcePage={currentPage3}
                        />
                      ) : null}
                    </nav>
                  )}
                </main>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CreateAppSettingModal
        createModal={createModal}
        handleCreateHideModal={handleCreateHideModal}
        handleFile={handleFile}
        inputChangeCreate={inputChangeCreate}
        fieldsAppSetting={fieldsAppSetting}
        errorsAppSetting={errorsAppSetting}
        createAppSettingSubmit={createAppSettingSubmit}
      />

      <UpdateAppSettingModal
        updateModal={updateModal}
        handleUpdateHideModal={handleUpdateHideModal}
        handleFile={handleFile}
        inputChange={inputChange}
        fieldsUpdate={fieldsUpdate}
        errorsUpdate={errorsUpdate}
        updateAppSettingSubmit={updateAppSettingSubmit}
      />

      <ViewAppSettingModal
        viewModal={viewModal}
        handleViewHideModal={handleViewHideModal}
      />
    </>
  )
}

export default BankDetails
