/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { FaRupeeSign } from "react-icons/fa";
import moment from "moment";
import { gamesActions, walletActions, userActions, appSettingActions } from "../../_actions";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import Loader from "../../components/Loader/Loader";
import ViewGameModal from "./ViewGameModal";
import ChangePasswordModel from "./ChangePasswordModel";
import { FaUsers } from "react-icons/fa";
import { SiMonogame } from "react-icons/si";
import { GiBabyfootPlayers } from "react-icons/gi";
import { BiSolidUserX, BiSolidUserCheck } from "react-icons/bi";
import { CgProfile } from "react-icons/cg";
import { LuBarChart4 } from "react-icons/lu";
const size = 10;
const pageNo = 1;
const Dashboard = () => {
  const selector = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { gamesList, karachiGamesList } = useSelector((state) => state.games);
  const today = new Date().toISOString().split("T")[0];
  const { adminProfileDetails } = useSelector((state) => state.users);
  let [rowData, setRowData] = useState(null);
  let [viewModal, setViewModal] = useState(false);

  const [gameIds, setgameIds] = useState("");
  const [marketIds, setmarketIds] = useState("");
  const [fromDates, setfromDates] = useState("");
  const [openmodel, setopenmodel] = useState(false);

  let sessiondata = JSON.parse(window.sessionStorage.getItem("adminuser"));
  let [localStoragedata, setlocalStoragedata] = useState();

  useEffect(() => {
    const stateData = window.sessionStorage.getItem("adminuser");
    setlocalStoragedata(JSON.parse(stateData));

    const fetchData = async () => {
      let gameReq = {
        fromDate: "",
        toDate: "",
        keyWord: "",
        sortBy: "",
        sortOrder: "",
        pageNo: pageNo,
        size: 1000,
      };

      dispatch(gamesActions.getAllGames(gameReq));
      dispatch(gamesActions.getAllKarachiGames(gameReq));
      dispatch(userActions.getAdminProfile());

      let paymentListReq = {
        type: "DEPOSIT",
        fromDate: "",
        toDate: "",
        keyWord: "",
        sortBy: "",
        sortOrder: "",
        pageNo: pageNo,
        size: size,
      };

      dispatch(walletActions.getPaymentListForAdmin(paymentListReq));
      dispatch(userActions.getTotalCount());
      dispatch(gamesActions.getAllMarket());

      let obj = {
        gameId: gameIds,
        marketId: marketIds,
        fromDate: fromDates,
      };
      dispatch(walletActions.getBetsTotalCount(obj));
      let appsettingReq = {
        "keyWord": "",
        "pageNo": pageNo,
        "size": size
      }
      dispatch(appSettingActions.getAppSettingList(appsettingReq));
    };
    fetchData();
  }, []);

  const handleChange = (data) => {
    navigate(`/app/placebet/${data?._id}/${data?.name}`)
  }

  const handleKarachiChange = (data) => {
    navigate(`/app/KarachiPlaceBet/${data?._id}/${data?.name}`)
  }



  const createGameSubmit = (e) => {
    e.preventDefault();

    let obj = {
      gameId: gameIds || "",
      marketId: marketIds || "",
      fromDate: fromDates || "",
    };
    dispatch(walletActions.getBetsTotalCount(obj));
  };

  const deleteGame2 = (data, elem) => {
    let gameIdReq = {
      paymentId: data._id,
      status: elem,
    };

    let gamePageRefresh = {
      fromDate: "",
      toDate: "",
      keyWord: "",
      sortBy: "",
      sortOrder: "",
      type: "DEPOSIT",
      pageNo: pageNo,
      size: size,
    };

    confirmAlert({
      title: "Confirm to Change Status?",
      message: `Are you sure you want to change Status of ${data?.userId?.userName}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () =>
            dispatch(
              gamesActions.approvePaymentByAdmin(gameIdReq, gamePageRefresh)
            ),
        },
        {
          label: "No",
        },
      ],
    });
    setViewModal(false);
  };

  const handleViewHideModal = (data) => {
    setViewModal(false);
    setRowData({});
  };

  let { users, wallet } = selector ? selector : {};

  let { games } = selector ? selector : {};
  let { allMarket } = games ? games : {};
  let { totalCountBet } = wallet ? wallet : {};
  let { totalCounts } = users ? users : {};
  let { loading } = users ? users : {};

  return (
    <>
      <Loader loading={loading} />
      <div className=" bg-[#911212] text-white mt-1 flex justify-center items-center">
      </div>
      <div className="z-0 overflow-hidden overflow-y-auto focus:outline-none">
        <div className="relative">
          <div className="relative z-20 flex min-h-screen">
            <div className="flex w-full overflow-hidden ">
              <div className="flex flex-col flex-1 overflow-hidden">
                <main className="relative flex-1 mx-4">
                  <div className="p-3  sm:p-5">
                    <div className="mx-auto max-w-screen-3xl space-y-3">
                      {localStoragedata?.roleId === 0 ?
                        <div className="w-full grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-3">
                          <div to="/app/userbidhistory" className="w-full dashboard-card flex justify-between items-center rounded-xl md:p-8 p-5">
                            <div className="w-full flex flex-col justify-center items-start">
                              <p className="text-white font-semibold">My Level :{" "} <span className="text-white font-semibold"> {adminProfileDetails?.adminData?.roleId === 0 && "Super Admin"}
                                {adminProfileDetails?.adminData?.roleId === 1 && "Admin"}
                                {adminProfileDetails?.adminData?.roleId === 2 && "Super MASTER"}
                                {adminProfileDetails?.adminData?.roleId === 3 && "MASTER"}
                                {adminProfileDetails?.adminData?.roleId === 4 && "AGENT"}
                                {adminProfileDetails?.adminData?.roleId === 20 && "OPERATOR"}</span></p>
                            </div>
                            <div className="bgcolor w-12 h-12 flex justify-center items-center px-3 rounded-full">
                              <CgProfile className="w-6 h-6 text-white" />
                            </div>
                          </div>

                          <div className="w-full dashboard-card flex justify-between items-center rounded-xl md:p-8 p-5">
                            <div className="w-full flex flex-col justify-center items-start">
                              <span className="text-white font-semibold">Total Users</span>
                              <span className="font-semibold text-white text-xl">
                                {totalCounts?.data?.totalUserCount || 0}
                              </span>
                            </div>
                            <div className="bgcolor w-12 h-12 px-3 flex justify-center items-center rounded-full">
                              <FaUsers className="w-7 h-7 text-white font-semibold" />
                            </div>
                          </div>

                          <div className="w-full dashboard-card flex justify-between items-center rounded-xl md:p-8 p-5">
                            <div className="w-full flex flex-col justify-center items-start">
                              <span className="text-white font-semibold">Active Users</span>
                              <span className="font-semibold text-white text-xl">
                                {totalCounts?.data &&
                                  totalCounts?.data?.totalActiveUsers
                                  ? totalCounts?.data?.totalActiveUsers
                                  : 0}
                              </span>
                            </div>
                            <div className="bgcolor w-12 h-12 flex justify-center items-center px-3 rounded-full">
                              <BiSolidUserCheck className="w-6 h-6 text-white font-semibold" />
                            </div>
                          </div>

                          <div className="w-full dashboard-card flex justify-between items-center rounded-xl md:p-8 p-5">
                            <div className="w-full flex flex-col justify-center items-start">
                              <span className="text-white font-semibold">Inactive Users</span>
                              <span className="font-semibold text-white  text-xl">
                                {totalCounts?.data &&
                                  totalCounts?.data?.totalDeactiveUsers
                                  ? totalCounts?.data?.totalDeactiveUsers
                                  : 0}
                              </span>
                            </div>
                            <div className="bgcolor w-12 h-12 flex justify-center items-center px-3 rounded-full">
                              <BiSolidUserX className="w-7 h-7 text-white font-semibold" />
                            </div>
                          </div>

                          <Link to="/app/game" className="w-full dashboard-card flex justify-between items-center rounded-xl md:p-8 p-5 cursor-pointer">
                            <div className="w-full flex flex-col justify-center items-start">
                              <span className="text-white font-semibold">Games</span>
                              <span className="font-semibold text-white text-xl">
                                {totalCounts?.data?.totalGameCount || 0}
                              </span>
                            </div>
                            <div className="bgcolor w-12 h-12 flex justify-center items-center px-3 rounded-full">
                              <SiMonogame className="w-6 h-6 text-white font-semibold" />
                            </div>
                          </Link>

                          <Link to="/app/userbidhistory" className="w-full dashboard-card flex justify-between items-center rounded-xl md:p-8 p-5">
                            <div className="w-full flex flex-col justify-center items-start">
                              <span className="text-white font-semibold">Players (Today)</span>
                              <span className="font-semibold text-white text-xl">
                                {totalCounts?.data?.todaysPlayerCount || 0}
                              </span>
                            </div>
                            <div className="bgcolor w-12 h-12 flex justify-center items-center px-3 rounded-full">
                              <GiBabyfootPlayers className="w-6 h-6 text-white" />
                            </div>
                          </Link>

                          {sessiondata && sessiondata.roleId !== 0 &&
                            <div to="/app/userbidhistory" className="w-full bg-white flex justify-between items-center rounded-xl md:p-8 p-5 border border-[#5c202031]">
                              <div className="w-full flex flex-col justify-center items-start">
                                <span>
                                  Balance :{" "}
                                  {adminProfileDetails &&
                                    adminProfileDetails?.adminData &&
                                    adminProfileDetails?.adminData && adminProfileDetails?.balance?.toString()}
                                </span>
                              </div>
                              <div className="bgcolor w-12 h-12 flex justify-center items-center p-4 rounded-full text-white">
                                <FaRupeeSign />
                              </div>
                            </div>}
                        </div>
                        :
                        null
                      }
                      {sessiondata && sessiondata.roleId !== 1 && (
                        <div className="bg-white rounded-xl  border border-[#a11c1c]">
                          <div className="flex justify-between items-center px-3 border-b">
                            <span className="p-1 text-lg text-left font-bold text-gray-900 bg-white">Bids Report</span>
                          </div>

                          <div className="p-5 space-y-2">
                            <div className="grid lg:grid-cols-4 gap-4 md:grid-cols-2 grid-cols-1">
                              <div className="w-full">
                                <label class="block text-gray-700 text-base mb-1" for="name">Date</label>
                                <input className="w-full px-3 py-1.5 text-base bg-white border border-gray-400 rounded focus:outline-none" id="fromDate" name="fromDate" value={fromDates} max={today} type="date" onChange={(e) => setfromDates(e.target.value)} />
                              </div>
                              <div className="w-full">
                                <label class="block text-gray-700 text-base mb-1" for="name">Game Name</label>
                                <select className="w-full px-3 py-2 text-base capitalize bg-white border border-gray-400 rounded focus:outline-none" id="userId" name="userId" placeholder="name" value={gameIds} type="text" onChange={(e) => setgameIds(e.target.value)}>
                                  <option value="">Select Game</option>
                                  {gamesList && gamesList.length > 0 ? gamesList.map((element, index) => (<option value={element && element._id ? element._id : ""}   >     {element && element.name ? element.name : ""}</option>)) : null}
                                </select>
                              </div>
                              <div className="w-full">
                                <label class="block text-gray-700 text-base mb-1" for="name">  Market Name</label>
                                <select className="w-full px-3 py-2 text-base bg-white border border-gray-400 rounded focus:outline-none" id="userId" name="userId" placeholder="name" value={marketIds} type="text" onChange={(e) => setmarketIds(e.target.value)}>
                                  <option value="">Select Market</option>
                                  {allMarket && allMarket.length > 0 ? allMarket.map((element, index) => (<option value={element && element._id ? element._id : ""}>  {element && element.name ? element.name : ""}</option>)) : null}
                                </select>
                              </div>
                              <div className="w-full pt-6">
                                <button className="px-8 mt-1 py-1 w-full text-base font-semibold text-white capitalize  all-btn all-btn:hover" type="button" onClick={createGameSubmit}>Submit</button>
                              </div>
                            </div>
                          </div>
                        </div>)}

                      {sessiondata && sessiondata.roleId !== 1 && (
                        <div className="bg-white w-full grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-5 p-5 rounded-xl border border-[#a11c1c]">
                          <div className="w-full space-y-2">
                            <div className="flex justify-between items-center border-[1px] border-dashed border-[#7c24248a] p-2 ">
                              <span className="font-medium">
                                Total Bets Count
                              </span>
                              <span className="font-medium">
                                {totalCountBet?.data?.totalBets || 0}
                              </span>
                            </div>
                            <div className="flex justify-between items-center border-[1px] border-dashed border-[#7c24248a] 46] p-2">
                              <span className="font-medium">
                                Total Bet Amount
                              </span>
                              <span className="font-medium">
                                {totalCountBet?.data?.totalWinAmount || 0}
                              </span>
                            </div>
                          </div>
                          <div className="w-full space-y-2">
                            <div className="flex justify-between items-center border-[1px] border-dashed border-[#7c24248a] p-2">
                              <span className="font-medium">Total user Winning</span>
                              <span className="font-medium">
                                {totalCountBet?.data?.totalWins || 0}
                              </span>
                            </div>
                            <div className="flex justify-between items-center border-[1px] border-dashed border-[#7c24248a] p-2">
                              <span className="font-medium">Total user Loss</span>
                              <span className="font-medium">
                                {totalCountBet?.data?.totalLosses || 0}
                              </span>
                            </div>
                          </div>
                        </div>
                      )}


                      {localStoragedata?.roleId === 1 ?
                        <>
                          <p className="text-base lg:text-lg font-bold">Game List</p>
                          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4">
                            {gamesList && gamesList.length > 0
                              ? (() => {
                                const currentTime = moment().hours() * 3600 + moment().minutes() * 60 + moment().seconds(); // Current time in seconds 
                                return gamesList.map((element, index) => {
                                  if (!element?.brandGame) {
                                    const isButtonDisabled = currentTime < element.openTime || currentTime > element.closingTime || element?.isGameClose; // Disable condition

                                    return (
                                      <div key={index} class="block text-base rounded-2xl bg-white text-center text-surface shadow-secondary-1 text-black border border-slate-300">
                                        <div class={`flex justify-between items-center text-white font-semibold px-2 py-2  rounded-t-2xl border-b-2 border-neutral-100 ${isButtonDisabled ? 'bgcolor' : 'bggreen'}`}>
                                          <p className="text-base">Open Time : <span> {element && element.openTime ? moment().hours(parseInt(element.openTime / 3600)).minutes(parseInt((element.openTime % 3600) / 60)).seconds(parseInt(element.openTime % 60)).milliseconds(0).format("h:mm A") : "-"}</span></p>
                                          <p className="text-base">Close Time : <span>{element && element.closingTime ? moment().hours(parseInt(element.closingTime / 3600)).minutes(parseInt((element.closingTime % 3600) / 60)).seconds(parseInt(element.closingTime % 60)).milliseconds(0).format("h:mm A") : "-"}</span></p>
                                        </div>
                                        <div class="flex justify-between items-center p-3">
                                          <div className="textcolor cursor-pointer" onClick={() => handleChange(element)}>
                                            <LuBarChart4 className="w-8 h-8" />
                                          </div>
                                          <div>
                                            <h5 class="mb-2 text-base font-medium leading-tight capitalize cursor-pointer hover:underline textcolor" onClick={() => handleChange(element)}>{element && element.name ? element.name : "-"}</h5>
                                            <p class="mb-2 text-base">XXX-XX-XXX</p>
                                            <p class={`text-base capitalize ${isButtonDisabled ? 'text-red-600' : 'text-green-600'}`}>{isButtonDisabled ? 'Market Stop' : 'Market Running'}</p>
                                          </div>
                                          <div onClick={!isButtonDisabled ? () => handleChange(element) : undefined}>
                                            <h5 class={`mb-2 text-base font-medium leading-tight capitalize border px-3 py-1 rounded-full ${isButtonDisabled ? 'text-gray-400 border border-red-600 textcolor cursor-not-allowed' : 'border border-green-600 cursor-pointer'}`}>{isButtonDisabled ? 'Stop' : 'Running'}</h5>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                });
                              })()
                              : null}
                          </div>
                          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-4">
                            {karachiGamesList && karachiGamesList.length > 0
                              ? (() => {
                                const currentTime = moment().hours() * 3600 + moment().minutes() * 60 + moment().seconds(); // Current time in seconds 
                                return karachiGamesList.map((element, index) => {

                                  const isButtonDisabled = currentTime < element.openTime || currentTime > element.closingTime; // Disable condition 
                                  return (!element?.brandGame &&
                                    <div key={index} class="block text-base rounded-2xl bg-white text-center text-surface shadow-secondary-1 text-black border border-slate-300">
                                      <div class={`flex justify-between items-center text-white font-semibold px-2 py-2  rounded-t-2xl border-b-2 border-neutral-100 ${isButtonDisabled ? 'bgcolor' : 'bggreen'}`}>
                                        <p className="text-base">Open Time : <span> {element && element.openTime ? moment().hours(parseInt(element.openTime / 3600)).minutes(parseInt((element.openTime % 3600) / 60)).seconds(parseInt(element.openTime % 60)).milliseconds(0).format("h:mm A") : "-"}</span></p>
                                        <p className="text-base">Close Time : <span>{element && element.closingTime ? moment().hours(parseInt(element.closingTime / 3600)).minutes(parseInt((element.closingTime % 3600) / 60)).seconds(parseInt(element.closingTime % 60)).milliseconds(0).format("h:mm A") : "-"}</span></p>
                                      </div>
                                      <div class="flex justify-between items-center p-3">
                                        <div className="textcolor cursor-pointer" onClick={() => handleChange(element)}>
                                          <LuBarChart4 className="w-8 h-8" />
                                        </div>
                                        <div>
                                          <h5 class="mb-2 text-base font-medium leading-tight capitalize cursor-pointer hover:underline textcolor" onClick={() => handleChange(element)}>{element && element.name ? element.name : "-"}</h5>
                                          <p class="mb-2 text-base">XXX-XX-XXX</p>
                                          <p class={`text-base capitalize ${isButtonDisabled ? 'text-red-600' : 'text-green-600'}`}>{isButtonDisabled ? 'Market Stop' : 'Market Running'}</p>
                                        </div>
                                        <div onClick={!isButtonDisabled ? () => handleKarachiChange(element) : undefined}>
                                          <h5 class={`mb-2 text-base font-medium leading-tight capitalize border px-3 py-1 rounded-full ${isButtonDisabled ? 'text-gray-400 border border-red-600 textcolor cursor-not-allowed' : 'border border-green-600 cursor-pointer'}`}>{isButtonDisabled ? 'Stop' : 'Running'}</h5>
                                        </div>
                                      </div>
                                    </div>
                                  );

                                });
                              })()
                              : null}
                          </div>
                        </>
                        : null}
                    </div>
                  </div>
                </main>

              </div>
            </div>
          </div>
        </div>
      </div>

      <ChangePasswordModel openmodel={openmodel} setopenmodel={setopenmodel} />
      <ViewGameModal
        rowData={rowData}
        viewModal={viewModal}
        handleViewHideModal={handleViewHideModal}
        deleteGame2={deleteGame2}
      />
    </>
  );
};

export default Dashboard;
